import { React, useEffect, useState, useMemo } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Pressable,
  Hidden,
  useBreakpointValue,
  Flex,
} from "native-base";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { aw, ah } from "../../../utils";
import "react-phone-input-2/lib/material.css";
import PhoneInput from "react-phone-input-2";
//components
import LeadsWatchSearch from "../../../components/LeadsWatchSearch";
import LeadsWatchTable from "../../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../../components/LeadsWatchButton";
import LeadsWatchFilterInput from "../../../components/LeadsWatchFilterInput";
import LeadsWatchModal from "../../../components/LeadsWatchModal";
//svg
import LeadsWatchSearchIcon from "../../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchCrossIcon from "../../../components/svg/LeadsWatchCrossIcon";
import LeadsWatchAddIcon from "../../../components/svg/LeadsWatchAddIcon";
//redux
import {
  getAllContacts,
  removeContactDetails,
} from "../../../store/actions/crmcontacts";

const CrmContacts = ({ isOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //states from redux
  const contacts = useSelector((state) => state.crmcontacts.allContacts);
  const profile = useSelector((state) => state.auth.profile);
  //component states
  const [phoneNo, setPhoneNo] = useState(null);
  const [clearSearch, setClearSearch] = useState(false);
  const [sort, setSort] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [refresh, setRefresh] = useState(false);

  //pagination
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [currentPage, setCurrentPage] = useState(
    page && page.crmcontacts ? parseInt(page.crmcontacts) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.crmcontacts ? parseInt(pagecount.crmcontacts) : 10
  );
  //responsive
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  //form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
  });
  const { control: control1 } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.crmcontacts
          ? pagecount.crmcontacts.toString()
          : "10",
    },
  });
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    getValues: getValues2,
  } = useForm({
    mode: "all",
  });
  const [search, setSearch] = useState(getValues2("search"));
  //change no of entries
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, crmcontacts: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, crmconatcts: 1 }));
  };
  //search
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      data.search !== "" && setClearSearch(true);
      localStorage.setItem("page", JSON.stringify({ ...page, crmcontacts: 1 }));
    }
  };
  //Table functions
  //sort
  const handleSort = (order) => {
    setSort(order);
  };

  //fetch contacts
  const fetchContacts = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllContacts(currentPage, selectCount, sort, filters, search)
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["crm"] &&
        profile.permission_ids["crm"].includes("view")
      ) {
        fetchContacts();
      } else {
        navigate("/settings/profile");
      }
    } else {
      fetchContacts();
    }
  }, [currentPage, selectCount, sort, filters, search]);
  useEffect(() => {
    dispatch(removeContactDetails());
  }, []);
  const handleView = (id) => {
    // console.log(id);
    navigate(`view/${id}`);
  };

  //children components memonization
  const memoizedChildComponent = useMemo(() => {
    return (
      <LeadsWatchTable
        isOpen={isOpen}
        sort={sort}
        screen="templates"
        handleView={handleView}
        handleSort={handleSort}
        isLoading={isLoading}
        heading={["Email", "Phone", "pipeline_name", "crm_status", "Actions"]}
        sortIcon={true}
        data={contacts && contacts.list ? contacts.list : null}
      />
    );
  }, [isLoading, isOpen]);
  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <VStack
            marginTop={ah(7)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              lineHeight={ah(34)}
              justifyContent={"space-between"}
              space={aw(8)}
              alignItems={"flex-end"}
            >
              <HStack
                alignItems={"baseline"}
                // width={aw(194)}
                justifyContent="space-between"
                space={aw(8)}
              >
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily="RobotoMedium"
                >
                  Contacts
                </Text>
                <Text
                  top={ah(20)}
                  fontSize={aw(18)}
                  color={"#919191"}
                  fontFamily="RobotoMedium"
                >
                  {contacts && contacts.total_count ? contacts.total_count : 0}
                </Text>
              </HStack>
              <Hidden only={["sm", "base"]}>
                {/* <HStack space={aw(10)}>
                 
                </HStack> */}
              </Hidden>
            </HStack>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All your Contacts list will be shown here
            </Text>

            <Divider
              mt={ah(9)}
              bg="#E6E7E9"
              height={ah(1)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            />
          </VStack>
          <VStack height={ah(35)} mt={ah(15)}>
            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control1}
                    name={"select"}
                    list={["10", "20", "50", "100"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    // width={aw(80)}
                    width={79}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>
              <Hidden only={["sm", "base"]}>
                <HStack space={aw(12)}>
                  <LeadsWatchSearch
                    placeholder={[
                      'Search by "Name"',
                      'Search by "Company"',
                      'Search by "Email"',
                      'Search by "Phone"',
                      'Search by "Status"',
                    ]}
                    height={ah(38)}
                    control={control2}
                    name="search"
                    onFocus={() => setClearSearch(false)}
                    inputRightElement={
                      clearSearch ? (
                        <Pressable
                          w={aw(20)}
                          onPress={() => {
                            reset2();
                            setSearch(getValues2("search"));
                            setClearSearch(false);
                            setCurrentPage(1);
                            localStorage.setItem(
                              "page",
                              JSON.stringify({ ...page, crmcontacts: 1 })
                            );
                          }}
                        >
                          <LeadsWatchCrossIcon />
                        </Pressable>
                      ) : (
                        <Pressable
                          w={aw(30)}
                          onPress={handleSubmit2(handleSearch)}
                          // pr={aw(10)}
                        >
                          <LeadsWatchSearchIcon />
                        </Pressable>
                      )
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit2(
                          handleSearch({ search: getValues2("search") })
                        );
                      }
                    }}
                  />

                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "crm"
                        ? true
                        : profile && profile.role === "user"
                        ? profile.permission_ids["crm"].includes("create")
                          ? false
                          : true
                        : false
                    }
                    onPress={() => {
                      setIsModalOpen(true);
                    }}
                    label="Create"
                    style={{ width: aw(101), height: ah(38) }}
                    bg="#2230F8"
                    borderColor="#2230F8"
                    color="#FFFFFF"
                    leftIcon={<LeadsWatchAddIcon />}
                  />
                </HStack>
              </Hidden>
            </HStack>
          </VStack>

          <VStack mt={ah(15)}>
            <VStack>{memoizedChildComponent}</VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={
                  contacts && contacts.total_count ? contacts.total_count : 1
                }
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, crmcontacts: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>
      <LeadsWatchModal
        width={aw(400)}
        height={ah(470)}
        showModal={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setRefresh(!refresh);
          reset({});
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Add Contact
          </Text>
        }
      >
        <VStack px={aw(5)} space={aw(20)}>
          <Flex
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Flex width={"48%"}>
              <LeadsWatchFilterInput
                refresh={refresh}
                screen={
                  getValues("firstName") && getValues("firstName").lengtg > 0
                    ? "edit"
                    : "create"
                }
                placeholder="Enter FirstName"
                control={control}
                name="firstName"
                rules={{
                  required: {
                    value: true,
                    message: "FirstName is required",
                  },
                  pattern: {
                    // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                    message:
                      "FirstName should start with alphabets and can not contain special characters",
                  },
                }}
                invalid={errors.firstName ? true : false}
                error={errors.firstName ? errors.firstName.message : ""}
              />
            </Flex>
            <Flex width={"48%"}>
              <LeadsWatchFilterInput
                // width={"48%"}
                refresh={refresh}
                screen={
                  getValues("lastName") && getValues("lastName").lengtg > 0
                    ? "edit"
                    : "create"
                }
                placeholder="Enter LastName"
                control={control}
                name="lastName"
                rules={{
                  required: {
                    value: true,
                    message: "lastName is required",
                  },
                  pattern: {
                    // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                    message:
                      "LastName should start with alphabets and can not contain special characters",
                  },
                }}
                invalid={errors.FirstName ? true : false}
                error={errors.FirstName ? errors.FirstName.message : ""}
              />
            </Flex>
          </Flex>
          <VStack zIndex={1}>
            <Controller
              control={control}
              name={"phone"}
              // style={{
              //   width: aw(330),
              //   heigth: ah(40),
              // }}
              render={({ field: { onChange, onBlur, value } }) => (
                <PhoneInput
                  className={"phoneInput"}
                  country={"in"}
                  isValid={(inputNumber, country, countries) => {
                    setPhoneNo(country);
                    return !errors.phone;
                  }}
                  placeholder="Enter PhoneNumber"
                  value={value}
                  containerStyle={{
                    borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",
                  }}
                  inputStyle={{
                    borderWidth: errors.phone ? 2 : 1,
                    borderRadius: aw(5),
                    width: "100%",
                    height: ah(7),
                    fontFamily: "RobotoRegular",
                    backgroundColor: "#fff",
                    // borderWidth: 0,
                    fontSize: aw(14),
                    borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",

                    // borderColor: !errors.phone && "#D1D5DB",
                  }}
                  onChange={(value, data) => {
                    onChange(value);
                    // onChange(value);
                  }}
                  onBlur={() => {
                    onBlur();
                  }}
                  dropdownStyle={{
                    height: ah(130),
                    width: aw(330),
                  }}
                />
              )}
              // rules={{
              //   required: {
              //     value: true,
              //     // validate: (value) =>
              //     //   phoneNo &&
              //     //   phoneNo.format.match(/\./g).length === value.length,
              //     message: "Enter a valid password",
              //     validate: (value) =>
              //       value.length === phoneNo.format.match(/\./g).length,
              //   },
              // }}
              rules={{
                //   required: {
                //     value: getValues("email") ? true : false,
                //   },
                validate: (value) => {
                  if (getValues("email")) {
                    return (
                      value &&
                      value.length === phoneNo.format.match(/\./g).length
                    );
                  }
                },
              }}
              invalid={errors.phone ? true : false}
              // invalid={true}
              error={errors.phone ? errors.phone.message : ""}
            />
            {errors.phone && (
              <Text
                color="#FA4E2D"
                fontFamily="RobotoRegular"
                fontSize={aw(12)}
                pt={ah(2)}
              >
                {"Please enter a valid phone number"}
              </Text>
            )}
          </VStack>
          <LeadsWatchFilterInput
            refresh={refresh}
            screen={
              getValues("email") && getValues("email").lengtg > 0
                ? "edit"
                : "create"
            }
            placeholder="Enter Email"
            control={control}
            name="email"
            rules={{
              required: {
                value: true,
                message: "Email is required",
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Please Enter Valid Email",
              },
            }}
            invalid={errors.firstName ? true : false}
            error={errors.firstName ? errors.firstName.message : ""}
          />

          <HStack justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              style={{ height: ah(32), width: aw(80) }}
              label="Cancel"
              onPress={() => {
                setIsModalOpen(false);
                setRefresh(!refresh);
                reset({});
              }}
            />
            <LeadsWatchButton
              // isLoading={loading}
              // onPress={handleSubmit(addPipeline)}
              label="Add"
              style={{ height: ah(32), width: aw(80) }}
              bg="#0000FF"
              color="#fff"
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};
export default CrmContacts;
