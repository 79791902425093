import React from "react";
import { FormControl, Select } from "native-base";
import LeadsWatchDownArrow from "./svg/LeadsWatchDownArrow";
import { aw, ah } from "../utils";
import { Controller } from "react-hook-form";
const LeadsWatchSelect = (props) => {
  return (
    <FormControl
      width={props.width}
      height={props.height}
      // isRequired={props.error&&props.isRequired}
      isInvalid={props.invalid}
      borderColor={props.borderColor || "#D1D5DB"}
      // isRequired isInvalid
      // _invalid={{
      //   borderColor: "#0000FF",
      // }}
    >
      <Controller
        control={props.control}
        rules={props.rules}
        name={props.name}
        style={{
          zIndex: 1,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            // isHovered={false}
            // isFocused={false}
            bgColor={props.bgColor || "white"}
            isFocusVisible={false}
            {...props}
            borderRadius={props.borderRadius}
            textProps={{ fontFamily: "RobotoMedium", fontSize: aw(14) }}
            color="#212121"
            borderColor={props.borderColor || "#D1D5DB"}
            // rounded={5}
            _customDropdownIconProps={{
              color: "muted.500",
              size: aw(10),
            }}
            // isInvalid={props.invalid}
            isDisabled={props.isDisabled}
            // isHovered={props.isHovered}
            // isFocused={props.isFocused}
            // isFocused={true}

            selectedValue={value}
            onValueChange={(value) => {
              onChange(value);
              if (props.handleChange) {
                props.handleChange(value);
              }
            }}
            accessibilityLabel={props.accessibilityLabel}
            placeholder={props.placeholder}
            _focus={{
              bgColor: "white",
              borderColor: "#0000FF",
              // borderWidth: aw(1),
            }}
            _hover={{
              borderColor: "#0000FF",
            }}
            placeholderTextColor={props.placeholderTextColor || "#919191"}
            fontFamily={props.fontFamily || "RobotoRegular"}
            fontSize={props.fontSize || aw(12)}
            _selectedItem={{
              bg: "white",
              endIcon: <LeadsWatchDownArrow />,
            }}
            variant={props.variant}
            h={props.height || ah(32)}
            // width={props.width}
            _actionSheetBody={{
              paddingBottom: ah(30),

              _contentContainerStyle: {
                padding: aw(5),
                margin: aw(5),
                paddingBottom: ah(30),
                // borderColor: "red.500",
                borderWidth: aw(1),
              },
            }}
            _actionSheet={{
              paddingBottom: ah(30),
              marginBottom: ah(30),
              left: aw(30),
              right: aw(30),
            }}
            _actionSheetContent={{
              // paddingLeft: aw(30),
              paddingRight: aw(30),
              marginLeft: aw(30),
              marginRight: aw(30),
              left: aw(30),
              right: aw(30),
            }}
            _actionSheetBodyContent={{
              paddingLeft: aw(30),
              paddingRight: aw(30),
              marginLeft: aw(30),
              marginRight: aw(30),
            }}
          >
            {props.type === "array"
              ? props.list.map((val) => (
                  <Select.Item
                    p={aw(30)}
                    margin={aw(30)}
                    key={val.label}
                    value={val.value}
                    // label={<Text>{val}</Text>}
                    label={val.label}
                  />
                ))
              : props.list.map((val) => (
                  <Select.Item
                    p={aw(30)}
                    margin={aw(30)}
                    key={val}
                    value={
                      val === "none"
                        ? ""
                        : props.notToLowerCase
                        ? val
                        : val.toLowerCase()
                    }
                    // label={<Text>{val}</Text>}
                    label={val}
                  />
                ))}
          </Select>
        )}
      />
      <FormControl.ErrorMessage>{props.error}</FormControl.ErrorMessage>
    </FormControl>
  );
};
export default LeadsWatchSelect;
