import * as events from "../Events";
const initialState = {
  allTemplates: null,
};
export const crmtemplates = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_CRMTEMPLATES:
      return {
        ...state,
        allTemplates: action.data,
      };
    case events.CREATE_CRMTEMPLATES: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
