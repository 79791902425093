import React from "react";
import { aw, ah } from "../../utils";

function LeadsWatchInvoiceIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
    >
      <g
        data-name="Group 1107"
        transform="translate(-2826 -294) translate(2826 294)"
      >
        <g
          fill={
            props.disabled
              ? "#C1C1C1"
              : props.hover === "true"
              ? "#2230F8"
              : "#212121"
          }
        >
          <path
            d="M20.831 3.169A4.031 4.031 0 0018 2H5.669A3.669 3.669 0 002 5.669v3a4 4 0 004 4h2V20a2 2 0 002 2h10a2 2 0 002-2V6a3.975 3.975 0 00-1.169-2.831zM8 11.331H6a2.669 2.669 0 01-2.669-2.669V5.669a2.334 2.334 0 114.669 0zM20.669 20a.669.669 0 01-.669.669H10A.669.669 0 019.331 20V5.669a3.65 3.65 0 00-.837-2.331H18a2.687 2.687 0 011.875.781 2.65 2.65 0 01.781 1.875z"
            data-name="Path 10382"
          ></path>
          <path
            d="M25.47 25.47h2.749v1.371H25.47z"
            data-name="Path 10383"
            transform="translate(-8.961 -8.833)"
          ></path>
          <path
            d="M25.47 20.13h2.749v1.37H25.47z"
            data-name="Path 10384"
            transform="translate(-8.961 -6.823)"
          ></path>
          <path
            d="M15.87 20.13h4.12v1.37h-4.12z"
            data-name="Path 10385"
            transform="translate(-5.266 -6.823)"
          ></path>
          <path
            d="M25.47 15.87h2.749v1.371H25.47z"
            data-name="Path 10386"
            transform="translate(-8.961 -5.22)"
          ></path>
          <path
            d="M15.87 15.87h4.12v1.371h-4.12z"
            data-name="Path 10387"
            transform="translate(-5.266 -5.22)"
          ></path>
          <path
            d="M15.87 10.53h4.12v1.37h-4.12z"
            data-name="Path 10388"
            transform="translate(-5.266 -3.21)"
          ></path>
        </g>
        <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 894"></path>
      </g>
    </svg>
  );
}

export default LeadsWatchInvoiceIcon;
