import React, { useState, useEffect } from "react";
import {
  HStack,
  VStack,
  Text,
  Stack,
  Pressable,
  FlatList,
  Flex,
} from "native-base";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
//utils
import { aw, ah } from "../../utils";
//components
import LeadsWatchCard from "../../components/LeadsWatchCard";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchModal from "../../components/LeadsWatchModal";

const DataFormats = (props) => {
  const { select, setSelect, isOpen } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      select: "10",
      data_formats_Date: "MM-DD-YYYY",
      data_formats_Time: "HH-MM-SS",
      data_formats_Phone: "+91123456780",
    },
    mode: "all",
  });
  const [openFormatModal, setOpenFormatModal] = useState(false);
  const allVerticalFields = useSelector(
    (state) => state.verticals.allVerticalFields
  );
  const [editField, setEditField] = useState("");

  const dateVerticalFields = [];
  const timeVerticalFields = [];
  const phoneVerticalFields = [];

  allVerticalFields &&
    allVerticalFields.list.forEach((val) => {
      if (val.format_type === "date") {
        dateVerticalFields.push(val);
      } else if (val.format_type === "time") {
        timeVerticalFields.push(val);
      } else if (val.format_type === "phone") {
        phoneVerticalFields.push(val);
      }
    });

  //formated array for formats
  const formatedArray = (array1, array2) => {
    const newArray = new Map(array2.map((val) => [val.map_field, val]));
    const combinedArray = array1.map((val) => {
      const matched = newArray.get(val.name);
      if (matched) {
        return { ...val, ...matched };
      } else {
        return val;
      }
    });
    return combinedArray;
  };

  //change Format
  const handleChangeFormat = (item, type) => {
    setOpenFormatModal(true);
    setEditField({ ...item, type });
  };
  useEffect(() => {
    if (editField) {
      reset({
        current_format:
          editField.type === "date"
            ? editField.value
              ? editField.value
              : "MM-DD-YYYY"
            : editField.type === "time"
            ? editField.value
              ? editField.value
              : "HH-MM-SS"
            : editField.value
            ? editField.value
            : "+91123456780",
      });
    }
  }, [editField]);
  const handleUpdateField = (data) => {
    setOpenFormatModal(false);
    const newData = {
      value: data.new_format && data.new_format.value,
      key: editField.type,
      map_field: editField.name,
    };

    const existingData = select.route_formats.find(
      (val) => val.map_field === newData.map_field
    );

    if (existingData) {
      Object.assign(existingData, newData);
    } else {
      setSelect({
        ...select,
        route_formats: [...select.route_formats, newData],
      });
    }
  };
  return (
    <>
      <LeadsWatchCard
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        height={ah(1000)}
      >
        <VStack
          pb={6}
          justifyContent={"center"}
          px={aw(15)}
          width={"100%"}
          space={ah(20)}
        >
          <HStack width={"100%"} height={ah(40)} marginTop={ah(18)}>
            <Text
              style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
              color="#121233"
            >
              Data Formats
            </Text>
          </HStack>
          <VStack>
            <Text
              style={{ fontFamily: "RobotoMedium", fontSize: aw(14) }}
              color="#121233"
              px={aw(10)}
            >
              Date
            </Text>
            <VStack width={"100%"}>
              <HStack
                alignItems={"center"}
                style={{
                  height: ah(60),
                  background: "#F9FAFB 0% 0% no-repeat padding-box",
                  border: "1px solid #D7D7D7",
                  borderTopLeftRadius: aw(10),
                  borderTopRightRadius: aw(10),
                  borderBottomWidth: 0,
                }}
              >
                {["Vertical Field", "Format", "Change Format"].map((val) => {
                  return (
                    <HStack
                      key={val}
                      alignItems={"center"}
                      width={
                        val === "Format"
                          ? aw(450)
                          : val === "Vertical Field"
                          ? aw(400)
                          : aw(350)
                      }
                      justifyContent="flex-start"
                    >
                      <Text
                        width={aw(290)}
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                        ml={aw(20)}
                        color="#121233"
                      >
                        {val}
                      </Text>
                    </HStack>
                  );
                })}
              </HStack>
              <FlatList
                borderColor="#D7D7D7"
                borderBottomRightRadius={aw(10)}
                borderBottomLeftRadius={aw(10)}
                borderWidth={1}
                borderTopWidth={0}
                maxHeight={ah(200)}
                ListEmptyComponent={
                  <HStack
                    justifyContent="center"
                    alignItems={"center"}
                    height={ah(190)}
                  >
                    <Text
                      color="black"
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                    >
                      No data found
                    </Text>
                  </HStack>
                }
                data={
                  dateVerticalFields
                    ? formatedArray(dateVerticalFields, select.route_formats)
                    : []
                }
                keyExtractor={(item, index) => item.name}
                renderItem={({ item, index }) => (
                  <VStack>
                    <HStack
                      height={ah(60)}
                      alignItems={"center"}
                      bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                      style={{
                        borderBottomWidth:
                          index === dateVerticalFields.length - 1
                            ? "0px"
                            : "1px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      <Text
                        color="#121233"
                        width={aw(400)}
                        ml={aw(20)}
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                      >
                        {item.name}
                      </Text>
                      <Stack width={aw(450)}>
                        <Text
                          color="#121233"
                          width={aw(400)}
                          // ml={aw(20)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoRegular"}
                        >
                          {item.value || "MM-DD-YYYY"}
                        </Text>
                      </Stack>

                      <Pressable
                        width={aw(140)}
                        onPress={() => handleChangeFormat(item, "date")}
                      >
                        <Text
                          color="#2230F8"
                          width={aw(400)}
                          // ml={aw(20)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                        >
                          Change Format
                        </Text>
                      </Pressable>
                    </HStack>
                  </VStack>
                )}
              />
            </VStack>
          </VStack>
          <VStack>
            <Text
              style={{ fontFamily: "RobotoMedium", fontSize: aw(14) }}
              color="#121233"
              px={aw(10)}
            >
              Time
            </Text>
            <VStack width={"100%"}>
              <HStack
                alignItems={"center"}
                style={{
                  height: ah(60),
                  background: "#F9FAFB 0% 0% no-repeat padding-box",
                  border: "1px solid #D7D7D7",
                  borderTopLeftRadius: aw(10),
                  borderTopRightRadius: aw(10),
                  borderBottomWidth: 0,
                }}
              >
                {["Vertical Field", "Format", "Change Format"].map((val) => {
                  return (
                    <HStack
                      key={val}
                      alignItems={"center"}
                      width={
                        val === "Format"
                          ? aw(450)
                          : val === "Vertical Field"
                          ? aw(400)
                          : aw(350)
                      }
                      justifyContent="flex-start"
                    >
                      <Text
                        width={aw(290)}
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                        ml={aw(20)}
                        color="#121233"
                      >
                        {val}
                      </Text>
                    </HStack>
                  );
                })}
              </HStack>
              <FlatList
                borderColor="#D7D7D7"
                borderBottomRightRadius={aw(10)}
                borderBottomLeftRadius={aw(10)}
                borderWidth={1}
                borderTopWidth={0}
                maxHeight={ah(200)}
                ListEmptyComponent={
                  <HStack
                    justifyContent="center"
                    alignItems={"center"}
                    height={ah(190)}
                  >
                    <Text
                      color="black"
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                    >
                      No data found
                    </Text>
                  </HStack>
                }
                data={
                  dateVerticalFields
                    ? formatedArray(timeVerticalFields, select.route_formats)
                    : []
                }
                keyExtractor={(item) => item.name}
                renderItem={({ item, index }) => (
                  <VStack>
                    <HStack
                      height={ah(60)}
                      alignItems={"center"}
                      bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                      style={{
                        borderBottomWidth:
                          index === timeVerticalFields.length - 1
                            ? "0px"
                            : "1px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      <Text
                        color="#121233"
                        width={aw(400)}
                        ml={aw(20)}
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                      >
                        {item.name}
                      </Text>
                      <Stack width={aw(450)}>
                        <Text
                          color="#121233"
                          width={aw(400)}
                          // ml={aw(20)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoRegular"}
                        >
                          {item.value || "HH-MM-SS"}
                        </Text>
                      </Stack>

                      <Pressable
                        width={aw(140)}
                        onPress={() => handleChangeFormat(item, "time")}
                      >
                        <Text
                          color="#2230F8"
                          width={aw(400)}
                          // ml={aw(20)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                        >
                          Change Format
                        </Text>
                      </Pressable>
                    </HStack>
                  </VStack>
                )}
              />
            </VStack>
          </VStack>
          <VStack>
            <Text
              style={{ fontFamily: "RobotoMedium", fontSize: aw(14) }}
              color="#121233"
              px={aw(10)}
            >
              Phone
            </Text>
            <VStack
              width={"100%"}
              // borderRadius={"10px"}
              // borderColor={"#D7D7D7"}
            >
              <HStack
                alignItems={"center"}
                style={{
                  height: ah(60),
                  background: "#F9FAFB 0% 0% no-repeat padding-box",
                  border: "1px solid #D7D7D7",
                  borderTopLeftRadius: aw(10),
                  borderTopRightRadius: aw(10),
                  borderBottomWidth: 0,
                }}
              >
                {["Vertical Field", "Format", "Change Format"].map((val) => {
                  return (
                    <HStack
                      key={val}
                      alignItems={"center"}
                      width={
                        val === "Format"
                          ? aw(450)
                          : val === "Vertical Field"
                          ? aw(400)
                          : aw(350)
                      }
                      justifyContent="flex-start"
                    >
                      <Text
                        width={aw(290)}
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                        ml={aw(20)}
                        color="#121233"
                      >
                        {val}
                      </Text>
                    </HStack>
                  );
                })}
              </HStack>
              <FlatList
                borderColor="#D7D7D7"
                borderBottomRightRadius={aw(10)}
                borderBottomLeftRadius={aw(10)}
                borderWidth={1}
                borderTopWidth={0}
                maxHeight={ah(200)}
                ListEmptyComponent={
                  <HStack
                    justifyContent="center"
                    alignItems={"center"}
                    height={ah(190)}
                  >
                    <Text
                      color="black"
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                    >
                      No data found
                    </Text>
                  </HStack>
                }
                // data={phoneVerticalFields}
                data={
                  dateVerticalFields
                    ? formatedArray(phoneVerticalFields, select.route_formats)
                    : []
                }
                keyExtractor={(item, index) => item.name}
                renderItem={({ item, index }) => (
                  <VStack>
                    <HStack
                      height={ah(60)}
                      alignItems={"center"}
                      bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                      style={{
                        borderBottomWidth:
                          index === phoneVerticalFields.length - 1
                            ? "0px"
                            : "1px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      <Text
                        color="#121233"
                        width={aw(400)}
                        ml={aw(20)}
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                      >
                        {item.name}
                      </Text>
                      <Stack width={aw(450)}>
                        <Text
                          color="#121233"
                          width={aw(400)}
                          // ml={aw(20)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoRegular"}
                        >
                          {item.value || "+91123456780"}
                        </Text>
                      </Stack>

                      <Pressable
                        width={aw(140)}
                        onPress={() => handleChangeFormat(item, "phone")}
                      >
                        <Text
                          color="#2230F8"
                          width={aw(400)}
                          // ml={aw(20)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                        >
                          Change Format
                        </Text>
                      </Pressable>
                    </HStack>
                  </VStack>
                )}
              />
            </VStack>
          </VStack>
        </VStack>
      </LeadsWatchCard>
      <LeadsWatchModal
        height={ah(590)}
        width={aw(400)}
        showModal={openFormatModal}
        onClose={() => {
          setOpenFormatModal(false);
        }}
        heading={
          <Text color={"#000000"} fontFamily={"RobotoMedium"} fontSize={aw(18)}>
            {editField && editField.name}
          </Text>
        }
      >
        <Flex gap={ah(20)}>
          <LeadsWatchFilterInput
            isReadOnly={true}
            screen={
              getValues("current_format") &&
              getValues("current_format").length > 0
                ? "edit"
                : "create"
            }
            control={control}
            name={"current_format"}
            placeholder="Currrent Format"
          />
          <LeadsWatchAsyncSelect
            screen={
              getValues("new_format") && getValues("new_format").value
                ? "edit"
                : "create"
            }
            control={control}
            name={"new_format"}
            placeholder="New Format"
            defaultOptions={
              editField && editField.type === "date"
                ? [
                    { value: "YEARS", label: "YEARS" },
                    {
                      value: "MM-DD-YYYY",
                      label: "MM-DD-YYYY",
                    },
                    {
                      value: "DD-MM-YYYY",
                      label: "DD-MM-YYYY",
                    },
                    {
                      value: "YYYY-MM-DD",
                      label: "YYYY-MM-DD",
                    },
                    {
                      value: "MM/DD/YYYY",
                      label: "MM/DD/YYYY",
                    },
                    {
                      value: "DD/MM/YYYY",
                      label: "DD/MM/YYYY",
                    },
                    {
                      value: "YYYY/MM/DD",
                      label: "YYYY/MM/DD",
                    },

                    {
                      value: "YY-MM-DD",
                      label: "YY-MM-DD",
                    },
                    {
                      value: "MM-DD-YY",
                      label: "MM-DD-YY",
                    },

                    {
                      value: "DD-MM-YY",
                      label: "DD-MM-YY",
                    },
                  ]
                : editField && editField.type === "time"
                ? [
                    {
                      value: "HH-MM-SS",
                      label: "HH-MM-SS",
                    },
                    {
                      value: "SS-MM-HH",
                      label: "SS-MM-HH",
                    },
                    {
                      value: "SS:MM:HH",
                      label: "SS:MM:HH",
                    },
                    {
                      value: "HH:MM:SS",
                      label: "HH:MM:SS",
                    },
                  ]
                : [
                    {
                      value: "1(555)-555-5555",
                      label: "1(555)-555-5555",
                    },
                    {
                      value: "123-123-1234",
                      label: "123-123-1234",
                    },
                    {
                      value: "9090909090",
                      label: "9090909090",
                    },
                  ]
            }
            rules={{
              required: {
                value: true,
                message: "New Format is required",
              },
            }}
            invalid={errors.new_format ? true : false}
            error={errors.new_format ? errors.new_format.message : ""}
          />
          <Flex justifyContent="flex-end" direction="row" gap={aw(20)}>
            <LeadsWatchButton
              onPress={() => {
                setOpenFormatModal(false);
                setEditField("");
              }}
              label="Cancel"
              style={{ width: aw(100), height: ah(36) }}
            />
            <LeadsWatchButton
              onPress={handleSubmit(handleUpdateField)}
              label="Update"
              style={{ width: aw(100), height: ah(36) }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </Flex>
        </Flex>
      </LeadsWatchModal>
    </>
  );
};

export default DataFormats;
