import { React, useState, useEffect, useMemo } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Stack,
  Avatar,
  Pressable,
  Hidden,
  Flex,
  useBreakpointValue,
} from "native-base";
import { get, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
// import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/material.css";
import { toast } from "react-toastify";
import { aw, ah } from "../../utils";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchCrossIcon from "../../components/svg/LeadsWatchCrossIcon";
import {
  getAllGroups,
  createGroup,
  editGroup,
  deleteGroup,
} from "../../store/actions/groups";
import { getAllVerticals } from "../../store/actions/verticals";

const Groups = ({ isOpen }) => {
  const navigate = useNavigate();
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [refresh, setRefresh] = useState(false);
  const [type, setType] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const profile = useSelector((state) => state.auth.profile);
  const [openCreateGroup, setOpenCreateGroup] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const allGroups = useSelector((state) => state.groups.allGroups);
  const allVerticals = useSelector((state) => state.verticals.allVerticals);
  const [currentPage, setCurrentPage] = useState(
    page && page.buyers ? parseInt(page.buyers) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.groups ? parseInt(pagecount.groups) : 10
  );
  const [isLoading, setIsLoading] = useState(true);
  const [edit, setEdit] = useState("");
  const [sort, setSort] = useState({});
  const [clearSearch, setClearSearch] = useState(false);
  const dispatch = useDispatch();
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,

    getValues,
  } = useForm({
    defaultValues: {
      select:
        pagecount && pagecount.groups ? pagecount.groups.toString() : "10",
    },
    mode: "all",
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,

    getValues: getValues1,
    reset: reset1,
  } = useForm({
    mode: "all",
    // defaultValues: { select: "10" },
  });
  const [search, setSearch] = useState(getValues1("search"));
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, groups: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, groups: 1 }));
  };

  const fetchGroups = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllGroups(currentPage, selectCount, undefined, search, sort)
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["buyer"] &&
        profile.permission_ids["buyer"].includes("view")
      ) {
        fetchGroups();
      } else {
        navigate("/settings/profile");
      }
    } else {
      fetchGroups();
    }
  }, [currentPage, selectCount, sort, search]);

  const handleSort = (order) => {
    setSort(order);
  };

  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);

      data.search !== "" && setClearSearch(true);
      localStorage.setItem("page", JSON.stringify({ ...page, groups: 1 }));
    }
  };
  const handleCreateGroup = async (data) => {
    setGroupLoading(true);
    if (edit) {
      try {
        await dispatch(
          editGroup(edit.id, {
            vertical_id: data.vertical_id.value,
            vertical_name: data.vertical_id.label,
            name: data.name,
            status: data.status.value,
            type: data.type.value,
          })
        );
        // await dispatch(getAllGroups(currentPage, selectCount));

        fetchGroups();

        setOpenCreateGroup(false);
        reset({
          select:
            pagecount && pagecount.groups ? pagecount.groups.toString() : "10",
          vertical_id: "",
          type: "",
          name: "",
        });
        setEdit("");
        toast.success("Group Updated Successfully");
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    } else {
      try {
        await dispatch(
          createGroup({
            vertical_id: data.vertical_id.value,
            vertical_name: data.vertical_id.label,
            name: data.name,
            type: data.type.value,
          })
        );
        if (currentPage !== 1) {
          setCurrentPage(1);
          localStorage.setItem("page", JSON.stringify({ ...page, groups: 1 }));
        } else {
          fetchGroups();
        }
        setOpenCreateGroup(false);
        // setRefresh(!refresh);
        reset({
          select:
            pagecount && pagecount.buyerRoutes
              ? pagecount.buyerRoutes.toString()
              : "10",
          vertical_id: "",
          type: "",
          name: "",
        });
        toast.success("Group Created Successfully");
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
    setGroupLoading(false);
  };
  const handleDelete = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        await dispatch(deleteGroup(id));
        setDeleteId(null);
        setConfirmModal(false);
        await dispatch(
          getAllGroups(currentPage, selectCount, undefined, search, sort)
        );
        toast.success("Group Deleted Succesfully");
      } catch (err) {
        setDeleteId(null);
        setConfirmModal(false);
        toast.error(err.message.toLowerCase());
      }
      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };
  const fetchVerticals = async () => {
    try {
      await dispatch(getAllVerticals(1, 10));
    } catch (err) {
      console.log(err.message);
    }
  };
  const loadOptions = async (inputValue, callback, list) => {
    try {
      const data = await dispatch(
        getAllVerticals(1, 10, undefined, inputValue)
      );

      callback(
        data &&
          data.list.map((val) => ({
            value: val._id,
            label: val.name,
          }))
      );
    } catch (error) {
      console.error(error);
    }
  };
  const handleEdit = (item) => {
    setEdit({
      vertical_id:
        item.type === "lead"
          ? { value: item.vertical_id, label: item.vertical_name }
          : null,
      name: item.name,
      id: item._id,
      status: {
        value: item.status,
        label: item.status
          .toLowerCase()
          .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
      },
      type: {
        value: item.type,
        label:
          item.type &&
          item.type
            .toLowerCase()
            .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
      },
    });
    setOpenCreateGroup(true);
  };
  useEffect(() => {
    // alert("useEffect run");
    if (edit) {
      reset(edit);
      // setRefresh(!refresh);
    } else {
      reset({
        select:
          pagecount && pagecount.groups ? pagecount.groups.toString() : "10",
        vertical_id: "",
        name: "",
        type: "",
      });
      // setRefresh(!refresh);
    }
  }, [edit]);

  useEffect(() => {
    fetchVerticals();
  }, []);
  const defaultVerticals = allVerticals
    ? allVerticals.list.map((val) => {
        return {
          label: val.name,
          value: val._id,
        };
      })
    : [];
  const memoizedChildComponent = useMemo(() => {
    return (
      <LeadsWatchTable
        isOpen={isOpen}
        sort={sort}
        handleSort={handleSort}
        heading={["name", "vertical_name", "status", "Actions"]}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        sortIcon={true}
        data={allGroups && allGroups.list ? allGroups.list : []}
        // screen={"buyer"}
        isLoading={isLoading}
      />
    );
  }, [isLoading, isOpen]);
  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
        // left={[aw(0), aw(3), aw(98)]}
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <VStack
            marginTop={ah(7)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              lineHeight={ah(34)}
              justifyContent={"space-between"}
              space={aw(8)}
              alignItems={"flex-end"}
            >
              <HStack
                alignItems={"baseline"}
                // width={aw(194)}
                justifyContent="space-between"
                space={aw(8)}
              >
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily="RobotoMedium"
                >
                  Groups
                </Text>
                <Text
                  fontSize={aw(18)}
                  color={"#919191"}
                  fontFamily="RobotoMedium"
                >
                  {allGroups && allGroups.list ? allGroups.total_count : 0}
                </Text>
              </HStack>
              {/* <Hidden only={["sm", "base", "md"]}>
                <HStack space={aw(10)}>
               
                </HStack>
              </Hidden> */}
            </HStack>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All your Buyers list will be shown here
            </Text>

            <Divider
              mt={ah(9)}
              bg="#E6E7E9"
              height={ah(1)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            />
          </VStack>
          <VStack height={ah(35)} mt={ah(15)}>
            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control}
                    name={"select"}
                    list={["10", "20", "50", "100"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    width={aw(80)}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>
              <HStack space={ah(12)}>
                <LeadsWatchSearch
                  placeholder={[
                    'Search by "Name"',
                    'Search by "Vertical"',
                    'Search by "Status"',
                  ]}
                  height={ah(32)}
                  control={control1}
                  name="search"
                  onFocus={() => setClearSearch(false)}
                  inputRightElement={
                    clearSearch ? (
                      <Pressable
                        w={aw(20)}
                        onPress={() => {
                          reset1();
                          setSearch(getValues1("search"));
                          setClearSearch(false);
                          setCurrentPage(1);
                          localStorage.setItem(
                            "page",
                            JSON.stringify({ ...page, groups: 1 })
                          );
                        }}
                      >
                        <LeadsWatchCrossIcon />
                      </Pressable>
                    ) : (
                      <Pressable
                        w={aw(30)}
                        onPress={handleSubmit1(handleSearch)}
                        // pr={aw(10)}
                      >
                        <LeadsWatchSearchIcon />
                      </Pressable>
                    )
                  }
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit1(
                        handleSearch({ search: getValues1("search") })
                      );
                    }
                  }}
                />
                <LeadsWatchButton
                  isDisabled={
                    profile && profile.role === "user"
                      ? profile.permission_ids["buyer"].includes("create")
                        ? false
                        : true
                      : false
                  }
                  onPress={() => setOpenCreateGroup(true)}
                  label="Craete"
                  style={{
                    width: aw(101),
                    height: ah(32),
                    // borderRadius: aw(6),
                  }}
                  bg="#2230F8"
                  borderColor="#2230F8"
                  // fontSize={aw(15)}
                  variant={"solid"}
                  color="#FFFFFF"
                  leftIcon={<LeadsWatchAddIcon />}
                />
              </HStack>
            </HStack>
          </VStack>

          <VStack mt={ah(15)}>
            <VStack>{memoizedChildComponent}</VStack>
            <VStack bottom={aw(5)} position="fixed">
              <LeadsWatchPagination
                totalCount={
                  allGroups && allGroups.list ? allGroups.total_count : 1
                }
                // totalCount={100}
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, groups: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(500)}
        showModal={openCreateGroup}
        onClose={() => {
          setOpenCreateGroup(false);
          // setRefresh(!refresh);
          reset({
            select:
              pagecount && pagecount.buyerRoutes
                ? pagecount.buyerRoutes.toString()
                : "10",
          });
          setEdit("");
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            {edit ? "Edit Group" : "Create Group"}
          </Text>
        }
      >
        <VStack px={aw(10)} space={ah(20)}>
          <LeadsWatchFilterInput
            maxLength={32}
            screen={
              getValues("name") && getValues("name").length > 0
                ? "edit"
                : "create"
            }
            refresh={refresh}
            control={control}
            name="name"
            placeholder="Enter Group Name"
            rules={{
              required: {
                value: true,
                message: "Group Name is required",
              },
              pattern: {
                // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                message:
                  "Group Name should start with alphabets and can not contain special characters",
              },
            }}
            invalid={errors.name ? true : false}
            error={errors.name ? errors.name.message : ""}
          />
          <Flex>
            <LeadsWatchAsyncSelect
              screen={
                getValues("type") && getValues("type").value ? "edit" : "create"
              }
              refresh={refresh}
              height={40}
              defaultOptions={[
                { label: "Lead", value: "lead" },
                { label: "IVR", value: "ivr" },
                { label: "Pay Per Call(PPC)", value: "ppc" },
              ]}
              handleChange={(selectedOption) => setType(selectedOption)}
              control={control}
              placeholder="Select Type"
              name="type"
              rules={{
                required: {
                  value: true,
                  message: "Type is required",
                },
              }}
              invalid={errors.type ? true : false}
              error={errors.type ? errors.type.message : ""}
            />
          </Flex>

          <Flex>
            <LeadsWatchAsyncSelect
              isClearable={true}
              screen={
                getValues("vertical_id") && getValues("vertical_id").value
                  ? "edit"
                  : "create"
              }
              refresh={refresh}
              height={40}
              type="async"
              loadOptions={(inputValues, callback) =>
                loadOptions(inputValues, callback, "verticals")
              }
              onInputChange={(inputValues) => {
                console.log(inputValues);
                if (inputValues === "" || inputValues === undefined) {
                  fetchVerticals();
                }
              }}
              defaultOptions={defaultVerticals}
              control={control}
              placeholder="Select Vertical"
              name="vertical_id"
              rules={{
                required: {
                  value:
                    getValues("type") && getValues("type").value === "lead"
                      ? true
                      : false,
                  message: "For Lead Type Vertical is Required",
                },
              }}
              invalid={errors.vertical_id ? true : false}
              error={errors.vertical_id ? errors.vertical_id.message : ""}
            />
          </Flex>

          {edit && (
            <Flex>
              <LeadsWatchAsyncSelect
                screen={
                  getValues("status") && getValues("status").value
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                height={40}
                defaultOptions={[
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" },
                ]}
                control={control}
                placeholder="Select Status"
                name="status"
                rules={{
                  required: {
                    value: true,
                  },
                }}
                invalid={errors.status ? true : false}
                error={errors.status ? errors.status.message : ""}
              />
            </Flex>
          )}
          <Flex direction="row" justifyContent={"flex-end"} gap={aw(10)}>
            <LeadsWatchButton
              label="Cancel"
              style={{ width: aw(100), height: ah(40) }}
              borderColor="#D1D5DB"
              onPress={() => {
                setOpenCreateGroup(false);
                setRefresh(!refresh);
                reset({
                  select:
                    pagecount && pagecount.buyerRoutes
                      ? pagecount.buyerRoutes.toString()
                      : "10",
                  name: "",
                  vertical_id: "",
                  type: "",
                });
                setEdit("");
              }}
            />
            <LeadsWatchButton
              isLoading={groupLoading}
              onPress={handleSubmit(handleCreateGroup)}
              label={edit ? "Edit Group" : "Create"}
              style={{ width: aw(100), height: ah(40) }}
              bg="#2230F8"
              borderColor="#D1D5DB"
              color="#FFFFFF"
            />
          </Flex>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this Delete.
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => {
                setConfirmModal(false);
                setDeleteId(null);
              }}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};
export default Groups;
