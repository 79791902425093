import React from "react";
import DOMPurify from "dompurify";

const RenderHTML = ({ userHTML }) => {
  const sanitizedHTML = DOMPurify.sanitize(userHTML);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
};

export default RenderHTML;
