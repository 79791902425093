import { React, useEffect, useState, useMemo } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Avatar,
  Stack,
  Pressable,
  Hidden,
  useBreakpointValue,
  Flex,
  Switch,
} from "native-base";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { aw, ah } from "../../../utils";
//components
import LeadsWatchSearch from "../../../components/LeadsWatchSearch";
import LeadsWatchTable from "../../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../../components/LeadsWatchButton";
import LeadsWatchTextArea from "../../../components/LeadsWatchTextArea";
import LeadsWatchFilterInput from "../../../components/LeadsWatchFilterInput";
import LeadsWatchModal from "../../../components/LeadsWatchModal";
import LeadsWatchAsyncSelect from "../../../components/LeadsWatchAsyncSelect";
//svg
import LeadsWatchSearchIcon from "../../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchCrossIcon from "../../../components/svg/LeadsWatchCrossIcon";
import LeadsWatchAddIcon from "../../../components/svg/LeadsWatchAddIcon";
//redux
import { getAllSignatures } from "../../../store/actions/crmsignatures";
import {
  getAllTemplates,
  getSignatureById,
  createTemplates,
  deleteTemplate,
  editTemplate,
} from "../../../store/actions/crmtemplates";
import RenderHTML from "../../../components/LeadsWatchRenderHTML";

const Templates = ({ isOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [previewModal, setPreviewModal] = useState(false);
  //states from redux
  const signatures = useSelector((state) => state.crmsignatures.allSignatures);
  const templates = useSelector((state) => state.crmtemplates.allTemplates);
  const profile = useSelector((state) => state.auth.profile);
  //component states
  const [type, setType] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [changeType, setChangeType] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [sort, setSort] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [edit, setEdit] = useState("");
  const [filters, setFilters] = useState({});
  const [toogle, setToogle] = useState(false);
  const [disableToogle, setDisableToogle] = useState(true);

  //pagination
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [currentPage, setCurrentPage] = useState(
    page && page.crmtemplate ? parseInt(page.crmtemplate) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.crmtemplate ? parseInt(pagecount.crmtemplate) : 10
  );
  //responsive
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  //form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
    getValues,
  } = useForm({
    mode: "all",
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    getValues: getValues1,
    reset: reset1,
  } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.crmtemplate
          ? pagecount.crmtemplate.toString()
          : "10",
    },
  });
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    getValues: getValues2,
  } = useForm({
    mode: "all",
  });
  const [search, setSearch] = useState(getValues2("search"));
  //change no of entries
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, crmtemplate: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, crmtemplate: 1 }));
  };
  //search
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      data.search !== "" && setClearSearch(true);
      localStorage.setItem("page", JSON.stringify({ ...page, crmtemplate: 1 }));
    }
  };
  //Table functions
  //sort
  const handleSort = (order) => {
    setSort(order);
  };
  //delete
  const handleDelete = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        await dispatch(deleteTemplate(id));
        toast.success("Template Deleted Successfully");
        fetchTemplates();
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };
  //fetch templates
  const fetchTemplates = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllTemplates(currentPage, selectCount, sort, filters, search)
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["crm"] &&
        profile.permission_ids["crm"].includes("view")
      ) {
        fetchTemplates();
      } else {
        navigate("/settings/profile");
      }
    } else {
      fetchTemplates();
    }
  }, [currentPage, selectCount, sort, filters, search]);
  //edit
  const handleEdit = async (item) => {
    setEdit({
      name: item.name,
      signature: {
        label: item.signature,
        value: item.signature_id,
      },
      type:
        item.type === "email"
          ? {
              label: "Email",
              value: "email",
            }
          : {
              label: "SMS",
              value: "sms",
            },

      subject: item.subject,
      body: item.content,
      id: item._id,
      status: {
        label:
          item.status &&
          item.status
            .toLowerCase()
            .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
        value: item.status,
      },
    });
    setToogle(item.include_body);
    item.type && setType(item.type);
    setDisableToogle(false);
    await dispatch(getAllSignatures(1, 10, undefined, { type: item.type }));
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (edit) {
      reset(edit);
    } else {
      reset({
        name: "",
        type: "",
        signature: "",
        subject: "",
        body: "",
        status: "",
      });
    }
  }, [edit]);
  //create
  const handleCreateTempaltes = async (data) => {
    setIsSave(true);
    if (edit) {
      try {
        await dispatch(
          editTemplate(edit.id, {
            name: data.name,
            type: data.type.value,
            signature_id: data.signature.value,
            subject: data.subject,
            content: data.body,
            signature: data.signature.label,
            include_body: toogle,
          })
        );

        toast.success("Template Edited Successfully");
        setIsModalOpen(false);
        fetchTemplates();
        setToogle(false);
        setEdit("");
        reset();
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    } else {
      try {
        await dispatch(
          createTemplates({
            name: data.name,
            type: data.type.value,
            signature_id: data.signature.value,
            subject: data.subject,
            content: data.body,
            signature: data.signature.label,
            include_body: toogle,
          })
        );
        toast.success("Template Created Successfully");
        setIsModalOpen(false);
        fetchTemplates();
        setToogle(false);
        reset();
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
    setIsSave(false);
  };
  //all select signature functions
  const defaultOptionsForSignature =
    getValues("type") && getValues("type").value
      ? signatures && signatures.list
        ? signatures.list.map((val) => {
            return { value: val._id, label: val.label };
          })
        : []
      : [];
  const fetchData = async (value) => {
    console.log(value);
    setType(value);
    setChangeType(!changeType);
    // setRefresh(!refresh);
    try {
      await dispatch(getAllSignatures(1, 10, undefined, { type: value }));
      setValue("signature", "");
      setValue("body", "");
    } catch (err) {
      console.log(err);
    }
  };
  const loadOptions = async (inputValue, callback) => {
    try {
      const data = await dispatch(
        getAllSignatures(1, 10, undefined, { type: type, search: inputValue })
      );

      callback(
        data &&
          data.list.map((val) => ({
            value: val.label,
            label: val._id,
          }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //setting predefined body to template
  useEffect(() => {
    const body = async () => {
      try {
        const response = await dispatch(
          getSignatureById(getValues("signature").value)
        );
        setValue(
          "body",
          `<img  width="128" height="96" src="${response.logo}"/>
          <p style="margin:0;padding:0" id="is-start">
          <span style="color:black;font-weight:bold">${response.name}</span>
<span style="color:black;">${response.phone}</span>
${response.from_email}</p>
`
        );
        clearErrors(["body"]);
      } catch (err) {
        console.log(err);
      }
    };
    if (toogle && getValues("signature") && getValues("signature").value) {
      body();
    } else {
      setValue("body", "");
    }
  }, [toogle]);

  //children components memonization
  const memoizedChildComponent = useMemo(() => {
    return (
      <LeadsWatchTable
        isOpen={isOpen}
        sort={sort}
        screen="templates"
        handleDelete={handleDelete}
        handleSort={handleSort}
        handleEdit={handleEdit}
        isLoading={isLoading}
        heading={["name", "type", "signature", "status", "Actions"]}
        sortIcon={true}
        // data={allPublishers && allPublishers.list ? allPublishers.list : null}
        data={templates && templates.list ? templates.list : null}
      />
    );
  }, [isLoading, isOpen]);
  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <VStack
            marginTop={ah(7)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              lineHeight={ah(34)}
              justifyContent={"space-between"}
              space={aw(8)}
              alignItems={"flex-end"}
            >
              <HStack
                alignItems={"baseline"}
                // width={aw(194)}
                justifyContent="space-between"
                space={aw(8)}
              >
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily="RobotoMedium"
                >
                  Templates
                </Text>
                <Text
                  top={ah(20)}
                  fontSize={aw(18)}
                  color={"#919191"}
                  fontFamily="RobotoMedium"
                >
                  {templates && templates.total_count
                    ? templates.total_count
                    : 0}
                </Text>
              </HStack>
              <Hidden only={["sm", "base"]}>
                {/* <HStack space={aw(10)}>
                 
                </HStack> */}
              </Hidden>
            </HStack>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All your Templates list will be shown here
            </Text>

            <Divider
              mt={ah(9)}
              bg="#E6E7E9"
              height={ah(1)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            />
          </VStack>
          <VStack height={ah(35)} mt={ah(15)}>
            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control1}
                    name={"select"}
                    list={["10", "20", "50", "100"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    // width={aw(80)}
                    width={79}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>
              <Hidden only={["sm", "base"]}>
                <HStack space={aw(12)}>
                  {/* <LeadsWatchSelect
                    control={control}
                    name={"status1"}
                    placeholder="Status"
                    list={["Active", "InActive"]}
                    handleChange={(value) =>
                      setFilters({ ...filters, status: value })
                    }
                    width={aw(100)}
                    // height={ah(32)}
                  /> */}
                  <LeadsWatchAsyncSelect
                    isClearable={true}
                    height={ah(38)}
                    required={true}
                    minWidth={aw(100)}
                    defaultOptions={[
                      { label: "Active", value: "active" },
                      { label: "Inactive", value: "inactive" },
                    ]}
                    control={control}
                    placeholder="Status"
                    name="status1"
                    handleChange={(value) => {
                      if (value) {
                        setFilters({ ...filters, status: value.value });
                      } else {
                        // // setFilters(delete filters.status);
                        delete filters.status;
                        setFilters({ ...filters });
                      }
                    }}
                  />

                  <LeadsWatchSearch
                    placeholder={[
                      'Search by "Name"',
                      'Search by "Company"',
                      'Search by "Email"',
                      'Search by "Phone"',
                      'Search by "Status"',
                    ]}
                    height={ah(38)}
                    control={control2}
                    name="search"
                    onFocus={() => setClearSearch(false)}
                    inputRightElement={
                      clearSearch ? (
                        <Pressable
                          w={aw(20)}
                          onPress={() => {
                            reset2();
                            setSearch(getValues2("search"));
                            setClearSearch(false);
                            setCurrentPage(1);
                            localStorage.setItem(
                              "page",
                              JSON.stringify({ ...page, crmtemplate: 1 })
                            );
                          }}
                        >
                          <LeadsWatchCrossIcon />
                        </Pressable>
                      ) : (
                        <Pressable
                          w={aw(30)}
                          onPress={handleSubmit2(handleSearch)}
                          // pr={aw(10)}
                        >
                          <LeadsWatchSearchIcon />
                        </Pressable>
                      )
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit2(
                          handleSearch({ search: getValues2("search") })
                        );
                      }
                    }}
                  />

                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "crm"
                        ? true
                        : profile && profile.role === "user"
                        ? profile.permission_ids["crm"].includes("create")
                          ? false
                          : true
                        : false
                    }
                    onPress={() => {
                      setIsModalOpen(true);
                    }}
                    label="Create"
                    style={{ width: aw(101), height: ah(38) }}
                    bg="#2230F8"
                    borderColor="#2230F8"
                    color="#FFFFFF"
                    leftIcon={<LeadsWatchAddIcon />}
                  />
                </HStack>
              </Hidden>
            </HStack>
          </VStack>

          <VStack mt={ah(15)}>
            <VStack>{memoizedChildComponent}</VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={
                  templates && templates.total_count ? templates.total_count : 1
                }
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, crmtemplate: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>

      <LeadsWatchModal
        width={aw(700)}
        height={ah(700)}
        showModal={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          reset();
          setRefresh(!refresh);
          setEdit("");
          setType("");
          setToogle(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            {edit ? "Edit Templates" : "Add Templates"}
          </Text>
        }
      >
        <Flex gap={ah(20)} px={aw(5)}>
          <LeadsWatchFilterInput
            screen={
              getValues("name") && getValues("name").length > 0
                ? "edit"
                : "create"
            }
            refresh={refresh}
            control={control}
            name="name"
            placeholder="Name"
            rules={{
              required: {
                value: true,
                message: "Name is required",
              },
              pattern: {
                // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                message:
                  "Name should start with alphabets and can not contain special characters",
              },
            }}
            invalid={errors.name ? true : false}
            error={errors.name ? errors.name.message : ""}
          />
          <Flex direction="row" gap={aw(12)} width="100%">
            <Flex width="49%">
              <LeadsWatchAsyncSelect
                screen={
                  getValues("type") && getValues("type").value
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                // height={ah(38)}
                minWidth={"100%"}
                defaultOptions={[
                  { label: "Email", value: "email" },
                  { label: "SMS", value: "sms" },
                ]}
                control={control}
                placeholder="Template Type"
                name="type"
                loadOptions={(inputValues, callback) =>
                  loadOptions(inputValues, callback)
                }
                handleChange={(value) => {
                  if (value && value.value !== type) {
                    fetchData(value.value);
                  }
                }}
                rules={{
                  required: {
                    value: true,
                    message: "Template Type is required",
                  },
                }}
                invalid={errors.type ? true : false}
                error={errors.type ? errors.type.message : ""}
              />
            </Flex>
            <Flex width="49%" gap={ah(5)}>
              <LeadsWatchAsyncSelect
                screen={
                  getValues("signature") && getValues("signature").value
                    ? "edit"
                    : "create"
                }
                refresh={refresh || changeType}
                // height={ah(38)}
                minWidth={"100%"}
                defaultOptions={defaultOptionsForSignature}
                control={control}
                placeholder="Select Signature"
                name="signature"
                handleChange={(value) => {
                  if (value && value.value) {
                    setDisableToogle(false);
                    setToogle(false);
                  }
                }}
                onInputChange={(inputValues) => {
                  if (inputValues === "") {
                    fetchData();
                  }

                  // return inputValue;
                }}
                rules={{
                  required: {
                    value: true,
                    message: "Signature  is required",
                  },
                }}
                invalid={errors.signature ? true : false}
                error={errors.signature ? errors.signature.message : ""}
              />
              {type === "email" && (
                <Flex
                  direction="row"
                  width="100%"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Text
                    fontFamily="RobotoRegular"
                    fontSize={aw(13)}
                    color="#2230F8"
                  >
                    Include in body ?
                  </Text>

                  <Switch
                    disabled={disableToogle}
                    onToggle={() => setToogle(!toogle)}
                    offTrackColor="#C1C1C1"
                    onTrackColor="#0000FF"
                    _hover={{
                      offTrackColor: "#C1C1C1",
                      onTrackColor: "#0000FF",
                    }}
                    isChecked={toogle}
                    size={aw(12)}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
          {type === "email" && (
            <LeadsWatchFilterInput
              // refresh={type}
              screen={edit ? "edit" : "create"}
              name="subject"
              placeholder="Subject"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Subject  is required",
                },
              }}
              invalid={errors.subject ? true : false}
              error={errors.subject ? errors.subject.message : ""}
            />
          )}
          <LeadsWatchTextArea
            height={aw(200)}
            refresh={refresh || changeType}
            screen={
              getValues("body") && getValues("body").length > 0
                ? "edit"
                : "create"
            }
            name="body"
            control={control}
            placeholder="Email Body"
            rules={{
              required: {
                value: true,
                message: "Body  is required",
              },
            }}
            invalid={errors.body ? true : false}
            error={errors.body ? errors.body.message : ""}
          />

          <Flex
            direction="row"
            width="100%"
            justifyContent={"space-between"}
            // gap={aw(12)}
          >
            <Flex>
              <LeadsWatchButton
                bg={"#FFFFFF"}
                // borderColor={"#FFFFFF"}
                // color={"#2230F8"}
                onPress={() => setPreviewModal(true)}
                label="Preview"
                style={{ width: aw(100), height: ah(40) }}
              />
            </Flex>
            <HStack space={aw(12)}>
              <LeadsWatchButton
                label="cancel"
                style={{ width: aw(100), height: ah(40) }}
                onPress={() => {
                  setIsModalOpen(false);
                  reset();
                  setRefresh(!refresh);
                  setEdit("");
                  setType("");
                  setToogle(false);
                }}
              />
              <LeadsWatchButton
                label={edit ? "Update" : "Add"}
                style={{ width: aw(100), height: ah(40) }}
                isLoading={isSave}
                onPress={handleSubmit(handleCreateTempaltes)}
                bg="#2230F8"
                borderColor="#D1D5DB"
                color="#FFFFFF"
              />
            </HStack>
          </Flex>
        </Flex>
      </LeadsWatchModal>

      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this Template ?
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>

      <LeadsWatchModal
        width={aw(700)}
        height={ah(500)}
        showModal={previewModal}
        onClose={() => {
          setPreviewModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Preview
          </Text>
        }
      >
        <RenderHTML userHTML={getValues("body")} />
      </LeadsWatchModal>
    </>
  );
};
export default Templates;
