// uri
export const API_URL = "https://apitest.leadswatch.com/v1";
// export const API_URL = "http://192.168.1.195:3000/v1";
// export const API_URL = "http://192.168.1.102:7000/v1";
// export const API_URL1 =
//   "https://22b5-2405-201-c400-1b41-c952-2e15-f82d-cb4f.ngrok-free.app/v1";
//auth events
export const SET_USER = "SET_USER";
export const EMAIL_SEND = "EMAIL_SEND";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_PROFILE = "GET_PROFILE";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const UPDATE_LOGO = "UPDATE_LOGO";
export const REMOVE_PROFILE = "REMOVE_PROFILE";
export const GET_OTP = "GET_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const SELECT_USER = "SELECT_USER";
// export const NEW_REGISTER = "NEW_REGISTER";
//vertical events
export const GET_ALL_VERTICALS = "GET_ALL_VERTICALS";
export const ADD_VERTICAL = "ADD_VERTICAL";
export const EDIT_VERTICAL = "EDIT_VERTICAL";
export const DELETE_VERTICAL = "DELETE_VERTICAL";
export const GET_SINGLE_VERTICAL = "GET_All_VERTICAL";
export const ADD_VERTICAL_FIELD = "ADD_VERTICAL_FIELD";
export const GET_ALL_VERTICAL_FIELDS = "GET_ALL_VERTICAL_FIELDS";
export const EDIT_VERTICAL_FIELD = "EDIT_VERTICAL_FIELD";
export const CHOOSE_DEFAULT_FIELD = "CHOOSE_DEFAULT_FIELD";
export const CREATE_VERTICAL_FIELD_DEFAULT = "CREATE_VERTICAL_FIELD_DEFAULT";
export const DELETE_VERTICAL_FIELD = "DELETE_VERTICAL_FIELD";
export const GET_VERTICAL_FIELD_BY_FORMAT = "GET_VERTICAL_FIELD_BY_FORMAT";

//publisher events
export const GET_ALL_PUBLISHERS = "GET_ALL_PUBLISHERS";
export const ADD_PUBLISHER = "ADD_PUBLISHER";
export const GET_SINGLE_PUBLISHER = "GET_SINGLE_PUBLISHER";
export const EDIT_PUBLISHER = "EDIT_PUBLISHER";
export const DELETE_PUBLISHER = "DELETE_PUBLISHER";
export const GET_ALL_INVITED_PUBLISHERS = "GET_ALL_INVITED_PUBLISHERS";

//buyers events
export const GET_ALL_BUYERS = "GET_ALL_BUYERS";
export const ADD_BUYER = "ADD_BUYER";
export const EDIT_BUYER = "EDIT_BUYER";
export const BUYER_DETAILS = "BUYER_DETAILS";
export const CREATE_BUYER_ROUTE = "CREATE_BUYER_ROUTE";
export const SINGLE_BUYER_ROUTE = "SINGLE_BUYER_ROUTE";
export const EDIT_BUYER_ROUTE = "EDIT_BUYER_ROUTE";
export const GET_SINGLE_BUYER = "GET_SINGLE_BUYER";
export const REMOVE_SINGLE_BUYER = "REMOVE_SINGLE_BUYER";
export const DELETE_BUYER = "DELETE_BUYER";
export const DELETE_BUYER_ROUTE = "DELETE_BUYER_ROUTE";

//campaigns events
export const GET_ALL_CAMPAIGNS = "GET_ALL_CAMPAIGNS";
export const GET_BUYERS_BY_VERTICAL = "GET_BUYERS_BY_VERTICAL";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const GET_SINGLE_CAMPAIGN = "GET_SINGLE_CAMPAIGN";
export const EDIT_CAMPAIGN = "EDIT_CAMPAIGN";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";

//Leads events
export const GET_ALL_LEADS = "GET_ALL_LEADS";
export const GET_SINGLE_LEAD = "GET_SINGLE_LEAD";
export const REMOVE_SINGLE_LEAD = "REMOVE_SINGLE_LEAD";
export const EXPORT_LEADS = "EXPORT_LEADS";
export const SINGLE_EXPORT = "SINGLE_EXPORT";
export const SEND_BUYERS = "SEND_BUYERS";
export const GET_FORM_FIELDS = "GET_FORM_FIELDS";
export const CREATE_LEADS = "CREATE_LEADS";
export const CREATE_MULTIPLE_LEADS = "CREATE_MULTIPLE_LEADS";
export const GET_CAMPAIGNS_BY_VERTICALS = "GET_CAMPAIGNS_BY_VERTICALS";
export const GET_PUB_BY_CAMPAIGNS = "GET_PUB_BY_CAMPAIGNS";

//Subscription
export const GET_ALL_PLANS = "GET_ALL_PLANS";
export const GET_ALL_CARDS = "GET_ALL_CARDS";
export const ADD_CARD = "ADD_CARD";
export const Default_CARD = "Default_CARD";
export const DELETE_CARD = "DELETE_CARD";
export const GET_INVOICES = "GET_INVOICES";
export const GET_PLAN_DETAILS = "GET_PLAN_DETAILS";
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const AUTO_RELOAD_CREDITS = "AUTO_RELOAD_CREDITS";
export const REMOVE_INVOICES = "REMOVE_INVOICES";
//Team
export const GET_ALL_TEAM_INVITES = "GET_ALL_TEAM_INVITES";
export const ADD_TEAMMEMBER = "ADD_TEAMMEMBER";
export const UPDATE_TEAMINVITES_PERMISSIONS = "UPDATE_TEAMINVITES_PERMISSIONS";
export const DELETE_TEAM_INVITE = "DELETE_TEAM_INVITE";
export const GET_TEAM_MEMBERS = " GET_TEAM_MEMBERS";

//Calls
export const GET_ALL_CALLS = "GET_ALL_CALLS";
export const GET_ACTIVE_CALLS = "GET_ACTIVE_CALLS";
export const SEARCH_NUMBERS = "SEARCH_NUMBERS";
export const BUY_NUMBER = "BUY_NUMBER";
export const GET_ALL_NUMBERS = "GET_ALL_NUMBERS";
export const REMOVE_SEARCHED_NUMBERS = "REMOVE_SEARCHED_NUMBERS";
export const GET_UNALLOCATED_NUM = "GET_UNALLOCATED_NUM";
export const DELETE_NUMBERS = "DELETE_NUMBERS";
export const GET_PHONE_NUM = "GET_PHONE_NUM";
export const CALL_LOGS = "CALL_LOGS";
export const FROM_NUMBER = "FROM_NUMBER";
export const SEND_RESPONSE = "SEND_RESPONSE";

//reports
export const LEADS_REPORT = "LEADS_REPORT";
export const VERTICALS_REPORT = "VERTICALS_REPORT";
export const PUBLISHERS_REPORT = "PUBLISHERS_REPORT";
export const BUYERS_REPORT = "BUYERS_REPORT";
export const CAMPAIGNS_REPORT = "CAMPAIGNS_REPORT";
export const BUYER_ROUTES_REPORT = "BUYER_ROUTES_REPORT";
export const HANDLE_DOWNLOAD = "HANDLE_DOWNLOAD";
export const VERTICAL_FIELDS = "VERTICAL_FIELDS";
export const PRIORITY = "PRIORITY";

//group
export const CREATE_GROUP = "CREATE_GROUP";
export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const GET_SINGLE_GROUP = "GET_SINGLE_GROUP";
export const GET_VERTICAL_GROUPS = "GET_VERTICAL_GROUPS";
export const EDIT_GROUP = "EDIT_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";

//non api calls
export const ADD_CALL_SCHEDULE = "ADD_CALL_SCHEDULE";
export const EDIT_CALL_SCHEDULE = "EDIT_CALL_SCHEDULE";
export const DELETE_CALL_SCHEDULE = "DELETE_CALL_SCHEDULE";
export const ADD_IVR_NUMBERS = "ADD_IVR_NUMBERS";
export const EDIT_IVR_NUMBERS = "EDIT_IVR_NUMBERS";
export const DELETE_IVR_NUMBERS = "DELETE_IVR_NUMBERS";

//dashboard
export const GET_TOP_CAMPAIGNS = "GET_TOP_CAMPAIGNS";
export const GET_TOP_BUYERS = "GET_TOP_BUYERS";
export const GET_TOP_PUBLISHERS = "GET_TOP_PUBLISHERS";
export const GET_TOTAL_LEADS = "GET_TOTAL_LEADS";
export const GET_LEAD_PERFORMANCE = "GET_LEAD_PERFORMANCE";
export const GET_AMOUNT_PERFORMANCE = "GET_AMOUNT_PERFORMANCE";
export const GET_LEADS_METER = "GET_LEADS_METER";
export const CREDITS_ANALYSIS = "CREDITS_ANALYSIS";

//invoices
export const GET_ALL_INVOICES = "GET_ALL_INVOICES";
export const GENERATE_INVOICES = "GENERATE_INVOICES";
export const AUTO_GENERATE_INVOICES = "AUTO_GENERATE_INVOICES";
export const GET_START_DATE = "GET_START_DATE";
export const GENERATE_AUTOMATE_INVOICES = "GENERATE_AUTUMATE_INVOICES";
export const EDIT_AUTO_INVOICES = "EDIT_AUTO_INVOICES";
export const EDIT_STATUS = "EDIT_STATUS";
export const DELETE_INVOICE = "DELETE_INVOICE";

//delete account
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";

//crm
//pipelines
export const CREATE_PIPELINE = "CREATE_PIPELINE";
export const GET_ALL_PIPELINES = "GET_ALL_PIPELINES";
export const DELETE_PIPELINES = "DELETE_PIPELINES";
export const MOVE_PIPELINE = "MOVE_PIPELINE";
export const UNASSIGNED_LEADS = "UNASSIGNED_LEADS";
export const ADD_LEADS_TO_PIPELINE = "ADD_LEADS_TO_PIPELINE";
export const PIPELINE_LEADS = "PIPELINE_LEADS";
export const MOVE_PIPELINE_LEADS = "MOVE_PIPELINE_LEADS";
export const ADD_NOTES = "ADD_NOTES";

//campaigns
export const GET_ALL_CRMCAMPAIGNS = "GET_ALL_CRMCAMPAIGNS";
export const CREATE_CRMCAMPAIGN = "CREATE_CRMCAMPAIGN";
export const EDIT_CRMCAMPAIGN = "EDIT_CRMCAMPAIGN";
export const DELETE_CRMCAMPAIGN = "DELETE_CRMCAMPAIGN";
export const ATTACTH_CAMPAIGN = "ATTACH_CAMPAIGN";
export const DETACH_CAMPAIGN = "DETACH_CAMPAIGN";

//templetes
export const GET_ALL_CRMTEMPLATES = "GET_ALL_CRMTEMPLETES";
export const CREATE_CRMTEMPLATES = "CREATE_CRMTEMPLETES";
export const EDIT_CRMTEMPLATE = "EDIT_CRMTEMPLETE";
export const DELETE_CRMTEMPLATE = "DELETE_CRMTEMPLETE";

//signatures
export const GET_ALL_CRMSIGNATURES = "GET_ALL_CRMSIGNATURES";
export const CREATE_CRMSIGNATURES = "CREATE_CRMSIGNATURES";
export const EDIT_CRMSIGNATURES = "EDIT_CRMSIGNATURES";
export const DELETE_CRMSIGNATURES = "DELETE_CRMSIGNATURES";
export const GET_SIGNATURE_BY_ID = "GET_SIGNATURE_BY_ID";
export const ADD_SMTP = "ADD_SMTP";
export const GET_ALL_SMTP = "GET_ALL_SMTP";
export const UPDATE_SMTP = "UPDATE_SMTP";
export const DELETE_SMTP = "DELETE_SMTP";

//contacts
export const GET_ALL_CRMCONTACTS = "GET_ALL_CRMCONTACTS";
export const CREATE_CRMCONTACTS = "CREATE_CRMCONTACTS";
export const EDIT_CRMCONTACTS = "EDIT_CRMCONTACTS";
export const GET_SINGLE_CONTACT = "GET_SINGLE_CONTACT";
export const GET_CONTACT_EMAILS = "GET_CONTACT_EMAILS";
export const REMOVE_EMAILS = "REMOVE_EMAILS";
export const REMOVE_CONTACT_DETAILS = "REMOVE_CONTACT_DETAILS";
