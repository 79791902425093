import React, { useState, useEffect, useMemo } from "react";
import {
  HStack,
  VStack,
  Text,
  Divider,
  Stack,
  Box,
  ScrollView,
  Pressable,
  Checkbox,
  FlatList,
  Switch,
  Flex,
  Tooltip,
  // useToast,
} from "native-base";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import XMLParser from "react-xml-parser";
// import xmljs from "xml-js";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchCard from "../../components/LeadsWatchCard";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
import { aw, ah } from "../../utils";
import LeadsWatchCopyCodeIcon from "../../components/svg/LeadesWatchCopyCodeIcon";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchInput from "../../components/LeadsWatchInput";
import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchTextArea from "../../components/LeadsWatchTextArea";
// import ToastAlert from "../../components/ToastAlert";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import {
  getAllVerticals,
  getAllVerticalFields,
  getVerticalFieldsByFormat,
} from "../../store/actions/verticals";
import {
  createBuyerRoute,
  getBuyerRouteById,
  editBuyerRoute,
} from "../../store/actions/buyers";
import { addcallSchedule, addivrNum } from "../../store/actions/common";
import { getAllGroups } from "../../store/actions/groups";
import LeadsWatchEditIcon from "../../components/svg/LeadsWatchEditIcon";
import LeadsWatchDeleteIcon from "../../components/svg/LeadsWatchDeleteIcon";
import DataMapping from "./DataMapping";
import IVRCallConfiguration from "./IVRCallConfiguration";
import LeadsWatchInfoIcon from "../../components/svg/LeadsWatchInfoIcon";
import DataFormats from "./DataFormats";

const data = ["Vertical Field", "Filter Value", "Match Type"];

const CreateBuyer = (props) => {
  //vertical Fields pagination
  const [vfCurrentPage, setVfCurrentPage] = useState(1);
  const allGroups = useSelector((state) => state.groups.allGroups);
  const callSchedule = useSelector((state) => state.common.scheduletime);
  const ivrnumbers = useSelector((state) => state.common.ivrnumbers);
  const [vfCount, setVFCout] = useState(10);
  const profile = useSelector((state) => state.auth.profile);
  const { id, bId, type } = useParams();
  const allVerticals = useSelector((state) => state.verticals.allVerticals);
  const [bEdit, setBEdit] = useState("");
  const navigate = useNavigate();
  const allVerticalFields = useSelector(
    (state) => state.verticals.allVerticalFields
  );
  const allVerticalFieldsByFormat = useSelector(
    (state) => state.verticals.verticalFieldsByFormat
  );
  const singleRoute = useSelector((state) => state.buyers.singleBuyerRoute);
  const [buttonValue, setButtonValue] = useState("params");
  const [buttonValue1, setButtonValue1] = useState(
    type === "ppc" ? "ping" : "post"
  );
  const [b, setB] = useState(false);
  const [addResModal, setAddResModal] = useState(false);
  const [mapFields, setMapFields] = useState({
    params: [],
    body: [],
    header: [],
    auth: [],
    ping_params: [],
    ping_body: [],
    ping_header: [],
    ping_auth: [],
  });
  const [fMapFields, setFMapFields] = useState([]);
  const [pingMapFields, setPingMapFields] = useState([]);
  let fileReader;
  // const [edit, setEdit] = useState({});
  const dispatch = useDispatch();

  const [editId, setEditId] = useState("");
  const [editRouteStatusId, setEditRouteStatusId] = useState("");

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [pFile, setPFile] = useState("");
  const [routeStatus, setRouteStatus] = useState([]);
  const [file, setFile] = useState(null);
  const [pingFile, setPingFile] = useState(null);
  const [select, setSelect] = useState({
    route_formats: [],
    route_filters: [],
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      select: "10",
      data_formats_Date: "MM-DD-YYYY",
      data_formats_Time: "HH-MM-SS",
      data_formats_Phone: "+91123456780",
    },
    mode: "all",
  });

  const {
    control: control3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    reset: reset3,
    getValues: getValues3,
  } = useForm({
    mode: "all",
  });
  const fetchVerticals = async () => {
    try {
      await dispatch(getAllVerticals(1, 10));
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchVerticals();
  }, []);

  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["buyer"] &&
        !profile.permission_ids["buyer"].includes("view")
      )
        navigate("/settings/profile");
    }
  }, []);
  const handleChangeVertical = async (value) => {
    setSelect({ ...select, vertical_id: value.value });
    if (getValues("group_id")) {
      setValue("group_id", "");
      // setRefresh(!refresh);
    }
    setVfCurrentPage(1);
  };

  useEffect(() => {
    const groups = async () => {
      try {
        await dispatch(
          getAllVerticalFields(
            vfCurrentPage,
            vfCount,
            getValues("vertical_id").value
          )
        );
        await dispatch(
          getAllGroups(1, 10, {
            vertical_id: [getValues("vertical_id").value],
            type: type,
          })
        );
      } catch (err) {
        console.log(err.message);
      }
    };
    if (getValues("vertical_id") && type === "lead") {
      groups();
    }
  }, [getValues("vertical_id"), vfCurrentPage, vfCount]);
  useEffect(() => {
    const groups = async () => {
      try {
        dispatch(
          getAllGroups(1, 10, {
            type: type,
          })
        );
      } catch (err) {
        console.log(err.message);
      }
    };
    if (type === "ivr" || type === "ppc") {
      groups();
    }
  }, [type]);
  const handleCreate = async (data) => {
    if (type === "lead") {
      if (
        mapFields.params.length === 0 &&
        mapFields.header.length === 0 &&
        mapFields.body.length === 0 &&
        mapFields.auth.length === 0
      ) {
        toast.error("Data Mapping is Required");
      } else {
        setLoading(true);
        let mapped_keys_ping = {};
        let mapped_keys_post = {};

        if (data.file_type) {
          for (let c of Object.keys(mapFields)) {
            for (let d of mapFields[c]) {
              if (c.includes("ping")) {
                mapped_keys_ping[d.key] = d.map_field;
              } else {
                mapped_keys_post[d.key] = d.map_field;
              }
            }
          }
        }

        const newData = select.route_filters.map((val) => {
          if (`f_${val.key}` in data) {
            return {
              ...val,
              value:
                val.dataType === "number" && val.match !== "in_between"
                  ? parseFloat(data[`f_${val.key}`])
                    ? parseFloat(data[`f_${val.key}`])
                    : ""
                  : data[`f_${val.key}`],
            };
          } else {
            return val;
          }
        });

        setSelect({
          ...select,
          route_filters: newData,
        });

        const finalData = {
          type: "lead",
          vertical_id: data.vertical_id.value,
          vertical_name: data.vertical_id.label,
          group:
            data.groups &&
            data.groups.map((val) => {
              return { group_name: val.label, group_id: val.value };
            }),
          vertical_buyer_status: data.buyer_status.value,
          name: data.route_name,
          method: data.method.value,
          ping_url: data.ping_url,
          post_url: data.post_url,
          desc: data.description,
          price: data.price && parseFloat(data.price),
          delivery_type: data.delivery_type.value,
          no_of_leads: data.no_of_leads && parseFloat(data.no_of_leads),
          success_key: data.success_key,
          failure_key: data.failure_key,
          include_lead_id: data.include_lead_id,
          buyer_send_mode: data.content_type.value,
          buyer_response_mode: data.response_type.value,
          route_filters: newData.filter(
            (val) =>
              val.value !== undefined &&
              val.value !== "" &&
              val.match !== undefined &&
              val.match !== ""
          ),
          route_formats: select.route_formats,
          route_settings: mapFields,
          route_statuses: routeStatus,
          ping_id_key: data.ping_id,
          ping_payload_data: select.file_type && pingFile,
          post_payload_data: select.file_type && file,
          payload_format: data.file_type,
          mapped_keys_ping: mapped_keys_ping,
          mapped_keys_post: mapped_keys_post,
        };

        if (props.name === "edit") {
          try {
            await dispatch(editBuyerRoute(id, bId, finalData));
            navigate(-1);
            setBEdit("");
          } catch (err) {
            toast.error(err.message.toLowerCase());
          }
        } else {
          try {
            await dispatch(createBuyerRoute(finalData, id));
            navigate(-1);
            reset();
            setMapFields({
              params: [],
              body: [],
              header: [],
              auth: [],
              ping_params: [],
              ping_body: [],
              ping_header: [],
              ping_auth: [],
            });
            setChecked(false);
            setSelect({ route_formats: [], route_filters: [] });
          } catch (err) {
            toast.error(err.message.toLowerCase());
          }
        }
        setLoading(false);
      }
    } else {
      setLoading(true);
      const finalData =
        type === "ivr"
          ? {
              type: "ivr",
              group_id: data.group_id && data.group_id.value,
              group_name: data.group_id && data.group_id.label,
              vertical_buyer_status: data.buyer_status.value,
              name: data.route_name,
              desc: data.description,
              price: data.price && parseFloat(data.price),
              delivery_type: data.delivery_type.value,
              no_of_calls: data.no_of_calls && parseFloat(data.no_of_calls),
              success_duration:
                data.success_time && parseFloat(data.success_time),
              schedules: callSchedule,
              ivr_settings: ivrnumbers,
              welcome_message: data.welcome_message,
              wrong_input_message: data.wrong_input_message,
              no_input_message: data.no_input_message,
            }
          : {
              type: "ppc",
              group_id: data.group_id && data.group_id.value,
              group_name: data.group_id && data.group_id.label,
              vertical_buyer_status: data.buyer_status.value,
              name: data.route_name,
              desc: data.description,
              price: data.price && parseFloat(data.price),
              delivery_type: data.delivery_type.value,
              no_of_calls: data.no_of_calls && parseFloat(data.no_of_calls),
              success_duration:
                data.success_time && parseFloat(data.success_time),
              schedules: callSchedule,
              transfer_number: data.transfer_number,
              call_type: data.call_type && data.call_type.value,
              ping_url: data.ping_url,
              buyer_send_mode: data.content_type && data.content_type.value,
              buyer_response_mode:
                data.response_type && data.response_type.value,
              route_settings:
                data.call_type && data.call_type.value === "ping-post"
                  ? mapFields
                  : [],
            };

      if (props.name === "edit") {
        try {
          await dispatch(editBuyerRoute(id, bId, finalData));
          dispatch(addcallSchedule([]));
          dispatch(addivrNum([]));
          navigate(-1);
          setBEdit("");
        } catch (err) {
          toast.error(err.message.toLowerCase());
        }
      } else {
        try {
          await dispatch(createBuyerRoute(finalData, id));
          dispatch(addcallSchedule([]));
          dispatch(addivrNum([]));
          navigate(-1);
        } catch (err) {
          toast.error(err.message.toLowerCase());
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type !== "ppc") {
      if (select.method === "ping-post") {
        setButtonValue1("ping");
        setFMapFields([]);
        setPingMapFields([]);
        setFile(null);
        setPingFile(null);
      } else {
        setButtonValue1("post");
        setFMapFields([]);
        setPingMapFields([]);
        setFile(null);
        setPingFile(null);
      }
    }
  }, [select && select.method]);
  const handleDelete = (id) => {
    if (buttonValue1 === "post") {
      setMapFields({
        ...mapFields,
        [buttonValue]: mapFields[buttonValue].filter((val) => val.id !== id),
      });
      const data = mapFields[buttonValue].filter((val) => val.id !== id);
    } else {
      setMapFields({
        ...mapFields,
        [`${buttonValue1}_${buttonValue}`]: mapFields[
          `${buttonValue1}_${buttonValue}`
        ].filter((val) => val.id !== id),
      });
    }
  };
  const handleEditField = (data) => {
    const exists =
      buttonValue1 === "post"
        ? mapFields[buttonValue].some(
            (val) =>
              val.key.toLowerCase() === data.edit_buyer_field.toLowerCase() &&
              val.id !== editId
          )
        : mapFields[`${buttonValue1}_${buttonValue}`].find(
            (val) =>
              val.key.toLowerCase() === data.edit_buyer_field.toLowerCase() &&
              val.id !== editId
          );
    if (exists) {
      toast.error("Buyer field  already exists");
    } else {
      if (buttonValue1 === "post") {
        const data1 = mapFields[buttonValue].map((val) => {
          if (val.id === editId) {
            if (val.default === 0) {
              return {
                ...val,
                key: data.edit_buyer_field,
                map_field: data.edit_map_field.value,
              };
            } else {
              return {
                ...val,
                key: data.edit_buyer_field,
                value: data.edit_map_field,
              };
            }
          } else {
            return val;
          }
        });
        setMapFields({ ...mapFields, [buttonValue]: data1 });
      } else {
        const data1 = mapFields[`${buttonValue1}_${buttonValue}`].map((val) => {
          if (val.id === editId) {
            if (val.default === 0) {
              return {
                ...val,
                key: data.edit_buyer_field,
                map_field: data.edit_map_field.value,
              };
            } else {
              return {
                ...val,
                key: data.edit_buyer_field,
                value: data.edit_map_field,
              };
            }
          } else {
            return val;
          }
        });
        setMapFields({
          ...mapFields,
          [`${buttonValue1}_${buttonValue}`]: data1,
        });
      }
      setEditId("");
    }
  };
  const handleFileRead = (e) => {
    setFile(fileReader.result);
  };
  const handleFileRead1 = (e) => {
    setPingFile(fileReader.result);
  };

  const handleFileChosen = async (e) => {
    if (
      e.target.files[0].type === "text/xml" ||
      e.target.files[0].type === "application/json"
    ) {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(e.target.files[0]);
    } else {
      toast.error("Please Upload Valid File");
    }
  };
  const handleFileChosenForPing = (e) => {
    if (
      e.target.files[0].type === "text/xml" ||
      e.target.files[0].type === "application/json"
    ) {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead1;
      fileReader.readAsText(e.target.files[0]);
    } else {
      toast.error("Please Upload Valid File");
    }
  };

  const handleClone = () => {
    setMapFields({
      ...mapFields,
      params: mapFields.ping_params,
      header: mapFields.ping_header,
      auth: mapFields.ping_auth,
      body: mapFields.ping_body,
    });
  };

  useEffect(() => {
    if (select.file_type === "xml") {
      if (file) {
        const xml = new XMLParser().parseFromString(file);
        if (xml) {
          setFMapFields(xml);
        } else {
          alert("Invalid XML file");
        }
      }
      if (pingFile) {
        const xml = new XMLParser().parseFromString(pingFile);
        if (xml) {
          setPingMapFields(xml);
        } else {
          alert("Invalid XML file");
        }
      }
    } else {
      if (file) {
        try {
          const json = JSON.parse(file);
          setFMapFields(json);
        } catch (e) {
          toast.error("Invalid JSON File");
        }
      }
      if (pingFile) {
        try {
          const json = JSON.parse(pingFile);
          setPingMapFields(json);
        } catch (e) {
          toast.error(e.message.toLowerCase());
        }
      }
    }
  }, [file, pingFile]);
  const modifymapFields = (route_settings) => {
    let data1 = {
      params: [],
      body: [],
      header: [],
      auth: [],
      ping_params: [],
      ping_body: [],
      ping_header: [],
      ping_auth: [],
    };
    route_settings &&
      route_settings.map((val) => {
        if (val.category === "ping_params") {
          return (data1 = {
            ...data1,
            ping_params: [...data1.ping_params, val],
          });
        } else if (val.category === "ping_header") {
          return (data1 = {
            ...data1,
            ping_header: [...data1.ping_header, val],
          });
        } else if (val.category === "ping_auth") {
          return (data1 = {
            ...data1,
            ping_auth: [...data1.ping_auth, val],
          });
        } else if (val.category === "ping_body") {
          return (data1 = {
            ...data1,
            ping_body: [...data1.ping_body, val],
          });
        } else if (val.category === "params") {
          return (data1 = { ...data1, params: [...data1.params, val] });
        } else if (val.category === "body") {
          return (data1 = { ...data1, body: [...data1.body, val] });
        } else if (val.category === "auth") {
          return (data1 = { ...data1, auth: [...data1.auth, val] });
        } else if (val.category === "header") {
          return (data1 = { ...data1, header: [...data1.header, val] });
        }
      });
    setMapFields(data1);
  };

  //..........................for edit.........................
  const getById = async () => {
    setBEdit(bId);
    try {
      await dispatch(getBuyerRouteById(id, bId));
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (type === "lead") {
      if (props.name === "edit" && singleRoute) {
        let editData = {
          select: "10",
          route_name: singleRoute.name,
          ping_url: singleRoute.ping_url,
          post_url: singleRoute.post_url,
          description: singleRoute.desc,
          price: singleRoute.price.toString(),
          no_of_leads: singleRoute.no_of_leads.toString(),
          success_key: singleRoute.success_key,
          failure_key: singleRoute.failure_key,
          ping_id: singleRoute.ping_id_key,
          include_lead_id: singleRoute.include_lead_id,
          buyer_status: {
            value: singleRoute.vertical_buyer_status,
            label:
              singleRoute.vertical_buyer_status &&
              singleRoute.vertical_buyer_status
                .toLowerCase()
                .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
          },
          postback_url: singleRoute.postback_url,
          method:
            singleRoute.method === "post"
              ? { value: "post", label: "Post" }
              : { label: "Ping-Post", value: "ping-post" },
          content_type: {
            label: singleRoute.buyer_send_mode && singleRoute.buyer_send_mode
              .toLowerCase()
              .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
            value: singleRoute.buyer_send_mode,
          },
          response_type: {
            value: singleRoute.buyer_response_mode,
            label: singleRoute.buyer_response_mode && singleRoute.buyer_response_mode
              .toLowerCase()
              .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
          },
          vertical_id: {
            value: singleRoute.vertical_id,
            label: singleRoute.vertical_name,
          },
          groups: singleRoute.group
            ? singleRoute.group.map((val) => {
                return { value: val.group_id, label: val.group_name };
              })
            : [],
          delivery_type: {
            label: singleRoute.delivery_type && singleRoute.delivery_type
              .toLowerCase()
              .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
            value: singleRoute.delivery_type,
          },
        };

        for (let c of singleRoute.route_filters) {
          editData = {
            ...editData,
            [`f_${c.key}`]: c["value"],
            [`select_${c.key}`]: { value: c["match"], label: c["label"] },
          };
        }

        for (let d of singleRoute.route_formats) {
          if (
            d["value"] === "YEARS" ||
            d["value"] === "MM-DD-YYYY" ||
            d["value"] === "HH-MM-SS" ||
            d["value"] === "SS-MM-HH" ||
            d["value"] === "1(555)-555-5555" ||
            d["value"] === "123-123-1234" ||
            d["value"] === "9090909090"
          ) {
            editData = {
              ...editData,
              [`select_dataFormats_${d.key}`]: {
                value: d["map_field"],
                label: d["map_field"],
              },
              [`dataFormats_${d.key}`]: {
                value: d["value"],
                label: d["value"],
              },
            };
          } else {
            editData = {
              ...editData,
              [`select_dataFormats_${d.key}`]: {
                value: d["map_field"],
                label: d["map_field"],
              },
              [`dataFormats_custom_${d.key}`]: d["value"],
            };
          }
        }

        handleChangeVertical(singleRoute.vertical_id);
        singleRoute.route_settings &&
          modifymapFields(singleRoute.route_settings);

        setSelect({
          ...select,
          method: singleRoute.method,

          route_formats: singleRoute.route_formats
            ? singleRoute.route_formats
            : [],
          route_filters: singleRoute.route_filters
            ? singleRoute.route_filters
            : [],
        });
        reset(editData);
        setChecked(singleRoute.include_lead_id ? true : false);

        // setMapFields(data1);
        setRouteStatus(singleRoute.route_statuses);
      } else {
        setMapFields({
          params: [],
          body: [],
          header: [],
          auth: [],
          ping_params: [],
          ping_body: [],
          ping_header: [],
          ping_auth: [],
        });
        setSelect({
          route_formats: [],
          route_filters: [],
        });
      }
    } else {
      if (props.name === "edit" && singleRoute && bEdit) {
        let editData = {
          select: "10",
          route_name: singleRoute.name,
          description: singleRoute.desc,
          price: singleRoute.price.toString(),
          no_of_calls: singleRoute.no_of_calls.toString(),
          success_time: singleRoute.success_duration.toString(),
          transfer_number: singleRoute.transfer_number,
          buyer_status: {
            value: singleRoute.status,
            label:
              singleRoute.status &&
              singleRoute.status
                .toLowerCase()
                .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
          },
          delivery_type: {
            label: singleRoute.delivery_type && singleRoute.delivery_type
              .toLowerCase()
              .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
            value: singleRoute.delivery_type,
          },
          group_id: singleRoute.group_id
            ? {
                value: singleRoute.group_id,
                label: singleRoute.group_name,
              }
            : "",
          content_type: {
            label: singleRoute.buyer_send_mode &&  singleRoute.buyer_send_mode 
              .toLowerCase()
              .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
            value: singleRoute.buyer_send_mode,
          },
          response_type: {
            value: singleRoute.buyer_response_mode,
            label: singleRoute.buyer_response_mode && singleRoute.buyer_response_mode
              .toLowerCase()
              .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
            value: singleRoute.delivery_type,
          },
          call_type: singleRoute.call_type && {
            value: singleRoute.call_type,
            label: singleRoute.call_type === "ping-post" ? "Ping Post" : "Post",
          },

          welcome_message: singleRoute.welcome_message,
          wrong_input_message: singleRoute.wrong_input_message,
          no_input_message: singleRoute.no_input_message,
          ping_url: singleRoute.ping_url,
        };
        reset(editData);
        dispatch(addcallSchedule(singleRoute.schedules));
        type === "ivr" && dispatch(addivrNum(singleRoute.ivr_settings));
        type === "ppc" &&
          singleRoute.call_type === "ping-post" &&
          modifymapFields(singleRoute.route_settings);
        // setMapFields(singleRoute.route_settings);
      }
    }
    reset();
  }, [singleRoute, bEdit]);
  useEffect(() => {
    if (props.name === "edit") {
      getById();
    }
  }, []);
  const handleRouteStatus = (data) => {
    const newData = {
      status_name: data.select_status.value,
      buyer_status_name: data.status,
      id: Date.now().toString(),
    };
    if (editRouteStatusId === "") {
      const exists = routeStatus.some(
        (val) =>
          val.buyer_status_name.toLowerCase() === data.status.toLowerCase()
      );

      if (exists) {
        toast.error("Status Already Exists");
      } else {
        setRouteStatus([...routeStatus, newData]);
        reset3({ select_status: "", status: "" });
      }
    } else {
      const exists = routeStatus.some(
        (val) =>
          val.buyer_status_name.toLowerCase() === data.status.toLowerCase() &&
          val.id !== editRouteStatusId
      );
      if (exists) {
        toast.error("Status Already Exists");
      } else {
        setRouteStatus(
          routeStatus.map((val) =>
            val.id === editRouteStatusId ? newData : val
          )
        );
        setEditRouteStatusId("");
        reset3({ select_status: "", status: "" });
      }
    }
  };
  const handleDeleteRouteStatus = (id) => {
    setRouteStatus(routeStatus.filter((val) => val.id !== id));
  };
  const handleEditRouteStatus = (data) => {
    reset3({
      select_status: { value: data.status_name, label: data.status_name },
      status: data.buyer_status_name,
    });
    setEditRouteStatusId(data.id);
  };
  const onError = (errors, e) => toast.error("Please Fill Required Fields");
  const loadOptions = async (inputValue, callback, list, type) => {
    try {
      const data =
        list === "verticalFields"
          ? await dispatch(
              getAllVerticalFields(
                1,
                10,
                getValues("vertical_id").value,
                undefined,
                inputValue
              )
            )
          : list === "verticals"
          ? await dispatch(getAllVerticals(1, 10, undefined, inputValue))
          : list === "verticalFieldsbyFormat"
          ? await dispatch(
              getVerticalFieldsByFormat(
                1,
                10,
                inputValue,
                type,
                getValues("vertical_id").value
              )
            )
          : await dispatch(
              getAllGroups(
                1,
                10,
                {
                  vertical_id: getValues("vertical_id")
                    ? [getValues("vertical_id").value]
                    : [],
                  type: type,
                },
                inputValue
              )
            );

      callback(
        list === "verticalFields"
          ? data &&
              data.list.map((val) => ({
                value: val.name,
                label: val.name,
              }))
          : data &&
              data.list.map((val) => ({
                value: val._id,
                label: val.name,
              }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const defaultVerticals = allVerticals
    ? allVerticals.list.map((val) => {
        return {
          label: val.name,
          value: val._id,
        };
      })
    : [];
  const defaultVerticalFields = allVerticalFields
    ? allVerticalFields.list.map((val) => {
        return {
          label: val.name,
          value: val.name,
        };
      })
    : [];
  const verticalFieldsByFormat =
    allVerticalFieldsByFormat &&
    allVerticalFieldsByFormat.list.map((val) => {
      return {
        label: val.name,
        value: val.name,
      };
    });
  const defaultGroups = allGroups
    ? allGroups.list.map((val) => {
        return {
          label: val.name,
          value: val._id,
        };
      })
    : [];
  //handle data filters
  const handleChangeFilters = (value, item, label) => {
    if (value) {
      if (select.route_filters.length) {
        if (select.route_filters.find((val) => val.key === item.name)) {
          const data = select.route_filters.map((val) => {
            if (val.key === item.name) {
              return {
                ...val,
                match: value ? value : "",
                label: label ? label : "",
              };
            } else {
              return val;
            }
          });
          setSelect({
            ...select,
            [item.name]: value ? value : "",
            route_filters: data,
          });
        } else {
          setSelect({
            ...select,
            [item.name]: value ? value : "",
            route_filters: [
              ...select.route_filters,
              {
                key: item.name,
                match: value ? value : "",
                dataType: item.datatype,
                label: label ? label : "",
              },
            ],
          });
        }
      } else {
        setSelect({
          ...select,
          [item.name]: value ? value : "",
          route_filters: [
            ...select.route_filters,
            {
              key: item.name,
              match: value ? value : "",
              label: label ? label : "",
              dataType: item.datatype,
            },
          ],
        });
      }
    } else {
      clearErrors([`f_${item.name}`]);
      if (select.route_filters.length) {
        if (select.route_filters.find((val) => val.key === item.name)) {
          const data = select.route_filters.map((val) => {
            if (val.key === item.name) {
              return {
                ...val,
                match: value ? value : "",
                label: label ? label : "",
              };
            } else {
              return val;
            }
          });
          setSelect({
            ...select,
            [item.name]: value ? value : "",
            route_filters: data,
          });
        } else {
          setSelect({
            ...select,
            [item.name]: value ? value : "",
            route_filters: [
              ...select.route_filters,
              {
                key: item.name,
                match: value ? value : "",
                dataType: item.datatype,
                label: label ? label : "",
              },
            ],
          });
        }
      }
    }
  };

  const handleChangeSelect = (value) => {
    setVFCout(parseInt(value));
    setVfCurrentPage(1);
  };
  const memonizedDataFormats = useMemo(() => {
    return (
      <DataFormats
        isOpen={props.isOpen}
        select={select}
        setSelect={setSelect}
      />
    );
  }, [props.isOpen, select]);
  return (
    <>
      <ScrollView
        marginLeft={
          props.isOpen ? [aw(0), aw(3), aw(176)] : [aw(0), aw(3), aw(96)]
        }
        height={ah(698)}
        paddingBottom={ah(50)}
      >
        <Box marginTop={ah(30)}>
          <LeadsWatchCard
            // width={aw(1235)}
            width={
              props.isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
            // width={[aw(350), aw(350), aw(1248)]}
            // left={
            //   props.isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]
            // }
            // left={[aw(0), aw(3), aw(98)]}
            height={ah(650)}
            alignItems={"flex-start"}
          >
            <VStack py={ah(5)} width="100%" px={aw(15)}>
              <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <VStack>
                  <Text
                    style={{ fontFamily: "RobotoMedium", fontSize: aw(26) }}
                    color="#121233"
                  >
                    {props.name === "edit"
                      ? "Edit Buyer Route"
                      : "Create  Buyer Route"}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "RobotoRegular",
                      fontSize: aw(14),
                      color: "#919191",
                    }}
                  >
                    {props.name === "create"
                      ? "Create  Buyer Route"
                      : "Edit  Buyer Route"}
                  </Text>
                </VStack>
                <HStack space={aw(12)}>
                  <LeadsWatchButton
                    onPress={() => {
                      navigate(-1);
                      if (type === "ivr" || type === "ppc") {
                        dispatch(addcallSchedule([]));
                        dispatch(addivrNum([]));
                      }
                    }}
                    label={"Cancel"}
                    style={{
                      width: aw(100),
                      height: ah(40),
                      borderRadius: "6px",
                    }}
                    color="#212121"
                    fontSize={aw(14)}
                    fontFamily="RobotoMedium"
                    bg={"#FFFFFF"}
                    borderColor={"#D1D5DB"}
                    fontWeight="400"
                  />
                  <LeadsWatchButton
                    isLoading={loading}
                    onPress={handleSubmit(handleCreate, onError)}
                    label={props.name === "edit" ? "update" : "Create"}
                    style={{
                      width: aw(100),
                      height: ah(40),
                      borderRadius: "6px",
                    }}
                    color="#FFFFFF"
                    fontSize={aw(14)}
                    fontFamily="RobotoMedium"
                    bg={"#2230F8"}
                    fontWeight="400"
                    borderColor="#FFFFFF"
                  />
                </HStack>
              </HStack>
              <Divider
                marginTop={ah(18)}
                bg={"#D1D5DB"}
                marginBottom={ah(18)}
              />
              {type === "lead" && (
                <VStack width="100%" space={ah(15)}>
                  <Text
                    // marginTop={ah(18)}
                    style={{
                      fontFamily: "RobotoMedium",
                      color: "#121233",
                      fontSize: ah(18),
                    }}
                  >
                    Route Details
                  </Text>
                  <Flex
                    direction="row"
                    height={ah(50)}
                    // width={aw(1195)}
                    width="100%"
                    // marginTop={ah(10)}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Flex width="49%">
                      <LeadsWatchFilterInput
                        maxLength={32}
                        screen={props.name}
                        hasValue={
                          getValues("route_name") &&
                          getValues("route_name").length > 0
                            ? false
                            : false
                        }
                        name={"route_name"}
                        placeholder={"Name"}
                        control={control}
                        width={"100%"}
                        rules={{
                          required: {
                            value: true,
                            message: "Name is required",
                          },
                          pattern: {
                            // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                            message:
                              "Buyer Name should start with alphabets and can not contain special characters",
                          },
                        }}
                        invalid={errors.route_name ? true : false}
                        error={
                          errors.route_name ? errors.route_name.message : ""
                        }
                      />
                    </Flex>
                    <Flex width="49%">
                      <LeadsWatchAsyncSelect
                        height={38}
                        screen={props.name}
                        hasValue={getValues("buyer_status") ? false : true}
                        minWidth={"100%"}
                        defaultOptions={[
                          { label: "Active", value: "active" },
                          { label: "Inactive", value: "inactive" },
                        ]}
                        control={control}
                        placeholder="Status"
                        name="buyer_status"
                        rules={{
                          required: {
                            value: true,
                            message: "Status is required",
                          },
                        }}
                        invalid={errors.buyer_status ? true : false}
                        error={
                          errors.buyer_status ? errors.buyer_status.message : ""
                        }
                      />
                    </Flex>
                  </Flex>

                  <Flex
                    // mt={ah(20)}
                    justifyContent={"space-between"}
                    height={ah(173)}
                    direction="row"
                  >
                    <LeadsWatchTextArea
                      screen={
                        getValues("description") &&
                        getValues("description").length > 0
                          ? "edit"
                          : "create"
                      }
                      hasValue={
                        getValues("description") &&
                        getValues("description").length > 0
                          ? false
                          : true
                      }
                      marginTop={ah(18)}
                      height={ah(160)}
                      control={control}
                      name="description"
                      placeholder="Description"
                      style={{ width: "49%" }}
                    />

                    <VStack
                      justifyContent={"space-between"}
                      width="49%"
                      height={ah(160)}
                    >
                      <LeadsWatchAsyncSelect
                        height={38}
                        screen={props.name}
                        hasValue={getValues("vertical_id") ? false : true}
                        minWidth={"100%"}
                        type="async"
                        handleChange={(value) => handleChangeVertical(value)}
                        loadOptions={(inputValues, callback) =>
                          loadOptions(inputValues, callback, "verticals")
                        }
                        onInputChange={(inputValues) => {
                          if (inputValues === "") {
                            fetchVerticals();
                          }
                        }}
                        defaultOptions={defaultVerticals}
                        control={control}
                        placeholder="Select Vertical"
                        name="vertical_id"
                        rules={{
                          required: {
                            value: true,
                            message: "Vertical is required",
                          },
                        }}
                        invalid={errors.vertical_id ? true : false}
                        error={
                          errors.vertical_id ? errors.vertical_id.message : ""
                        }
                      />
                      <Flex width="100%">
                        <LeadsWatchAsyncSelect
                          height={38}
                          screen={props.name}
                          hasValue={getValues("content_type") ? false : true}
                          minWidth={"100%"}
                          defaultOptions={[
                            {
                              label: "Application/json",
                              value: "application/json",
                            },
                            {
                              label: "Application/x-www-from-url-encoded",
                              value: "application/x-www-from-url-encoded",
                            },
                            {
                              label: "Multipart/form-data",
                              value: "multipart/form-data",
                            },
                          ]}
                          control={control}
                          placeholder="Content Type"
                          name="content_type"
                          rules={{
                            required: {
                              value: true,
                              message: "Content Type is required",
                            },
                          }}
                          invalid={errors.content_type ? true : false}
                          error={
                            errors.content_type
                              ? errors.content_type.message
                              : ""
                          }
                        />
                      </Flex>
                      <Flex width="100%">
                        <LeadsWatchAsyncSelect
                          isMulti={true}
                          height={38}
                          screen={
                            getValues("groups") && getValues("groups").length
                              ? "edit"
                              : "create"
                          }
                          // refresh={refresh}
                          minWidth={"100%"}
                          type="async"
                          loadOptions={(inputValues, callback) =>
                            loadOptions(inputValues, callback, "groups")
                          }
                          onInputChange={async (inputValues) => {
                            if (inputValues === "") {
                              if (getValues("vertical_id")) {
                                try {
                                  await dispatch(
                                    getAllGroups(1, 10, {
                                      vertical_id: getValues("vertical_id")
                                        ? [getValues("vertical_id").value]
                                        : [],
                                      type: type,
                                    })
                                  );
                                } catch (err) {
                                  console.log(err.message);
                                }
                              }
                            }
                          }}
                          isClearable={true}
                          defaultOptions={defaultGroups}
                          control={control}
                          placeholder="Select Group"
                          name="groups"
                          // rules={{
                          //   required: {
                          //     value: true,
                          //   },
                          // }}
                          // invalid={errors.group_id ? true : false}
                          // error={errors.group_id ? errors.group_id.message : ""}
                        />
                      </Flex>
                    </VStack>
                  </Flex>

                  {props.name === "edit" && (
                    <HStack width={"100%"} mb={ah(15)}>
                      <LeadsWatchFilterInput
                        screen={props.name}
                        isReadOnly={true}
                        width={"100%"}
                        height={ah(40)}
                        name="postback_url"
                        control={control}
                        placeholder="postback url"
                        rightIcon={
                          <CopyToClipboard
                            text={getValues("postback_url")}
                            onCopy={() => {
                              toast.success("postback URL Copied to Clipboard");
                            }}
                          >
                            <LeadsWatchCopyCodeIcon />
                          </CopyToClipboard>
                        }
                      />
                    </HStack>
                  )}
                </VStack>
              )}
              {(type === "ivr" || type === "ppc") && (
                <VStack width="100%" space={ah(15)} pb={ah(20)}>
                  <Text
                    // marginTop={ah(5)}
                    style={{
                      fontFamily: "RobotoMedium",
                      color: "#121233",
                      fontSize: ah(18),
                    }}
                  >
                    Route Details
                  </Text>
                  <Flex
                    direction="row"
                    // height={ah(50)}
                    // width={aw(1195)}
                    width="100%"
                    marginTop={ah(10)}
                    // alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Flex width="49%" gap={ah(20)}>
                      <Flex width="100%">
                        <LeadsWatchFilterInput
                          screen={props.name}
                          hasValue={
                            getValues("route_name") &&
                            getValues("route_name").length > 0
                              ? false
                              : false
                          }
                          name={"route_name"}
                          placeholder={"Name"}
                          control={control}
                          width={"100%"}
                          rules={{
                            required: {
                              value: true,
                              message: "Name is required",
                            },
                          }}
                          invalid={errors.route_name ? true : false}
                          error={
                            errors.route_name ? errors.route_name.message : ""
                          }
                        />
                      </Flex>
                      <Flex width="100%">
                        <LeadsWatchAsyncSelect
                          height={38}
                          screen={props.name}
                          hasValue={getValues("buyer_status") ? false : true}
                          minWidth={"100%"}
                          defaultOptions={[
                            { label: "Active", value: "active" },
                            { label: "Inactive", value: "inactive" },
                          ]}
                          control={control}
                          placeholder="Status"
                          name="buyer_status"
                          rules={{
                            required: {
                              value: true,
                              message: "Status is required",
                            },
                          }}
                          invalid={errors.buyer_status ? true : false}
                          error={
                            errors.buyer_status
                              ? errors.buyer_status.message
                              : ""
                          }
                        />
                      </Flex>
                      <Flex width="100%" justifyContent={"space-between"}>
                        <LeadsWatchAsyncSelect
                          height={38}
                          screen={
                            getValues("group_id") && getValues("group_id").value
                              ? "edit"
                              : "create"
                          }
                          // refresh={refresh}
                          minWidth={"100%"}
                          type="async"
                          loadOptions={(inputValues, callback) =>
                            loadOptions(inputValues, callback, "groups")
                          }
                          onInputChange={async (inputValues) => {
                            if (inputValues === "") {
                              try {
                                await dispatch(
                                  getAllGroups(1, 10, {
                                    vertical_id: getValues("vertical_id")
                                      ? [getValues("vertical_id").value]
                                      : [],
                                    type: type,
                                  })
                                );
                              } catch (err) {
                                console.log(err.message);
                              }
                            }
                          }}
                          isClearable={true}
                          defaultOptions={defaultGroups}
                          control={control}
                          placeholder="Select Group"
                          name="group_id"
                          // rules={{
                          //   required: {
                          //     value: true,
                          //   },
                          // }}
                          // invalid={errors.group_id ? true : false}
                          // error={errors.group_id ? errors.group_id.message : ""}
                        />
                      </Flex>
                    </Flex>
                    <Flex width="49%">
                      <LeadsWatchTextArea
                        screen={
                          getValues("description") &&
                          getValues("description").length > 0
                            ? "edit"
                            : "create"
                        }
                        hasValue={
                          getValues("description") &&
                          getValues("description").length > 0
                            ? false
                            : true
                        }
                        // marginTop={ah(18)}
                        height={ah(157)}
                        control={control}
                        name="description"
                        placeholder="Description"
                        style={{ width: "100%" }}
                      />
                    </Flex>
                  </Flex>
                </VStack>
              )}
            </VStack>
          </LeadsWatchCard>
        </Box>
        {type === "lead" && (
          <>
            <Box marginTop={ah(30)}>
              <LeadsWatchCard
                width={
                  props.isOpen
                    ? [aw(350), aw(350), aw(1168)]
                    : [aw(350), aw(350), aw(1248)]
                }
                height={ah(600)}
                alignItems={"flex-start"}
              >
                <VStack
                  py={5}
                  justifyContent={"center"}
                  px={aw(15)}
                  width={"100%"}
                >
                  <HStack width={"100%"} height={ah(40)}>
                    <Text
                      style={{
                        fontFamily: "RobotoMedium",
                        fontSize: aw(18),
                        color: "#121233",
                      }}
                    >
                      Route Configuration
                    </Text>
                  </HStack>
                  <HStack
                    marginTop={ah(5)}
                    width={"100%"}
                    justifyContent={"space-between"}
                  >
                    <VStack space={ah(25)} width="49%">
                      <LeadsWatchAsyncSelect
                        screen={props.name}
                        hasValue={getValues("method") ? false : true}
                        minWidth={"100%"}
                        defaultOptions={[
                          { label: "Post", value: "post" },
                          { label: "Ping-Post", value: "ping-post" },
                        ]}
                        handleChange={(value) =>
                          setSelect({ ...select, method: value.value })
                        }
                        control={control}
                        placeholder="Method"
                        name="method"
                        rules={{
                          required: {
                            value: true,
                            message: "Method is required",
                          },
                        }}
                        invalid={errors.method ? true : false}
                        error={errors.method ? errors.method.message : ""}
                      />
                      <LeadsWatchFilterInput
                        screen={props.name}
                        hasValue={
                          getValues("post_url") &&
                          getValues("post_url").length > 0
                            ? false
                            : true
                        }
                        name={"post_url"}
                        placeholder={"URL"}
                        control={control}
                        // style={{ width: aw(587), height: ah(40) }}
                        width="100%"
                        height={ah(40)}
                        rules={{
                          required: {
                            value: true,
                            message: "URL is required",
                          },
                          pattern: {
                            value:
                              /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                            message: "Please enter a valid URL",
                          },
                        }}
                        invalid={errors.post_url ? true : false}
                        error={errors.post_url ? errors.post_url.message : ""}
                      />
                      {select.method && select.method === "ping-post" && (
                        <LeadsWatchFilterInput
                          screen={props.name}
                          hasValue={
                            getValues("ping_url") &&
                            getValues("ping_url").length > 0
                              ? false
                              : true
                          }
                          name={"ping_url"}
                          placeholder={"Ping URL"}
                          control={control}
                          // style={{ width: aw(587), height: ah(40) }}
                          width="100%"
                          height={ah(40)}
                          rules={{
                            required: {
                              value: true,
                              message: "Ping URL is required",
                            },
                            pattern: {
                              value:
                                /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                              message: "Please enter a valid URL",
                            },
                          }}
                          invalid={errors.ping_url ? true : false}
                          error={errors.ping_url ? errors.ping_url.message : ""}
                        />
                      )}
                      <Flex width="100%">
                        <LeadsWatchAsyncSelect
                          maxLength={32}
                          screen={props.name}
                          hasValue={getValues("delivery_type") ? false : true}
                          minWidth={"100%"}
                          defaultOptions={[
                            { label: "Hourly", value: "hourly" },
                            { label: "Daily", value: "daily" },
                            { label: "Weekly", value: "weekly" },
                            { label: "Montly", value: "monthly" },
                            { label: "Yearly", value: "yearly" },
                          ]}
                          control={control}
                          placeholder="Leads Limit Type"
                          name="delivery_type"
                          rules={{
                            required: {
                              value: true,
                              message: "Leads Limit Type is required",
                            },
                          }}
                          invalid={errors.delivery_type ? true : false}
                          error={
                            errors.delivery_type
                              ? errors.delivery_type.message
                              : ""
                          }
                        />
                      </Flex>
                      <LeadsWatchFilterInput
                        onlyNumers={true}
                        screen={props.name}
                        hasValue={
                          getValues("no_of_leads") &&
                          getValues("no_of_leads").length > 0
                            ? false
                            : true
                        }
                        name={"no_of_leads"}
                        placeholder={"Leads Limit"}
                        control={control}
                        // style={{ width: aw(587), height: ah(40) }}
                        width="100%"
                        height={ah(40)}
                        rules={{
                          required: {
                            value: true,
                            message: "Leads Limit is required",
                          },
                        }}
                        invalid={errors.no_of_leads ? true : false}
                        error={
                          errors.no_of_leads ? errors.no_of_leads.message : ""
                        }
                      />

                      <LeadsWatchFilterInput
                        onlyNumers={true}
                        screen={props.name}
                        hasValue={
                          getValues("price") && getValues("price").length > 0
                            ? false
                            : true
                        }
                        name={"price"}
                        placeholder={"Amount"}
                        control={control}
                        // style={{ width: aw(587), height: ah(40) }}
                        width={"100%"}
                        height={ah(40)}
                        rules={{
                          required: {
                            value: true,
                            message: "Amount is required",
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Amount should be numbers only",
                          },
                        }}
                        invalid={errors.price ? true : false}
                        error={errors.price ? errors.price.message : ""}
                      />
                    </VStack>
                    <VStack space={aw(20)} width="49%">
                      <LeadsWatchCard
                        shadow={true}
                        borderWidth={"1px"}
                        borderColor={"#0000001A"}
                        // width={aw(587)}
                        width={"100%"}
                        height={ah(500)}
                        pb={5}
                      >
                        <HStack
                          px={5}
                          borderBottomColor={"#D7D7D7"}
                          borderBottomWidth={"1px"}
                          borderTopRadius={"14px"}
                          // width={aw(584)}
                          width="100%"
                          bg="#FAFBFD"
                          height={ah(50)}
                          justifyContent="space-between"
                          alignItems={"center"}
                        >
                          <Text
                            fontFamily="RobotoMedium"
                            fontSize={aw(14)}
                            color="#121233"
                          >
                            Response
                          </Text>
                          <LeadsWatchButton
                            onPress={() => setAddResModal(true)}
                            label={" Status Response Config"}
                            style={{
                              width: aw(200),
                              height: ah(32),
                              borderRadius: "6px",
                            }}
                            color="#212121"
                            fontSize={aw(14)}
                            fontFamily="RobotoRegular"
                            bg={"#FFFFFF"}
                            borderColor={"#D1D5DB"}
                            fontWeight="400"
                            leftIcon={<LeadsWatchAddIcon fill="#212121" />}
                            //   onPress={() => navigate("/")}
                          />
                        </HStack>
                        <Flex
                          justifyContent={"space-between"}
                          mt={aw(20)}
                          width="100%"
                          px={5}
                          direction="row"
                        >
                          <Flex width="49%">
                            <LeadsWatchFilterInput
                              screen={props.name}
                              hasValue={
                                getValues("success_key") &&
                                getValues("success_key").length > 0
                                  ? false
                                  : true
                              }
                              name={"success_key"}
                              placeholder={"Success"}
                              control={control}
                              // style={{ width: aw(280), height: ah(40) }}
                              width={"100%"}
                              height={ah(40)}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Success key is required",
                                },
                              }}
                              invalid={errors.success_key ? true : false}
                              error={
                                errors.success_key
                                  ? errors.success_key.message
                                  : ""
                              }
                            />
                          </Flex>
                          <Flex width="49%">
                            <LeadsWatchFilterInput
                              screen={props.name}
                              hasValue={
                                getValues("failure_key") &&
                                getValues("failure_key").length > 0
                                  ? false
                                  : true
                              }
                              name={"failure_key"}
                              placeholder={"Failure"}
                              control={control}
                              // style={{ width: aw(280), height: ah(40) }}
                              width={"100%"}
                              height={ah(40)}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Failure key is required",
                                },
                              }}
                              invalid={errors.failure_key ? true : false}
                              error={
                                errors.failure_key
                                  ? errors.failure_key.message
                                  : ""
                              }
                            />
                          </Flex>
                        </Flex>
                        <HStack
                          mt={ah(20)}
                          px={5}
                          space={aw(5)}
                          alignItems="center"
                          height={ah(20)}
                          width={"100%"}
                        >
                          <Checkbox
                            accessibilityLabel="Include Lead Id"
                            style={{ height: aw(16), width: aw(16) }}
                            bgColor="#FAFBFD"
                            _checked={{
                              bgColor: "#0000FF",
                              borderColor: "#0000FF",
                            }}
                            _icon={{ color: "#FAFBFD" }}
                            _hover={{ color: "#FAFBFD" }}
                            onChange={() => setChecked(!checked)}
                            isChecked={checked ? true : false}
                          />
                          <Text
                            color="#212121"
                            fontSize={aw(14)}
                            fontFamily={"RobotoRegular"}
                          >
                            Custom Lead Id Name
                          </Text>
                        </HStack>
                        {checked && (
                          <HStack
                            mt={ah(20)}
                            px={3}
                            space={aw(5)}
                            alignItems="center"
                            // height={ah(20)}
                            // width={aw(587)}
                            width="100%"
                          >
                            <LeadsWatchFilterInput
                              screen={
                                getValues("include_lead_id") &&
                                getValues("include_lead_id").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              hasValue={
                                getValues("include_lead_id") &&
                                getValues("include_lead_id").length > 0
                                  ? false
                                  : true
                              }
                              name={"include_lead_id"}
                              placeholder={"Lead ID"}
                              control={control}
                              // style={{ width: aw(570), height: ah(40) }}
                              width={"100%"}
                              height={ah(40)}
                            />
                          </HStack>
                        )}

                        {select.method && select.method === "ping-post" && (
                          <HStack
                            mt={ah(20)}
                            px={3}
                            space={aw(5)}
                            alignItems="center"
                            // height={ah(20)}
                            // width={aw(587)}
                            width="100%"
                          >
                            <LeadsWatchFilterInput
                              screen={props.name}
                              hasValue={
                                getValues("ping_id") &&
                                getValues("ping_id").length > 0
                                  ? false
                                  : true
                              }
                              name={"ping_id"}
                              placeholder={"Ping ID"}
                              control={control}
                              // style={{ width: aw(570), height: ah(40) }}
                              width={"100%"}
                              height={ah(40)}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Ping ID is required",
                                },
                              }}
                              invalid={errors.ping_id ? true : false}
                              error={
                                errors.ping_id ? errors.ping_id.message : ""
                              }
                            />
                          </HStack>
                        )}
                      </LeadsWatchCard>
                      <Flex width="100%">
                        <LeadsWatchAsyncSelect
                          screen={props.name}
                          hasValue={getValues("response_type") ? false : true}
                          minWidth={"100%"}
                          defaultOptions={[
                            {
                              label: "Application/json",
                              value: "application/json",
                            },
                            {
                              label: "Application/xml",
                              value: "application/xml",
                            },
                          ]}
                          control={control}
                          placeholder="Response Content Type"
                          name="response_type"
                          rules={{
                            required: {
                              value: true,
                              message: "Response Content Type is required",
                            },
                          }}
                          invalid={errors.response_type ? true : false}
                          error={
                            errors.response_type
                              ? errors.response_type.message
                              : ""
                          }
                        />
                      </Flex>
                    </VStack>
                  </HStack>
                </VStack>
              </LeadsWatchCard>
            </Box>

            <Box marginTop={ah(30)}>{memonizedDataFormats}</Box>
            <Box marginTop={ah(30)}>
              <LeadsWatchCard
                width={
                  props.isOpen
                    ? [aw(350), aw(350), aw(1168)]
                    : [aw(350), aw(350), aw(1248)]
                }
                height={ah(497)}
                pb={ah(10)}
              >
                <VStack
                  justifyContent={"center"}
                  pb={ah(10)}
                  px={aw(15)}
                  width={"100%"}
                >
                  <HStack width={"100%"} height={ah(40)} marginTop={ah(18)}>
                    <Text
                      style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
                      color="#121233"
                    >
                      Data Filters
                    </Text>
                  </HStack>
                  <VStack
                    width={"100%"}
                    borderRadius={"10px"}
                    borderColor={"#D7D7D7"}
                  >
                    <HStack
                      alignItems={"center"}
                      style={{
                        height: ah(60),
                        background: "#F9FAFB 0% 0% no-repeat padding-box",
                        border: "1px solid #D7D7D7",
                        borderTopLeftRadius: aw(10),
                        borderTopRightRadius: aw(10),
                        borderBottom: "0px",
                      }}
                    >
                      {data.map((val) => {
                        return (
                          <HStack
                            alignItems={"center"}
                            width={
                              val === "Filter Value"
                                ? aw(450)
                                : val === "Vertical Field"
                                ? aw(400)
                                : aw(350)
                            }
                            justifyContent="flex-start"
                            key={val}
                          >
                            {val === "Filter Value" ? (
                              <HStack
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <Text
                                  // width={aw(340)}
                                  fontSize={aw(14)}
                                  fontFamily={"RobotoMedium"}
                                  ml={aw(20)}
                                  color="#121233"
                                >
                                  {val}
                                </Text>
                                <Tooltip
                                  label={
                                    "Please enter values based on data types.For match type ex: in between:[1,10], equal to:1,exact:hello"
                                  }
                                  width={aw(150)}
                                >
                                  <Flex>
                                    <LeadsWatchInfoIcon />
                                  </Flex>
                                </Tooltip>
                              </HStack>
                            ) : (
                              <Text
                                width={aw(340)}
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                                ml={aw(20)}
                                color="#121233"
                              >
                                {val}
                              </Text>
                            )}
                          </HStack>
                        );
                      })}
                    </HStack>

                    <FlatList
                      borderColor="#D7D7D7"
                      borderBottomRightRadius={aw(10)}
                      borderBottomLeftRadius={aw(10)}
                      borderWidth={"1px"}
                      maxHeight={ah(300)}
                      ListEmptyComponent={
                        <HStack
                          justifyContent="center"
                          alignItems={"center"}
                          height={ah(290)}
                        >
                          <Text
                            color="black"
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            No data found
                          </Text>
                        </HStack>
                      }
                      data={
                        getValues("vertical_id")
                          ? allVerticalFields &&
                            allVerticalFields.list &&
                            allVerticalFields.list
                          : []
                      }
                      keyExtractor={(item, index) => item.name}
                      renderItem={({ item, index }) => (
                        <VStack>
                          <HStack
                            height={ah(60)}
                            alignItems={"center"}
                            bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                            style={{
                              borderBottomWidth:
                                index === allVerticalFields.list.length - 1
                                  ? "0px"
                                  : "1px",
                              borderColor: "#D7D7D7",
                            }}
                          >
                            <Text
                              color="#121233"
                              width={aw(400)}
                              ml={aw(20)}
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                            >
                              {item.name}
                            </Text>
                            <Stack width={aw(450)}>
                              <LeadsWatchInput
                                name={`f_${item.name}`}
                                onBlur={() => setB(!b)}
                                control={control}
                                style={{ width: aw(140), height: ah(32) }}
                                onChangeText={(value) =>
                                  !value && clearErrors([`select_${item.name}`])
                                }
                                rules={{
                                  required: {
                                    value: getValues(`select_${item.name}`)
                                      ? true
                                      : false,
                                  },
                                }}
                                invalid={
                                  errors[`f_${item.name}`] ? true : false
                                }
                                // invalid={true}
                                error={
                                  errors[`f_${item.name}`]
                                    ? errors[`f_${item.name}`].message
                                    : ""
                                }
                              />
                            </Stack>

                            <HStack width={aw(140)}>
                              <LeadsWatchAsyncSelect
                                // minHeight={34}
                                required={true}
                                // screen={props.name}
                                // hasValue={getValues("vertical_id") ? false : true}
                                isClearable={true}
                                minWidth={aw(140)}
                                defaultOptions={
                                  item.datatype === "string"
                                    ? [
                                        {
                                          label: "exact",
                                          value: "exact",
                                        },
                                        {
                                          label: "contains",
                                          value: "contains",
                                        },
                                        {
                                          label: "not contains",
                                          value: "not_contains",
                                        },
                                      ]
                                    : item.datatype === "boolean"
                                    ? [
                                        {
                                          label: "equal to(=)",
                                          value: "=",
                                        },
                                        {
                                          label: "not equal to(!=)",
                                          value: "!=",
                                        },
                                      ]
                                    : item.datatype === "list"
                                    ? [
                                        {
                                          label: "equal to(=)",
                                          value: "=",
                                        },
                                        {
                                          label: "not equal to(!=)",
                                          value: "!=",
                                        },
                                        {
                                          label: "greater than(>)",
                                          value: ">",
                                        },
                                        {
                                          label: "lessthan(<)",
                                          value: "<",
                                        },
                                        {
                                          label: "greatertham or equal to(>=)",
                                          value: ">=",
                                        },
                                        {
                                          label: "lessthan or equal to(<=)",
                                          value: "<=",
                                        },
                                        {
                                          label: "in between",
                                          value: "in_between",
                                        },
                                        {
                                          label: "exact",
                                          value: "exact",
                                        },
                                        {
                                          label: "contains",
                                          value: "contains",
                                        },
                                        {
                                          label: "not contains",
                                          value: "not_contains",
                                        },
                                      ]
                                    : [
                                        {
                                          label: "equal to(=)",
                                          value: "=",
                                        },
                                        {
                                          label: "not equal to(!=)",
                                          value: "!=",
                                        },
                                        {
                                          label: "greater than(>)",
                                          value: ">",
                                        },
                                        {
                                          label: "lessthan(<)",
                                          value: "<",
                                        },
                                        {
                                          label: "greatertham or equal to(>=)",
                                          value: ">=",
                                        },
                                        {
                                          label: "lessthan or equal to(<=)",
                                          value: "<=",
                                        },
                                        {
                                          label: "in between",
                                          value: "in_between",
                                        },
                                      ]
                                }
                                control={control}
                                placeholder="Select"
                                name={`select_${item.name}`}
                                handleChange={(value) =>
                                  handleChangeFilters(
                                    value ? value.value : "",
                                    item,
                                    value ? value.label : ""
                                  )
                                }
                                rules={{
                                  required: {
                                    value: getValues(`f_${item.name}`)
                                      ? true
                                      : false,
                                  },
                                }}
                                invalid={
                                  errors[`select_${item.name}`] ? true : false
                                }
                                // invalid={true}
                                error={
                                  errors[`select_${item.name}`]
                                    ? errors[`select_${item.name}`].message
                                    : ""
                                }
                              />
                            </HStack>
                          </HStack>
                        </VStack>
                      )}
                    />
                  </VStack>
                </VStack>
                <HStack
                  width={"100%"}
                  justifyContent={"space-between"}
                  px={aw(15)}
                >
                  {getValues("vertical_id") && (
                    <>
                      {allVerticalFields &&
                        allVerticalFields.total_count > vfCount && (
                          <LeadsWatchSelect
                            control={control}
                            name="select"
                            width={79}
                            list={["10", "15", "20", "25"]}
                            // value={status}
                            // handleChange={(value) => setStatus(value)}
                            handleChange={(value) =>
                              handleChangeSelect(parseInt(value))
                            }
                            height={ah(40)}
                          />
                        )}
                      <LeadsWatchPagination
                        // width="100%"
                        type="box"
                        totalCount={
                          allVerticalFields && allVerticalFields.total_count
                            ? allVerticalFields.total_count
                            : 1
                        }
                        currentPage={vfCurrentPage}
                        pageSize={vfCount}
                        onPageChange={async (currentPage) => {
                          const combinedArray = allVerticalFields.list.reduce(
                            (acc, val) => {
                              acc.push(`select_${val.name}`, val.name);
                              return acc;
                            },
                            []
                          );

                          // console.log(combinedArray)
                          const valid = await trigger(combinedArray);

                          // await trigger1()
                          if (valid) {
                            setVfCurrentPage(currentPage);
                          }
                        }}
                      />
                    </>
                  )}
                </HStack>
              </LeadsWatchCard>
            </Box>
            <DataMapping
              isOpen={props.isOpen}
              vfCount={vfCount}
              vfCurrentPage={vfCurrentPage}
              loadOptions={loadOptions}
              defaultVerticalFields={defaultVerticalFields}
              setPingFile={setPingFile}
              pFile={pFile}
              setPFile={setPFile}
              mapFields={mapFields}
              setMapFields={setMapFields}
              select={select}
              buttonValue={buttonValue}
              buttonValue1={buttonValue1}
              setButtonValue={setButtonValue}
              setButtonValue1={setButtonValue1}
              handleClone={handleClone}
              editId={editId}
              setEditId={setEditId}
              handleFileChosen={handleFileChosen}
              handleFileChosenForPing={handleFileChosenForPing}
              handleEditField={handleEditField}
              handleDelete={handleDelete}
              control={control}
              setSelect={setSelect}
              fMapFields={fMapFields}
              pingMapFields={pingMapFields}
              setPingMapFields={setPingMapFields}
              name={props.name}
              file={file}
              pingFile={pingFile}
              setFMapFields={setFMapFields}
              setFile={setFile}
              getValues={getValues}
            />
          </>
        )}
        {(type === "ivr" || type === "ppc") && (
          <IVRCallConfiguration
            control={control}
            errors={errors}
            getValues={getValues}
            isOpen={props.isOpen}
            name={props.name}
            type={type}
            mapFields={mapFields}
            setMapFields={setMapFields}
            buttonValue={buttonValue}
            setButtonValue={setButtonValue}
            editIdm={editId}
            setEditIdm={setEditId}
            handleEditField={handleEditField}
            handleDeletem={handleDelete}
          />
        )}
      </ScrollView>
      <LeadsWatchModal
        height={ah(590)}
        width={aw(650)}
        showModal={addResModal}
        onClose={() => {
          setAddResModal(false);
          reset3({ select_status: "", status: "" });
        }}
        heading={
          <Text color={"#000000"} fontFamily={"RobotoMedium"} fontSize={aw(18)}>
            Status Response Configuration
          </Text>
        }
      >
        <VStack space={ah(20)}>
          <HStack space={aw(10)}>
            <LeadsWatchFilterInput
              // screen={props.name}
              // style={{ width: aw(250), height: ah(40) }}
              width={aw(250)}
              height={36}
              screen={
                getValues3("status") && getValues3("status").length > 0
                  ? "edit"
                  : "create"
              }
              control={control3}
              name="status"
              placeholder="Your Status"
              rules={{
                required: {
                  value: true,
                  message: "Status is required",
                },
              }}
              invalid={errors3.status ? true : false}
              error={errors3.status ? errors3.status.message : ""}
            />
            <HStack>
              <LeadsWatchAsyncSelect
                minWidth={aw(250)}
                screen={getValues3("select_status") ? "edit" : "create"}
                // minWidth={aw(583)}
                defaultOptions={[
                  { value: "Accepted", label: "Accepted" },
                  { value: "Duplicate", label: "Duplicate" },
                  { value: "Failure", label: "Failure" },
                  { value: "New", label: "New" },
                  { value: "Pending", label: "Pending" },
                  { value: "Rejected", label: "Rejected" },
                  { value: "Trash", label: "Trash" },
                ]}
                control={control3}
                name="select_status"
                placeholder="Select"
                rules={{
                  required: {
                    value: true,
                    message: "Select Status is required",
                  },
                }}
                invalid={errors3.select_status ? true : false}
                error={
                  errors3.select_status ? errors3.select_status.message : ""
                }
              />
            </HStack>
            <LeadsWatchButton
              label={editRouteStatusId === "" ? "Add" : "Update"}
              style={{
                width: aw(100),
                height: ah(40),
              }}
              color="#FAFBFD"
              fontSize={aw(14)}
              fontFamily="RobotoMedium"
              bg={"#0000FF"}
              borderColor={"#D1D5DB"}
              fontWeight="400"
              onPress={handleSubmit3(handleRouteStatus)}
            />
          </HStack>
          <VStack>
            <HStack
              alignItems={"center"}
              style={{
                height: ah(60),
                background: "#F9FAFB 0% 0% no-repeat padding-box",
                border: "1px solid #D7D7D7",
                borderTopLeftRadius: aw(10),
                borderTopRightRadius: aw(10),
              }}
            >
              {["Buyer Field", "Map Field", "Actions"].map((val) => {
                return (
                  <HStack
                    width={aw(250)}
                    alignItems={"center"}
                    justifyContent="flex-start"
                    key={val}
                  >
                    {val === "Actions" ? (
                      <Text
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                        ml={aw(20)}
                        color="#121233"
                      >
                        {val}
                      </Text>
                    ) : (
                      <Text
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                        ml={aw(20)}
                        color="#121233"
                      >
                        {val}
                      </Text>
                    )}
                  </HStack>
                );
              })}
            </HStack>
            <ScrollView
              maxHeight={ah(200)}
              // showsVerticalScrollIndicator={false}
              style={{
                borderBottomRightRadius: aw(10),
                borderBottomLeftRadius: aw(10),
                borderbottomWidth: "1px solid #D7D7D7",
                borderTopWidth: "0px",
                borderColor: "#D7D7D7",
                borderWidth: "1px",
              }}
            >
              <FlatList
                ListEmptyComponent={
                  <HStack
                    justifyContent="center"
                    alignItems={"center"}
                    height={ah(100)}
                  >
                    <Text
                      color="black"
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                    >
                      No data found
                    </Text>
                  </HStack>
                }
                data={routeStatus}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index }) => (
                  <HStack
                    height={ah(50)}
                    style={{
                      borderTopWidth: index === 0 ? 0 : "1px",
                      borderLeftWidth: "0px",
                      borderRightWidth: "0px",
                      borderBottomWidth: "0px",
                      borderColor: "#D7D7D7",
                    }}
                  >
                    <HStack
                      width={aw(250)}
                      alignItems={"center"}
                      justifyContent="flex-start"
                    >
                      <Text
                        fontSize={aw(14)}
                        fontFamily={"RobotoRegular"}
                        ml={aw(20)}
                        color="#121233"
                      >
                        {item.buyer_status_name}
                      </Text>
                    </HStack>

                    <HStack
                      width={aw(250)}
                      alignItems={"center"}
                      justifyContent="flex-start"
                    >
                      <Text
                        fontSize={aw(14)}
                        fontFamily={"RobotoRegular"}
                        ml={aw(20)}
                        color="#121233"
                      >
                        {item.status_name}
                      </Text>
                    </HStack>

                    <HStack
                      pl={5}
                      width={aw(500)}
                      alignItems={"center"}
                      justifyContent="flex-start"
                    >
                      <Pressable onPress={() => handleEditRouteStatus(item)}>
                        <LeadsWatchEditIcon />
                      </Pressable>
                      <Pressable
                        onPress={() => handleDeleteRouteStatus(item.id)}
                      >
                        <LeadsWatchDeleteIcon />
                      </Pressable>
                    </HStack>
                  </HStack>
                )}
              />
            </ScrollView>
          </VStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};

export default React.memo(CreateBuyer);
