import {
  FlatList,
  HStack,
  VStack,
  Pressable,
  // Stack,
  Box,
  Popover,
  Text,
  Checkbox,
  ScrollView,
  Spinner,
  // useToast,
  Tooltip,
  Menu,
  Flex,
} from "native-base";
import React, { useState, useRef, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { toast } from "react-toastify";

import { aw, ah } from "../utils";
// import LeadsWatchMoreIcon from "./svg/LeadsWatchMoreIcon";
// import LeadsWatchSortIcon from "./svg/LeadsWatchSortIcon";
// import LeadsWatchMenu from "../components/LeadsWatchMenu";
import LeadsWatchEyeIcon from "./svg/LeadsWatchEyeIcon";
import LeadsWatchDownloadIcon from "./svg/LeadsWatchDownloadIcon";
import LeadsWatchDeleteIcon from "./svg/LeadsWatchDeleteIcon";
import LeadsWatchEditIcon from "./svg/LeadsWatchEditIcon";

// import LeadsWatchExitIcon from "./svg/LeadsWatchExitIcon";
import LeadsWatchModal from "./LeadsWatchModal";
// import LeadsWatchCopyCodeIcon from "./svg/LeadesWatchCopyCodeIcon";

import { viewVertical } from "../store/actions/verticals";
import { addnotes } from "../store/actions/pipelines";
import LeadsWatchUpArrow from "./svg/LeadsWatchUpArrow";
import LeadsWatchDownArrow from "./svg/LeadsWatchDownArrow";
import LeadsWatchCallsIcon from "./svg/LeadsWatchCallsIcon";
import LeadsWatchMenu from "./LeadsWatchMenu";
import LeadsWatchOutGoingCallIcon from "./svg/LeadsWatchOutGoingCallIcon";
import LeadsWatchInComingIcon from "./svg/LeadsWatchIncomingCallIcon";
import LeadsWatchPlayIcon from "./svg/LeadsWatchPlayIcon";
import LeadsWatchMoveIcon from "./svg/LeadsWatchMoveIcon";
import LeadsWatchEditHeaderIcon from "./svg/LeadsWatchEditHeaderIcon";
import LeadsWatchInput from "./LeadsWatchInput";
import LeadsWatchEmailIcon from "./svg/LeadsWatchEmailIcon";
import LeadsWatchInfoIcon from "./svg/LeadsWatchInfoIcon";
import LeadsWatchSingleProfileIcon from "./svg/LeadsWatchSingleProfileIcon";

const LeadsWatchTable = React.memo((props) => {
  const [url, setUrl] = useState("");
  const initialFocusRef = useRef();
  const profile = useSelector((state) => state.auth.profile);
  const [openAudioModal, setOpenAudioModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isVerticalEditModalOpen, setIsVerticalEditModalOpen] = useState(false);
  const [editVertical, setEditVertical] = useState([]);
  const [edit, setEdit] = useState([]);
  const [saving, setSaving] = useState(false);
  // const [selectedRows, setSelectedRows] = useState([]);
  const [noteLoading, setNoteLoading] = useState(false);

  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },

    reset: reset1,
  } = useForm({
    mode: "onSubmit",
  });

  const handleVerticalView = async (id) => {
    try {
      await dispatch(viewVertical(id));
      navigate(`/verticals/ViewVertical/${id}`);
    } catch (err) {
      alert(err.message);
    }
  };
  const capitalizeString = (str) => {
    let wordsArray = str.split("_");

    for (let i = 0; i < wordsArray.length; i++) {
      let word = wordsArray[i];
      wordsArray[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    let capitalizedString = wordsArray.join(" ");

    return capitalizedString;
  };
  const handleAddNotes = async (data, id) => {
    setNoteLoading(true);
    try {
      await dispatch(addnotes(id, data.note));
      reset1();
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setNoteLoading(false);
  };

  const memoizedFlatList = useMemo(() => {
    return (
      <FlatList
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={
          <HStack
            justifyContent="center"
            alignItems={"center"}
            height={props.height ? ah(358) : props.filter ? ah(270) : ah(400)}
          >
            <Text color="black" fontSize={aw(14)} fontFamily={"RobotoMedium"}>
              No data found
            </Text>
          </HStack>
        }
        data={props.data}
        keyExtractor={
          props.screen === "lead"
            ? (item, index) => item.response_id + index.toString()
            : (item, index) => item._id + index.toString()
        }
        renderItem={({ item, index }) => (
          <MemoizedFlatListItem
            item={item}
            index={index}
            props={props}
            navigate={navigate}
            initialFocusRef={initialFocusRef}
            handleSubmit1={handleSubmit1}
            profile={profile}
            handleAddNotes={handleAddNotes}
            control1={control1}
            errors1={errors1}
            noteLoading={noteLoading}
            setUrl={setUrl}
            setOpenAudioModal={setOpenAudioModal}
            // handleEditVertical={handleEditVertical}
            handleVerticalView={handleVerticalView}
            selectedRows={props.selectedRows}
          />
        )}
      />
    );
  }, [
    props.isLoading,
    // props.checked,
    props.selectedRows,
    props.data,
    noteLoading,
    // isVerticalEditModalOpen === false,
  ]);
  return (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      width={
        props.width || props.isOpen
          ? [aw(350), aw(350), aw(1168)]
          : [aw(350), aw(350), aw(1248)]
      }
      borderColor={"#D7D7D7"}
      style={{
        borderRadius: aw(10),
        borderColor: "#D7D7D7",
        borderWidth: aw(1),
      }}
    >
      <VStack
        minWidth={props.width || props.isOpen ? aw(1167) : aw(1247)}
        borderRadius={aw(10)}
        style={{ shadowColor: "#D7D7D7", shadowRadius: aw(5) }}
      >
        <HStack
          justifyContent="space-evenly"
          alignItems={"center"}
          bgColor="#D7D7D7"
          style={{
            height: ah(60),
            background: "#F9FAFB 0% 0% no-repeat padding-box",
            borderColor: "#D7D7D7",
            borderBottomWidth: aw(1),
          }}
        >
          <HStack
            width={aw(55)}
            justifyContent="center"
            // position="fixed"
            // left={5}
          >
            {props.onChangeHeaderCheckbox ? (
              <Checkbox
                accessibilityLabel="selectAll"
                style={{ height: aw(16), width: aw(16) }}
                bgColor="#FAFBFD"
                _checked={{ bgColor: "#0000FF", borderColor: "#0000FF" }}
                _icon={{ color: "#FAFBFD" }}
                _hover={{ color: "#FAFBFD" }}
                borderWidth={aw(2)}
                onChange={() =>
                  props.onChangeHeaderCheckbox && props.onChangeHeaderCheckbox()
                }
                isChecked={
                  props.screen === "lead"
                    ? props.data && props.data.length && props.selectedRows
                      ? props.data.every((val) =>
                          props.selectedRows.includes(val.response_id)
                        )
                      : false
                    : props.data && props.data.length && props.selectedRows
                    ? props.data.every((val) =>
                        props.selectedRows.includes(val._id)
                      )
                    : false
                }
              />
            ) : (
              <Text
                fontSize={aw(14)}
                color="#212121"
                fontFamily={"RobotoMedium"}
              >
                S.No
              </Text>
            )}
          </HStack>

          {props.heading.map((val, index) => {
            return (
              <HStack key={index}>
                {val !== "Actions" &&
                val !== "Leads Accepted / total" &&
                val !== "View Leads" ? (
                  <HStack
                    width={
                      val.length < 10
                        ? (val === "name" && props.screen !== "number") ||
                          val === "email" ||
                          val === "Email" ||
                          val === "desc" ||
                          val === "phone" ||
                          val === "number"
                          ? val === "desc" ||
                            val === "phone" ||
                            val === "number"
                            ? val === "phone" || val === "number"
                              ? val === "number"
                                ? aw(150)
                                : props.screen === "lead"
                                ? aw(110)
                                : aw(150)
                              : aw(500)
                            : props.screen === "lead"
                            ? aw(150)
                            : aw(250)
                          : val === "_id"
                          ? aw(val.length + 150)
                          : aw(val.length + 100)
                        : val === "Description" ||
                          (val === "publisher_name" && props.screen !== "lead")
                        ? aw(200)
                        : aw(val.length + 120)
                    }
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    key={val}
                  >
                    <Text
                      fontSize={aw(14)}
                      color="#212121"
                      fontFamily={"RobotoMedium"}
                    >
                      {val === "_id" ||
                      val === "renewat" ||
                      val === "desc" ||
                      val === "vertical_name" ||
                      val === "publisher_name" ||
                      val === "buyer_name" ? (
                        val === "_id" || val === "buyer_name" ? (
                          val === "_id" ? (
                            "ID"
                          ) : (
                            "Buyer"
                          )
                        ) : val === "desc" ||
                          val === "vertical_name" ||
                          val === "publisher_name" ? (
                          val === "desc" ? (
                            "Description"
                          ) : val === "vertical_name" ? (
                            "Vertical"
                          ) : (
                            "Publisher"
                          )
                        ) : (
                          "Renew"
                        )
                      ) : val === "status" && props.info ? (
                        <Flex
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Text>Status</Text>
                          <Tooltip
                            label="You can change status by clicking on it"
                            width={aw(140)}
                          >
                            <Flex>
                              <LeadsWatchInfoIcon />
                            </Flex>
                          </Tooltip>
                        </Flex>
                      ) : (
                        capitalizeString(val)
                      )}
                    </Text>
                    <VStack width={ah(12)} justifyContent={"center"}>
                      {props.sortIcon && (
                        <>
                          <Pressable
                            onPress={() => props.handleSort({ [val]: 1 })}
                          >
                            <LeadsWatchUpArrow
                              sort={"true"}
                              color={
                                props.sort
                                  ? Object.keys(props.sort)[0] === val &&
                                    props.sort[val] === 1
                                    ? "#212121"
                                    : "#D7D7D7"
                                  : "#D7D7D7"
                              }
                            />
                          </Pressable>
                          <Pressable
                            onPress={() => props.handleSort({ [val]: -1 })}
                          >
                            <LeadsWatchDownArrow
                              sort={"true"}
                              color={
                                props.sort
                                  ? Object.keys(props.sort)[0] === val &&
                                    props.sort[val] === -1
                                    ? "#212121"
                                    : "#D7D7D7"
                                  : "#D7D7D7"
                              }
                            />
                          </Pressable>
                        </>
                      )}
                    </VStack>
                  </HStack>
                ) : val === "Actions" ? (
                  <HStack
                    width={
                      props.screen === "vertical"
                        ? aw(val.length + 150)
                        : props.filter
                        ? props.data && props.data.length > 5
                          ? aw(val.length + 107)
                          : aw(val.length + 100)
                        : props.data && props.data.length >= 7
                        ? aw(val.length + 107)
                        : aw(val.length + 100)
                    }
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    key={index.toString() + val}
                  >
                    <Text
                      fontSize={aw(14)}
                      color="#212121"
                      fontFamily={"RobotoMedium"}
                    >
                      {props.screen === "calls" ? "Call" : "Actions"}
                    </Text>
                  </HStack>
                ) : val === "View Leads" ? (
                  <Text
                    width={aw(120)}
                    fontSize={aw(14)}
                    color="#212121"
                    fontFamily={"RobotoMedium"}
                  >
                    View Leads
                  </Text>
                ) : (
                  <VStack
                    width={aw(120)}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    key={index}
                  >
                    <Text
                      fontSize={aw(14)}
                      color="#212121"
                      fontFamily={"RobotoMedium"}
                    >
                      Leads
                    </Text>
                    <Text
                      fontSize={aw(14)}
                      color="#212121"
                      fontFamily={"RobotoMedium"}
                    >
                      {props.screen === "buyer" || props.subScreen === "buyer"
                        ? "Accepted/Sent"
                        : "Accepted/Total"}
                    </Text>
                  </VStack>
                )}
              </HStack>
            );
          })}
        </HStack>

        {!props.isLoading ? (
          <ScrollView maxHeight={ah(400)}>{memoizedFlatList}</ScrollView>
        ) : (
          <HStack
            justifyContent="center"
            alignItems={"center"}
            height={props.filter ? ah(280) : ah(400)}
          >
            <Spinner size="lg" color="#2230F8" />
          </HStack>
        )}

        {/* <LeadsWatchModal
          height={ah(534)}
          width={aw(370)}
          showModal={isVerticalEditModalOpen}
          onClose={() => {
            setIsVerticalEditModalOpen(false);
          }}
          heading={
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Edit vertical
            </Text>
          }
        >
          <VStack alignItems="center">
            <LeadsWatchFilterInput
              screen={getValues("verticalName") ? "edit" : "create"}
              mt={ah(29)}
              // style={{ width: aw(330), heigth: ah(40) }}
              width={aw(330)}
              height={ah(40)}
              name="verticalName"
              placeholder="Vertical Name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Vertical name is required",
                },
                pattern: {
                  // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                  message:
                    "Vertical Name should start with alphabets and can not contain special characters",
                },
              }}
              invalid={errors.verticalName ? true : false}
              error={errors.verticalName ? errors.verticalName.message : ""}
            />
            <HStack mt={aw(15)}>
              <LeadsWatchTextArea
                screen={
                  getValues("description") &&
                  getValues("description").length > 0
                    ? "edit"
                    : "create"
                }
                width={aw(330)}
                control={control}
                name="description"
                placeholder="Description"
                style={{ width: aw(330), heigth: ah(120) }}
              />
            </HStack>

            <HStack mt={ah(45)} space={ah(20)}>
              <LeadsWatchButton
                onPress={() => {
                  setIsVerticalEditModalOpen(false);
                }}
                label="cancel"
                style={{ width: aw(155), height: ah(40), borderRadius: aw(6) }}
                borderColor="#D1D5DB"
                fontSize={aw(14)}
              />
              <LeadsWatchButton
                onPress={handleSubmit(handleEdit)}
                isLoading={saving}
                label="edit Vertical"
                style={{ width: aw(155), height: ah(40), borderRadius: aw(6) }}
                bg="#2230F8"
                borderColor="#D1D5DB"
                fontSize={aw(14)}
                color="#FFFFFF"
              />
            </HStack>
          </VStack>
        </LeadsWatchModal> */}
        <LeadsWatchModal
          height={ah(550)}
          width={aw(370)}
          showModal={openAudioModal}
          onClose={() => {
            setOpenAudioModal(false);
            setUrl("");
          }}
          heading={
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Audio
            </Text>
          }
        >
          {url && (
            <Flex justifyContent="center" alignItems={"center"} pb={ah(10)}>
              <audio controls style={{ height: ah(30) }}>
                <source src={url} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </Flex>
          )}
        </LeadsWatchModal>
      </VStack>
    </ScrollView>
  );
});

export default LeadsWatchTable;

const MemoizedFlatListItem = React.memo(
  ({
    item,
    index,
    props,
    navigate,
    initialFocusRef,
    handleSubmit1,
    profile,
    handleAddNotes,
    control1,
    errors1,
    noteLoading,
    setUrl,
    setOpenAudioModal,
    // handleEditVertical,
    selectedRows,
    handleVerticalView,
  }) => {
    // console.log("rerender from Item");
    return (
      <HStack
        justifyContent="space-evenly"
        alignItems={"center"}
        style={{
          backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
          minHeight: ah(58),
          borderTopWidth: index === 0 ? 0 : aw(1),
          borderLeftWidth: "0",
          borderRightWidth: "0",
          borderBottomWidth: "0",
          borderColor: "#D7D7D7",
        }}
      >
        <HStack width={aw(55)} justifyContent="center">
          {props.handleChangeCheckBox ? (
            <Checkbox
              borderWidth={aw(2)}
              accessibilityLabel={`selectAll${item._id}`}
              style={{ height: aw(16), width: aw(16) }}
              bgColor="#FAFBFD"
              _checked={{
                bgColor: "#0000FF",
                borderColor: "#0000FF",
              }}
              _icon={{ color: "#FAFBFD" }}
              _hover={{ color: "#FAFBFD" }}
              isChecked={
                props.screen === "lead"
                  ? selectedRows && selectedRows.includes(item.response_id)
                    ? true
                    : false
                  : selectedRows && selectedRows.includes(item._id)
                  ? true
                  : false
              }
              onChange={() =>
                props.screen === "lead"
                  ? props.handleChangeCheckBox &&
                    props.handleChangeCheckBox(item._id, item.response_id)
                  : props.handleChangeCheckBox &&
                    props.handleChangeCheckBox(item._id)
              }
            />
          ) : (
            <Text
              fontSize={aw(14)}
              fontFamily="RobotoRegular"
              color="#212121"
              width={aw(20)}
            >
              {index + 1}
            </Text>
          )}
        </HStack>

        {props.heading.map((val, index) => (
          <HStack
            width={
              val !== "Actions" &&
              val !== "Leads Accepted / total" &&
              val !== "View Leads"
                ? val.length < 10
                  ? (val === "name" && props.screen !== "number") ||
                    val === "email" ||
                    val === "Email" ||
                    val === "desc" ||
                    val === "phone" ||
                    val === "number"
                    ? val === "desc" || val === "phone" || val === "number"
                      ? val === "phone" || val === "number"
                        ? val === "number"
                          ? aw(150)
                          : props.screen === "lead"
                          ? aw(110)
                          : aw(150)
                        : aw(500)
                      : props.screen === "lead"
                      ? aw(150)
                      : aw(250)
                    : val === "_id"
                    ? aw(val.length + 150)
                    : aw(val.length + 100)
                  : val === "Description" ||
                    (val === "publisher_name" && props.screen !== "lead")
                  ? aw(200)
                  : aw(val.length + 120)
                : val !== "Leads Accepted / total" && val !== "View Leads"
                ? props.screen === "vertical"
                  ? aw(val.length + 150)
                  : aw(val.length + 100)
                : aw(120)
            }
            justifyContent={"flex-start"}
            alignItems={"center"}
            key={val + item._id}
          >
            {val !== "Actions" &&
            val !== "Leads Accepted / total" &&
            val !== "View Leads" ? (
              val === "system_response" ||
              val === "buyer_response" ||
              val === "crm_status" ||
              val === "status" ? (
                <>
                  <Box
                    size={aw(10)}
                    borderRadius="100%"
                    backgroundColor={
                      item[val] === "Approved" ||
                      item[val] === "Accepted" ||
                      item[val] === "active" ||
                      item[val] === "paid" ||
                      item[val] === "completed"
                        ? "#1AB173"
                        : item[val] === "Pending" ||
                          item[val] === "pending" ||
                          item[val] === "running"
                        ? "#F9C034"
                        : item[val] === "Rejected" || item[val] === "unpaid"
                        ? "#C81E1E"
                        : item[val] === "Trash"
                        ? "#EB672D"
                        : item[val] === "Duplicate"
                        ? "#3374B4"
                        : item[val] === "inactive"
                        ? "#919191"
                        : item[val] === "Failure"
                        ? "#34476C"
                        : null
                    }
                  ></Box>
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    {" "}
                    {val === "status" || val === "crm_status" ? (
                      item[val] ? (
                        item[val] === "unpaid" ? (
                          <LeadsWatchMenu
                            ml={aw(20)}
                            width={aw(100)}
                            title={
                              <Text>
                                {item[val].charAt(0).toUpperCase() +
                                  item[val].slice(1)}
                              </Text>
                            }
                          >
                            {["Paid"].map((val) => (
                              <Menu.Item
                                h={35}
                                key={val}
                                bgColor="white"
                                _hover={{ bgColor: "#F5F7FB" }}
                                onPress={() =>
                                  props.handleChangeStatus(
                                    item._id,
                                    item.status === val.toLowerCase()
                                      ? ""
                                      : val.toLowerCase()
                                  )
                                }
                              >
                                <Text
                                  fontSize={aw(14)}
                                  fontFamily="RobotoRegular"
                                  color="#212121"
                                  width="100%"
                                >
                                  <Box
                                    size={aw(10)}
                                    borderRadius="100%"
                                    backgroundColor={
                                      val === "Paid" ? "#1AB173" : "#C81E1E"
                                    }
                                  ></Box>{" "}
                                  {val}
                                </Text>
                              </Menu.Item>
                            ))}
                          </LeadsWatchMenu>
                        ) : (
                          item[val].charAt(0).toUpperCase() + item[val].slice(1)
                        )
                      ) : (
                        " _"
                      )
                    ) : item[val] ? (
                      val === "group" ? (
                        item["group_name"]
                      ) : (
                        item[val]
                      )
                    ) : (
                      " _"
                    )}
                  </Text>
                </>
              ) : val === "_id" ||
                val === "Add Notes" ||
                val === "publisher_id" ||
                val === "buyer_id" ||
                val === "vertical_id" ||
                val === "start_date" ||
                val === "end_date" ? (
                val === "_id" ||
                val === "publisher_id" ||
                val === "buyer_id" ||
                val === "vertical_id" ? (
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                    width={
                      val !== "Actions" &&
                      val !== "Leads Accepted / total" &&
                      val !== "View Leads"
                        ? val.length < 10
                          ? (val === "name" && props.screen !== "number") ||
                            val === "email" ||
                            val === "Email" ||
                            val === "desc" ||
                            val === "phone" ||
                            val === "number"
                            ? val === "desc" ||
                              val === "phone" ||
                              val === "number"
                              ? val === "phone" || val === "number"
                                ? val === "number"
                                  ? aw(150)
                                  : props.screen === "lead"
                                  ? aw(110)
                                  : aw(150)
                                : aw(500)
                              : props.screen === "lead"
                              ? aw(150)
                              : aw(250)
                            : val === "_id"
                            ? aw(val.length + 150)
                            : aw(val.length + 100)
                          : val === "Description" ||
                            val ===
                              (val === "publisher_name" &&
                                props.screen !== "lead")
                          ? aw(200)
                          : aw(val.length + 110)
                        : props.screen === "vertical"
                        ? aw(val.length + 150)
                        : aw(val.length + 100)
                    }
                  >
                    {props.screen === "campaign" ||
                    props.subScreen === "campaigns"
                      ? item["unique_id"]
                      : item[val]}
                  </Text>
                ) : val === "Add Notes" ? (
                  // <LeadsWatchButton
                  //   label="Notes"
                  //   color="#0000FF"
                  //   // bg="#FFFFFF"
                  //   borderWidth={0}
                  //   borderColor={"#FFFFFF"}
                  // />
                  // <Pressable>
                  //   <HStack>
                  //     <LeadsWatchEditHeaderIcon />
                  //     <Text
                  //       color="#0000FF"
                  //       fontFamily="RobotoRegular"
                  //       fontSize={aw(14)}
                  //     >
                  //       Notes
                  //     </Text>
                  //   </HStack>
                  // </Pressable>
                  <Box w="100%">
                    <Popover
                      initialFocusRef={initialFocusRef}
                      trigger={(triggerProps) => {
                        return (
                          <Pressable {...triggerProps}>
                            <HStack>
                              <LeadsWatchEditHeaderIcon />
                              <Text
                                color="#0000FF"
                                fontFamily="RobotoRegular"
                                fontSize={aw(14)}
                              >
                                Notes{" "}
                                {item["notes"] && `(${item["notes"].length})`}
                              </Text>
                            </HStack>
                          </Pressable>
                        );
                      }}
                    >
                      <Popover.Content
                        accessibilityLabel="Add Notes"
                        w={aw(350)}
                      >
                        <Popover.Arrow />
                        <Popover.CloseButton />
                        <Popover.Header bgColor={"#FFFFFF"}>
                          Add Notes
                        </Popover.Header>
                        <Popover.Body bgColor={"#FFFFFF"}>
                          <HStack>
                            <LeadsWatchInput
                              ref={initialFocusRef}
                              variant="underlined"
                              control={control1}
                              name="note"
                              // rightIcon={
                              //   // noteLoading ? (
                              //   <Spinner size="sm" color="#0000FF" />
                              //   // ) : null
                              // }
                              placeholder="Enter Note"
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  handleSubmit1(handleAddNotes)(item._id);
                                }
                              }}
                              rules={{
                                required: true,
                              }}
                              invalid={errors1.note ? true : false}
                              error={errors1.note ? errors1.note.message : ""}
                            />
                            {noteLoading && (
                              <Spinner
                                size="sm"
                                color="#0000FF"
                                position="absolute"
                                right={0}
                                top={2}
                              />
                            )}
                          </HStack>
                          <ScrollView maxHeight={ah(200)}>
                            <VStack key={index} space={ah(10)}>
                              {item["notes"] &&
                                item["notes"].map((note, index) => (
                                  <VStack key={index + note.note}>
                                    <Text
                                      fontSize={aw(14)}
                                      fontFamily="RobotoRegular"
                                    >
                                      {note.note}
                                    </Text>
                                    <Text
                                      color="#C1C1C1"
                                      fontSize={aw(13)}
                                      fontFamily="RobotoRegular"
                                    >
                                      {note.createdname}
                                      {" - "}
                                      {moment(note.created).format(
                                        "YYYY-MM-DD"
                                      )}
                                      {" - "}
                                      {moment(note.created).format("HH:mm:ss")}
                                    </Text>
                                  </VStack>
                                ))}
                            </VStack>
                          </ScrollView>
                        </Popover.Body>
                      </Popover.Content>
                    </Popover>
                  </Box>
                ) : (
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    {item[val] ? moment(item[val]).format("YYYY-MM-DD") : " _"}
                  </Text>
                )
              ) : val === "recorded_audio" || val === "direction" ? (
                val === "recorded_audio" ? (
                  item[val] ? (
                    <Pressable
                      width="70%"
                      alignItems={"center"}
                      onPress={() => {
                        setUrl(item[val]);
                        setOpenAudioModal(true);
                      }}
                    >
                      <LeadsWatchPlayIcon />
                    </Pressable>
                  ) : (
                    <Text
                      width="70%"
                      textAlign={"center"}
                      fontSize={aw(14)}
                      fontFamily="RobotoRegular"
                      color="#212121"
                    >
                      _
                    </Text>
                  )
                ) : item[val] === "outbound" ? (
                  <Flex alignItems={"center"} width="50%">
                    <LeadsWatchOutGoingCallIcon color={"#4caf50"} />
                  </Flex>
                ) : (
                  <Flex alignItems={"center"} width="50%">
                    <LeadsWatchInComingIcon
                      color={
                        item["Status"] === "EARLY MEDIA" ||
                        item["Status"] === "DELAYED NEGOTIATION"
                          ? "#F5384C"
                          : "#F59D38"
                      }
                    />
                  </Flex>
                )
              ) : (
                <VStack>
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                    width={
                      val !== "Actions" &&
                      val !== "Leads Accepted / total" &&
                      val !== "View Leads"
                        ? val.length < 10
                          ? (val === "name" && props.screen !== "number") ||
                            val === "email" ||
                            val === "Email" ||
                            val === "desc" ||
                            val === "phone" ||
                            val === "number"
                            ? val === "desc" ||
                              val === "phone" ||
                              val === "number"
                              ? val === "phone" || val === "number"
                                ? val === "number"
                                  ? aw(150)
                                  : props.screen === "lead"
                                  ? aw(110)
                                  : aw(200)
                                : aw(500)
                              : props.screen === "lead"
                              ? aw(150)
                              : aw(250)
                            : val === "_id"
                            ? aw(val.length + 150)
                            : aw(val.length + 100)
                          : val === "Description" ||
                            val ===
                              (val === "publisher_name" &&
                                props.screen !== "lead")
                          ? aw(200)
                          : aw(val.length + 120)
                        : props.screen === "vertical"
                        ? aw(val.length + 150)
                        : aw(val.length + 100)
                    }
                  >
                    {val === "date" || val === "time" || val === "renewat"
                      ? val === "date" || val === "time"
                        ? `${moment(item["created"]).format("YYYY-MM-DD")}`
                        : moment(item["renewat"]).format("YYYY-MM-DD")
                      : item[val]
                      ? item[val]
                      : " _"}
                  </Text>
                  {val === "date" && (
                    <Text
                      fontSize={aw(12)}
                      fontFamily="RobotoRegular"
                      color="#212121"
                    >
                      {moment(item["created"]).format("hh:mm:ss")}
                    </Text>
                  )}
                </VStack>
              )
            ) : val === "Actions" ? (
              (props.screen && props.screen === "vertical") ||
              (props.screen && props.screen === "buyer") ? (
                props.screen === "vertical" ? (
                  <HStack space={aw(5)} alignItems={"center"}>
                    <Tooltip
                      label={"No Access"}
                      isDisabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids[props.screen].includes(
                              "update"
                            )
                            ? true
                            : false
                          : true
                      }
                    >
                      <Pressable
                        onPress={() => props.handleEdit(item)}
                        // here I need to write
                        isDisabled={
                          profile && profile.role === "user"
                            ? profile.permission_ids[props.screen].includes(
                                "update"
                              )
                              ? false
                              : true
                            : profile && profile.role === "publisher"
                            ? true
                            : false
                        }
                      >
                        <LeadsWatchEditIcon
                          color={
                            profile && profile.role === "user"
                              ? profile.permission_ids[props.screen].includes(
                                  "update"
                                )
                                ? "#212121"
                                : "#C1C1C1"
                              : profile && profile.role === "publisher"
                              ? "#C1C1C1"
                              : "#212121"
                          }
                        />
                      </Pressable>
                    </Tooltip>
                    <Tooltip
                      label={"No Access"}
                      isDisabled={
                        profile && profile.role !== "publisher"
                          ? profile && profile.role === "user"
                            ? profile.permission_ids[props.screen].includes(
                                "delete"
                              )
                              ? true
                              : false
                            : true
                          : false
                      }
                    >
                      <Pressable
                        onPress={() => props.handleDelete(item._id)}
                        isDisabled={
                          profile && profile.role !== "publisher"
                            ? profile && profile.role === "user"
                              ? profile.permission_ids[props.screen].includes(
                                  "delete"
                                )
                                ? false
                                : true
                              : false
                            : true
                        }
                      >
                        <LeadsWatchDeleteIcon
                          color={
                            profile && profile.role !== "publisher"
                              ? profile && profile.role === "user"
                                ? profile.permission_ids[props.screen] &&
                                  profile.permission_ids[props.screen].includes(
                                    "delete"
                                  )
                                  ? "#212121"
                                  : "#C1C1C1"
                                : "#212121"
                              : "#C1C1C1"
                          }
                        />
                      </Pressable>
                    </Tooltip>
                    <Pressable onPress={() => handleVerticalView(item._id)}>
                      <LeadsWatchEyeIcon color="#212121" />
                    </Pressable>
                  </HStack>
                ) : props.screen === "buyer" &&
                  props.subScreen === "buyerRoute" ? (
                  <HStack alignItems={"center"}>
                    <Tooltip
                      label={"No Access"}
                      isDisabled={
                        profile && profile.role !== "publisher"
                          ? profile && profile.role === "user"
                            ? profile.permission_ids[props.screen].includes(
                                "update"
                              )
                              ? true
                              : false
                            : true
                          : false
                      }
                    >
                      <Pressable
                        onPress={() => props.handleEdit(item)}
                        isDisabled={
                          profile && profile.role !== "publisher"
                            ? profile && profile.role === "user"
                              ? profile.permission_ids[props.screen].includes(
                                  "update"
                                )
                                ? false
                                : true
                              : false
                            : true
                        }
                      >
                        <LeadsWatchEditIcon
                          color={
                            profile && profile.role !== "publisher"
                              ? profile && profile.role === "user"
                                ? profile.permission_ids[props.screen].includes(
                                    "update"
                                  )
                                  ? "#212121"
                                  : "#C1C1C1"
                                : "#212121"
                              : "#C1C1C1"
                          }
                        />
                      </Pressable>
                    </Tooltip>
                    {item.status === "active" ? (
                      <Tooltip
                        label={"No Access"}
                        isDisabled={
                          profile && profile.role !== "publisher"
                            ? profile && profile.role === "user"
                              ? profile.permission_ids[props.screen].includes(
                                  "update"
                                )
                                ? true
                                : false
                              : true
                            : false
                        }
                      >
                        <Pressable
                          onPress={() => props.handleDelete(item._id)}
                          isDisabled={
                            profile && profile.role !== "publisher"
                              ? profile && profile.role === "user"
                                ? profile.permission_ids[props.screen].includes(
                                    "delete"
                                  )
                                  ? false
                                  : true
                                : false
                              : true
                          }
                        >
                          <LeadsWatchDeleteIcon
                            color={
                              profile && profile.role !== "publisher"
                                ? profile && profile.role === "user"
                                  ? profile.permission_ids[
                                      props.screen
                                    ].includes("update")
                                    ? "#212121"
                                    : "#C1C1C1"
                                  : "#212121"
                                : "#C1C1C1"
                            }
                          />
                        </Pressable>
                      </Tooltip>
                    ) : (
                      <Tooltip label="No Access">
                        <Pressable isDisabled={true}>
                          <LeadsWatchDeleteIcon color="#C1C1C1" />
                        </Pressable>
                      </Tooltip>
                    )}
                  </HStack>
                ) : (
                  <HStack alignItems={"center"}>
                    <Tooltip
                      label={"No Access"}
                      isDisabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids[props.screen].includes(
                              "update"
                            )
                            ? true
                            : false
                          : true
                      }
                    >
                      <Pressable
                        onPress={() => props.handleEdit(item)}
                        isDisabled={
                          profile && profile.role === "user"
                            ? profile.permission_ids[props.screen].includes(
                                "update"
                              )
                              ? false
                              : true
                            : false
                        }
                      >
                        <LeadsWatchEditIcon
                          color={
                            profile && profile.role === "user"
                              ? profile.permission_ids[props.screen].includes(
                                  "update"
                                )
                                ? "#212121"
                                : "#C1C1C1"
                              : "#212121"
                          }
                        />
                      </Pressable>
                    </Tooltip>
                    {item.status === "active" ? (
                      <Tooltip
                        label={"No Access"}
                        isDisabled={
                          profile && profile.role === "user"
                            ? profile.permission_ids[props.screen].includes(
                                "delete"
                              )
                              ? true
                              : false
                            : true
                        }
                      >
                        <Pressable
                          onPress={() => props.handleDelete(item._id)}
                          isDisabled={
                            profile && profile.role === "user"
                              ? profile.permission_ids[props.screen].includes(
                                  "delete"
                                )
                                ? false
                                : true
                              : false
                          }
                        >
                          <LeadsWatchDeleteIcon
                            color={
                              profile && profile.role === "user"
                                ? profile.permission_ids[props.screen].includes(
                                    "delete"
                                  )
                                  ? "#212121"
                                  : "#C1C1C1"
                                : "#212121"
                            }
                          />
                        </Pressable>
                      </Tooltip>
                    ) : (
                      <Tooltip label="No Access">
                        <Pressable isDisabled={true}>
                          <LeadsWatchDeleteIcon color="#C1C1C1" />
                        </Pressable>
                      </Tooltip>
                    )}
                    {item.status === "active" ? (
                      <Pressable
                        onPress={() =>
                          navigate(`/buyers/buyerRoutes/${item._id}/lead`)
                        }
                      >
                        <LeadsWatchEyeIcon color="#212121" />
                      </Pressable>
                    ) : (
                      <Tooltip label="No Access">
                        <Pressable isDisabled={true}>
                          <LeadsWatchEyeIcon color="#C1C1C1" />
                        </Pressable>
                      </Tooltip>
                    )}
                  </HStack>
                )
              ) : props.screen === "calls" ? (
                <LeadsWatchMenu width={aw(130)} title={<LeadsWatchCallsIcon />}>
                  {props.allNumbers &&
                    props.allNumbers.list.map((val) => (
                      <Menu.Item
                        key={val.number}
                        bgColor="white"
                        _hover={{ bgColor: "#F5F7FB" }}
                        onPress={() =>
                          props.handleFromNumber(
                            val.number,
                            item.Phone,
                            item.response_id
                          )
                        }
                      >
                        <Text
                          fontSize={aw(14)}
                          fontFamily="RobotoRegular"
                          color="#212121"
                          width="100%"
                        >
                          {val.number}
                        </Text>
                      </Menu.Item>
                    ))}
                </LeadsWatchMenu>
              ) : (
                <HStack>
                  <>
                    {props.handleEdit && (
                      <Tooltip
                        label={"No Access"}
                        isDisabled={
                          profile && profile.role === "user"
                            ? profile.permission_ids[props.screen].includes(
                                "update"
                              )
                              ? true
                              : false
                            : profile && profile.role === "publisher"
                            ? false
                            : true
                        }
                      >
                        <Pressable
                          onPress={() => props.handleEdit(item)}
                          disabled={
                            profile && profile.role === "user"
                              ? profile.permission_ids[props.screen].includes(
                                  "update"
                                )
                                ? false
                                : true
                              : profile && profile.role === "publisher"
                              ? true
                              : false
                          }
                        >
                          <LeadsWatchEditIcon
                            color={
                              profile && profile.role === "user"
                                ? profile.permission_ids[props.screen].includes(
                                    "update"
                                  )
                                  ? "#212121"
                                  : "#C1C1C1"
                                : profile && profile.role === "publisher"
                                ? "#C1C1C1"
                                : "#212121"
                            }
                          />
                        </Pressable>
                      </Tooltip>
                    )}
                    {item.status === "active" ||
                    item.status === "pending" ||
                    props.screen === "pipelines"
                      ? props.handleDelete && (
                          <Tooltip
                            label={"No Access"}
                            isDisabled={
                              profile && profile.role === "user"
                                ? profile.permission_ids[props.screen] &&
                                  profile.permission_ids[props.screen].includes(
                                    "delete"
                                  )
                                  ? true
                                  : false
                                : profile && profile.role === "publisher"
                                ? false
                                : true
                            }
                          >
                            <Pressable
                              onPress={() =>
                                props.handleDelete(
                                  props.screen === "number"
                                    ? item.number
                                    : item._id,
                                  item.response_id
                                )
                              }
                              disabled={
                                profile && profile.role === "user"
                                  ? profile.permission_ids[
                                      props.screen
                                    ].includes("delete")
                                    ? false
                                    : true
                                  : profile && profile.role === "publisher"
                                  ? true
                                  : false
                              }
                            >
                              <LeadsWatchDeleteIcon
                                color={
                                  profile && profile.role === "user"
                                    ? profile.permission_ids[
                                        props.screen
                                      ].includes("delete")
                                      ? "#212121"
                                      : "#C1C1C1"
                                    : profile && profile.role === "publisher"
                                    ? "#C1C1C1"
                                    : "#212121"
                                }
                              />
                            </Pressable>
                          </Tooltip>
                        )
                      : props.handleDelete && (
                          <Tooltip label="No Access">
                            <Pressable disabled>
                              <LeadsWatchDeleteIcon color="#C1C1C1" />
                            </Pressable>
                          </Tooltip>
                        )}
                    {props.handleView && (
                      <Pressable
                        onPress={() =>
                          props.handleView(item._id, item.response_id)
                        }
                      >
                        <LeadsWatchEyeIcon color="#212121" />
                      </Pressable>
                    )}
                    {props.handleDownload && (
                      <Pressable
                        onPress={() =>
                          props.handleDownload(item._id, item.response_id)
                        }
                      >
                        <LeadsWatchDownloadIcon />
                      </Pressable>
                    )}
                    {props.handleMove && (
                      <Pressable onPress={() => props.handleMove(item._id)}>
                        <LeadsWatchMoveIcon />
                      </Pressable>
                    )}
                    {props.handleSendEmail && (
                      <Pressable
                        onPress={() => props.handleSendEmail(item._id)}
                      >
                        <LeadsWatchEmailIcon color="#212121" />
                      </Pressable>
                    )}
                  </>
                </HStack>
              )
            ) : val === "View Leads" ? (
              <Tooltip
                label="No Access"
                isDisabled={
                  profile && profile.role === "user"
                    ? profile.permission_ids["lead"].includes("view")
                      ? true
                      : false
                    : true
                }
              >
                <Pressable
                  isDisabled={
                    profile && profile.role === "user"
                      ? profile.permission_ids["lead"].includes("view")
                        ? false
                        : true
                      : false
                  }
                  onPress={() =>
                    navigate(`/leads/campaign/${item.unique_id}/${props.type}`)
                  }
                >
                  <LeadsWatchEyeIcon
                    color={
                      profile && profile.role === "user"
                        ? profile.permission_ids["lead"].includes("view")
                          ? "#212121"
                          : "#C1C1C1"
                        : "#212121"
                    }
                  />
                </Pressable>
              </Tooltip>
            ) : (
              <HStack width={aw(120)} justifyContent={"center"}>
                <Text
                  alignSelf={"center"}
                  fontSize={aw(12)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  {props.screen === "buyer" || props.subScreen === "buyer"
                    ? item["sent_leads"] || item["accepted_leads"]
                      ? `${
                          item["accepted_leads"] ? item["accepted_leads"] : "0"
                        }/${item["sent_leads"] ? item["sent_leads"] : "0"}`
                      : "0"
                    : item["all_leads"] || item["accepted_leads"]
                    ? `${
                        item["accepted_leads"] ? item["accepted_leads"] : "0"
                      }/${item["all_leads"] ? item["all_leads"] : "0"}`
                    : "0"}
                </Text>
              </HStack>
            )}
          </HStack>
        ))}
      </HStack>
    );
  }
);
