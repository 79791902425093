import React, { useState, useEffect, useRef } from "react";
import { Text, FormControl, TextArea } from "native-base";
import { Controller } from "react-hook-form";
import { Animated } from "react-native";

import { aw, ah } from "../utils";

const LeadsWatchTextArea = React.forwardRef((props, ref) => {
  const animatedTop = useRef(
    props.screen === "edit" ? new Animated.Value(-9) : new Animated.Value(8)
  ).current;
  const [show, setShow] = useState(props.screen === "edit" ? false : true);

  useEffect(() => {
    setShow(props.screen === "edit" ? false : true);
  }, [props.screen]);
  useEffect(() => {
    if (!show) {
      Animated.timing(animatedTop, {
        toValue: -9,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(animatedTop, {
        toValue: 8,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
  }, [show]);
  useEffect(() => {
    (props.refresh === true || props.refresh === false) && setShow(true);
  }, [props.refresh]);

  return (
    <FormControl
      // position="relative"
      isRequired={props.isRequired}
      isInvalid={props.invalid}
      isReadOnly={props.isReadOnly || false}
      style={{
        // height: ah(129),
        ...props.style,
      }}
    >
      {props.required ? null : (
        <Animated.View
          style={{
            top: animatedTop,
            zIndex: !show ? 999 : 0,
            paddingLeft: aw(5),
            position: "absolute",
          }}
        >
          <Text
            px={aw(3)}
            style={{
              fontSize: !show ? aw(12) : aw(14),
              fontFamily: "RobotoRegular",
              // color: "#494440",
              backgroundColor: "white",
              // width: !show ? aw(90) : props.width,
              color: !show ? "#494440" : "#919191",
            }}
          >
            {props.placeholder}
          </Text>
        </Animated.View>
      )}
      <Controller
        control={props.control}
        rules={props.rules}
        name={props.name}
        style={{
          zIndex: 1,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextArea
            _hover={{ borderColor: "#D1D5DB" }}
            color="#212121"
            h={props.height || ah(120)}
            // w={props.width}
            mt={props.mt}
            placeholder={props.required ? props.placeholder : ""}
            tabIndex={props.tabIndex}
            isReadOnly={props.isReadOnly || false}
            ref={ref}
            isInvalid={props.invalid}
            autoComplete="off"
            autoFocus={props.autoFocus || false}
            onSubmitEditing={props.onSubmitEditing}
            onKeyPress={props.onKeyPress}
            borderWidth={aw(1)}
            borderRadius={props.borderRadius}
            borderColor="#D1D5DB"
            value={value || ""}
            onChangeText={(value) => {
              onChange(value);
              if (props.onChangeText) {
                props.onChangeText();
              }
            }}
            onBlur={() => {
              value ? setShow(false) : setShow(true);
              onBlur();
            }}
            onFocus={() => {
              setShow(false);
            }}
            _focus={{
              backgroundColor: "white",
              borderColor: props.focusOutlineColor || "#0000FF",
              borderWidth: aw(1),
            }}
            invalidOutlineColor={"#FA4E2D"}
            focusOutlineColor={props.focusOutlineColor || "#0000FF"}
            _invalid={{
              borderColor: "#FA4E2D",
              borderWidth: aw(1),
            }}
            _light={{
              borderWidth: "1",
            }}
            _dark={{
              borderWidth: "1",
            }}
            _web={{
              borderWidth: "1",
            }}
            style={{
              fontSize: aw(14),
              fontFamily: "RobotoRegular",
              // backgroundColor: "white",
              zIndex: 1,
            }}
          />
        )}
      />
      {props.error && (
        <Text
          color="#FA4E2D"
          fontFamily="RobotoRegular"
          fontSize={aw(12)}
          pt={ah(2)}
        >
          {props.error}
        </Text>
      )}

      {/* <FormControl.ErrorMessage>{props.error}</FormControl.ErrorMessage>
      <FormControl.HelperText>{props.help}</FormControl.HelperText> */}
      {/* <Box
        position="absolute"
        top={aw(-9)}
        left={aw(6)}
        bgColor="white"
        px={show ? 0 : 1}
      >
        {show ? (
          ""
        ) : (
          <Text fontSize={aw(12)} fontFamily={"RobotoRegular"} color="#494440">
            {props.placeholder}
          </Text>
        )}
      </Box> */}
    </FormControl>
  );
});

export default LeadsWatchTextArea;
