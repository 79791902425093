import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllCrmCampaigns = (page, limit, sort, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/crm/campaigns`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          page: page,
          limit: limit,
          sort: sort,
          filters: filters,
          search: search,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allcampaigns = resData.data;
        dispatch({ type: events.GET_ALL_CRMCAMPAIGNS, data: allcampaigns });
        return allcampaigns ? allcampaigns : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//create signatures
export const createCampaigns = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/crm/campaigns/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const newCampaign = resData.data;
        dispatch({ type: events.CREATE_CRMCAMPAIGN, data: newCampaign });
        return newCampaign ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//edit signatures
export const editCampaign = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/crm/campaigns/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const campaign = resData.data;
        dispatch({ type: events.EDIT_CRMCAMPAIGN, data: campaign });
        return campaign ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//delete signatures
export const deletecampaign = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/crm/campaigns/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const campaign = resData.data;
        dispatch({ type: events.DELETE_CRMCAMPAIGN, data: campaign });
        return campaign ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//Attach campaign
export const attachCampaign = (pId, id, name) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/pipelines/${pId}/campaign/attach`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            campaign_id: id,
            campaign_name: name,
          }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const campaign = resData.data;
        dispatch({ type: events.ATTACTH_CAMPAIGN, data: campaign });
        return campaign ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
//detach Campaign
export const detachCampaign = (pId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/pipelines/${pId}/campaign/detach`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const campaign = resData.data;
        dispatch({ type: events.ATTACTH_CAMPAIGN, data: campaign });
        return campaign ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
