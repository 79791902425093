import { Box, Pressable, Text } from "native-base";
import React, { useRef, useEffect } from "react";

import { Animated } from "react-native";
import { aw, ah } from "../utils";

const LeadsWatchAnimatedCard = (props) => {
  const animatedHeight = useRef(
    new Animated.Value(props.isSelected ? 50 : 3000)
  ).current;
  useEffect(() => {
    if (props.isSelected) {
      Animated.timing(animatedHeight, {
        toValue: 50,
        duration: 300,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(animatedHeight, {
        toValue: 3500,
        duration: 300,
        useNativeDriver: true,
      }).start();
    }
  }, [props.isSelected]);
  return (
    <Box>
      <Animated.View
        style={{
          paddingBottom: props.pb,
          paddingTop: props.pt,
          paddingHorizontal: props.px,
          width: props.width,
          maxHeight: animatedHeight,
          overflow: "hidden",
          borderWidth: props.borderWidth,
          borderColor: props.borderColor,
          alignItems: props.alignItems,
          backgroundColor: props.backgroundColor || "#FFFFFF",
          shadowColor: props.shadow ? null : props.shadowColor || "#0000001A",
          shadowOffset: props.shadow
            ? null
            : props.shadowOffset || { width: "0", height: aw(1) },
          shadowRadius: props.shadow ? null : props.shadowRadius || aw(6),
          borderRadius: props.borderRadius || aw(6),
        }}
      >
        <Box maxH={animatedHeight} height={props.h} pb={props.pb} width="100%">
          {props.children}
        </Box>
      </Animated.View>
    </Box>
  );
};

export default LeadsWatchAnimatedCard;
