import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllPipelines = (page, limit, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/pipelines`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          page: page,
          limit: limit,
          sort: { order: 1 },
          search: search,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allPipelines = resData.data;
        dispatch({ type: events.GET_ALL_PIPELINES, data: allPipelines });
        return allPipelines ? allPipelines : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const createPipeline = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/pipelines/create`, {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const newPipelines = resData.data;
        dispatch({ type: events.CREATE_PIPELINE, data: newPipelines });
        return newPipelines ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const deletePipelines = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/pipelines/${id}`, {
        method: "DELETE",
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const deletedPipeline = resData.data;
        dispatch({ type: events.DELETE_PIPELINES, data: deletedPipeline });
        return deletedPipeline ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const movePipelines = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/pipelines/order`, {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allPipelines = resData.data;
        dispatch({ type: events.MOVE_PIPELINE, data: allPipelines });
        return allPipelines ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const unassignedLeads = (page, limit, id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/pipelines/${id}/contacts`,
        {
          method: "POST",
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ page: page, limit: limit }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const unassignedLeads = resData.data;
        dispatch({ type: events.UNASSIGNED_LEADS, data: unassignedLeads });
        return unassignedLeads ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const addleadstopipelines = (id, selected, unSelected) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/pipelines/${id}/leads/add`,
        {
          method: "POST",
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ selected: selected, unselected: unSelected }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const res = resData.data;
        dispatch({ type: events.ADD_LEADS_TO_PIPELINE, data: res });
        return res ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const moveleadsFrompipeline = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/pipelines/${id}/leads/move`,
        {
          method: "POST",
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const res = resData.data;
        dispatch({ type: events.MOVE_PIPELINE_LEADS, data: res });
        return res ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const pipelineleads = (id, page, limit) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/pipelines/${id}/leads`, {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ page: page, limit: limit }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const pipelineLeads = resData.data;
        dispatch({ type: events.PIPELINE_LEADS, data: pipelineLeads });
        return pipelineLeads ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const addnotes = (id, note) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/pipelines/leads/${id}/notes`,
        {
          method: "POST",
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ note: note }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const notes = resData.data;
        dispatch({ type: events.ADD_NOTES, data: notes });
        return notes ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
