import {
  Text,
  VStack,
  HStack,
  Divider,
  Box,
  Pressable,
  Tooltip,
} from "native-base";
import React, { useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { aw, ah } from "../../utils";
import { getAllTeamMembers } from "../../store/actions/team";
import { removeInvoices } from "../../store/actions/subscription";

const Settings = ({ isOpen }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profile);
  const teamMembers = useSelector((state) => state.team.teamMembers);
  const location = useLocation();
  const navigate = useNavigate();
  const fetchTeamMembers = async () => {
    try {
      await dispatch(getAllTeamMembers());
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (profile && profile.role !== "publisher" && profile.subscribed) {
      if (profile && profile.role === "user") {
        if (profile.permission_ids["invite"].includes("view")) {
          fetchTeamMembers();
        }
      } else {
        fetchTeamMembers();
      }
    }
  }, []);

  return (
    <HStack height={ah(698)}>
      <VStack
        top={ah(70)}
        // left={[0, 0, 0, aw(68)]}
        left={isOpen ? [aw(0), aw(3), aw(158)] : [aw(0), aw(3), aw(68)]}
        // width={aw(1298)}
        width={
          isOpen ? [aw(350), aw(350), aw(1208)] : [aw(350), aw(350), aw(1298)]
        }
        position={"fixed"}
      >
        <VStack top={ah(8)} marginLeft={aw(20)}>
          <HStack
            lineHeight={ah(34)}
            justifyContent={"space-between"}
            space={aw(8)}
            alignItems={"flex-end"}
            // width={aw(1248)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              alignItems={"baseline"}
              // width={aw(194)}
              space={aw(5)}
              justifyContent="space-between"
            >
              <Text
                fontSize={aw(26)}
                color={"#121233"}
                fontFamily={"RobotoMedium"}
              >
                Your Settings
              </Text>
            </HStack>
          </HStack>
          <Text
            lineHeight={ah(34)}
            fontSize={aw(14)}
            fontFamily={"RobotoRegular"}
            color={"#919191"}
            fontWeight={400}
          >
            All your settings will be shown here
          </Text>

          <Divider
            top={ah(10)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
            bg="#E6E7E9"
          />
          <HStack mt={ah(20)} space={aw(56)} height={ah(30)}>
            {[
              { name: "My Profile", value: "profile" },
              { name: "Team", value: "team" },
              { name: "Subscription", value: "subscription" },
              { name: "Credits", value: "credits" },
              { name: "Invoices", value: "invoice" },
              // { name: "Buyer Cards", value: "buyerCard" },
              // { name: "Pay Publisher", value: "payPublisher" },
            ].map((val, index) =>
              val.value === "team" || val.value === "invoice" ? (
                profile &&
                profile.subscribed &&
                profile.subscribed.status === "active" &&
                profile.role !== "publisher" && (
                  <Tooltip
                    key={index}
                    label={"No Access"}
                    isDisabled={
                      profile && profile.role === "user"
                        ? profile.permission_ids["invite"].includes("view")
                          ? true
                          : false
                        : true
                    }
                  >
                    <Pressable
                      isDisabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids["invite"].includes("view")
                            ? false
                            : true
                          : false
                      }
                      onPress={() => {
                        val.value === "invoice" && dispatch(removeInvoices());
                        navigate(`/settings/${val.value}`);
                      }}
                      key={val.name}
                      borderBottomWidth={
                        location.pathname.includes(val.value) && aw(3)
                      }
                      borderColor={"#0000FF"}
                      height={ah(41)}
                    >
                      <HStack alignItems={"center"} space={aw(4)}>
                        <Text
                          lineHeight={ah(34)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoRegular"}
                          color={
                            location.pathname.includes(val.value)
                              ? "#0000FF"
                              : "#919191"
                          }
                          fontWeight={400}
                        >
                          {val.name}
                        </Text>

                        {val.value === "team" && (
                          <Box
                            width={aw(17)}
                            height={ah(17)}
                            rounded="100%"
                            bgColor={
                              location.pathname.includes(val.value)
                                ? "#0000FF"
                                : "#EFEFEF"
                            }
                          >
                            <Text
                              alignSelf={"center"}
                              fontSize={aw(10)}
                              fontFamily={"RobotoRegular"}
                              color={
                                location.pathname.includes(val.value)
                                  ? "#FFFFFF"
                                  : "#919191"
                              }
                              fontWeight={400}
                            >
                              {teamMembers && teamMembers.total_count
                                ? teamMembers.total_count
                                : 0}
                            </Text>
                          </Box>
                        )}
                      </HStack>
                    </Pressable>
                  </Tooltip>
                )
              ) : val.value === "subscription" || val.value === "credits" ? (
                val.value === "credits" ? (
                  profile &&
                  profile.free &&
                  profile.role !== "publisher" && (
                    <Pressable
                      onPress={() => {
                        dispatch(removeInvoices());
                        navigate(`/settings/${val.value}`);
                      }}
                      key={val.name}
                      borderBottomWidth={
                        (location.pathname.includes(val.value) && aw(3)) ||
                        (val.name === "My Profile" &&
                          location.pathname === "/" &&
                          aw(3))
                      }
                      borderColor={"#0000FF"}
                      height={ah(41)}
                    >
                      <Text
                        lineHeight={ah(34)}
                        fontSize={aw(14)}
                        fontFamily={"RobotoRegular"}
                        color={
                          location.pathname.includes(val.value) ||
                          (val.name === "My Profile" &&
                            location.pathname === "/")
                            ? "#0000FF"
                            : "#919191"
                        }
                        fontWeight={400}
                      >
                        {val.name}
                      </Text>
                    </Pressable>
                  )
                ) : (
                  profile &&
                  profile.role !== "publisher" && (
                    <Pressable
                      onPress={() => navigate(`/settings/${val.value}`)}
                      key={val.name}
                      borderBottomWidth={
                        (location.pathname.includes(val.value) && aw(3)) ||
                        (val.name === "My Profile" &&
                          location.pathname === "/" &&
                          aw(3))
                      }
                      borderColor={"#0000FF"}
                      height={ah(41)}
                    >
                      <Text
                        lineHeight={ah(34)}
                        fontSize={aw(14)}
                        fontFamily={"RobotoRegular"}
                        color={
                          location.pathname.includes(val.value) ||
                          (val.name === "My Profile" &&
                            location.pathname === "/")
                            ? "#0000FF"
                            : "#919191"
                        }
                        fontWeight={400}
                      >
                        {val.name}
                      </Text>
                    </Pressable>
                  )
                )
              ) : (
                <Pressable
                  onPress={() => navigate(`/settings/${val.value}`)}
                  key={val.name}
                  borderBottomWidth={
                    (location.pathname.includes(val.value) && aw(3)) ||
                    (val.name === "My Profile" &&
                      location.pathname === "/" &&
                      aw(3))
                  }
                  borderColor={"#0000FF"}
                  height={ah(41)}
                >
                  <Text
                    lineHeight={ah(34)}
                    fontSize={aw(14)}
                    fontFamily={"RobotoRegular"}
                    color={
                      location.pathname.includes(val.value) ||
                      (val.name === "My Profile" && location.pathname === "/")
                        ? "#0000FF"
                        : "#919191"
                    }
                    fontWeight={400}
                  >
                    {val.name}
                  </Text>
                </Pressable>
              )
            )}
          </HStack>
          <Divider
            top={ah(10)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
            bg="#E6E7E9"
          />
          <VStack mt={ah(10)}>
            <Outlet />
          </VStack>
        </VStack>
      </VStack>
    </HStack>
  );
};

export default Settings;
