import { VStack, Text, HStack, Center, Box, Flex } from "native-base";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import { useForm } from "react-hook-form";
import React, { useState, useEffect, useMemo } from "react";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import { aw, ah } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import LeadsWatchFilterIcon from "../../components/svg/LeadsWatchFilterIcon";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import { getAllVerticals } from "../../store/actions/verticals";
import { getAllCampaigns } from "../../store/actions/campaigns";
import { getAllBuyers } from "../../store/actions/buyers";
import { getAllPublishers } from "../../store/actions/publishers";
import {
  getPhones,
  fromNumber,
  sendResponseId,
} from "../../store/actions/calls";

const MakeCalls = ({ plivoRef, outGoingCall, refresh, isOpen }) => {
  const allNumbers = useSelector((state) => state.calls.allBuyedNumbers);
  const [loading, setLoading] = useState(true);
  const [responseId, setResponseId] = useState(null);
  const phoneNums = useSelector((state) => state.calls.allPhoneNums);

  const [selectedValues, setSelectedValues] = useState({});
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [filters1, setFilters1] = useState({});
  const allVerticals = useSelector((state) => state.verticals.allVerticals);
  const allCampaigns = useSelector((state) => state.campaigns.allCampaigns);
  const allPublishers = useSelector((state) => state.publishers.allPublishers);
  const [openFilter, setOpenFilter] = useState(false);
  const allBuyers = useSelector((state) => state.buyers.allBuyers);
  const { control, getValues, reset } = useForm({
    defaultValues: { select: "10" },
    mode: "all",
  });
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [selectCount, setSelectCount] = useState(10);
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, calls: 1 }));
  };
  const [currentPage, setCurrentPage] = useState(
    page && page.calls ? parseInt(page.calls) : 1
  );
  const loadOptions = async (inputValue, callback, list, defaultOptions) => {
    try {
      const data =
        list === "vertical"
          ? await dispatch(getAllVerticals(1, 10, undefined, inputValue))
          : list === "campaign"
          ? await dispatch(
              getAllCampaigns(1, 10, undefined, undefined, inputValue)
            )
          : list === "buyers"
          ? await dispatch(
              getAllBuyers(1, 10, undefined, undefined, inputValue)
            )
          : await dispatch(
              getAllPublishers(1, 10, undefined, undefined, inputValue)
            );

      callback(
        list === "campaign"
          ? data &&
              data.list.map((val) => ({
                value: val.unique_id,
                label: `${val.name}(${val.unique_id})`,
              }))
          : list === "publisher"
          ? data &&
            data.list.map((val) => ({
              value: val.unique_id,
              label: `${val.firstname} ${val.lastname}`,
            }))
          : data &&
            data.list.map((val) => ({
              value: val._id,
              label: val.name,
            }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleOptionChange = (key, selectedOption) => {
    const ids = selectedOption.map((val) => val.value);
    setSelectedValues({ ...selectedValues, [key]: selectedOption });
    setFilters({ ...filters, [key]: ids });
  };
  //default options
  const defaultOptions = allVerticals
    ? allVerticals.list.map((val) => ({ value: val._id, label: val.name }))
    : [];
  const defaultOptionsForCam = allCampaigns
    ? allCampaigns.list.map((val) => ({
        value: val.unique_id,
        label: `${val.name}( ${val.unique_id})`,
      }))
    : [];
  const defaultOptionsForBuyers = allBuyers
    ? allBuyers.list.map((val) => ({
        value: val._id,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];
  const defaultOptionsForPub = allPublishers
    ? allPublishers.list.map((val) => ({
        value: val._id,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];
  const fetchAllVerticals = async () => {
    try {
      await dispatch(getAllVerticals(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAllBuyers = async () => {
    try {
      await dispatch(getAllBuyers(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAllPublishers = async () => {
    try {
      await dispatch(getAllPublishers(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const getData = async () => {
    try {
      await dispatch(getAllCampaigns(1, 10));
    } catch (err) {
      console.log(err.message);
    }
  };
  const fetchPhoneNums = async () => {
    setLoading(true);
    try {
      await dispatch(getPhones(currentPage, selectCount, filters1));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchPhoneNums();
  }, [currentPage, selectCount, filters1]);
  useEffect(() => {
    fetchAllVerticals();
    getData();
    fetchAllBuyers();
    fetchAllPublishers();
  }, []);
  const handleFromNumber = async (number, toNumber, responseId) => {
    try {
      await dispatch(fromNumber({ number: number }));
      setResponseId(responseId);
      plivoRef.current.client.call(toNumber);
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleFilters = () => {
    setFilters1(filters);
    setOpenFilter(false);
  };
  useEffect(() => {
    const sendResponse = async () => {
      try {
        await dispatch(sendResponseId(outGoingCall.callUUID, responseId));
        setResponseId(null);
      } catch (err) {
        console.log(err.message);
      }
    };
    if (outGoingCall && responseId) {
      sendResponse();
    }
  }, [responseId, refresh]);
  const memoizedChildComponent = useMemo(() => {
    return (
      <LeadsWatchTable
        isOpen={isOpen}
        isLoading={loading}
        heading={[
          "Phone",
          "Email",
          "dial_status",
          "campaign_id",
          "publisher_id",
          "vertical_id",
          "Actions",
        ]}
        screen="calls"
        sortIcon={true}
        data={phoneNums ? phoneNums.list : []}
        handleFromNumber={handleFromNumber}
        allNumbers={allNumbers}
      />
    );
  }, [loading, isOpen]);
  return (
    <>
      <HStack justifyContent={"space-between"}>
        <HStack height={ah(32)} alignItems="center">
          <Text fontSize={aw(14)} fontFamily="RobotoRegular" color="#212121">
            Show Entries :{" "}
          </Text>
          <Center>
            <LeadsWatchSelect
              control={control}
              name={"select"}
              list={["10", "20", "50", "100"]}
              // value={selectCount}
              handleChange={(value) => handleChangeSelect(parseInt(value))}
              width={79}
              // height={ah(32)}
            />
          </Center>
        </HStack>
        <HStack justifyContent="flex-end">
          <LeadsWatchButton
            bg="#FFFFFF"
            onPress={() => setOpenFilter(!openFilter)}
            leftIcon={<LeadsWatchFilterIcon />}
            label={"Filter"}
            style={{
              width: aw(115),
              height: ah(32),
              borderColor: "#D1D5DB",
            }}
            fontWeight="400"
          />
          {openFilter && (
            <Box
              px={aw(20)}
              position="absolute"
              zIndex={1}
              top={ah(35)}
              right={aw(98)}
              width={aw(570)}
              minH={ah(180)}
              py={ah(10)}
              // my={ah(10)}
              bgColor="#FFFFFF"
              borderColor="#E1E1E1"
              style={{
                shadowColor: "#00000033",
                shadowRadius: aw(20),
              }}
              borderWidth={aw(1)}
              borderRadius={aw(6)}
            >
              <Flex direction="row" wrap="wrap" gap={ah(20)}>
                <AsyncSelect
                  cacheOptions={true}
                  onInputChange={(inputValues) => {
                    if (inputValues === "") {
                      fetchAllVerticals();
                      // return inputValue;
                    }
                  }}
                  isMulti
                  defaultOptions={defaultOptions}
                  loadOptions={(inputValues, callback) =>
                    loadOptions(
                      inputValues,
                      callback,
                      "vertical",
                      defaultOptions
                    )
                  }
                  placeholder="Select Vertical"
                  onChange={(selectedOption) =>
                    handleOptionChange("vertical_id", selectedOption)
                  }
                  value={selectedValues.vertical_id}
                  // onChange={handleOptionClick}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#0000FF" : "#D1D5DB",
                      width: aw(250),
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                      color: "#919191",
                      // fontSize: aw(14),
                    }),
                    menuList: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),

                    multiValueLabel: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),
                  }}
                />
                <AsyncSelect
                  isMulti
                  cacheOptions={true}
                  defaultOptions={defaultOptionsForCam}
                  loadOptions={(inputValues, callback) =>
                    loadOptions(inputValues, callback, "campaign")
                  }
                  placeholder="Select Campaign"
                  onChange={(selectedOption) =>
                    handleOptionChange("campaign_id", selectedOption)
                  }
                  onInputChange={(inputValues) => {
                    if (inputValues === "") {
                      getData();
                      // return inputValue;
                    }
                  }}
                  value={selectedValues.campaign_id}
                  // onChange={handleOptionClick}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#0000FF" : "#D1D5DB",
                      width: aw(250),
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                      color: "#919191",
                    }),
                    menuList: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),
                    multiValueLabel: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),
                  }}
                />
                <AsyncSelect
                  cacheOptions={true}
                  isMulti
                  defaultOptions={defaultOptionsForBuyers}
                  loadOptions={(inputValues, callback) =>
                    loadOptions(inputValues, callback, "buyer", defaultOptions)
                  }
                  onInputChange={(inputValues) => {
                    if (inputValues === "") {
                      fetchAllVerticals();
                      // return inputValue;
                    }
                  }}
                  placeholder="Select Buyers"
                  onChange={(selectedOption) =>
                    handleOptionChange("buyer_id", selectedOption)
                  }
                  value={selectedValues.buyer_id}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#0000FF" : "#D1D5DB",
                      width: aw(250),
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                      color: "#919191",
                    }),
                    menuList: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),
                    multiValueLabel: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),
                  }}
                />
                <AsyncSelect
                  cacheOptions={true}
                  isMulti
                  defaultOptions={defaultOptionsForPub}
                  loadOptions={(inputValues, callback) =>
                    loadOptions(
                      inputValues,
                      callback,
                      "publisher",
                      defaultOptions
                    )
                  }
                  // loadOptions={loadOptions}
                  onInputChange={(inputValues) => {
                    if (inputValues === "") {
                      fetchAllPublishers();
                      // return inputValue;
                    }
                  }}
                  placeholder="Select Publishers"
                  onChange={(selectedOption) =>
                    handleOptionChange("publisher_id", selectedOption)
                  }
                  value={selectedValues.publisher_id}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: state.isFocused ? "#0000FF" : "#D1D5DB",
                      width: aw(250),
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                      color: "#919191",
                    }),
                    menuList: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),
                    multiValueLabel: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: "RobotoRegular",
                    }),
                  }}
                />
              </Flex>
              <Flex
                width={aw(520)}
                justifyContent={"flex-end"}
                direction="row"
                gap={aw(10)}
                zIndex={-1}
                mt={ah(10)}
              >
                <LeadsWatchButton
                  label="Cancel"
                  onPress={() => {
                    setOpenFilter(false);
                    setSelectedValues({});
                    setFilters({});
                    setFilters({});
                  }}
                  style={{ height: ah(32) }}
                />
                <LeadsWatchButton
                  onPress={handleFilters}
                  label="Apply"
                  style={{
                    height: ah(32),
                  }}
                  bg="#2230F8"
                  color="#FFFFFF"
                  borderColor="#05BBC9"
                />
              </Flex>
            </Box>
          )}
        </HStack>
      </HStack>
      <VStack top={5} zIndex={-1}>
        {memoizedChildComponent}
      </VStack>
      <VStack
        bottom={aw(5)}
        position="fixed"
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
      >
        <LeadsWatchPagination
          width="100%"
          totalCount={phoneNums ? phoneNums.total_count : 0}
          currentPage={currentPage}
          pageSize={selectCount}
          onPageChange={(currentPage) => {
            setCurrentPage(currentPage);
            localStorage.setItem(
              "page",
              JSON.stringify({ ...page, calls: currentPage })
            );
          }}
        />
      </VStack>
    </>
  );
};

export default MakeCalls;
