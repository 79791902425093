import React, { useState, useEffect, useRef } from "react";
import { Input, Text, FormControl } from "native-base";
import { Controller } from "react-hook-form";
import { Animated } from "react-native";

import { aw, ah } from "../utils";

const LeadsWatchFilterInput = React.forwardRef((props, ref) => {
  const animatedTop = useRef(
    props.screen === "edit" ? new Animated.Value(-9) : new Animated.Value(8)
  ).current;
  const [show, setShow] = useState(props.screen === "edit" ? false : true);

  useEffect(() => {
    setShow(props.screen === "edit" ? false : true);
  }, [props.screen]);
  useEffect(() => {
    if (!show) {
      Animated.timing(animatedTop, {
        toValue: -9,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(animatedTop, {
        toValue: 8,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
  }, [show]);
  useEffect(() => {
    (props.refresh === true || props.refresh === false) && setShow(true);
  }, [props.refresh]);

  return (
    <>
      <FormControl
        isRequired={props.isRequired}
        isInvalid={props.invalid}
        isReadOnly={props.isReadOnly || false}
        width={props.width}
        // height={props.height||40}
      >
        <Animated.View
          style={{
            top: animatedTop,
            zIndex: !show ? 999 : 0,
            paddingLeft: aw(5),
            position: "absolute",
          }}
        >
          <Text
            px={aw(3)}
            style={{
              fontSize: !show ? aw(12) : aw(14),
              fontFamily: "RobotoRegular",
              backgroundColor: "white",
              color: !show ? "#494440" : "#919191",
            }}
          >
            {props.placeholder}
          </Text>
        </Animated.View>

        <Controller
          control={props.control}
          rules={props.rules}
          name={props.name}
          style={{
            zIndex: 1,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              width={props.width}
              // borderRadius={props.borderRadius || 4}
              // isFocused={focused}
              isDisabled={props.isDisabled || false}
              _focus={{
                backgroundColor: "white",
                borderColor: props.focusOutlineColor || "#0000FF",
              }}
              color="#212121"
              tabIndex={props.tabIndex}
              isReadOnly={props.isReadOnly || false}
              ref={ref}
              size={props.size || "lg"}
              multiline={props.multiline || false}
              numberOfLines={props.numberOfLines || 1}
              minLength={props.minLength || 1}
              maxLength={props.maxLength || 255}
              isInvalid={props.invalid}
              autoComplete="off"
              autoFocus={props.autoFocus || false}
              type={props.type || "text"}
              onSubmitEditing={props.onSubmitEditing}
              onKeyPress={props.onKeyPress}
              borderColor={"#D1D5DB" || props.borderColor}
              onChangeText={(value) => {
                if (props.onlyNumers) {
                  // if (props.float) {
                  onChange(
                    value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
                  );
                  if (props.onChangeText) {
                    props.onChangeText();
                  }
                  // } else {
                  //   onChange(value.replace(/\D/g, ""));
                  //   if (props.onChangeText) {
                  //     props.onChangeText();
                  //   }
                  // }
                } else {
                  onChange(props.toCaptlize ? value.toUpperCase() : value);
                  if (props.onChangeText) {
                    props.onChangeText();
                  }
                }
                // setShow(value ? false : true);
              }}
              onBlur={() => {
                value ? setShow(false) : setShow(true);
                onBlur();
              }}
              onFocus={() => {
                setShow(false);
              }}
              value={value || ""}
              variant={"outline"}
              invalidOutlineColor={"#FA4E2D"}
              focusOutlineColor={props.focusOutlineColor || "#0000FF"}
              _invalid={{
                borderColor: "#FA4E2D",
                borderWidth: aw(1),
              }}
              _light={{
                borderWidth: 1,
              }}
              _dark={{
                borderWidth: 1,
              }}
              _web={{
                borderWidth: 1,
              }}
              _hover={{
                borderColor: "#D1D5DB",
              }}
              _disabled={{
                borderColor: props.disabledBorderColor || "#9BA6B2",
                _hover: {
                  borderColor: props.disabledBorderColor || "#9BA6B2",
                },

                color: "#919191",
              }}
              style={{
                fontSize: aw(14),
                fontFamily: "RobotoRegular",
                // backgroundColor: "white",
                borderRadius: props.borderRadius || 4,
                zIndex: 1,
                // minHeight: props.height || 36,
                // maxHeight: props.height || 36,
                //  height:props.height  36
                height: props.height || ah(37),
              }}
              // height={props.height || 40}
              InputLeftElement={props.leftIcon || null}
              InputRightElement={props.rightIcon || null}
              // placeholder={show ? props.placeholder : ""}
            />
          )}
        />
        {props.error && (
          <>
            {/* <FormControl.ErrorMessage>{props.error}</FormControl.ErrorMessage> */}
            {/*   <FormControl.HelperText>{props.help}</FormControl.HelperText> */}
            <Text
              color="#FA4E2D"
              fontFamily="RobotoRegular"
              fontSize={aw(12)}
              pt={ah(2)}
            >
              {props.error}
            </Text>
          </>
        )}
        {/* <Animated.View
          style={{
            top: animatedTop,
            zIndex: !show ? 999 : 0,
            position: "absolute",
            backgroundColor: "white",
            marginLeft: aw(5),
            p: aw(10),
          }}
        >
          <Box position="absolute" top={aw(-9)} left={aw(6)} bgColor="white">
          <Text
            fontSize={aw(13)}
            fontFamily={"RobotoRegular"}
            color="#494440"
            // onPress={() => {
            //   setShow(!show);
            //   setFocused(true);
            // }}
          >
            {props.placeholder}
          </Text>
          </Box>
        </Animated.View> */}
      </FormControl>
    </>
  );
});

export default LeadsWatchFilterInput;
