import React, { useState, useRef, useMemo, useEffect } from "react";
import {
  Text,
  HStack,
  VStack,
  Divider,
  Pressable,
  Input,
  FormControl,
  ScrollView,
  FlatList,
  Select,
  Spinner,
  // Tooltip,
  Menu,
} from "native-base";
import { toast } from "react-toastify";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import countryList from "react-select-country-list";

import { aw, ah } from "../../utils";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchCallsIcon from "../../components/svg/LeadsWatchCallsIcon";

import LeadsWatchAddInActiveIcon from "../../components/svg/LeadsWatchAddInActiveIcon";

import LeadsWatchDownArrow from "../../components/svg/LeadsWatchDownArrow";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import {
  getAvailbleNum,
  buyNumber,
  removeSearchedNumbers,
  fromNumber,
  fetchAllNumbers,
} from "../../store/actions/calls";
import LeadsWatchProfileIcon from "../../components/svg/LeadsWatchProfileIcon";
import LeadsWatchMuteOffIcon from "../../components/svg/LeadsWatchMuteOffIcon";
import LeadsWatchCallPauseIcon from "../../components/svg/LeadsWatchCallPauseIcon";
import LeadsWatchCallForwardIcon from "../../components/svg/LeadsWatchCallForwardIcon";
import LeadsWatchAddPersonIcon from "../../components/svg/LeadsWatchAddPersonIcon";
import LeadsWatchAddCallIcon from "../../components/svg/LeadsWatchAddCallIcon";
import LeadsWatchKeyPadIcon from "../../components/svg/LeadsWatchKeyPadIcon";
import LeadsWatchMoreIcon from "../../components/svg/LeadsWatchMoreIcon";
import LeadsWatchRecordIcon from "../../components/svg/LeadsWatchRecordIcon";
import LeadsWatchCallCancelIcon from "../../components/svg/LeadsWatchCallCancelIcon";
import LeadsWatchMenu from "../../components/LeadsWatchMenu";
const Calls = ({
  plivoRef,
  outGoingCall,
  setOutGoingCall,
  refresh,
  close,
  isOpen1,
}) => {
  const [selNumber, setSelNumber] = useState(null);
  const allNumbers = useSelector((state) => state.calls.allBuyedNumbers);
  const profile = useSelector((state) => state.auth.profile);
  const availNumbers = useSelector((state) => state.calls.availbleNum);
  const [confirmModal, setConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [value, setValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [dialCall, setDialCall] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [number, setNumber] = useState({});
  const [details, setDetails] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading1, setLoading1] = useState(false);

  const textRef = useRef(null);
  const [mute, setMute] = useState(false);
  const [hold, setHold] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "all",
  });

  const searchAvailNumbers = async (data) => {
    setIsLoading(true);
    try {
      await dispatch(getAvailbleNum(data, currentPage));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setLoading(false);
    setIsLoading(false);
  };
  const onSubmit = async (data) => {
    setLoading(true);
    searchAvailNumbers({
      country_iso: data.country_name,
      type: data.number_type,
    });
    setDetails({ country_iso: data.country_name, type: data.number_type });
  };
  useEffect(() => {
    if (getValues("country_name")) {
      searchAvailNumbers({
        country_iso: getValues("country_name"),
        type: getValues("number_type"),
      });
    }
  }, [currentPage]);
  const handleAddNumber = () => {
    setIsOpen(true);
  };
  const handleBuyNumber = async () => {
    setLoading1(true);
    try {
      await dispatch(
        buyNumber({
          number: number.number,
          country_iso: details.country_iso,
          type: details.type,
        })
      );

      setConfirmModal(false);
      getAllNumbers();
      setNumber({});
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }

    setLoading1(false);
  };
  const options = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    if (textRef.current) {
      if (outGoingCall && outGoingCall.state === "answered") {
        let initialTime = 0;
        const interval = setInterval(() => {
          initialTime += 1;
          const formattedTime = formatTime(initialTime);
          textRef.current.textContent = formattedTime;
        }, 1000);

        return () => {
          clearInterval(interval);
        };
      } else {
        textRef.current.textContent = "00:00";
      }
    }
  }, [outGoingCall, refresh]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };
  useEffect(() => {
    if (close) {
      setDialCall(false);
    }
  }, [close]);
  const handleFromNumber = async (number) => {
    try {
      await dispatch(fromNumber({ number: number }));
      setSelNumber(number);
    } catch (err) {
      console.log(err.message);
    }
  };
  const getAllNumbers = async () => {
    try {
      await dispatch(fetchAllNumbers());
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    getAllNumbers();
  }, []);
  useEffect(() => {
    if (outGoingCall) {
      setDialCall(true);
    }
  }, [outGoingCall]);

  return (
    <HStack
      height={ah(698)}
      width={
        isOpen1 ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
      }
      // width={[aw(350), aw(350), aw(1248)]}
      left={isOpen1 ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
    >
      <VStack
        width={
          isOpen1 ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
      >
        <VStack marginTop={ah(7)}>
          <HStack
            width={
              isOpen1
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
            justifyContent={"space-between"}
            alignItems={"center"}
            // width={aw(1248)}
          >
            <HStack lineHeight={ah(34)} space={aw(8)} alignItems={"baseline"}>
              <Text
                fontSize={aw(26)}
                color={"#121233"}
                fontFamily="RobotoMedium"
              >
                Calls
              </Text>
            </HStack>
          </HStack>
          <HStack justifyContent={"space-between"}>
            <HStack
              justifyContent={"space-between"}
              alignItems={"center"}
              width={aw(700)}
            >
              <Text
                lineHeight={ah(34)}
                fontSize={aw(14)}
                fontFamily={"RobotoRegular"}
                color={"#919191"}
                fontWeight={400}
              >
                All your Calls list will be shown here
              </Text>
              {/* <HStack
                justifyContent={"center"}
                alignItems={"center"}
                space={aw(40)}
              >
                <Tooltip
                  label={"No Access"}
                  isDisabled={
                    profile && profile.role !== "publisher"
                      ? profile && profile.role === "user"
                        ? profile.permission_ids["number"].includes("buy") ||
                          profile.permission_ids["number"].includes("delete")
                          ? true
                          : false
                        : true
                      : false
                  }
                >
                  <Pressable
                    isDisabled={
                      profile && profile.role !== "publisher"
                        ? profile && profile.role === "user"
                          ? profile.permission_ids["number"].includes("buy") ||
                            profile.permission_ids["number"].includes("delete")
                            ? false
                            : true
                          : false
                        : true
                    }
                    borderBottomWidth={
                      location.pathname.includes("numbers") && aw(3)
                    }
                    borderColor={"#0000FF"}
                    onPress={() => navigate(`/calls/numbers/manageNumbers`)}
                    height={ah(42)}
                  >
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={
                        location.pathname.includes("numbers")
                          ? "#0000FF"
                          : profile && profile.role === "user"
                          ? profile.permission_ids["number"].includes("buy") ||
                            profile.permission_ids["number"].includes("delete")
                            ? "#919191"
                            : "#C1C1C1"
                          : "#919191"
                      }
                      fontWeight={400}
                    >
                      Numbers
                    </Text>
                  </Pressable>
                </Tooltip>
                <Pressable
                  borderColor={"#0000FF"}
                  onPress={() => navigate(`/calls/makeCalls`)}
                  borderBottomWidth={
                    location.pathname.includes("makeCalls") && aw(3)
                  }
                  height={ah(42)}
                >
                  <Text
                    lineHeight={ah(34)}
                    fontSize={aw(14)}
                    fontFamily={"RobotoRegular"}
                    fontWeight={400}
                    color={
                      location.pathname.includes("makeCalls")
                        ? "#0000FF"
                        : "#919191"
                    }
                  >
                    Make a Call
                  </Text>
                </Pressable>
                <Pressable
                  borderColor={"#0000FF"}
                  onPress={() => navigate(`/calls/incomingCalls`)}
                  borderBottomWidth={
                    location.pathname.includes("incomingCalls") && aw(3)
                  }
                  height={ah(42)}
                >
                  <Text
                    lineHeight={ah(34)}
                    fontSize={aw(14)}
                    fontFamily={"RobotoRegular"}
                    fontWeight={400}
                    color={
                      location.pathname.includes("incomingCalls")
                        ? "#0000FF"
                        : "#919191"
                    }
                  >
                    Incoming Calls
                  </Text>
                </Pressable>
                <Pressable
                  borderColor={"#0000FF"}
                  onPress={() => navigate(`/calls/callLogs`)}
                  borderBottomWidth={
                    location.pathname.includes("callLogs") && aw(3)
                  }
                  height={ah(42)}
                >
                  <Text
                    lineHeight={ah(34)}
                    fontSize={aw(14)}
                    fontFamily={"RobotoRegular"}
                    fontWeight={400}
                    color={
                      location.pathname.includes("callLogs")
                        ? "#0000FF"
                        : "#919191"
                    }
                  >
                    Call Logs
                  </Text>
                </Pressable>
              </HStack> */}
            </HStack>

            {location.pathname.includes("numbers") && (
              <HStack>
                <LeadsWatchButton
                  onPress={handleAddNumber}
                  label="Add Number"
                  style={{
                    width: aw(140),
                    height: ah(36),
                    borderRadius: "6px",
                  }}
                  color="#FFFFFF"
                  fontSize={aw(14)}
                  fontFamily="RobotoMedium"
                  bg={"#2230F8"}
                  fontWeight="400"
                  leftIcon={<LeadsWatchAddIcon />}
                />
              </HStack>
            )}
            {location.pathname.includes("makeCalls") && (
              <HStack>
                <LeadsWatchButton
                  onPress={() => setDialCall(true)}
                  label="Make A Call"
                  style={{
                    width: aw(140),
                    height: ah(36),
                    borderRadius: "6px",
                  }}
                  color="#FFFFFF"
                  fontSize={aw(14)}
                  fontFamily="RobotoMedium"
                  bg={"#2230F8"}
                  fontWeight="400"
                  leftIcon={<LeadsWatchAddIcon />}
                />
              </HStack>
            )}
          </HStack>
          <Divider mt={ah(9)} bg="#E6E7E9" height={ah(1)} />
          <VStack mt={ah(15)}>
            <Outlet />
          </VStack>
        </VStack>
      </VStack>
      <LeadsWatchModal
        // animationPreset="slide"
        height={ah(590)}
        width={aw(850)}
        showModal={isOpen}
        onClose={() => {
          reset({ number_type: "", country_name: "" });
          dispatch(removeSearchedNumbers());
          setIsOpen(false);
        }}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Add Number
            </Text>
          </HStack>
        }
      >
        <VStack>
          <HStack mb={ah(20)} space={aw(10)} width={aw(800)}>
            <HStack width={aw(350)}>
              <FormControl isInvalid={errors.country_name}>
                <Controller
                  control={control}
                  name={"country_name"}
                  style={{
                    zIndex: 1,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      color="#212121"
                      placeholderTextColor={"#919191"}
                      selectedValue={value}
                      _customDropdownIconProps={{
                        color: "muted.500",
                        mr: "3",
                        size: aw(10),
                        p: "1",
                      }}
                      _hover={{
                        borderColor: "#D1D5DB",
                      }}
                      // w={aw(400)}
                      h={ah(40)}
                      // selectedValue={props.value}
                      baseStyle={{
                        fontFamily: "RobotoRegular",
                        fontSize: aw(14),
                      }}
                      borderColor={"#D1D5DB"}
                      rounded={5}
                      accessibilityLabel="Choose vertical"
                      _selectedItem={{
                        endIcon: <LeadsWatchDownArrow />,
                      }}
                      fontFamily={"RobotoRegular"}
                      fontSize={aw(14)}
                      _focus={{
                        bgColor: "white",
                        borderColor: "#D1D5DB",
                      }}
                      placeholder={"Select Country"}
                      // onValueChange={(itemValue) =>
                      //   handleChangeVertical(itemValue)
                      // }
                      onValueChange={(value) => {
                        onChange(value);
                        // handleChangeVertical(value);
                      }}
                      _isFocusVisible={false}
                      focusOutlineColor={"gray.500"}
                      onBlur={onBlur}
                    >
                      {options &&
                        options &&
                        options.map((val) => (
                          <Select.Item
                            key={val.value}
                            value={val.value}
                            label={val.label}
                            color="#212121"
                          />
                        ))}
                    </Select>
                  )}
                  rules={{
                    required: {
                      value: true,
                    },
                  }}
                  invalid={errors.country_name ? true : false}
                  // invalid={true}
                  error={errors.country_name ? errors.country_name.message : ""}
                />
              </FormControl>
            </HStack>
            <LeadsWatchSelect
              control={control}
              name={"number_type"}
              placeholder="Number Type"
              width={aw(350)}
              height={ah(40)}
              list={["any", "local", "national", "fixed", "mobile", "tollfree"]}
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.number_type ? true : false}
              error={errors.number_type ? errors.number_type.message : ""}
            />
            <LeadsWatchButton
              isLoading={loading}
              label="search"
              style={{
                width: aw(100),
                height: ah(40),
                backgroundColor: "#2230F8",
              }}
              color="#FFFFFF"
              onPress={handleSubmit(onSubmit)}
            />
          </HStack>
          <HStack
            justifyContent="space-evenly"
            alignItems={"center"}
            bgColor="#D7D7D7"
            style={{
              height: ah(60),
              background: "#F9FAFB 0% 0% no-repeat padding-box",

              borderWidth: aw(1),
              borderColor: "#D7D7D7",
              borderBottomWidth: 0,
              borderTopLeftRadius: aw(10),
              borderTopRightRadius: aw(10),
            }}
          >
            {["Number", "Type", "Monthly Fee", "Setup Fee", "Actions"].map(
              (val) => (
                <HStack
                  width={aw(120)}
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={val}
                  ml={aw(10)}
                >
                  <Text
                    fontSize={aw(14)}
                    width={aw(120)}
                    fontFamily={"RobotoMedium"}
                    color="#212121"
                  >
                    {val}
                  </Text>
                </HStack>
              )
            )}
          </HStack>

          <ScrollView
            maxHeight={ah(280)}
            showsVerticalScrollIndicator={false}
            borderBottomRightRadius={aw(10)}
            borderBottomLeftRadius={aw(10)}
            borderColor="#D7D7D7"
            borderWidth={1}
          >
            {!isLoading ? (
              <FlatList
                ListEmptyComponent={
                  <HStack
                    justifyContent="center"
                    alignItems={"center"}
                    height={ah(280)}
                  >
                    <Text
                      color="black"
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                    >
                      No data found
                    </Text>
                  </HStack>
                }
                data={availNumbers ? availNumbers.num_objects : []}
                keyExtractor={(item) => item.number.toString()}
                renderItem={({ item, index }) => (
                  <>
                    <HStack
                      justifyContent="space-evenly"
                      alignItems={"center"}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",

                        minHeight: ah(58),

                        borderBottomWidth: aw(1),
                        borderColor: "#D7D7D7",
                      }}
                    >
                      {[
                        "number",
                        "type",
                        "monthly_rental_rate",
                        "setup_rate",
                        "Actions",
                      ].map((val, idx) => (
                        <HStack
                          width={aw(120)}
                          justifyContent="flex-start"
                          alignItems={"center"}
                          key={val + idx}
                          ml={aw(10)}
                        >
                          {val === "Actions" ? (
                            <LeadsWatchButton
                              onPress={() => {
                                setNumber({
                                  number: item.number,
                                  credit:
                                    parseFloat(item["monthly_rental_rate"]) * 2,
                                });
                                setIsOpen(false);
                                reset({ number_type: "", country_name: "" });
                                dispatch(removeSearchedNumbers());
                                setConfirmModal(true);
                              }}
                              label="Buy Number"
                              style={{
                                height: ah(30),
                              }}
                            />
                          ) : (
                            <Text
                              fontSize={aw(14)}
                              width={aw(120)}
                              fontFamily={"RobotoRegular"}
                              color="#212121"
                            >
                              {val === "setup_rate" ||
                              val === "monthly_rental_rate"
                                ? val === "setup_rate"
                                  ? "Free"
                                  : `${
                                      parseFloat(item["monthly_rental_rate"]) *
                                      2
                                    } ${
                                      parseFloat(item["monthly_rental_rate"]) <=
                                      1
                                        ? "credit"
                                        : "credits"
                                    }`
                                : item[val]}
                            </Text>
                          )}
                        </HStack>
                      ))}
                    </HStack>
                  </>
                )}
              />
            ) : (
              <HStack
                justifyContent="center"
                alignItems={"center"}
                height={ah(280)}
                style={{
                  borderBottomRightRadius: aw(10),
                  borderBottomLeftRadius: aw(10),
                  borderTopWidth: "0px",
                  borderColor: "#D7D7D7",
                  borderWidth: aw(1),
                }}
              >
                <Spinner size="lg" color="#2230F8" />
              </HStack>
            )}
          </ScrollView>
          <VStack
            mt={ah(10)}
            mb={ah(0)}
            px={aw(10)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <LeadsWatchPagination
              width={"100%"}
              totalCount={
                availNumbers ? availNumbers.total_count : 0
                // displayLeads && displayLeads.length
              }
              currentPage={currentPage}
              pageSize={10}
              onPageChange={(currentPage) => setCurrentPage(currentPage)}
            />
          </VStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        required={true}
        height={ah(500)}
        width={aw(320)}
        showModal={dialCall}
        onClose={() => {
          setDialCall(false);
          setValue("");
          setSelNumber("");
        }}
        style={{
          marginBottom: 0,
          marginTop: "auto",
          marginRight: aw(30),
          marginLeft: "auto",
        }}
      >
        <>
          {outGoingCall ? (
            <VStack alignItems={"center"} justifyContent={"center"}>
              <HStack
                style={{ shadowColor: "#00000033", shadowRadius: aw(12) }}
                borderRadius={"full"}
                alignItems={"center"}
              >
                <LeadsWatchProfileIcon />
              </HStack>
              <Text
                pt={ah(10)}
                color={"#212121"}
                fontFamily={"RobotoMedium"}
                fontSize={aw(18)}
              ></Text>
              <Text
                color={"#212121"}
                fontFamily={"RobotoMedium"}
                fontSize={aw(12)}
              >
                {outGoingCall.dest}
              </Text>
              {outGoingCall.state === "ringing" ? (
                <Text
                  color={"#212121"}
                  fontFamily={"RobotoMedium"}
                  fontSize={aw(12)}
                >
                  Ringing...
                </Text>
              ) : (
                <Text
                  color={"#212121"}
                  fontFamily={"RobotoMedium"}
                  fontSize={aw(12)}
                  ref={textRef}
                ></Text>
              )}
              <Divider
                my={ah(20)}
                bg="#E6E7E9"
                height={ah(1)}
                width={
                  isOpen
                    ? [aw(350), aw(350), aw(1168)]
                    : [aw(350), aw(350), aw(1248)]
                }
              />
              <VStack space={ah(20)}>
                <HStack space={aw(30)}>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    bg={mute ? "#919191" : "#FFFFFF"}
                    onPress={() => {
                      if (!mute) {
                        plivoRef.current.client.mute();
                        setMute(!mute);
                      } else {
                        plivoRef.current.client.unmute();
                        setMute(!mute);
                      }
                    }}
                  >
                    <LeadsWatchMuteOffIcon />
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    bg={hold ? "#919191" : "#FFFFFF"}
                    onPress={() => {
                      if (!hold) {
                        plivoRef.current.calls.hold({
                          call_uuid: outGoingCall.callUuid,
                        });
                        setHold(!hold);
                      } else {
                        plivoRef.current.calls.unhold({
                          call_uuid: outGoingCall.callUuid,
                        });
                        setHold(!hold);
                      }
                    }}
                  >
                    <LeadsWatchCallPauseIcon />
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchCallForwardIcon />
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchRecordIcon />
                  </Pressable>
                </HStack>
                <HStack space={aw(30)}>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchAddCallIcon />
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchAddPersonIcon />
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchKeyPadIcon />
                  </Pressable>
                  <Pressable
                    isDisabled={true}
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchMoreIcon />
                  </Pressable>
                </HStack>
              </VStack>
              <HStack my={ah(40)}>
                {outGoingCall.state === "ringing" ? (
                  <Pressable
                    onPress={() => {
                      plivoRef.current.client.hangup();
                      setDialCall(false);
                      setTimeout(() => {
                        setOutGoingCall(null);
                      }, 2000);
                    }}
                    size={ah(60)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{
                      background: "red",
                    }}
                  >
                    <LeadsWatchCallCancelIcon color="#FFFFFF" />
                  </Pressable>
                ) : (
                  <LeadsWatchButton
                    onPress={() => {
                      plivoRef.current.client.hangup();
                      setDialCall(false);
                      setTimeout(() => {
                        setOutGoingCall(null);
                      }, 2000);
                    }}
                    label=" End Session"
                    style={{
                      width: aw(170),
                      height: ah(40),
                      borderRadius: "6px",
                    }}
                    color="#FFFFFF"
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    bg={"#D64547"}
                    fontWeight="400"
                  />
                )}
              </HStack>
            </VStack>
          ) : (
            <VStack alignItems={"center"} justifyContent={"center"}>
              <VStack
                space={ah(20)}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <VStack>
                  <HStack alignItems={"center"} width={aw(250)} borderWidth={0}>
                    <Pressable>
                      <LeadsWatchAddInActiveIcon />
                    </Pressable>
                    <Input
                      placeholder={"1 999 999 999"}
                      keyboardType="numeric"
                      width={aw(200)}
                      ref={initialRef}
                      color="black"
                      fontSize={value ? aw(20) : aw(14)}
                      fontFamily={"RobotoBold"}
                      value={value}
                      onChangeText={(value) =>
                        setValue(value.replace(/\D/g, ""))
                      }
                      variant="unstyled"
                      focusOutlineColor={"#D7D7D7"}
                      _hover={{
                        borderColor: "#D7D7D7",
                      }}
                    />

                    <LeadsWatchMenu
                      width={aw(130)}
                      title={
                        // <Pressable>
                        <LeadsWatchDownArrow />
                        // </Pressable>
                      }
                    >
                      {allNumbers &&
                        allNumbers.list.map((val) => (
                          <Menu.Item
                            key={val.number}
                            bgColor="white"
                            _hover={{ bgColor: "#F5F7FB" }}
                            onPress={() => handleFromNumber(val.number)}
                          >
                            <Text
                              fontSize={aw(14)}
                              fontFamily="RobotoRegular"
                              color="#212121"
                              width="100%"
                            >
                              {val.number}
                            </Text>
                          </Menu.Item>
                        ))}
                    </LeadsWatchMenu>
                  </HStack>
                  <Divider bg="#D7D7D7" />
                </VStack>
                <HStack space={aw(30)}>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onPress={() => {
                      setValue(value + "1");
                    }}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      1
                    </Text>
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onPress={() => {
                      setValue(value + "2");
                    }}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      2
                    </Text>
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onPress={() => {
                      setValue(value + "3");
                    }}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      3
                    </Text>
                  </Pressable>
                </HStack>
                <HStack space={aw(30)}>
                  <Pressable
                    onPress={() => {
                      setValue(value + "4");
                    }}
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      4
                    </Text>
                  </Pressable>
                  <Pressable
                    onPress={() => {
                      setValue(value + "5");
                    }}
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      5
                    </Text>
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onPress={() => {
                      setValue(value + "6");
                    }}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      6
                    </Text>
                  </Pressable>
                </HStack>
                <HStack space={aw(30)}>
                  <Pressable
                    onPress={() => {
                      setValue(value + "7");
                    }}
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      7
                    </Text>
                  </Pressable>
                  <Pressable
                    onPress={() => {
                      setValue(value + "8");
                    }}
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      8
                    </Text>
                  </Pressable>
                  <Pressable
                    onPress={() => {
                      setValue(value + "9");
                    }}
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      9
                    </Text>
                  </Pressable>
                </HStack>
                <HStack space={aw(30)}>
                  <Pressable
                    alignSelf="center"
                    onPress={() => {
                      setValue(value + "*");
                    }}
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      *
                    </Text>
                  </Pressable>
                  <Pressable
                    onPress={() => {
                      setValue(value + "0");
                    }}
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      0
                    </Text>
                  </Pressable>
                  <Pressable
                    onPress={() => {
                      setValue(value + "#");
                    }}
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      fontSize={aw(22)}
                      color={"#121233"}
                      fontFamily="RobotoMedium"
                    >
                      #
                    </Text>
                  </Pressable>
                </HStack>
              </VStack>

              <Pressable
                onPress={() => {
                  plivoRef.current.client.call(value);
                }}
                isDisabled={selNumber && value ? false : true}
                my={ah(20)}
                size={ah(60)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
                style={{
                  background:
                    selNumber && value
                      ? "transparent linear-gradient(124deg, #0000FF 0%, #6E00FF 100%) 0% 0% no-repeat padding-box"
                      : "#E6E7E9",
                }}
              >
                <LeadsWatchCallsIcon color="#FFFFFF" />
              </Pressable>
            </VStack>
          )}
        </>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(350)}
        height={ah(250)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Buy Number
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to buy this number
          </Text>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Number : {number.number}
          </Text>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Cost : {number.credit} credit
          </Text>

          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={loading1}
              onPress={handleBuyNumber}
              label="Buy"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </HStack>
  );
};

export default Calls;
