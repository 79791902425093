import * as events from "../Events";
const initialState = {
  allSignatures: null,
  singleSignature: null,
  smtps: null,
};
export const crmsignatures = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_CRMSIGNATURES:
      return {
        ...state,
        allSignatures: action.data,
      };
    case events.GET_SIGNATURE_BY_ID: {
      return {
        ...state,
        singleSignature: action.data,
      };
    }
    case events.GET_ALL_SMTP:
      return {
        ...state,
        smtps: action.data,
      };

    default:
      return state;
  }
};
