import { React, useEffect, useState, useMemo } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Switch,
  Flex,
  Pressable,
  Hidden,
  useBreakpointValue,
  Tooltip,
} from "native-base";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { aw, ah } from "../../../utils";
//components
import LeadsWatchSearch from "../../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchTable from "../../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../../components/LeadsWatchButton";
import LeadsWatchFilterInput from "../../../components/LeadsWatchFilterInput";
import LeadsWatchModal from "../../../components/LeadsWatchModal";
import LeadsWatchTextArea from "../../../components/LeadsWatchTextArea";
//svg
import LeadsWatchAddIcon from "../../../components/svg/LeadsWatchAddIcon";
import LeadsWatchInfoIcon from "../../../components/svg/LeadsWatchInfoIcon";
import LeadsWatchDownArrow from "../../../components/svg/LeadsWatchDownArrow";
import LeadsWatchCrossIcon from "../../../components/svg/LeadsWatchCrossIcon";
import LeadsWatchAsyncSelect from "../../../components/LeadsWatchAsyncSelect";
import LeadsWatchAnimatedCard from "../../../components/LeadsWatchAnimatedCard";
//redux
import {
  getAllCrmCampaigns,
  createCampaigns,
  editCampaign,
  deletecampaign,
} from "../../../store/actions/crmcampaigns";
import { getAllTemplates } from "../../../store/actions/crmtemplates";
import LeadsWatchInput from "../../../components/LeadsWatchInput";

const Campaigns = ({ isOpen }) => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.auth.profile);
  const templates = useSelector((state) => state.crmtemplates.allTemplates);
  const campaigns = useSelector((state) => state.crmcampaigns.allCampaigns);
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));

  const [refresh, setRefresh] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModal1, setConfirmModal1] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteId1, setDeleteId1] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [toogle, setToogle] = useState({});
  const [openCardId, setOpenCardId] = useState(1);

  const [sort, setSort] = useState({});
  const [steps, setSteps] = useState([1]);
  const [currentPage, setCurrentPage] = useState(
    page && page.crmcampaign ? parseInt(page.crmcampaign) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.crmcampaign ? parseInt(pagecount.crmcampaign) : 10
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [edit, setEdit] = useState("");
  const [filters, setFilters] = useState({});
  const [selectTemplate, setSelectTemplate] = useState(false);
  const dispatch = useDispatch();

  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "all",
  });
  const { control: control1 } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.crmcampaign
          ? pagecount.crmcampaign.toString()
          : "10",
    },
  });
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    getValues: getValues2,
  } = useForm({
    mode: "all",
  });

  const [search, setSearch] = useState(getValues2("search"));
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, crmcampaign: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, crmcampaign: 1 }));
  };
  const fetchcrmCampaigns = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllCrmCampaigns(currentPage, selectCount, sort, filters, search)
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["crm"] &&
        profile.permission_ids["crm"].includes("view")
      ) {
        fetchcrmCampaigns();
      } else {
        navigate("/settings/profile");
      }
    } else {
      fetchcrmCampaigns();
    }
  }, [currentPage, selectCount, sort, filters, search]);
  const handleSort = (order) => {
    setSort(order);
  };

  const handleDelete = async () => {
    if (confirmModal) {
      // setDeleteLoading(true);

      setToogle({
        ...toogle,
        email_index: false,
        sms_index: false,
      });
      setSteps(steps.filter((val2) => val2 !== openCardId));

      // setDeleteLoading(false);
      setConfirmModal(false);
      // console.log(steps[steps.length - 1]);
      if (steps[steps.length - 1]) {
        setOpenCardId(steps[steps.length - 2]);
      } else {
        setOpenCardId(steps[steps.length - 1]);
      }
    } else {
      setConfirmModal(true);
      // setDeleteId();
    }
  };
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      data.search !== "" && setClearSearch(true);
      localStorage.setItem("page", JSON.stringify({ ...page, crmcampaign: 1 }));
    }
  };
  const defaultValuesFortime = [
    { label: "00:00", value: "00:00" },
    { label: "01:00", value: "01:00" },
    { label: "02:00", value: "02:00" },
    { label: "03:00", value: "03:00" },
    { label: "04:00", value: "04:00" },
    { label: "05:00", value: "05:00" },
    { label: "06:00", value: "06:00" },
    { label: "07:00", value: "07:00" },
    { label: "08:00", value: "08:00" },
    { label: "09:00", value: "09:00" },
    { label: "10:00", value: "10:00" },
    { label: "11:00", value: "11:00" },
    { label: "12:00", value: "12:00" },
    { label: "13:00", value: "13:00" },
    { label: "14:00", value: "14:00" },
    { label: "15:00", value: "15:00" },
    { label: "16:00", value: "16:00" },
    { label: "17:00", value: "17:00" },
    { label: "18:00", value: "18:00" },
    { label: "19:00", value: "19:00" },
    { label: "20:00", value: "20:00" },
    { label: "21:00", value: "21:00" },
    { label: "22:00", value: "22:00" },
    {
      label: "23:00",
      value: "23:00",
    },
  ];
  //for select templates
  const fetchTemplates = async (type) => {
    try {
      await dispatch(getAllTemplates(1, 10, undefined, { type: type }));
    } catch (err) {
      console.log(err);
    }
  };
  // useEffect(() => {
  //   fetchTemplates();
  // }, []);
  // const defaultOptionsFormailTemplate = [];
  // const defaultOptionsForsmsTemplates = [];
  const defaultOptionsForTemplates = templates
    ? templates.list.map((val) => {
        return {
          value: val._id,
          label: val.name,
        };
      })
    : [];
  const loadOptions = async (inputValue, callback, type) => {
    try {
      const data = await dispatch(
        getAllTemplates(1, 10, undefined, undefined, inputValue)
      );

      callback(
        data &&
          data.list.reduce((acc, val) => {
            if (val.type === type) {
              acc.push({ label: val.name, value: val._id });
            }
            return acc;
          }, [])
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleCreateCampaign = async (data) => {
    let valid;
    steps.forEach((val) => {
      if (
        (data[`mail_template_${val}`] &&
          data[`mail_template_${val}`]["value"]) ||
        (data[`sms_template_${val}`] && data[`sms_template_${val}`]["value"])
      ) {
        valid = true;
      } else {
        valid = false;
      }
    });

    if (valid === true) {
      setIsSave(true);
      const data1 = steps.map((val) => {
        return {
          email_template_id:
            data[`mail_template_${val}`] &&
            data[`mail_template_${val}`]["value"],
          email_send_at:
            data[`mail_time_${val}`] && data[`mail_time_${val}`]["value"],
          email_repeat: toogle[`email_${val}`] ? true : false,
          email_for_every:
            data[`repeat_eamil_${val}`] &&
            parseInt(data[`repeat_eamil_${val}`]),
          email_no_of_times:
            data[`mails_${val}`] && parseInt(data[`mails_${val}`]),
          sms_template_id:
            data[`sms_template_${val}`] && data[`sms_template_${val}`]["value"],
          sms_send_at:
            data[`sms_time_${val}`] && data[`sms_time_${val}`]["value"],
          sms_repeat: toogle[`sms_${val}`] ? true : false,
          sms_for_every:
            data[`repeat_sms_${val}`] && parseInt(data[`repeat_sms_${val}`]),
          sms_no_of_times: data[`sms_${val}`] && parseInt(data[`sms_${val}`]),
          email_template_name:
            data[`mail_template_${val}`] &&
            data[`mail_template_${val}`]["label"],
          sms_template_name:
            data[`sms_template_${val}`] && data[`sms_template_${val}`]["label"],
          id: val,
        };
      });
      const finalData = {
        name: data.name,
        desc: data.description,
        steps: data1,
        status: data.status?.value,
      };
      if (!edit) {
        try {
          await dispatch(createCampaigns(finalData));
          toast.success("Campaign Created Successfully");
          setIsModalOpen(false);
          fetchcrmCampaigns();
        } catch (err) {
          toast.error(err.message.toLowerCase());
        }
      } else {
        try {
          await dispatch(editCampaign(edit.id, finalData));
          toast.success("Campaign Edited Successfully");
          setIsModalOpen(false);
          fetchcrmCampaigns();
          setEdit("");
          reset({});
        } catch (err) {
          toast.error(err.message.toLowerCase());
        }
      }
      setIsSave(false);
    } else {
      toast.error(
        "Please Select template in current step to add this campaign"
      );
    }
  };
  const handleEdit = (item) => {
    let data = {};
    let switchData = {};
    setSteps(item.steps.map((val) => val.id));
    // console.log(steps);
    // setSteps(newArray);
    setOpenCardId(item.steps[item.steps.length - 1].id);
    item.steps.forEach((val, index) => {
      data = {
        status: {
          label:
            item.status &&
            item.status
              .toLowerCase()
              .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
          value: item.status,
        },
        ...data,
        [`mail_template_${val.id}`]: val.email_template_id
          ? {
              value: val.email_template_id,
              label: val.email_template_name,
            }
          : undefined,
        [`mail_time_${val.id}`]: val.email_send_at
          ? {
              label: val.email_send_at,
              value: val.email_send_at,
            }
          : undefined,
        [`repeat_eamil_${val.id}`]: val.email_for_every,
        [`mails_${val.id}`]: val.email_no_of_times,
        [`sms_template_${val.id}`]: val.sms_template_id
          ? {
              value: val.sms_template_id,
              label: val.sms_template_name,
            }
          : undefined,
        [`sms_time_${val.id}`]: val.sms_send_at
          ? {
              label: val.sms_send_at,
              value: val.sms_send_at,
            }
          : undefined,
        // sms_repeat: toogle[`sms_${index}`],
        [`repeat_sms_${val.id}`]: val.sms_for_every,
        [`sms_${val.id}`]: val.sms_no_of_times,
      };
      switchData = {
        ...switchData,
        [`sms_${val.id}`]: val.sms_repeat,
        [`email_${val.id}`]: val.email_repeat,
      };
    });

    setToogle(switchData);
    setEdit({
      id: item._id,
      name: item.name,
      description: item.desc,
      ...data,
    });

    setIsModalOpen(true);
  };
  useEffect(() => {
    if (edit) {
      reset(edit);
    } else {
      reset({});
    }
  }, [edit]);
  const handleDeleteCampaign = async (id) => {
    if (confirmModal1) {
      setDeleteLoading(true);
      try {
        await dispatch(deletecampaign(id));
        setConfirmModal1(false);
        setDeleteId1(null);
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
      setDeleteLoading(false);
    } else {
      setConfirmModal1(true);
      setDeleteId1(id);
    }
  };

  const memoizedChildComponent = useMemo(() => {
    return (
      <LeadsWatchTable
        isOpen={isOpen}
        sort={sort}
        screen="crmcampaigns"
        handleDelete={handleDeleteCampaign}
        handleSort={handleSort}
        handleEdit={handleEdit}
        isLoading={isLoading}
        heading={["name", "desc", "status", "Actions"]}
        sortIcon={true}
        // data={allPublishers && allPublishers.list ? allPublishers.list : null}
        data={campaigns ? campaigns.list : []}
      />
    );
  }, [isLoading, isOpen]);
  // console.log(getValues([`mail_template_${1}`]));

  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
        // left={[aw(0), aw(3), aw(98)]}
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
          // width={[aw(350), aw(350), aw(1248)]}
          // left={[aw(0), aw(3), aw(98)]}
        >
          <VStack
            marginTop={ah(7)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              lineHeight={ah(34)}
              justifyContent={"space-between"}
              space={aw(8)}
              alignItems={"flex-end"}
            >
              <HStack
                alignItems={"baseline"}
                // width={aw(194)}
                justifyContent="space-between"
                space={aw(8)}
              >
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily="RobotoMedium"
                >
                  Campaigns
                </Text>
                <Text
                  top={ah(20)}
                  fontSize={aw(18)}
                  color={"#919191"}
                  fontFamily="RobotoMedium"
                >
                  {campaigns && campaigns.total_count
                    ? campaigns.total_count
                    : 0}
                </Text>
              </HStack>
              <Hidden only={["sm", "base"]}>
                {/* <HStack space={aw(10)}>
                 
                </HStack> */}
              </Hidden>
            </HStack>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All your Campaigns list will be shown here
            </Text>

            <Divider
              mt={ah(9)}
              bg="#E6E7E9"
              height={ah(1)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            />
          </VStack>
          <VStack height={ah(35)} mt={ah(15)}>
            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control1}
                    name={"select"}
                    list={["10", "20", "50", "100"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    // width={aw(80)}
                    width={79}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>
              <Hidden only={["sm", "base"]}>
                <HStack space={aw(12)}>
                  {/* <LeadsWatchSelect
                    control={control}
                    name={"status1"}
                    placeholder="Status"
                    list={["Active", "InActive"]}
                    handleChange={(value) =>
                      setFilters({ ...filters, status: value })
                    }
                    width={aw(100)}
                    // height={ah(32)}
                  /> */}
                  <LeadsWatchAsyncSelect
                    isClearable={true}
                    height={ah(38)}
                    required={true}
                    minWidth={aw(100)}
                    defaultOptions={[
                      { label: "Active", value: "active" },
                      { label: "Inactive", value: "inactive" },
                    ]}
                    control={control}
                    placeholder="Status"
                    name="status1"
                    handleChange={(value) => {
                      if (value) {
                        setFilters({ ...filters, status: value.value });
                      } else {
                        // // setFilters(delete filters.status);
                        delete filters.status;
                        setFilters({ ...filters });
                      }
                    }}
                  />

                  <LeadsWatchSearch
                    placeholder={[
                      'Search by "Name"',
                      'Search by "Company"',
                      'Search by "Email"',
                      'Search by "Phone"',
                      'Search by "Status"',
                    ]}
                    height={ah(38)}
                    control={control2}
                    name="search"
                    onFocus={() => setClearSearch(false)}
                    inputRightElement={
                      clearSearch ? (
                        <Pressable
                          w={aw(20)}
                          onPress={() => {
                            reset2();
                            setSearch(getValues2("search"));
                            setClearSearch(false);
                            setCurrentPage(1);
                            localStorage.setItem(
                              "page",
                              JSON.stringify({ ...page, crmcampaign: 1 })
                            );
                          }}
                        >
                          <LeadsWatchCrossIcon />
                        </Pressable>
                      ) : (
                        <Pressable
                          w={aw(30)}
                          onPress={handleSubmit2(handleSearch)}
                          // pr={aw(10)}
                        >
                          <LeadsWatchSearchIcon />
                        </Pressable>
                      )
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit2(
                          handleSearch({ search: getValues2("search") })
                        );
                      }
                    }}
                  />

                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "crm"
                        ? true
                        : profile && profile.role === "user"
                        ? profile.permission_ids["crm"].includes("create")
                          ? false
                          : true
                        : false
                    }
                    onPress={() => {
                      setIsModalOpen(true);
                    }}
                    label="Create"
                    style={{ width: aw(101), height: ah(38) }}
                    bg="#2230F8"
                    borderColor="#2230F8"
                    color="#FFFFFF"
                    leftIcon={<LeadsWatchAddIcon />}
                  />
                </HStack>
              </Hidden>
            </HStack>
          </VStack>

          <VStack mt={ah(15)}>
            <VStack>{memoizedChildComponent}</VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={
                  campaigns && campaigns.total_count ? campaigns.total_count : 1
                }
                // totalCount={10}
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, crmcampaign: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>

      <LeadsWatchModal
        width={aw(500)}
        height={ah(700)}
        showModal={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEdit("");
          setSteps([1]);
          setOpenCardId(1);
          reset({});
          setRefresh(!refresh);
          setToogle({});
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            {edit ? "Edit Campaign" : "Add Campaign"}
          </Text>
        }
      >
        <Flex gap={ah(20)} px={aw(5)}>
          <LeadsWatchFilterInput
            screen={
              getValues("name") && getValues("name").length > 0
                ? "edit"
                : "create"
            }
            refresh={refresh}
            control={control}
            name="name"
            placeholder="Name"
            rules={{
              required: {
                value: true,
                message: "Name is required",
              },
              pattern: {
                // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                message:
                  "Name should start with alphabets and can not contain special characters",
              },
            }}
            invalid={errors.name ? true : false}
            error={errors.name ? errors.name.message : ""}
          />

          <LeadsWatchTextArea
            refresh={refresh}
            screen={
              getValues("description") && getValues("description").length > 0
                ? "edit"
                : "create"
            }
            name="description"
            control={control}
            placeholder="Description"
          />
          {edit && (
            <LeadsWatchAsyncSelect
              screen={
                getValues("status") && getValues("status").value
                  ? "edit"
                  : "create"
              }
              refresh={refresh}
              // height={ah(38)}
              minWidth={"100%"}
              defaultOptions={[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ]}
              control={control}
              placeholder="Status"
              name="status"
            />
          )}
          {/* <ScrollView maxH={aw(200)} px={1}> */}
          {steps.map((val, index) => (
            <Flex key={val.toString() + index}>
              <LeadsWatchAnimatedCard
                isSelected={!(openCardId === val)}
                backgroundColor={!(openCardId === val) ? "#E6E7E9" : "#FFFFFF"}
                mt={0.5}
                mb={ah(15)}
                width={"100%"}
                // key={index}
                alignItems="flex-start"
                px={aw(15)}
                pt={aw(10)}
                pb={aw(10)}
              >
                <Flex gap={ah(10)} width="100%">
                  <Pressable
                    onPress={() => {
                      if (openCardId !== val) {
                        setOpenCardId(val);
                      } else {
                        setOpenCardId(null);
                      }
                    }}
                  >
                    <Flex direction="row" justifyContent={"space-between"}>
                      <Text
                        fontFamily="RobotoMedium"
                        fontSize={aw(16)}
                        color="#2230F8"
                      >
                        Step {index + 1}
                      </Text>

                      <LeadsWatchDownArrow />
                    </Flex>
                  </Pressable>
                  <Flex gap={ah(5)} mt={ah(10)}>
                    <Flex width="100%" gap={ah(10)}>
                      <Flex
                        direction="row"
                        width="100%"
                        justifyContent={"space-between"}
                      >
                        <Flex width="48%">
                          <Text
                            style={{
                              fontSize: aw(13),
                              fontFamily: "RobotoMedium",
                              color: "#919191",
                            }}
                          >
                            Select Mail Template
                          </Text>

                          <LeadsWatchAsyncSelect
                            // screen={

                            //   (getValues()`mail_template_${val}`)&&
                            //     getValues(`mail_template_${val}`.["value"])
                            //     ? "edit"
                            //     : "create"
                            // }
                            required={true}
                            refresh={refresh}
                            placeholder="Select Mail Template"
                            type="async"
                            name={`mail_template_${val}`}
                            control={control}
                            defaultOptions={defaultOptionsForTemplates}
                            onInputChange={(inputValues) => {
                              if (inputValues === "") {
                                fetchTemplates();
                              }
                            }}
                            loadOptions={(inputValues, callback) =>
                              loadOptions(inputValues, callback, "email")
                            }
                            onFocus={() => fetchTemplates("email")}
                            handleChange={() =>
                              setSelectTemplate(!selectTemplate)
                            }
                            // rules={{
                            //   required: {
                            //     value: true,
                            //     message: "Name is required",
                            //   },
                            //   pattern: {
                            //     // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            //     value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                            //     message:
                            //       "Name should start with alphabets and can not contain special characters",
                            //   },
                            // }}
                            // invalid={errors.name ? true : false}
                            // error={errors.name ? errors.name.message : ""}
                          />
                        </Flex>
                        <Flex
                          width="48%"
                          // justifyContent={"space-between"}
                          // direction="row"
                          // alignItems={"center"}
                        >
                          <Flex direction="row">
                            <Text
                              style={{
                                fontSize: aw(14),
                                fontFamily: "RobotoRegular",
                                color: "#919191",
                              }}
                            >
                              Repeat mail Sending?
                            </Text>

                            <Tooltip
                              width={aw(150)}
                              label="mails will be send as long as the number of repetitions set is satisfied leaving the interval for each email given in interval in days"
                            >
                              <Flex>
                                <LeadsWatchInfoIcon />
                              </Flex>
                            </Tooltip>
                          </Flex>
                          <Flex justifyContent={"center"} height="50%">
                            <Switch
                              onToggle={() =>
                                setToogle({
                                  ...toogle,
                                  [`email_${val}`]: !toogle[`email_${val}`],
                                })
                              }
                              isDisabled={
                                getValues(`mail_template_${val}`) &&
                                getValues(`mail_template_${val}`.value)
                                  ? false
                                  : true
                              }
                              offTrackColor="#C1C1C1"
                              onTrackColor="#0000FF"
                              _hover={{
                                offTrackColor: "#C1C1C1",
                                onTrackColor: "#0000FF",
                              }}
                              isChecked={toogle && toogle[`email_${val}`]}
                              // size={aw(12)}
                            />
                          </Flex>
                        </Flex>
                      </Flex>
                      {toogle[`email_${val}`] && (
                        <Flex
                          direction="row"
                          justifyContent={"space-between"}
                          width="100%"
                        >
                          <Flex width="48%">
                            <LeadsWatchInput
                              label="Repeat sending every (in days)"
                              // refresh={refresh }
                              // screen={
                              //   getValues[`repeat_email_${index}`] &&
                              //   getValues[`repeat_email_${index}`].length > 0
                              //     ? "edit"
                              //     : "create"
                              // }
                              // required={true}
                              onlyNumbers={true}
                              control={control}
                              name={`repeat_eamil_${val}`}
                              placeholder={"Ex:10"}
                              // toogle[`email_${val}`]
                              rules={{
                                required: {
                                  // value: toogle[`email_${val}`] ? true : false,
                                  value: true,
                                  message: "Email Repeat period is required",
                                },
                              }}
                              invalid={
                                errors[`repeat_eamil_${val}`] ? true : false
                              }
                              error={
                                errors[`repeat_eamil_${val}`]
                                  ? errors[`repeat_eamil_${val}`]["message"]
                                  : ""
                              }
                            />
                          </Flex>
                          <Flex width="48%">
                            <LeadsWatchInput
                              label="Mails to send (in nos)"
                              onlyNumbers={true}
                              placeholder={"Ex:10"}
                              control={control}
                              name={`mails_${val}`}
                              rules={{
                                required: {
                                  value: toogle[`email_${val}`] ? true : false,
                                  message: "Email to send is required",
                                },
                              }}
                              invalid={errors[`mails_${val}`] ? true : false}
                              error={
                                errors[`mails_${val}`]
                                  ? errors[`mails_${val}`]["message"]
                                  : ""
                              }
                            />
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                    <Flex>
                      <Text
                        style={{
                          fontSize: aw(13),
                          fontFamily: "RobotoMedium",
                          color: "#919191",
                        }}
                      >
                        Send Time
                      </Text>
                      <LeadsWatchAsyncSelect
                        required={true}
                        control={control}
                        name={`mail_time_${val}`}
                        defaultOptions={defaultValuesFortime}
                        placeholder="Time"
                        rules={{
                          required: {
                            value:
                              getValues(`mail_template_${val}`) &&
                              getValues(`mail_template_${val}`.value)
                                ? true
                                : false,
                            message: "Time to send email is required",
                          },
                        }}
                        invalid={errors[`mail_time_${val}`] ? true : false}
                        error={
                          errors[`mail_time_${val}`]
                            ? errors[`mail_time_${val}`]["message"]
                            : ""
                        }
                      />
                    </Flex>
                  </Flex>

                  <Flex width="100%" gap={ah(20)}>
                    <Flex
                      direction="row"
                      width="100%"
                      justifyContent={"space-between"}
                    >
                      <Flex width="48%">
                        <Text
                          style={{
                            fontSize: aw(13),
                            fontFamily: "RobotoMedium",
                            color: "#919191",
                          }}
                        >
                          Select SMS Template
                        </Text>
                        <LeadsWatchAsyncSelect
                          required={true}
                          // refresh={refresh || changeSteps}
                          // screen={
                          //   getValues[`sms_template_${index}`] &&
                          //   getValues[`sms_template_${index}`]["value"]
                          //     ? "edit"
                          //     : "create"
                          // }
                          placeholder="Select SMS Template"
                          type="async"
                          name={`sms_template_${val}`}
                          control={control}
                          onInputChange={(inputValues) => {
                            if (inputValues === "") {
                              fetchTemplates();
                            }
                          }}
                          defaultOptions={defaultOptionsForTemplates}
                          loadOptions={(inputValues, callback) =>
                            loadOptions(inputValues, callback, "sms")
                          }
                          onFocus={() => fetchTemplates("sms")}
                          handleChange={() =>
                            setSelectTemplate(!selectTemplate)
                          }
                        />
                      </Flex>
                      <Flex
                        width="48%"
                        // justifyContent={"space-between"}
                        // alignItems={"center"}
                      >
                        <Flex direction="row">
                          <Text
                            style={{
                              fontSize: aw(13),
                              fontFamily: "RobotoMedium",
                              color: "#919191",
                            }}
                          >
                            Repeat SMS Sending?
                          </Text>
                          <Tooltip
                            width={aw(150)}
                            label="sms will be send as long as the number of repetitions set is satisfied leaving the interval for each sms given in interval in days"
                          >
                            <Flex>
                              <LeadsWatchInfoIcon />
                            </Flex>
                          </Tooltip>
                        </Flex>
                        <Flex height="50%" justifyContent={"center"}>
                          <Switch
                            onToggle={() =>
                              setToogle({
                                ...toogle,
                                [`sms_${val}`]: !toogle[`sms_${val}`],
                              })
                            }
                            offTrackColor="#C1C1C1"
                            onTrackColor="#0000FF"
                            _hover={{
                              offTrackColor: "#C1C1C1",
                              onTrackColor: "#0000FF",
                            }}
                            isChecked={toogle && toogle[`sms_${val}`]}
                            // size={aw(12)}
                            isDisabled={
                              getValues(`sms_template_${val}`) &&
                              getValues(`sms_template_${val}`.value)
                                ? false
                                : true
                            }
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                    {toogle[`sms_${val}`] && (
                      <Flex
                        direction="row"
                        justifyContent={"space-between"}
                        width="100%"
                      >
                        <Flex width="48%">
                          <LeadsWatchInput
                            label={"Repeat sending every (in days)"}
                            onlyNumbers={true}
                            control={control}
                            name={`repeat_sms_${val}`}
                            placeholder={"Ex: 10"}
                            rules={{
                              required: {
                                value: toogle[`sms_${val}`] ? true : false,
                                message: "sms Repeat period is required",
                              },
                            }}
                            invalid={errors[`repeat_sms_${val}`] ? true : false}
                            error={
                              errors[`repeat_sms_${val}`]
                                ? errors[`repeat_sms_${val}`]["message"]
                                : ""
                            }
                          />
                        </Flex>
                        <Flex width="48%">
                          <LeadsWatchInput
                            label={"SMS to send (in nos.)"}
                            onlyNumbers={true}
                            placeholder={"Ex : 10"}
                            control={control}
                            name={`sms_${val}`}
                            rules={{
                              required: {
                                value: toogle[`sms_${val}`] ? true : false,
                                message: "Sms to send is required ",
                              },
                            }}
                            invalid={errors[`sms_${val}`] ? true : false}
                            error={
                              errors[`sms_${val}`]
                                ? errors[`sms_${val}`]["message"]
                                : ""
                            }
                          />
                        </Flex>
                      </Flex>
                    )}
                  </Flex>
                  <Flex>
                    <Text
                      style={{
                        fontSize: aw(13),
                        fontFamily: "RobotoMedium",
                        color: "#919191",
                      }}
                    >
                      Send Time
                    </Text>
                    <LeadsWatchAsyncSelect
                      required={true}
                      control={control}
                      name={`sms_time_${val}`}
                      defaultOptions={defaultValuesFortime}
                      placeholder="Time"
                      rules={{
                        required: {
                          value:
                            getValues(`sms_template_${val}`) &&
                            getValues(`sms_template_${val}`.value)
                              ? true
                              : false,
                          message: "Time to send sms is required",
                        },
                      }}
                      invalid={errors[`sms_time_${val}`] ? true : false}
                      error={
                        errors[`sms_time_${val}`]
                          ? errors[`sms_time_${val}`]["message"]
                          : ""
                      }
                    />
                  </Flex>
                  {steps.length !== 1 && (
                    <Flex alignItems={"flex-end"}>
                      <Text
                        fontFamuly="RobotoRegular"
                        fontSize={aw(13)}
                        color="#FA4E2D"
                        onPress={handleDelete}
                      >
                        Remove step
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </LeadsWatchAnimatedCard>
            </Flex>
          ))}
          {/* </ScrollView> */}
          <Flex
            direction="row"
            width="100%"
            justifyContent={"space-between"}
            // gap={aw(12)}
          >
            <Flex>
              <LeadsWatchButton
                label="Add Step"
                style={{ width: aw(100), height: ah(40) }}
                onPress={() => {
                  if (
                    getValues(`mail_template_${steps[steps.length - 1]}`) ||
                    getValues(`sms_template_${steps[steps.length - 1]}}`)
                  ) {
                    const newStep = Date.now();
                    setSteps([...steps, newStep]);
                    setOpenCardId(newStep);
                  } else {
                    toast.error(
                      "Please select a template in current step to create new step"
                    );
                  }
                }}
                bg="#2230F8"
                borderColor="#D1D5DB"
                color="#FFFFFF"
              />
            </Flex>
            <Flex gap={aw(12)} direction="row">
              <LeadsWatchButton
                label="cancel"
                style={{ width: aw(100), height: ah(40) }}
                onPress={() => {
                  setIsModalOpen(false);
                  setEdit("");
                  setSteps([1]);
                  setOpenCardId(1);
                  reset({});
                  setRefresh(!refresh);
                  setToogle({});
                }}
              />
              <LeadsWatchButton
                label={edit ? "Update" : "Add"}
                style={{ width: aw(100), height: ah(40) }}
                isLoading={isSave}
                onPress={handleSubmit(handleCreateCampaign)}
                bg="#2230F8"
                borderColor="#D1D5DB"
                color="#FFFFFF"
              />
            </Flex>
          </Flex>
        </Flex>
      </LeadsWatchModal>

      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to remove this step?
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="No"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete(deleteId)}
              label="Yes"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal1}
        onClose={() => {
          setConfirmModal1(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this campaign?
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal1(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDeleteCampaign(deleteId1)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};
export default Campaigns;
