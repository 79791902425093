import { React, useEffect, useState, useRef, useMemo } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Pressable,
  Hidden,
  useBreakpointValue,
  Flex,
  Image,
  Tooltip,
} from "native-base";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/material.css";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { aw, ah } from "../../../utils";
//components
import LeadsWatchTable from "../../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../../components/LeadsWatchButton";
import LeadsWatchFilterInput from "../../../components/LeadsWatchFilterInput";
import LeadsWatchModal from "../../../components/LeadsWatchModal";
import LeadsWatchSearch from "../../../components/LeadsWatchSearch";
import LeadsWatchAsyncSelect from "../../../components/LeadsWatchAsyncSelect";
//icons
import LeadsWatchAddIcon from "../../../components/svg/LeadsWatchAddIcon";
import LeadsWatchEyeIcon from "../../../components/svg/LeadsWatchEyeIcon";
import LeadsWatchEyeOffIcon from "../../../components/svg/LeadsWatchEyeOffIcon";
import LeadsWatchSearchIcon from "../../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchCrossIcon from "../../../components/svg/LeadsWatchCrossIcon";

import {
  getAllSignatures,
  createSignature,
  editSignature,
  deleteSignature,
  createSmtp,
  getAllSmtps,
} from "../../../store/actions/crmsignatures";
import { fetchAllNumbers } from "../../../store/actions/calls";
import LeadsWatchUploadFileIcon from "../../../components/svg/LeadsWatchUploadFileIcon";
import LeadsWatchInfoIcon from "../../../components/svg/LeadsWatchInfoIcon";

// import * as ImagePicker from "expo-image-picker";

const Signatures = ({ isOpen }) => {
  const hiddenFileInput = useRef(null);
  const allNumbers = useSelector((state) => state.calls.allBuyedNumbers);
  const [phoneNo, setPhoneNo] = useState(null);
  const signatures = useSelector((state) => state.crmsignatures.allSignatures);
  const smtps = useSelector((state) => state.crmsignatures.smtps);
  const navigate = useNavigate();
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [image, setImage] = useState(null);
  const profile = useSelector((state) => state.auth.profile);
  const [refresh, setRefresh] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);

  const [sort, setSort] = useState({});
  const [currentPage, setCurrentPage] = useState(
    page && page.crmsignature ? parseInt(page.crmsignature) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.crmsignature ? parseInt(pagecount.crmsignature) : 10
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [edit, setEdit] = useState("");
  const [filters, setFilters] = useState({});
  const [show, setShow] = useState(false);
  const [smtpModal, setSmtpModal] = useState(false);
  const dispatch = useDispatch();

  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,

    getValues,
  } = useForm({
    mode: "all",
  });
  const { control: control1 } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.crmsignature
          ? pagecount.crmsignature.toString()
          : "10",
    },
  });
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    getValues: getValues2,
  } = useForm({
    mode: "all",
  });
  const {
    control: control3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    reset: reset3,
    getValues: getValues3,
  } = useForm({
    mode: "all",
  });
  const [search, setSearch] = useState(getValues2("search"));
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, crmsignature: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, crmsignature: 1 }));
  };
  const fetchsignatures = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllSignatures(currentPage, selectCount, sort, filters, search)
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["crm"] &&
        profile.permission_ids["crm"].includes("view")
      ) {
        fetchsignatures();
      } else {
        navigate("/settings/profile");
      }
    } else {
      fetchsignatures();
    }
  }, [currentPage, selectCount, sort, filters, search]);

  const handleSort = (order) => {
    setSort(order);
  };

  const handleDelete = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        await dispatch(deleteSignature(id));
        toast.success("Signature Deleted Successfully");
        setConfirmModal(false);
        setDeleteId("");
        fetchsignatures();
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }

      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      data.search !== "" && setClearSearch(true);
      localStorage.setItem(
        "page",
        JSON.stringify({ ...page, crmsignature: 1 })
      );
    }
  };
  const handleEdit = (item) => {
    // console.log(item);
    // setRefresh(!refresh);

    setEdit({
      label: item.label,
      name: item.name,
      email: item.from_email,
      smtp: { value: item.smtp_id, label: item.smtp_name },
      phone: item.phone ? item.phone.replace(/[^0-9]/g, "") : "91",
      number: { label: item.from_number, value: item.number_id },
      id: item._id,
      status: {
        label:
          item.status &&
          item.status
            .toLowerCase()
            .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
        value: item.status,
      },
    });
    item.logo && setImage({ image: item.logo, localImage: item.logo });
    (item.phone === undefined || item.phone === "") && setPhoneNo("in");
    // setEdit(item._id);
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (edit) {
      reset(edit);
    } else {
      setPhoneNo("in");

      reset({
        name: "",
        email: "",
        smtp: "",
        phone: "91",
        number: "",
        // select: "",
      });
      // clearErrors();
    }
  }, [edit]);
  const memoizedChildComponent = useMemo(() => {
    return (
      <LeadsWatchTable
        isOpen={isOpen}
        sort={sort}
        screen="signatures"
        handleDelete={handleDelete}
        handleSort={handleSort}
        handleEdit={handleEdit}
        isLoading={isLoading}
        heading={[
          "name",
          "from_email",
          "phone",
          "port",
          "host",
          "status",
          "Actions",
        ]}
        sortIcon={true}
        data={signatures && signatures.list ? signatures.list : null}
      />
    );
  }, [isLoading, isOpen]);

  const handleCreateSignature = async (data) => {
    if (!data.email && !data.number) {
      toast.error("Atleast One Configuration is Required");
    } else {
      let j = 0;
      const number = data.phone;
      let format = phoneNo && phoneNo.format.toString();
      for (let i = 0; i <= format.length - 1; i++) {
        if (format[i] === ".") {
          format = format.replace(".", number[j]);
          j++;
        }
      }
      let formData = new FormData();
      formData.append("name", data.name);
      formData.append("label", data.label);
      data.email && formData.append("from_email", data.email);
      data.email && formData.append("phone", format);
      data.email &&
        data.smtp &&
        data.smtp.value &&
        formData.append("smtp_id", data.smtp.value);
      data.email &&
        data.smtp &&
        data.smtp.label &&
        formData.append("smtp_name", data.smtp.label);

      data.number &&
        data.number.value &&
        formData.append("from_number", data.number.label);

      data.number &&
        data.number.value &&
        formData.append("number_id", data.number.value);
      formData.append("status", data.status && data.status.value);
      formData.append("logo", image ? image.image : null);
      setIsSave(true);
      if (edit) {
        try {
          await dispatch(editSignature(edit.id, formData));
          setIsModalOpen(false);
          setRefresh(!refresh);
          fetchsignatures();
          reset({
            name: "",
            email: "",
            smtp: "",
            phone: "91",
            number: "",
            // select: "",
          });
          setPhoneNo("in");
          toast.success("Signature Added Successfully");
          setShow(false);
          setEdit("");
          setImage(null);
        } catch (err) {
          toast.error(err.message.toLowerCase());
        }
      } else {
        try {
          await dispatch(createSignature(formData));
          setIsModalOpen(false);
          setRefresh(!refresh);
          fetchsignatures();
          reset({
            name: "",
            email: "",
            host: "",
            port: "",
            password: "",
            userName: "",
            phone: "91",
            number: "",
            // select: "",
          });
          setPhoneNo("in");
          setImage(null);
          toast.success("Signature Added Successfully");
          setShow(false);
        } catch (err) {
          toast.error(err.message.toLowerCase());
        }
      }
      setIsSave(false);
    }
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (e) => {
    setImage({
      image: e.target.files[0],
      localImage: URL.createObjectURL(e.target.files[0]),
    });
  };
  //for number select
  const fetchData = async () => {
    try {
      await dispatch(fetchAllNumbers(undefined, 1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  //for smtps select
  const fetchSmtps = async () => {
    try {
      await dispatch(getAllSmtps(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const defaultOptionsForSmtps = smtps
    ? smtps.list.map((val) => {
        return { value: val._id, label: val.name };
      })
    : [];

  const defaultOptionsForNums = allNumbers
    ? allNumbers.list.map((val) => {
        return { value: val._id, label: val.number };
      })
    : [];
  const loadOptions = async (inputValue, callback, list, type) => {
    try {
      const data =
        list === "smtp"
          ? await dispatch(fetchAllNumbers(1, 10, inputValue))
          : await dispatch(fetchAllNumbers({ search: inputValue }, 1, 10));

      callback(
        list === "smtp"
          ? data &&
              data.list.map((val) => ({
                value: val._id,
                label: val.name,
              }))
          : data &&
              data.list.map((val) => ({
                value: val._id,
                label: val.number,
              }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //create smtp
  const handleCreateSmtp = async (data) => {
    setIsSave(true);
    try {
      await dispatch(
        createSmtp({
          name: data.name,
          host: data.host,
          port: data.port,
          username: data.userName,
          password: data.password,
        })
      );
      toast.success("SMTP Created Successfully");
      setSmtpModal(false);
      reset3({});
      setRefresh(!refresh);
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsSave(false);
  };

  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
        // left={[aw(0), aw(3), aw(98)]}
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
          // width={[aw(350), aw(350), aw(1248)]}
          // left={[aw(0), aw(3), aw(98)]}
        >
          <VStack
            marginTop={ah(7)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              lineHeight={ah(34)}
              justifyContent={"space-between"}
              space={aw(8)}
              alignItems={"flex-end"}
            >
              <HStack
                alignItems={"baseline"}
                // width={aw(194)}
                justifyContent="space-between"
                space={aw(8)}
              >
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily="RobotoMedium"
                >
                  Signatures
                </Text>
                <Text
                  top={ah(20)}
                  fontSize={aw(18)}
                  color={"#919191"}
                  fontFamily="RobotoMedium"
                >
                  {signatures && signatures.total_count
                    ? signatures.total_count
                    : 0}
                </Text>
              </HStack>
              <Hidden only={["sm", "base"]}>
                {/* <HStack space={aw(10)}>
                 
                </HStack> */}
              </Hidden>
            </HStack>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All your Signatures list will be shown here
            </Text>

            <Divider
              mt={ah(9)}
              bg="#E6E7E9"
              height={ah(1)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            />
          </VStack>
          <VStack height={ah(35)} mt={ah(15)}>
            <HStack justifyContent={"space-between"}>
              <HStack space={aw(12)}>
                <HStack height={ah(38)} alignItems="center">
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    Show Entries :{" "}
                  </Text>
                  <Center>
                    <LeadsWatchSelect
                      control={control1}
                      name={"select"}
                      list={["10", "20", "50", "100"]}
                      // value={selectCount}
                      handleChange={(value) =>
                        handleChangeSelect(parseInt(value))
                      }
                      // width={aw(80)}
                      width={79}
                      height={ah(38)}
                    />
                  </Center>
                </HStack>
                <LeadsWatchButton
                  isDisabled={
                    profile && profile.role === "crm"
                      ? true
                      : profile && profile.role === "user"
                      ? profile.permission_ids["crm"].includes("create")
                        ? false
                        : true
                      : false
                  }
                  onPress={() => {
                    navigate("/crm/smtps");
                  }}
                  label="SMTP"
                  style={{ width: aw(100), height: ah(38) }}
                  bg="#2230F8"
                  borderColor="#2230F8"
                  color="#FFFFFF"
                  // leftIcon={<LeadsWatchAddIcon />}
                />
              </HStack>
              <Hidden only={["sm", "base"]}>
                <HStack space={aw(12)}>
                  <LeadsWatchAsyncSelect
                    isClearable={true}
                    height={ah(38)}
                    required={true}
                    minWidth={aw(100)}
                    defaultOptions={[
                      { label: "Active", value: "active" },
                      { label: "Inactive", value: "inactive" },
                    ]}
                    control={control}
                    placeholder="Status"
                    name="status1"
                    handleChange={(value) => {
                      if (value) {
                        setFilters({ ...filters, status: value.value });
                      } else {
                        // // setFilters(delete filters.status);
                        delete filters.status;
                        setFilters({ ...filters });
                      }
                    }}
                  />

                  <LeadsWatchSearch
                    placeholder={[
                      'Search by "Name"',
                      'Search by "Company"',
                      'Search by "Email"',
                      'Search by "Phone"',
                      'Search by "Status"',
                    ]}
                    height={ah(38)}
                    control={control2}
                    name="search"
                    onFocus={() => setClearSearch(false)}
                    inputRightElement={
                      clearSearch ? (
                        <Pressable
                          w={aw(20)}
                          onPress={() => {
                            reset2();
                            setSearch(getValues2("search"));
                            setClearSearch(false);
                            setCurrentPage(1);
                            localStorage.setItem(
                              "page",
                              JSON.stringify({ ...page, crmsignature: 1 })
                            );
                          }}
                        >
                          <LeadsWatchCrossIcon />
                        </Pressable>
                      ) : (
                        <Pressable
                          w={aw(30)}
                          onPress={handleSubmit2(handleSearch)}
                          // pr={aw(10)}
                        >
                          <LeadsWatchSearchIcon />
                        </Pressable>
                      )
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit2(
                          handleSearch({ search: getValues2("search") })
                        );
                      }
                    }}
                  />

                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "crm"
                        ? true
                        : profile && profile.role === "user"
                        ? profile.permission_ids["crm"].includes("create")
                          ? false
                          : true
                        : false
                    }
                    onPress={() => {
                      setIsModalOpen(true);
                    }}
                    label="Create"
                    style={{ width: aw(101), height: ah(38) }}
                    bg="#2230F8"
                    borderColor="#2230F8"
                    color="#FFFFFF"
                    leftIcon={<LeadsWatchAddIcon />}
                  />
                </HStack>
              </Hidden>
            </HStack>
          </VStack>

          <VStack mt={ah(15)}>
            <VStack>{memoizedChildComponent}</VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={
                  signatures && signatures.total_count
                    ? signatures.total_count
                    : 1
                }
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, crmsignature: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>

      <LeadsWatchModal
        width={aw(450)}
        height={ah(700)}
        showModal={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setPhoneNo("in");
          reset({
            name: "",
            email: "",
            smtp: "",
            phone: "91",
            number: "",
            // select: "",
          });
          setRefresh(!refresh);
          setEdit("");
          setShow(false);
          setImage(null);
        }}
        heading={
          <HStack justifyContent={"center"} alignItems={"center"}>
            <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
              {edit ? "Edit Signature" : "Add Signature"}
            </Text>

            <Tooltip
              width={aw(170)}
              label="A signature will be used as the sender details in the campaign to which it is attached"
              placement={"bottom"}
              openDelay={200}
            >
              <HStack>
                <LeadsWatchInfoIcon color="#919191" />
              </HStack>
            </Tooltip>
          </HStack>
        }
      >
        <Flex gap={ah(20)} px={aw(5)}>
          <Flex direction="row" width="100%" justifyContent={"space-between"}>
            <Flex width="48%">
              <LeadsWatchFilterInput
                screen={
                  getValues("label") && getValues("label").length > 0
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                control={control}
                name="label"
                placeholder="Label"
                rules={{
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                  pattern: {
                    // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                    message:
                      "Name should start with alphabets and can not contain special characters",
                  },
                }}
                invalid={errors.label ? true : false}
                error={errors.label ? errors.label.message : ""}
              />
            </Flex>
            <Flex width="48%">
              <LeadsWatchFilterInput
                screen={
                  getValues("name") && getValues("name").length > 0
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                control={control}
                name="name"
                placeholder="Name"
                rules={{
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                  pattern: {
                    // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                    message:
                      "Name should start with alphabets and can not contain special characters",
                  },
                }}
                invalid={errors.name ? true : false}
                error={errors.name ? errors.name.message : ""}
              />
            </Flex>
          </Flex>
          <Flex gap={ah(10)}>
            <Text fontSize={aw(14)} fontFamily="RobotoMedium">
              Email Configuration
            </Text>
            <Flex gap={ah(20)}>
              <LeadsWatchFilterInput
                screen={
                  getValues("email") && getValues("email").length > 0
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                control={control}
                name="email"
                placeholder="Email"
                rules={{
                  // required: {
                  //   value: true,
                  //   message: "Email is required",
                  // },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please Enter a Valid Email",
                  },
                }}
                invalid={errors.email ? true : false}
                error={errors.email ? errors.email.message : ""}
              />
              <VStack zIndex={1}>
                <Controller
                  control={control}
                  name={"phone"}
                  // style={{
                  //   width: aw(330),
                  //   heigth: ah(40),
                  // }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PhoneInput
                      className={"phoneInput"}
                      country={"in"}
                      isValid={(inputNumber, country, countries) => {
                        setPhoneNo(country);
                        return !errors.phone;
                      }}
                      placeholder="Enter PhoneNumber"
                      value={value}
                      containerStyle={{
                        borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",
                      }}
                      inputStyle={{
                        borderWidth: errors.phone ? 2 : 1,
                        borderRadius: aw(5),
                        width: "100%",
                        height: ah(7),
                        fontFamily: "RobotoRegular",
                        backgroundColor: "#fff",
                        // borderWidth: 0,
                        fontSize: aw(14),
                        borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",

                        // borderColor: !errors.phone && "#D1D5DB",
                      }}
                      onChange={(value, data) => {
                        onChange(value);
                        // onChange(value);
                      }}
                      onBlur={() => {
                        onBlur();
                      }}
                      dropdownStyle={{
                        height: ah(150),
                        width: aw(330),
                      }}
                    />
                  )}
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     // validate: (value) =>
                  //     //   phoneNo &&
                  //     //   phoneNo.format.match(/\./g).length === value.length,
                  //     message: "Enter a valid password",
                  //     validate: (value) =>
                  //       value.length === phoneNo.format.match(/\./g).length,
                  //   },
                  // }}
                  rules={{
                    //   required: {
                    //     value: getValues("email") ? true : false,
                    //   },
                    validate: (value) => {
                      if (getValues("email")) {
                        return (
                          value &&
                          value.length === phoneNo.format.match(/\./g).length
                        );
                      }
                    },
                  }}
                  invalid={errors.phone ? true : false}
                  // invalid={true}
                  error={errors.phone ? errors.phone.message : ""}
                />
                {errors.phone && (
                  <Text
                    color="#FA4E2D"
                    fontFamily="RobotoRegular"
                    fontSize={aw(12)}
                    pt={ah(2)}
                  >
                    {"Please enter a valid phone number"}
                  </Text>
                )}
              </VStack>
              <LeadsWatchAsyncSelect
                type="async"
                loadOptions={(inputValues, callback) =>
                  loadOptions(inputValues, callback, "smtp")
                }
                screen={
                  getValues("number") && getValues("number").value
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                // height={ah(38)}
                minWidth={"100%"}
                defaultOptions={defaultOptionsForSmtps}
                control={control}
                placeholder="Select SMTP"
                name="smtp"
                onInputChange={(inputValues) => {
                  if (inputValues === "" || inputValues === undefined) {
                    fetchSmtps();
                  }
                }}
                onFocus={fetchSmtps}
              />
            </Flex>
          </Flex>
          <Flex gap={ah(10)}>
            <Text fontSize={aw(14)} fontFamily="RobotoMedium">
              SMS Configuration
            </Text>
            <LeadsWatchAsyncSelect
              type="async"
              loadOptions={(inputValues, callback) =>
                loadOptions(inputValues, callback)
              }
              screen={
                getValues("number") && getValues("number").value
                  ? "edit"
                  : "create"
              }
              refresh={refresh}
              // height={ah(38)}
              minWidth={"100%"}
              defaultOptions={defaultOptionsForNums}
              control={control}
              placeholder="Select Number"
              name="number"
              onInputChange={(inputValues) => {
                if (inputValues === "" || inputValues === undefined) {
                  fetchData();
                }
              }}
              onFocus={fetchData}
            />
          </Flex>
          {edit && (
            <LeadsWatchAsyncSelect
              screen={
                getValues("status") && getValues("status").value
                  ? "edit"
                  : "create"
              }
              refresh={refresh}
              // height={ah(38)}
              minWidth={"100%"}
              defaultOptions={[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ]}
              control={control}
              placeholder="Status"
              name="status"
            />
          )}
          <Flex gap={ah(5)} width="100%">
            <Text fontFamily="RobotoMedium" fontSize={aw(14)} color="#212121">
              Click to Upload Your Image{" "}
            </Text>
            <Flex>
              <input
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
                type="file"
                onClick={(e) => (e.target.value = null)}
                name="picture"
                accept="image/png, image/jpg,image/jpeg"
              ></input>
              <Pressable onPress={handleClick}>
                {image && image.localImage ? (
                  <Image
                    source={{
                      uri: image.localImage,
                    }}
                    alt="Image not found"
                    width={aw(70)}
                    height={aw(70)}
                    resizeMode="contain"
                  />
                ) : (
                  // <Avatar
                  //   height={aw(72)}
                  //   width={aw(72)}
                  //   source={{
                  //     uri: image.localImage,
                  //   }}
                  // />
                  <LeadsWatchUploadFileIcon />
                )}
              </Pressable>
            </Flex>
          </Flex>
          <Flex
            direction="row"
            width="100%"
            justifyContent={"flex-end"}
            gap={aw(12)}
          >
            <LeadsWatchButton
              label="cancel"
              style={{ width: aw(100), height: ah(40) }}
              onPress={() => {
                setIsModalOpen(false);
                reset({
                  name: "",
                  email: "",
                  smtp: "",
                  phone: "91",
                  number: "",
                  // select: "",
                });
                setPhoneNo("in");
                setRefresh(!refresh);
                setEdit("");
                setShow(false);
                setImage(null);
              }}
            />

            <LeadsWatchButton
              label={edit ? "Update" : "Add"}
              style={{ width: aw(100), height: ah(40) }}
              isLoading={isSave}
              onPress={handleSubmit(handleCreateSignature)}
              bg="#2230F8"
              borderColor="#D1D5DB"
              color="#FFFFFF"
            />
          </Flex>
        </Flex>
      </LeadsWatchModal>

      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this Signature ?
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      {/* add smtp */}
      <LeadsWatchModal
        width={aw(450)}
        height={ah(700)}
        showModal={smtpModal}
        onClose={() => {
          setSmtpModal(false);

          reset({});
          setRefresh(!refresh);
          setShow(false);
        }}
        heading={
          <HStack justifyContent={"center"} alignItems={"center"}>
            <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
              {edit ? "Edit SMTP" : "Add SMTP"}
            </Text>

            <Tooltip
              width={aw(170)}
              label="A SMTP is used for attaching to signature"
              placement={"bottom"}
              openDelay={200}
            >
              <HStack>
                <LeadsWatchInfoIcon color="#919191" />
              </HStack>
            </Tooltip>
          </HStack>
        }
      >
        <Flex gap={ah(20)} px={aw(5)}>
          <Flex direction="row" width="100%" justifyContent={"space-between"}>
            <Flex width="100%">
              <LeadsWatchFilterInput
                screen={
                  getValues3("name") && getValues3("name").length > 0
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                control={control3}
                name="name"
                placeholder="Name"
                rules={{
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                  pattern: {
                    // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                    message:
                      "Name should start with alphabets and can not contain special characters",
                  },
                }}
                invalid={errors3.name ? true : false}
                error={errors3.name ? errors3.name.message : ""}
              />
            </Flex>
          </Flex>
          <Flex direction="row" width="100%" justifyContent={"space-between"}>
            {/* <Flex direction="row" width="100%" justifyContent={"space-between"}> */}
            <Flex width="48%">
              <LeadsWatchFilterInput
                screen={
                  getValues3("host") && getValues3("host").length > 0
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                control={control3}
                name="host"
                placeholder="Host"
                rules={{
                  required: {
                    value: true,
                    message: "Host is required",
                  },
                }}
                invalid={errors3.host ? true : false}
                error={errors3.host ? errors3.host.message : ""}
              />
            </Flex>
            <Flex width="48%">
              <LeadsWatchFilterInput
                screen={
                  getValues3("port") && getValues3("port").length > 0
                    ? "edit"
                    : "create"
                }
                maxLength={5}
                refresh={refresh}
                control={control3}
                name="port"
                placeholder="Port"
                rules={{
                  required: {
                    value: true,
                    message: "port is required",
                  },
                  //   pattern: {
                  //     // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  //     value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                  //     message:
                  //       "Name should start with alphabets and can not contain special characters",
                  //   },
                }}
                invalid={errors3.port ? true : false}
                error={errors3.port ? errors3.port.message : ""}
              />
            </Flex>
          </Flex>
          <Flex direction="row" width="100%" justifyContent={"space-between"}>
            <Flex width="48%">
              <LeadsWatchFilterInput
                screen={
                  getValues("userName") && getValues("userName").length > 0
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                control={control3}
                name="userName"
                placeholder="UserName"
                rules={{
                  required: {
                    value: true,
                    message: "UserName is required",
                  },
                  //   pattern: {
                  //     // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  //     value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                  //     message:
                  //       "UserName should start with alphabets and can not contain special characters",
                  //   },
                }}
                invalid={errors3.userName ? true : false}
                error={errors3.userName ? errors3.userName.message : ""}
              />
            </Flex>
            <Flex width="48%">
              <LeadsWatchFilterInput
                screen={
                  getValues3("password") && getValues3("password").length > 0
                    ? "edit"
                    : "create"
                }
                type={show ? "text" : "password"}
                refresh={refresh}
                control={control3}
                name="password"
                placeholder="Password"
                rightIcon={
                  show ? (
                    <Pressable onPress={() => setShow(!show)}>
                      <LeadsWatchEyeOffIcon />
                    </Pressable>
                  ) : (
                    <Pressable onPress={() => setShow(!show)}>
                      <LeadsWatchEyeIcon />
                    </Pressable>
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                }}
                invalid={errors3.password ? true : false}
                error={errors3.password ? errors3.password.message : ""}
              />
            </Flex>
            {/* </Flex> */}
          </Flex>

          {/* {edit && (
            <LeadsWatchAsyncSelect
              screen={
                getValues("status") && getValues("status").value
                  ? "edit"
                  : "create"
              }
              refresh={refresh}
              // height={ah(38)}
              minWidth={"100%"}
              defaultOptions={[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ]}
              control={control}
              placeholder="Status"
              name="status"
            />
          )} */}

          <Flex
            direction="row"
            width="100%"
            justifyContent={"flex-end"}
            gap={aw(12)}
          >
            <LeadsWatchButton
              label="cancel"
              style={{ width: aw(100), height: ah(40) }}
              onPress={() => {
                setSmtpModal(false);
                reset3({});
                setRefresh(!refresh);
                setShow(false);
              }}
            />

            <LeadsWatchButton
              label={edit ? "Update" : "Add"}
              style={{ width: aw(100), height: ah(40) }}
              isLoading={isSave}
              onPress={handleSubmit3(handleCreateSmtp)}
              bg="#2230F8"
              borderColor="#D1D5DB"
              color="#FFFFFF"
            />
          </Flex>
        </Flex>
      </LeadsWatchModal>
    </>
  );
};
export default Signatures;
