import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllSignatures = (page, limit, sort, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/signatures`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          page: page,
          limit: limit,
          sort: sort,
          filters: filters,
          search: search,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allsignatures = resData.data;
        dispatch({ type: events.GET_ALL_CRMSIGNATURES, data: allsignatures });
        return allsignatures ? allsignatures : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//create signatures
export const createSignature = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/signatures/create`, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const newSignatures = resData.data;
        dispatch({ type: events.CREATE_CRMSIGNATURES, data: newSignatures });
        return newSignatures ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//edit signatures
export const editSignature = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/signatures/${id}`, {
        method: "PATCH",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // body: JSON.stringify(data),
        body: data,
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const signature = resData.data;
        dispatch({ type: events.EDIT_CRMSIGNATURES, data: signature });
        return signature ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//delete signatures
export const deleteSignature = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/signatures/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const signature = resData.data;
        dispatch({ type: events.DELETE_CRMSIGNATURES, data: signature });
        return signature ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//add smtp
export const createSmtp = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/smtp/create`, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const newSmtp = resData.data;
        dispatch({ type: events.ADD_SMTP, data: newSmtp });
        return newSmtp ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
//update smtp
export const updateSmtp = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/smtp/${id}`, {
        method: "PATCH",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const smtp = resData.data;
        dispatch({ type: events.UPDATE_SMTP, data: smtp });
        return smtp ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
//delete smtp
export const deleteSmtp = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/smtp/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const smtp = resData.data;
        dispatch({ type: events.DELETE_SMTP, data: smtp });
        return smtp ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getAllSmtps = (page, limit, search, sort, filters) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/smtp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          page: page,
          limit: limit,
          search: search,
          sort: sort,
          filters: filters,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allsmtps = resData.data;
        dispatch({ type: events.GET_ALL_SMTP, data: allsmtps });
        return allsmtps ? allsmtps : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
