import { Image, Flex } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import { Animated, Easing } from "react-native";
//utils
import { aw, ah } from "../../utils";
import { set } from "react-hook-form";

const Splash = () => {
  const opacityValue = useRef(new Animated.Value(0.2)).current;
  const scaleValue = useRef(new Animated.Value(0)).current;
  const animatedValue = useRef(new Animated.Value(0)).current;
  const [show, setShow] = useState(false);
  useEffect(() => {
    const fadeInAnimation = Animated.timing(opacityValue, {
      toValue: 1,
      duration: 1500, // Adjust the duration as needed
      useNativeDriver: false,
    });

    const scaleAnimation = Animated.timing(scaleValue, {
      toValue: 1,
      duration: 1500, // Adjust the duration as needed
      easing: Easing.elastic(1.5), // Adjust the easing as needed
      useNativeDriver: false,
    });

    const animateGradient = Animated.loop(
      Animated.timing(animatedValue, {
        toValue: 1,
        duration: 1500, // Adjust the duration as needed
        easing: Easing.linear,
        useNativeDriver: false,
      })
    );
    // const animateGradient = Animated.loop(
    //   Animated.timing(animatedValue, {
    //     toValue: 1,
    //     duration: 1500, // Adjust the duration as needed
    //     easing: Easing.linear,
    //     useNativeDriver: false, // Add this line
    //   })
    // );
    fadeInAnimation.start(() => {
      scaleAnimation.start(() => {
        animateGradient.start(() => {
          // This callback is executed when the entire sequence completes
          // Change the state or perform any action
          //   setYourState(/* new state value */);
          setShow(true);
          console.log("animateGradient", animateGradient);
        });
      });
    });

    return () => {
      fadeInAnimation.stop();
      scaleAnimation.stop();
      animateGradient.stop();
    };

    // const sequenceAnimation = Animated.sequence([
    //   fadeInAnimation,
    //   scaleAnimation,
    //   animateGradient,
    // ]);

    // sequenceAnimation.start(() => setShow(true));

    // console.log("sequenceAnimation", sequenceAnimation);
    // console.log(fadeInAnimation);
    // sequenceAnimation.addListener(({ value }) => {
    //   if (value === 1) {
    //     // Both animations have completed
    //     setShow(true);
    //     animateGradient.start();
    //   }
    // });

    // return () => {
    //   sequenceAnimation.stop();
    // };
  }, [opacityValue, scaleValue, animatedValue]);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 3300);
  });

  const translateX = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 120],
  });
  //   console.log(show);

  return (
    <>
      <Animated.Image
        source={"/leadsWatchLogo.ico"}
        style={{
          opacity: opacityValue,
          width: aw(100),
          height: aw(100),
          position: "absolute",
        }}
        resizeMode="cover"
      />
      <Animated.View
        style={{
          width: 450, // Adjust the starting width as needed
          height: 450, // Adjust the starting height as needed
          borderRadius: "50%", // Adjust the starting border radius as needed
          backgroundColor: "#E5EAFC",
          transform: [{ scale: scaleValue }],
          alignItems: "center",
          justifyContent: "center",
          zIndex: -1,
        }}
      ></Animated.View>
      {show && (
        <Flex
          style={{
            width: 150,
            backgroundColor: "#FFFFFF",
            height: 3.2,
            position: "absolute",
            bottom: 220,
          }}
        >
          <Animated.View
            style={{
              //   backgroundColor: "transparent",
              width: 38,
              height: 3.2,
              transform: [{ translateX }],
              backgroundColor: "#4A3AFF",
            }}
          />
        </Flex>
      )}
    </>
  );
};

export default Splash;
