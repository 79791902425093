import * as events from "../Events";
const initialState = {
  invoices: null,
  startDate: null,
};
export const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_INVOICES:
      return {
        ...state,
        invoices: action.data,
      };
    case events.GENERATE_INVOICES:
      return {
        ...state,
      };
    case events.GET_START_DATE:
      return {
        ...state,
        startDate: action.data,
      };
    case events.GENERATE_AUTOMATE_INVOICES:
      return {
        ...state,
      };
    case events.EDIT_AUTO_INVOICES:
      return {
        ...state,
      };
    case events.EDIT_STATUS:
      return {
        ...state,
      };
    default:
      return state;
  }
};
