import React from "react";
import { aw, ah } from "../../utils";

function LeadsWatchUploadFileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(60)}
      height={ah(46.739)}
      viewBox="0 0 60 46.739"
    >
      <g fill="#00f" transform="translate(0 -4)">
        <path
          d="M48.75 38.389v3.746A11.048 11.048 0 0060 31.239a10.9 10.9 0 00-10.909-10.9 10.916 10.916 0 00-15.633-9.819A13.638 13.638 0 008.511 20.607a10.9 10.9 0 002.4 21.528H23.75v-3.746H10.909a7.151 7.151 0 01-1.577-14.127l3.657-.819-.819-3.653a9.889 9.889 0 0118.089-7.314l1.77 2.888 3.055-1.468a7.165 7.165 0 0110.257 6.448v3.745h3.75a7.155 7.155 0 017.159 7.15 7.269 7.269 0 01-7.5 7.15z"
          data-name="Path 10349"
        ></path>
        <path
          d="M19.917 11.475a1.257 1.257 0 011.969 0l9.371 11.8a1.257 1.257 0 01-.984 2.038h-4.344a1.257 1.257 0 00-1.257 1.257v12.574a1.257 1.257 0 01-1.257 1.256h-5.027a1.257 1.257 0 01-1.257-1.257V26.575a1.257 1.257 0 00-1.257-1.257h-4.343a1.257 1.257 0 01-.984-2.038z"
          data-name="Path 10350"
          transform="translate(15.33 10.339)"
        ></path>
      </g>
    </svg>
  );
}

export default LeadsWatchUploadFileIcon;
