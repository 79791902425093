import { React, useEffect, useState, useRef, useMemo } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Pressable,
  Hidden,
  useBreakpointValue,
  Flex,
  Image,
  Tooltip,
} from "native-base";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { aw, ah } from "../../../utils";
//components
import LeadsWatchTable from "../../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../../components/LeadsWatchButton";
import LeadsWatchFilterInput from "../../../components/LeadsWatchFilterInput";
import LeadsWatchModal from "../../../components/LeadsWatchModal";
import LeadsWatchSearch from "../../../components/LeadsWatchSearch";
import LeadsWatchAsyncSelect from "../../../components/LeadsWatchAsyncSelect";
//icons
import LeadsWatchAddIcon from "../../../components/svg/LeadsWatchAddIcon";
import LeadsWatchEyeIcon from "../../../components/svg/LeadsWatchEyeIcon";
import LeadsWatchEyeOffIcon from "../../../components/svg/LeadsWatchEyeOffIcon";
import LeadsWatchSearchIcon from "../../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchCrossIcon from "../../../components/svg/LeadsWatchCrossIcon";

import {
  createSmtp,
  getAllSmtps,
  updateSmtp,
  deleteSmtp,
} from "../../../store/actions/crmsignatures";
import LeadsWatchInfoIcon from "../../../components/svg/LeadsWatchInfoIcon";

const Smtps = ({ isOpen }) => {
  const smtps = useSelector((state) => state.crmsignatures.smtps);
  const navigate = useNavigate();
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const profile = useSelector((state) => state.auth.profile);
  const [refresh, setRefresh] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [sort, setSort] = useState({});
  const [currentPage, setCurrentPage] = useState(
    page && page.crmsignature ? parseInt(page.crmsignature) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.crmsignature ? parseInt(pagecount.crmsignature) : 10
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [edit, setEdit] = useState("");
  const [filters, setFilters] = useState({});
  const [show, setShow] = useState(false);
  const [smtpModal, setSmtpModal] = useState(false);
  const dispatch = useDispatch();

  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const { control } = useForm({
    mode: "all",
  });
  const { control: control1 } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.crmsignature
          ? pagecount.crmsignature.toString()
          : "10",
    },
  });
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    getValues: getValues2,
  } = useForm({
    mode: "all",
  });
  const {
    control: control3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    reset: reset3,
    getValues: getValues3,
  } = useForm({
    mode: "all",
  });
  const [search, setSearch] = useState(getValues2("search"));
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, crmsignature: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, crmsignature: 1 }));
  };
  const fetchSmtps = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllSmtps(currentPage, selectCount, search, sort, filters)
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["crm"] &&
        profile.permission_ids["crm"].includes("view")
      ) {
        fetchSmtps();
      } else {
        navigate("/settings/profile");
      }
    } else {
      fetchSmtps();
    }
  }, [currentPage, selectCount, sort, filters, search]);

  const handleSort = (order) => {
    setSort(order);
  };

  const handleDelete = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        await dispatch(deleteSmtp(id));
        toast.success("SMTP Deleted Successfully");
        setConfirmModal(false);
        setDeleteId("");
        fetchSmtps();
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }

      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      data.search !== "" && setClearSearch(true);
      localStorage.setItem(
        "page",
        JSON.stringify({ ...page, crmsignature: 1 })
      );
    }
  };
  const handleEdit = (item) => {
    setEdit({
      name: item.name,
      host: item.host,
      userName: item.username,
      port: item.port,
      password: item.password,
      status: {
        label:
          item.status &&
          item.status
            .toLowerCase()
            .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
        value: item.status,
      },
      id: item._id,
    });

    setSmtpModal(true);
  };
  useEffect(() => {
    if (edit) {
      reset3(edit);
    } else {
      reset3({
        port: "",
        userName: "",
        host: "",
        name: "",
        status: "",
      });
      // clearErrors();
    }
  }, [edit]);
  const memoizedChildComponent = useMemo(() => {
    return (
      <LeadsWatchTable
        isOpen={isOpen}
        sort={sort}
        screen="smtps"
        handleDelete={handleDelete}
        handleSort={handleSort}
        handleEdit={handleEdit}
        isLoading={isLoading}
        heading={["name", "username", "port", "host", "status", "Actions"]}
        sortIcon={true}
        data={smtps && smtps.list ? smtps.list : null}
      />
    );
  }, [isLoading, isOpen]);

  //create smtp
  const handleCreateSmtp = async (data) => {
    setIsSave(true);
    if (edit) {
      try {
        await dispatch(
          updateSmtp(edit.id, {
            name: data.name,
            host: data.host,
            port: data.port,
            username: data.userName,
            password: data.password,
          })
        );
        fetchSmtps();
        toast.success("SMTP Updated Successfully");
        setSmtpModal(false);
        setEdit("");
        reset3({});
        setRefresh(!refresh);
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    } else {
      try {
        await dispatch(
          createSmtp({
            name: data.name,
            host: data.host,
            port: data.port,
            username: data.userName,
            password: data.password,
          })
        );
        fetchSmtps();
        toast.success("SMTP Created Successfully");
        setSmtpModal(false);
        reset3({});
        setRefresh(!refresh);
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
    setIsSave(false);
  };

  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <VStack
            marginTop={ah(7)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              lineHeight={ah(34)}
              justifyContent={"space-between"}
              space={aw(8)}
              alignItems={"flex-end"}
            >
              <HStack
                alignItems={"baseline"}
                justifyContent="space-between"
                space={aw(8)}
              >
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily="RobotoMedium"
                >
                  SMTPS
                </Text>
                <Text
                  top={ah(20)}
                  fontSize={aw(18)}
                  color={"#919191"}
                  fontFamily="RobotoMedium"
                >
                  {smtps && smtps.total_count ? smtps.total_count : 0}
                </Text>
              </HStack>
              <Hidden only={["sm", "base"]}></Hidden>
            </HStack>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All your SMTPS list will be shown here
            </Text>

            <Divider
              mt={ah(9)}
              bg="#E6E7E9"
              height={ah(1)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            />
          </VStack>
          <VStack height={ah(35)} mt={ah(15)}>
            <HStack justifyContent={"space-between"}>
              <HStack space={aw(12)}>
                <HStack height={ah(38)} alignItems="center">
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    Show Entries :{" "}
                  </Text>
                  <Center>
                    <LeadsWatchSelect
                      control={control1}
                      name={"select"}
                      list={["10", "20", "50", "100"]}
                      handleChange={(value) =>
                        handleChangeSelect(parseInt(value))
                      }
                      width={79}
                      height={ah(38)}
                    />
                  </Center>
                </HStack>
              </HStack>
              <Hidden only={["sm", "base"]}>
                <HStack space={aw(12)}>
                  <LeadsWatchAsyncSelect
                    isClearable={true}
                    height={ah(38)}
                    required={true}
                    minWidth={aw(100)}
                    defaultOptions={[
                      { label: "Active", value: "active" },
                      { label: "Inactive", value: "inactive" },
                    ]}
                    control={control}
                    placeholder="Status"
                    name="status1"
                    handleChange={(value) => {
                      if (value) {
                        setFilters({ ...filters, status: value.value });
                      } else {
                        delete filters.status;
                        setFilters({ ...filters });
                      }
                    }}
                  />

                  <LeadsWatchSearch
                    placeholder={[
                      'Search by "Name"',
                      'Search by "Username"',
                      'Search by "Port"',
                      'Search by "Host"',
                      'Search by "Status"',
                    ]}
                    height={ah(38)}
                    control={control2}
                    name="search"
                    onFocus={() => setClearSearch(false)}
                    inputRightElement={
                      clearSearch ? (
                        <Pressable
                          w={aw(20)}
                          onPress={() => {
                            reset2();
                            setSearch(getValues2("search"));
                            setClearSearch(false);
                            setCurrentPage(1);
                            localStorage.setItem(
                              "page",
                              JSON.stringify({ ...page, crmsignature: 1 })
                            );
                          }}
                        >
                          <LeadsWatchCrossIcon />
                        </Pressable>
                      ) : (
                        <Pressable
                          w={aw(30)}
                          onPress={handleSubmit2(handleSearch)}
                        >
                          <LeadsWatchSearchIcon />
                        </Pressable>
                      )
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit2(
                          handleSearch({ search: getValues2("search") })
                        );
                      }
                    }}
                  />

                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "crm"
                        ? true
                        : profile && profile.role === "user"
                        ? profile.permission_ids["crm"].includes("create")
                          ? false
                          : true
                        : false
                    }
                    onPress={() => {
                      setSmtpModal(true);
                    }}
                    label="Create SMTP"
                    style={{ width: aw(130), height: ah(38) }}
                    bg="#2230F8"
                    borderColor="#2230F8"
                    color="#FFFFFF"
                    leftIcon={<LeadsWatchAddIcon />}
                  />
                </HStack>
              </Hidden>
            </HStack>
          </VStack>

          <VStack mt={ah(15)}>
            <VStack>{memoizedChildComponent}</VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={smtps && smtps.total_count ? smtps.total_count : 1}
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, crmsignature: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>

      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this SMTP ?
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      {/* add smtp */}
      <LeadsWatchModal
        width={aw(450)}
        height={ah(700)}
        showModal={smtpModal}
        onClose={() => {
          setSmtpModal(false);
          reset3({});
          setRefresh(!refresh);
          setShow(false);
        }}
        heading={
          <HStack justifyContent={"center"} alignItems={"center"}>
            <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
              {edit ? "Edit SMTP" : "Add SMTP"}
            </Text>

            <Tooltip
              width={aw(170)}
              label="A SMTP is used for attaching to signature"
              placement={"bottom"}
              openDelay={200}
            >
              <HStack>
                <LeadsWatchInfoIcon color="#919191" />
              </HStack>
            </Tooltip>
          </HStack>
        }
      >
        <Flex gap={ah(20)} px={aw(5)}>
          <Flex direction="row" width="100%" justifyContent={"space-between"}>
            <Flex width="100%">
              <LeadsWatchFilterInput
                screen={
                  getValues3("name") && getValues3("name").length > 0
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                control={control3}
                name="name"
                placeholder="Name"
                rules={{
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                  pattern: {
                    value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                    message:
                      "Name should start with alphabets and can not contain special characters",
                  },
                }}
                invalid={errors3.name ? true : false}
                error={errors3.name ? errors3.name.message : ""}
              />
            </Flex>
          </Flex>
          <Flex direction="row" width="100%" justifyContent={"space-between"}>
            {/* <Flex direction="row" width="100%" justifyContent={"space-between"}> */}
            <Flex width="48%">
              <LeadsWatchFilterInput
                screen={
                  getValues3("host") && getValues3("host").length > 0
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                control={control3}
                name="host"
                placeholder="Host"
                rules={{
                  required: {
                    value: true,
                    message: "Host is required",
                  },
                }}
                invalid={errors3.host ? true : false}
                error={errors3.host ? errors3.host.message : ""}
              />
            </Flex>
            <Flex width="48%">
              <LeadsWatchFilterInput
                screen={
                  getValues3("port") && getValues3("port").length > 0
                    ? "edit"
                    : "create"
                }
                maxLength={5}
                refresh={refresh}
                control={control3}
                name="port"
                placeholder="Port"
                rules={{
                  required: {
                    value: true,
                    message: "port is required",
                  },
                }}
                invalid={errors3.port ? true : false}
                error={errors3.port ? errors3.port.message : ""}
              />
            </Flex>
          </Flex>
          <Flex direction="row" width="100%" justifyContent={"space-between"}>
            <Flex width="48%">
              <LeadsWatchFilterInput
                screen={
                  getValues3("userName") && getValues3("userName").length > 0
                    ? "edit"
                    : "create"
                }
                refresh={refresh}
                control={control3}
                name="userName"
                placeholder="UserName"
                rules={{
                  required: {
                    value: true,
                    message: "UserName is required",
                  },
                }}
                invalid={errors3.userName ? true : false}
                error={errors3.userName ? errors3.userName.message : ""}
              />
            </Flex>
            <Flex width="48%">
              <LeadsWatchFilterInput
                screen={
                  getValues3("password") && getValues3("password").length > 0
                    ? "edit"
                    : "create"
                }
                type={show ? "text" : "password"}
                refresh={refresh}
                control={control3}
                name="password"
                placeholder="Password"
                rightIcon={
                  show ? (
                    <Pressable onPress={() => setShow(!show)}>
                      <LeadsWatchEyeOffIcon />
                    </Pressable>
                  ) : (
                    <Pressable onPress={() => setShow(!show)}>
                      <LeadsWatchEyeIcon />
                    </Pressable>
                  )
                }
                rules={{
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                }}
                invalid={errors3.password ? true : false}
                error={errors3.password ? errors3.password.message : ""}
              />
            </Flex>
            {/* </Flex> */}
          </Flex>
          {edit && (
            <LeadsWatchAsyncSelect
              screen={
                getValues3("status") && getValues3("status").value
                  ? "edit"
                  : "create"
              }
              // height={ah(38)}
              minWidth={aw(100)}
              defaultOptions={[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ]}
              control={control3}
              placeholder="Status"
              name="status"
              rules={{
                required: {
                  value: true,
                  message: "status is required",
                },
              }}
              invalid={errors3.status ? true : false}
              error={errors3.status ? errors3.status.message : ""}
            />
          )}

          <Flex
            direction="row"
            width="100%"
            justifyContent={"flex-end"}
            gap={aw(12)}
          >
            <LeadsWatchButton
              label="cancel"
              style={{ width: aw(100), height: ah(40) }}
              onPress={() => {
                setSmtpModal(false);
                reset3({});
                setRefresh(!refresh);
                setShow(false);
              }}
            />

            <LeadsWatchButton
              label={edit ? "Update" : "Add"}
              style={{ width: aw(100), height: ah(40) }}
              isLoading={isSave}
              onPress={handleSubmit3(handleCreateSmtp)}
              bg="#2230F8"
              borderColor="#D1D5DB"
              color="#FFFFFF"
            />
          </Flex>
        </Flex>
      </LeadsWatchModal>
    </>
  );
};
export default Smtps;
