import * as events from "../Events";
const initialState = {
  allContacts: null,
  singleContact: null,
  emails: null,
};
export const crmContacts = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_CRMCONTACTS:
      return {
        ...state,
        allContacts: action.data,
      };
    case events.GET_SINGLE_CONTACT:
      return {
        ...state,
        singleContact: action.data,
      };
    case events.GET_CONTACT_EMAILS:
      return {
        ...state,
        emails: action.data,
      };
    case events.REMOVE_EMAILS: {
      return {
        ...state,
        emails: null,
      };
    }
    case events.REMOVE_CONTACT_DETAILS:
      return {
        ...state,
        singleContact: null,
      };
    default:
      return state;
  }
};
