import React, { useEffect, useState, useRef } from "react";
import {
  Text,
  HStack,
  VStack,
  Divider,
  Pressable,
  useBreakpointValue,
  Flex,
  ScrollView,
  Avatar,
  Box,
  FlatList,
  Spinner,
} from "native-base";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useForm } from "react-hook-form";
//utils
import { aw, ah } from "../../../utils";
//redux
import {
  getEmails,
  getSingleContact,
  removeEmails,
} from "../../../store/actions/crmcontacts";
//components
import RenderHTML from "../../../components/LeadsWatchRenderHTML";
import LeadsWatchPagination from "../../../components/LeadsWatchPagination";
//svg
import LeadsWatchPrevIcon from "../../../components/svg/LeadsWatchPrevIcon";
import LeadsWatchNextIcon from "../../../components/svg/LeadsWatchNextIcon";
import LeadsWatchSelect from "../../../components/LeadsWatchSelect";

const ContactDetails = ({ isOpen }) => {
  const { control } = useForm({ mode: "all", defaultValues: { select: "10" } });
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(10);
  const [loading, setLoading] = useState(false);
  const scrollRef = useRef(null);
  const [show, setShow] = useState(false);
  const truncatedText = (text) => {
    return text.length > 13 ? text.slice(0, 10) + ".." : text;
  };
  const dispatch = useDispatch();
  const { id } = useParams();
  //state for focus
  const [type, setType] = useState("Pipeline");
  const [details, setDetails] = useState(null);
  //states from redux
  const contactDetails = useSelector(
    (state) => state.crmcontacts.singleContact
  );
  const emails = useSelector((state) => state.crmcontacts.emails);
  const handleChangeSelect = (value) => {
    setCount(parseInt(value));
    setCurrentPage(1);
  };
  useEffect(() => {
    const fetchSingleContact = async () => {
      try {
        await dispatch(getSingleContact(id));
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    };

    fetchSingleContact();
  }, [id]);

  useEffect(() => {
    const fetchSingleContact = async () => {
      setLoading(true);
      try {
        await dispatch(
          getEmails(
            id,
            type === "Email" ? "emails" : type.toLowerCase(),
            currentPage,
            10
          )
        );
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
      setLoading(false);
    };
    if (type !== "Pipeline") {
      fetchSingleContact();
    }
  }, [id, type, currentPage, count]);
  const scrollLeft = () => {
    scrollRef.current.scrollTo({
      x: scrollRef.current.contentOffset?.x - 100,
      animated: true,
    });
  };
  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollToEnd({ animated: true });
    }
  };
  useEffect(() => {
    scrollRef.current.offsetWidth > 690 && setShow(true);
  }, [contactDetails]);
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  return (
    <HStack
      height={ah(698)}
      width={
        isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
      }
      left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
    >
      <VStack
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
      >
        <VStack
          marginTop={ah(7)}
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <Flex style={{ flexDirection: "row", flexWrap: "wrap" }}>
            <Flex
              style={{
                width: "30%",
                borderRightWidth: aw(1),
                gap: ah(25),
                borderColor: "#0000004D",
                height: "85vh",
              }}
            >
              <HStack alignItems="center" space={aw(10)} mt={ah(10)}>
                <Avatar size={aw(60)}></Avatar>
                <VStack>
                  <Text fontSize={aw(16)} fontFamily="RobotoRegular">
                    {" "}
                    {contactDetails && contactDetails.Email}
                  </Text>
                  <Text fontSize={aw(16)} fontFamily="RobotoRegular">
                    {contactDetails && contactDetails.Phone}
                  </Text>
                </VStack>
              </HStack>
              <Flex>
                <HStack>
                  <Text
                    textAlign={"center"}
                    width={"50%"}
                    fontFamily={"RobotoMedium"}
                    fontSize={aw(14)}
                    borderBottomWidth={type === "Pipeline" && aw(3)}
                    borderColor={"#0000FF"}
                    onPress={() => {
                      setType("Pipeline");
                      setDetails(null);
                      dispatch(removeEmails());
                    }}
                    color={type === "Pipeline" && "#0000FF"}
                  >
                    Pipeline
                  </Text>
                  <Text
                    height={ah(45)}
                    textAlign={"center"}
                    width={"50%"}
                    fontFamily={"RobotoMedium"}
                    fontSize={aw(14)}
                    borderBottomWidth={type === "Email" && aw(3)}
                    borderColor={"#0000FF"}
                    onPress={() => {
                      setType("Email");
                      setDetails(null);
                      dispatch(removeEmails());
                    }}
                    color={type === "Email" && "#0000FF"}
                  >
                    Email
                  </Text>
                  <Text
                    height={ah(45)}
                    textAlign={"center"}
                    width={"50%"}
                    fontFamily={"RobotoMedium"}
                    fontSize={aw(14)}
                    borderBottomWidth={type === "Inbox" && aw(3)}
                    borderColor={"#0000FF"}
                    onPress={() => {
                      setType("Inbox");
                      setDetails(null);
                      dispatch(removeEmails());
                    }}
                    color={type === "Inbox" && "#0000FF"}
                  >
                    Inbox
                  </Text>
                  <Text
                    textAlign={"center"}
                    width={"50%"}
                    fontFamily={"RobotoMedium"}
                    fontSize={aw(14)}
                    borderBottomWidth={type === "SMS" && aw(3)}
                    borderColor={"#0000FF"}
                    onPress={() => {
                      setType("SMS");
                      setDetails(null);
                      dispatch(removeEmails());
                    }}
                    color={type === "SMS" && "#0000FF"}
                  >
                    SMS
                  </Text>
                </HStack>
                <Divider />
                {!loading ? (
                  <FlatList
                    maxHeight={ah(480)}
                    width="100%"
                    borderColor="#D7D7D7"
                    borderWidth={aw(1)}
                    borderTopWidth={0}
                    borderRightWidth={0}
                    ListEmptyComponent={
                      <HStack
                        justifyContent="center"
                        alignItems={"center"}
                        height={ah(470)}
                      >
                        <Text
                          color="black"
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                        >
                          No data found
                        </Text>
                      </HStack>
                    }
                    data={
                      type !== "Pipeline"
                        ? emails && emails.list
                        : contactDetails && contactDetails.pipeline_activity
                    }
                    keyExtractor={(item) => item._id}
                    renderItem={({ item, index }) =>
                      type === "Email" || type === "SMS" ? (
                        <Pressable onPress={() => setDetails(item)}>
                          <VStack
                            bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                            width={"100%"}
                            minHeight={ah(100)}
                            style={{
                              borderBottomWidth: aw(1),

                              // borderBottomWidth: aw(1),
                              borderColor: "#D7D7D7",
                            }}
                            //   justifyContent={"space-between"}
                            px={aw(10)}
                            py={ah(10)}
                            //   alignItems={"center"}
                            borderLeftWidth={
                              item._id === details?._id ? aw(5) : 0
                            }
                            borderLeftColor={"#0000FF"}
                          >
                            <HStack>
                              <Text
                                style={{
                                  fontFamily: "RobotoMedium",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: aw(80),
                                }}
                              >
                                From
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  // width: "70%",
                                }}
                              >
                                : {"  "}
                                {item.details.from}
                              </Text>
                            </HStack>
                            <HStack>
                              <Text
                                style={{
                                  fontFamily: "RobotoMedium",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: aw(80),
                                }}
                              >
                                Campaign
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  // width: "70%",
                                }}
                              >
                                :{"  "} {item.action}
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: "30%",
                                }}
                              >
                                {/* {item[getValues("cam_criteria")]} */}
                              </Text>
                            </HStack>
                            <HStack>
                              <Text
                                style={{
                                  fontFamily: "RobotoMedium",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: aw(80),
                                }}
                              >
                                Sent At
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  // width: "70%",
                                }}
                              >
                                : {"  "}
                                {moment(item.created).format(
                                  "YYYY-MM-DD hh:mm"
                                )}
                              </Text>
                            </HStack>
                          </VStack>
                        </Pressable>
                      ) : type === "Inbox" ? (
                        <Pressable onPress={() => setDetails(item)}>
                          <VStack
                            bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                            width={"100%"}
                            minHeight={ah(100)}
                            style={{
                              borderBottomWidth: aw(1),

                              // borderBottomWidth: aw(1),
                              borderColor: "#D7D7D7",
                            }}
                            //   justifyContent={"space-between"}
                            px={aw(10)}
                            py={ah(10)}
                            //   alignItems={"center"}
                            borderLeftWidth={
                              item._id === details?._id ? aw(5) : 0
                            }
                            borderLeftColor={"#0000FF"}
                          >
                            <HStack>
                              <Text
                                style={{
                                  fontFamily: "RobotoMedium",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: aw(80),
                                }}
                              >
                                From
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  // width: "70%",
                                }}
                              >
                                : {"  "}
                                {item.details.from_address}
                              </Text>
                            </HStack>
                            <HStack>
                              <Text
                                style={{
                                  fontFamily: "RobotoMedium",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: aw(80),
                                }}
                              >
                                Subject
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  // width: "70%",
                                }}
                              >
                                :{"  "} {item.details?.subject}
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: "30%",
                                }}
                              >
                                {/* {item[getValues("cam_criteria")]} */}
                              </Text>
                            </HStack>
                            <HStack>
                              <Text
                                style={{
                                  fontFamily: "RobotoMedium",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: aw(80),
                                }}
                              >
                                Sent At
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  // width: "70%",
                                }}
                              >
                                : {"  "}
                                {moment(item.created).format(
                                  "YYYY-MM-DD hh:mm"
                                )}
                              </Text>
                            </HStack>
                          </VStack>
                        </Pressable>
                      ) : (
                        <VStack
                          bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                          width={"100%"}
                          minHeight={ah(100)}
                          style={{
                            borderBottomWidth:
                              contactDetails.pipeline_activity - 1
                                ? aw(0)
                                : aw(1),

                            borderColor: "#D7D7D7",
                          }}
                          px={aw(10)}
                          py={ah(10)}
                          borderLeftWidth={
                            item.pipeline_id === details?.pipeline_id
                              ? aw(5)
                              : 0
                          }
                          borderLeftColor={"#0000FF"}
                        >
                          <HStack>
                            <Text
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(14),
                                color: "#121233",
                                width: aw(110),
                              }}
                            >
                              Pipeline Name
                            </Text>
                            <Text
                              style={{
                                fontFamily: "RobotoRegular",
                                fontSize: aw(14),
                                color: "#121233",
                                // width: "70%",
                              }}
                            >
                              : {"  "}
                              {item.pipeline_name}
                            </Text>
                          </HStack>
                          <HStack>
                            <Text
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(14),
                                color: "#121233",
                                width: aw(110),
                              }}
                            >
                              {item.createdby && "Created By"}
                            </Text>
                            <Text
                              style={{
                                fontFamily: "RobotoRegular",
                                fontSize: aw(14),
                                color: "#121233",
                              }}
                            >
                              : {"  "}
                              {item?.createdby?.firstname || "_"}{" "}
                              {item?.createdby?.lastname}
                            </Text>
                          </HStack>
                          <HStack>
                            <Text
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(14),
                                color: "#121233",
                                width: aw(110),
                              }}
                            >
                              Created
                            </Text>
                            <Text
                              style={{
                                fontFamily: "RobotoRegular",
                                fontSize: aw(14),
                                color: "#121233",
                              }}
                            >
                              : {"  "}
                              {item.created &&
                                moment(item.created).format("YYYY-MM-DD hh:mm")}
                            </Text>
                          </HStack>
                          {item.deleted && (
                            <HStack>
                              <Text
                                style={{
                                  fontFamily: "RobotoMedium",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: aw(110),
                                }}
                              >
                                {item.deletedby && "Deleted By"}
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                }}
                              >
                                : {"  "}
                                {item?.deletedby?.firstname || "_"}{" "}
                                {item?.deletedby?.lastname}
                              </Text>
                            </HStack>
                          )}
                          <HStack>
                            <Text
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(14),
                                color: "#121233",
                                width: aw(110),
                              }}
                            >
                              {item.deleted ? "Deleted" : "Status"}
                            </Text>
                            <Text
                              style={{
                                fontFamily: "RobotoRegular",
                                fontSize: aw(14),
                                color: "#121233",
                              }}
                            >
                              : {"  "}
                              {item.deleted
                                ? moment(item.deleted).format(
                                    "YYYY-MM-DD hh:mm"
                                  )
                                : "Running"}
                            </Text>
                          </HStack>
                        </VStack>
                        // </Pressable>
                      )
                    }
                  />
                ) : (
                  <HStack
                    justifyContent="center"
                    alignItems={"center"}
                    height={ah(480)}
                    borderWidth={aw(1)}
                    borderColor="#D7D7D7"
                  >
                    <Spinner size="lg" color="#2230F8" />
                  </HStack>
                )}
                {emails && emails.total_count > count && (
                  <HStack mt={ah(10)} justifyContent={"space-between"}>
                    <HStack bg="red.100">
                      <LeadsWatchSelect
                        control={control}
                        name="select"
                        width={79}
                        list={["10", "15", "20", "25"]}
                        // value={status}
                        // handleChange={(value) => setStatus(value)}
                        handleChange={(value) =>
                          handleChangeSelect(parseInt(value))
                        }
                        height={ah(40)}
                      />
                    </HStack>

                    <LeadsWatchPagination
                      type="box"
                      totalCount={emails ? 100 : 1}
                      currentPage={currentPage}
                      pageSize={10}
                      onPageChange={(currentPage) => {
                        setCurrentPage(currentPage);
                      }}
                    />
                  </HStack>
                )}
              </Flex>
            </Flex>
            <Flex
              style={{
                width: "70%",
                mt: aw(10),
                paddingLeft: aw(20),
              }}
            >
              {type === "Email" || type === "SMS" || type === "Inbox" ? (
                details ? (
                  <>
                    <Text
                      style={{
                        fontFamily: "RobotoMedium",
                        fontSize: aw(20),
                        height: ah(50),
                      }}
                    >
                      {(details && details.details.subject) ||
                        details.details.to}
                    </Text>
                    <ScrollView maxH={ah(620)}>
                      {type === "SMS" ? (
                        <Text fontFamily={"RobotoRegular"} fontSize={aw(15)}>
                          {details && details.details.message}
                        </Text>
                      ) : (
                        <RenderHTML userHTML={details?.details.body} />
                      )}
                    </ScrollView>
                  </>
                ) : (
                  <VStack
                    height={"80vh"}
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Text
                      style={{ fontSize: aw(17), fontFamily: "RobotoMedium" }}
                    >
                      {type === "Email" || type === "SMS" || type === "Inbox"
                        ? "No Message is Selected"
                        : "No Pipeline is Selected"}
                    </Text>
                  </VStack>
                )
              ) : (
                <VStack height={"80vh"} mt={ah(20)}>
                  <Text
                    style={{
                      fontFamily: "RobotoMedium",
                      fontSize: aw(20),
                      color: "#121233",
                    }}
                  >
                    Pipeline Stages
                  </Text>
                  <HStack
                    height={ah(100)}
                    alignSelf={"center"}
                    alignItems={"center"}
                  >
                    {show && (
                      <Pressable
                        onPress={scrollLeft}
                        alignItems={"center"}
                        // borderWidth={aw(1)}
                        borderRadius="full"
                        size={ah(24)}
                        background={"#D7D7D7"}
                      >
                        <LeadsWatchPrevIcon color="Gray 2" />
                      </Pressable>
                    )}
                    <ScrollView
                      alignContent={"center"}
                      scrollEventThrottle={16}
                      ref={scrollRef}
                      horizontal={true}
                      showsHorizontalScrollIndicator={false}
                      maxWidth={aw(750)}
                      height={"100%"}
                    >
                      <HStack
                        height={"100%"}
                        alignItems={"center"}
                        space={aw(40)}
                        // maxWidth={aw(2000)}
                        px={aw(20)}
                        // mx={aw(10)}
                      >
                        {contactDetails &&
                          contactDetails.pipeline_activity &&
                          contactDetails.pipeline_activity?.map(
                            (val, index) => (
                              <Box key={val.pipeline_name + index}>
                                {index !== 0 && (
                                  <Box
                                    style={{
                                      zIndex: 999,
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      height: ah(40),

                                      height: 0,
                                      borderLeftWidth: 20, // Width of the triangle
                                      borderRightWidth: 0,
                                      borderBottomWidth: 15, // Height of the triangle
                                      borderTopWidth: 20, // Height of the triangle
                                      borderStyle: "solid",
                                      borderLeftColor: "#FFFFFF",
                                      borderRightColor: "transparent", // Color of the triangle
                                      borderBottomColor: "transparent",
                                      borderTopColor: "transparent",
                                      // bg: "amber.300",
                                    }}
                                  />
                                )}
                                <Box
                                  left={aw(0)}
                                  borderLeftRadius={index === 0 && aw(50)}
                                  minW={aw(150)}
                                  bg={val.deleted ? "#D9F0E4" : "#008C44"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                  height={ah(40)}
                                >
                                  <Text
                                    color={val.deleted ? "#008C44" : "#FFFFFF"}
                                    fontFamily={"RobotoRegular"}
                                    fontSize={aw(14)}
                                  >
                                    {val.pipeline_name &&
                                      truncatedText(val.pipeline_name)}
                                  </Text>
                                </Box>
                                <Box
                                  style={{
                                    zIndex: 999,
                                    position: "absolute",
                                    top: 0,
                                    left: aw(150),
                                    height: ah(40),

                                    borderLeftWidth: 20, // Width of the triangle
                                    borderRightWidth: 0,
                                    borderBottomWidth: 17, // Height of the triangle
                                    borderTopWidth: 20, // Height of the triangle
                                    borderStyle: "solid",
                                    borderLeftColor: val.deleted
                                      ? "#D9F0E4"
                                      : "#008C44",
                                    borderRightColor: "transparent", // Color of the triangle
                                    borderBottomColor: "transparent",
                                    borderTopColor: "transparent",
                                    // bg: "amber.300",
                                  }}
                                />
                              </Box>
                            )
                          )}
                      </HStack>
                    </ScrollView>
                    {show && (
                      <Pressable
                        onPress={scrollRight}
                        alignItems={"center"}
                        // borderWidth={aw(1)}
                        borderRadius="full"
                        size={ah(24)}
                        background={"#D7D7D7"}
                      >
                        {/* LeadsWatchRightArrow */}
                        <LeadsWatchNextIcon color="Gray 2" />
                      </Pressable>
                    )}
                  </HStack>

                  <VStack space={ah(10)} mt={ah(10)}>
                    <Text
                      style={{
                        fontFamily: "RobotoMedium",
                        fontSize: aw(20),
                        color: "#121233",
                      }}
                    >
                      Notes
                    </Text>
                    <HStack
                      // justifyContent="space-evenly"
                      alignItems={"center"}
                      px={aw(20)}
                      bgColor="#D7D7D7"
                      style={{
                        height: ah(60),
                        background: "#F9FAFB 0% 0% no-repeat padding-box",
                        borderLeft: "1px solid #D7D7D7",
                        borderRight: "1px solid #D7D7D7",
                        borderTop: "1px solid #D7D7D7",
                        borderBottom: "1px solid #D7D7D7",
                        borderTopLeftRadius: aw(10),
                        borderTopRightRadius: aw(10),
                      }}
                    >
                      <HStack>
                        {["S.No", "Note", "Created By", "Created At"].map(
                          (val, idx) => (
                            <HStack
                              width={val === "S.No" ? aw(100) : aw(200)}
                              justifyContent="flex-start"
                              alignItems={"center"}
                              key={val + idx}
                            >
                              <Text
                                fontSize={aw(14)}
                                width={val === "S.No" ? aw(100) : aw(200)}
                                fontFamily={"RobotoMedium"}
                                color="#212121"
                              >
                                {val}
                              </Text>
                            </HStack>
                          )
                        )}
                      </HStack>
                    </HStack>
                    <ScrollView
                      maxHeight={ah(300)}
                      style={{
                        borderBottomRightRadius: aw(10),
                        borderBottomLeftRadius: aw(10),
                        borderbottomWidth: "1px solid #D7D7D7",
                        borderTopWidth: "0px",
                        borderColor: "#D7D7D7",
                        borderWidth: "1px",
                      }}
                    >
                      <FlatList
                        ListEmptyComponent={
                          <HStack
                            justifyContent="center"
                            alignItems={"center"}
                            height={ah(280)}
                          >
                            <Text
                              color="black"
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                            >
                              No data found
                            </Text>
                          </HStack>
                        }
                        data={contactDetails ? contactDetails.notes : []}
                        keyExtractor={(item) => item._id}
                        renderItem={({ item, index }) => (
                          <HStack
                            // justifyContent="space-evenly"
                            alignItems={"center"}
                            px={aw(20)}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                              minHeight: ah(58),
                              borderTopWidth: index === 0 ? 0 : "1px",
                              borderLeftWidth: "0px",
                              borderRightWidth: "0px",
                              borderBottomWidth: "0px",
                              borderColor: "#D7D7D7",
                            }}
                          >
                            {["s.no", "note", "createdname", "created"].map(
                              (val) => (
                                <HStack
                                  width={val === "s.no" ? aw(100) : aw(200)}
                                  justifyContent="flex-start"
                                  alignItems={"center"}
                                  key={val}
                                  // ml={aw(10)}
                                >
                                  <Text
                                    fontSize={aw(14)}
                                    width={val === "s.no" ? aw(100) : aw(200)}
                                    fontFamily={"RobotoRegular"}
                                    color="#212121"
                                  >
                                    {val === "s.no" || val === "created"
                                      ? val === "created"
                                        ? moment(item[val]).format(
                                            "YYYY-MM-DD hh:mm"
                                          )
                                        : index + 1
                                      : item[val]
                                      ? item[val]
                                      : " _"}
                                  </Text>
                                </HStack>
                              )
                            )}
                          </HStack>
                        )}
                      />
                    </ScrollView>
                  </VStack>
                </VStack>
              )}
            </Flex>
          </Flex>
        </VStack>
      </VStack>
    </HStack>
  );
};

export default ContactDetails;
