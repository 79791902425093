import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllTemplates = (page, limit, sort, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/templates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          page: page,
          limit: limit,
          sort: sort,
          filters: filters,
          search: search,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allTemplates = resData.data;
        dispatch({ type: events.GET_ALL_CRMTEMPLATES, data: allTemplates });
        return allTemplates ? allTemplates : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//create template
export const createTemplates = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/templates/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const newTemplates = resData.data;
        dispatch({ type: events.CREATE_CRMTEMPLATES, data: newTemplates });
        return newTemplates ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//edit signatures
export const editTemplate = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/templates/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const template = resData.data;
        dispatch({ type: events.EDIT_CRMTEMPLATE, data: template });
        return template ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//delete signatures
export const deleteTemplate = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/templates/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const template = resData.data;
        dispatch({ type: events.DELETE_CRMTEMPLATE, data: template });
        return template ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getSignatureById = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/signatures/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const signature = resData.data;
        dispatch({ type: events.GET_SIGNATURE_BY_ID, data: signature });
        return signature ? signature : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
