import {
  VStack,
  Text,
  HStack,
  Divider,
  FlatList,
  Pressable,
  Input,
  Avatar,
} from "native-base";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { createLead } from "../../store/actions/leads";
// import { getAllCalls, getActiveCalls } from "../../store/actions/calls";
import { aw, ah } from "../../utils";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchMuteOffIcon from "../../components/svg/LeadsWatchMuteOffIcon";
import LeadsWatchCallPauseIcon from "../../components/svg/LeadsWatchCallPauseIcon";
import LeadsWatchCallForwardIcon from "../../components/svg/LeadsWatchCallForwardIcon";
import LeadsWatchRecordIcon from "../../components/svg/LeadsWatchRecordIcon";

import LeadsWatchAddPersonIcon from "../../components/svg/LeadsWatchAddPersonIcon";
import LeadsWatchKeyPadIcon from "../../components/svg/LeadsWatchKeyPadIcon";
import LeadsWatchMoreIcon from "../../components/svg/LeadsWatchMoreIcon";
import LeadsWatchAddCallIcon from "../../components/svg/LeadsWatchAddCallIcon";
import LeadsWatchCallsIcon from "../../components/svg/LeadsWatchCallsIcon";

import LeadsWatchProfileIcon from "../../components/svg/LeadsWatchProfileIcon";

const MakeCalls = ({
  formFields,
  activeCall,
  onGoing,
  handleHangUp,
  setFormFields,
  plivoRef,
  isOpen1,
  handleMute,
  handleUnMute,
}) => {
  const [mute, setMute] = useState(false);

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isAnswerd, setIsAnswerd] = useState(false);
  const [dialCall, setDialCall] = useState(false);

  const [loading, setLoading] = useState(false);
  const textRef = useRef(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    // value,
    getValues,
  } = useForm({
    // mode: "onBlur",
    mode: "onSubmit",
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const finalData = { number: formFields.number, ...data };
    try {
      await dispatch(createLead(finalData));
      toast.success("Lead Submitted Successfully");
      reset();
      setFormFields([]);
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }

    setLoading(false);
  };
  // const getCallsHistory = async () => {
  //   try {
  //     await dispatch(getAllCalls());
  //   } catch (err) {
  //     toast.show({
  //       placement: "top",
  //       render: () => {
  //         return (
  //           <ToastAlert
  //             desc={err.message}
  //             // handleClose={handleToastClose}
  //             status="error"
  //             title="error"
  //           />
  //         );
  //       },
  //     });
  //   }
  // };
  // const getLiveCalls = async () => {
  //   try {
  //     await dispatch(getActiveCalls());
  //   } catch (err) {
  //     toast.show({
  //       placement: "top",
  //       render: () => {
  //         return (
  //           <ToastAlert
  //             desc={err.message}
  //             // handleClose={handleToastClose}
  //             status="error"
  //             title="error"
  //           />
  //         );
  //       },
  //     });
  //   }
  // };
  // useEffect(() => {
  //   getCallsHistory();
  //   getLiveCalls();
  // }, []);
  useEffect(() => {
    if (textRef.current) {
      if (onGoing) {
        let initialTime = 0;
        const interval = setInterval(() => {
          initialTime += 1;
          const formattedTime = formatTime(initialTime);
          textRef.current.textContent = formattedTime;
        }, 1000);

        return () => {
          clearInterval(interval);
        };
      } else {
        textRef.current.textContent = "00:00";
      }
    }
  }, [onGoing]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };
  return (
    <VStack>
      <HStack
        borderWidth={aw(1)}
        height={ah(550)}
        borderColor="#E6E7E9"
        width={
          isOpen1 ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
      >
        <HStack
          width={aw(1000)}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <FlatList
            width={aw(250)}
            showsVerticalScrollIndicator={false}
            height={ah(400)}
            ListEmptyComponent={
              <HStack
                justifyContent="center"
                alignItems={"center"}
                height={ah(400)}
              >
                <Text
                  color="black"
                  fontSize={aw(14)}
                  fontFamily={"RobotoMedium"}
                >
                  No data found
                </Text>
              </HStack>
            }
            data={formFields && formFields["campaign_posting_fields"]}
            keyExtractor={(item, index) => index}
            renderItem={({ item, index }) => (
              <VStack
                justifyContent={"center"}
                alignItems={"center"}
                my={ah(10)}
              >
                <LeadsWatchFilterInput
                  screen={
                    getValues(item.name) && getValues(item.name).length > 0
                      ? "edit"
                      : "create"
                  }
                  name={item.name}
                  placeholder={item.name}
                  control={control}
                  // style={{ width: aw(587), height: ah(40) }}
                  width={aw(587)}
                  height={ah(40)}
                  rules={{
                    required: {
                      value: item.mandatory === 1 ? true : false,
                    },
                    pattern: {
                      value:
                        item.name === "Email"
                          ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                          : null,
                    },
                  }}
                  invalid={errors[`${item.name}`] ? true : false}
                  error={
                    errors[`${item.name}`] ? errors[`${item.name}`].message : ""
                  }
                />{" "}
                {index === formFields["campaign_posting_fields"].length - 1 && (
                  <VStack my={ah(20)} space={aw(20)} alignItems={"center"}>
                    <LeadsWatchFilterInput
                      name={"click_id"}
                      placeholder={"Click Id"}
                      control={control}
                      // style={{ width: aw(587), height: ah(40) }}
                      width={aw(587)}
                      height={ah(40)}
                    />

                    <LeadsWatchButton
                      isLoading={loading}
                      style={{ width: aw(200) }}
                      label="submit"
                      onPress={handleSubmit(onSubmit)}
                      color="#FFFFFF"
                      bg={"#2230F8"}
                    />
                  </VStack>
                )}
              </VStack>
            )}
          />
        </HStack>
      </HStack>
      {/* {onGoing ? (
        <>
          <Pressable onPress={() => setIsOpen(true)}>
            <HStack
              space={aw(20)}
              alignItems={"center"}
              height={ah(70)}
              onPress={() => setIsOpen(true)}
            >
              <Avatar height={aw(40)} width={aw(40)} />
              <VStack>
                <Text
                  color={"#212121"}
                  fontFamily={"RobotoMedium"}
                  fontSize={aw(18)}
                ></Text>
                <Text
                  color={"#212121"}
                  fontFamily={"RobotoMedium"}
                  fontSize={aw(10)}
                >
                  {activeCall["src"]}
                </Text>
              </VStack>
              <Pressable
                // onPress={handleFormFields}
                onPress={handleHangUp}
                size={ah(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
                style={{
                  background: "red",
                }}
              >
                <LeadsWatchCallsIcon color="#FFFFFF" />
              </Pressable>
            </HStack>
          </Pressable>
        </>
      ) : null} */}
      {onGoing ? (
        <VStack
          borderRadius={aw(5)}
          // width={aw(250)}
          // height={ah(350)}
          alignItems={"center"}
          justifyContent={"center"}
          size={aw(50)}
          position="absolute"
          bottom={ah(0)}
          right={aw(0)}
          background={"white"}
          borderColor="#D7D7D7"
          borderWidth={aw(1)}
          pt={ah(10)}
          px={aw(10)}
          style={{ shadowColor: "#00000033", shadowRadius: aw(12) }}
        >
          <HStack
            style={{ shadowColor: "#00000033", shadowRadius: aw(12) }}
            borderRadius={"full"}
            // width={aw(80)}
            // height={ah(90)}
            // alignItems={"center"}
            // height={ah(70)}
            // width={aw(75)}
            // height={ah(70)}
            alignItems={"center"}

            // backgroundColor={"amber.500"}
          >
            <LeadsWatchProfileIcon />
          </HStack>
          <Text
            pt={ah(10)}
            color={"#212121"}
            fontFamily={"RobotoMedium"}
            fontSize={aw(18)}
          ></Text>
          <Text color={"#212121"} fontFamily={"RobotoMedium"} fontSize={aw(12)}>
            {/* (862) 633-0498 */}
            {activeCall && activeCall["src"]}
          </Text>
          <Text
            ref={textRef}
            color={"#212121"}
            fontFamily={"RobotoMedium"}
            fontSize={aw(14)}
          ></Text>

          <Divider my={ah(20)} bg="#E6E7E9" height={ah(1)} />
          <VStack space={ah(20)}>
            <HStack space={aw(30)}>
              <Pressable
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
                bg={mute ? "#919191" : "#FFFFFF"}
                onPress={() => {
                  if (!mute) {
                    // plivoRef.current.client.mute();
                    handleMute();
                    setMute(!mute);
                  } else {
                    // plivoRef.current.client.unmute();
                    handleUnMute();
                    setMute(!mute);
                  }
                }}
              >
                <LeadsWatchMuteOffIcon />
              </Pressable>
              <Pressable
                isDisabled={true}
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchCallPauseIcon />
              </Pressable>
              <Pressable
                isDisabled={true}
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchCallForwardIcon />
              </Pressable>
              <Pressable
                isDisabled={true}
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchRecordIcon />
              </Pressable>
            </HStack>
            <HStack space={aw(30)}>
              <Pressable
                isDisabled={true}
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchAddCallIcon />
              </Pressable>
              <Pressable
                isDisabled={true}
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchAddPersonIcon />
              </Pressable>
              <Pressable
                isDisabled={true}
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchKeyPadIcon />
              </Pressable>
              <Pressable
                isDisabled={true}
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchMoreIcon />
              </Pressable>
            </HStack>
          </VStack>
          <HStack my={ah(50)}>
            <LeadsWatchButton
              onPress={handleHangUp}
              label="End Session"
              style={{
                width: aw(170),
                height: ah(40),
                borderRadius: "6px",
              }}
              color="#FFFFFF"
              fontSize={aw(14)}
              fontFamily="RobotoRegular"
              bg={"#D64547"}
              fontWeight="400"
            />
          </HStack>
        </VStack>
      ) : null}
      <LeadsWatchModal
        required={true}
        // animationPreset="slide"
        height={ah(500)}
        width={aw(320)}
        showModal={dialCall}
        onClose={() => {
          setDialCall(false);
        }}
      >
        <>
          <VStack justifyContent={"center"} alignItems={"center"}>
            <Text fontSize={aw(14)} color="#212121" fontFamily={"RobotoMedium"}>
              UnKnown Number
            </Text>
            <Text fontSize={aw(14)} color="#212121" fontFamily={"RobotoMedium"}>
              9999999999999
            </Text>
            <HStack
              alignItems={"center"}
              justifyContent={"center"}
              space={aw(20)}
            >
              <Pressable
                // onPress={handleFormFields}
                onPress={() => setIsOpen(true)}
                my={ah(20)}
                size={ah(60)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
                style={{
                  background: "green",
                }}
              >
                <LeadsWatchCallsIcon color="red.300" />
              </Pressable>

              <Pressable
                // onPress={handleFormFields}
                my={ah(20)}
                size={ah(60)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
                style={{
                  background: "red",
                }}
              >
                <LeadsWatchCallsIcon color="#FFFFFF" />
              </Pressable>
            </HStack>
          </VStack>
        </>
      </LeadsWatchModal>
      <LeadsWatchModal
        // animationPreset="slide"
        height={ah(601)}
        width={aw(376)}
        showModal={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        style={{
          marginBottom: 0,
          marginTop: "auto",
          marginRight: aw(30),
          marginLeft: "auto",

          // zIndex: 1001,
        }}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Lead ID 233
            </Text>
          </HStack>
        }
      >
        {!isAnswerd ? (
          <>
            <VStack
              alignItems={"center"}
              justifyContent={"center"}
              size={aw(50)}
            >
              <Avatar height={aw(92)} width={aw(92)} />
              <Text
                color={"#212121"}
                fontFamily={"RobotoMedium"}
                fontSize={aw(18)}
              >
                Lydia Phillips
              </Text>
              <Text
                color={"#212121"}
                fontFamily={"RobotoMedium"}
                fontSize={aw(10)}
              >
                (862) 633-0498
              </Text>
              {/* <Text
              color={"#212121"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(10)}
            >
              Lydia Phillips
            </Text> */}
              <Divider my={ah(20)} bg="#E6E7E9" height={ah(1)} />
              <>
                <VStack justifyContent={"center"} alignItems={"center"}>
                  <HStack
                    alignItems={"center"}
                    justifyContent={"center"}
                    space={aw(20)}
                  >
                    <Pressable
                      // onPress={handleFormFields}
                      onPress={() => setIsAnswerd(true)}
                      my={ah(20)}
                      size={ah(60)}
                      borderColor="#EFF2F5"
                      borderWidth={aw(1)}
                      borderRadius={"full"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      style={{
                        background: "green",
                      }}
                    >
                      <LeadsWatchCallsIcon color="red.300" />
                    </Pressable>

                    <Pressable
                      // onPress={handleFormFields}
                      my={ah(20)}
                      size={ah(60)}
                      borderColor="#EFF2F5"
                      borderWidth={aw(1)}
                      borderRadius={"full"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      style={{
                        background: "red",
                      }}
                    >
                      <LeadsWatchCallsIcon color="#FFFFFF" />
                    </Pressable>
                  </HStack>
                </VStack>
              </>
            </VStack>
          </>
        ) : (
          <>
            <VStack
              alignItems={"center"}
              justifyContent={"center"}
              size={aw(50)}
            >
              <Avatar height={aw(92)} width={aw(92)} />
              <Text
                color={"#212121"}
                fontFamily={"RobotoMedium"}
                fontSize={aw(18)}
              >
                Lydia Phillips
              </Text>
              <Text
                color={"#212121"}
                fontFamily={"RobotoMedium"}
                fontSize={aw(10)}
              >
                (862) 633-0498
              </Text>
              {/* <Text
            color={"#212121"}
            fontFamily={"RobotoMedium"}
            fontSize={aw(10)}
          >
            Lydia Phillips
          </Text> */}
              <Divider my={ah(20)} bg="#E6E7E9" height={ah(1)} />
              <VStack space={ah(20)}>
                <HStack space={aw(30)}>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchMuteOffIcon />
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchCallPauseIcon />
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchCallForwardIcon />
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchRecordIcon />
                  </Pressable>
                </HStack>
                <HStack space={aw(30)}>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchAddCallIcon />
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchAddPersonIcon />
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchKeyPadIcon />
                  </Pressable>
                  <Pressable
                    size={aw(40)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <LeadsWatchMoreIcon />
                  </Pressable>
                </HStack>
              </VStack>
              <HStack my={ah(50)}>
                <LeadsWatchButton
                  label="01:00- End Session"
                  style={{
                    width: aw(170),
                    height: ah(40),
                    borderRadius: "6px",
                  }}
                  color="#FFFFFF"
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  bg={"#D64547"}
                  fontWeight="400"
                />
              </HStack>
            </VStack>
          </>
        )}
      </LeadsWatchModal>
    </VStack>
  );
};

export default MakeCalls;
