import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

import { authReducer } from "./reducer/auth";
import { verticalsReducer } from "./reducer/verticals";
import { publishersReducer } from "./reducer/publishers";
import { buyersReducer } from "./reducer/buyers";
import { campaignsReducer } from "./reducer/campaigns";
import { leadsReducer } from "./reducer/leads";
import { subscriptionreducer } from "./reducer/subscription";
import { teamReducer } from "./reducer/team";
import { callReducer } from "./reducer/calls";
import { reportReducer } from "./reducer/reports";
import { groupsReducer } from "./reducer/groups";
import { pipelinesReducer } from "./reducer/pipelines";
import { commonReducer } from "./reducer/common";
import { dashboardReducer } from "./reducer/dashboard";
import { invoicesReducer } from "./reducer/invoices";
import { crmsignatures } from "./reducer/crmsignatures";
import { crmtemplates } from "./reducer/crmtemplates";
import { crmcampaigns } from "./reducer/crmcampaigns";
import { crmContacts } from "./reducer/crmcontacts";

const reducer = combineReducers({
  auth: authReducer,
  verticals: verticalsReducer,
  publishers: publishersReducer,
  buyers: buyersReducer,
  campaigns: campaignsReducer,
  leads: leadsReducer,
  subscriptions: subscriptionreducer,
  team: teamReducer,
  calls: callReducer,
  reports: reportReducer,
  groups: groupsReducer,
  pipelines: pipelinesReducer,
  common: commonReducer,
  dashboard: dashboardReducer,
  invoices: invoicesReducer,
  crmsignatures: crmsignatures,
  crmtemplates: crmtemplates,
  crmcampaigns: crmcampaigns,
  crmcontacts: crmContacts,
});
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsDenylist, actionsCreators and other options
});
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
export default store;

//  process.env.NODE_ENV === "development"
//    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
//    : compose;

// const composeEnhancers =
//   process.env.NODE_ENV === "development"
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
//     : compose;
