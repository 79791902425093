import { React, useState, useEffect, useMemo } from "react";

import {
  Text,
  HStack,
  Center,
  VStack,
  Hidden,
  useBreakpointValue,
  Flex,
  Menu,
  Box,
  ScrollView,
} from "native-base";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
import moment from "moment/moment";

import { aw, ah } from "../../utils";
import LeadsWatchTable from "../../components/LeadsWatchTable";

import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchDownloadIcon from "../../components/svg/LeadsWatchDownloadIcon";
import LeadsWatchCalendar from "../../components/LeadsWatchCalendar";
import LeadsWatchFilterIcon from "../../components/svg/LeadsWatchFilterIcon";
import LeadsWatchMenu from "../../components/LeadsWatchMenu";
import {
  getBuyerRoutes,
  getVerticals,
  download,
  getBuyers,
} from "../../store/actions/reports";

const BuyerRoute = ({ isOpen }) => {
  const [downloading, setDownloading] = useState(false);
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const allVerticals = useSelector((state) => state.reports.verticals);
  const profile = useSelector((state) => state.auth.profile);

  const dispatch = useDispatch();

  const buyerRoutes = useSelector((state) => state.reports.buyerRoutes);
  const allBuyers = useSelector((state) => state.reports.buyers);

  const [currentPage, setCurrentPage] = useState(
    page && page.reportBuyerRoutes ? parseInt(page.reportBuyerRoutes) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.buyerRoutesReports
      ? parseInt(pagecount.buyerRoutesReports)
      : 10
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({});
  const [filters1, setFilters1] = useState({});

  const [selectedValues, setSelectedValues] = useState({});

  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const { control, getValues } = useForm({
    mode: "all",

    defaultValues: {
      select:
        pagecount && pagecount.buyerRoutesReports
          ? pagecount.buyerRoutesReports.toString()
          : "10",
    },
  });

  const handleChangeSelect = (value) => {
    setSelectCount(value);
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, buyerRoutesReports: value })
    );
    setCurrentPage(1);

    localStorage.setItem(
      "page",
      JSON.stringify({ ...page, reportBuyerRoutes: 1 })
    );
  };
  const handleFromDate = (date) => {
    if (date) {
      setStartDate(date);

      setFilters({ ...filters, start_date: moment(date).format("YYYY-MM-DD") });
    }
  };
  const handleEndDate = (date) => {
    if (date) {
      setEndDate(date);
      setFilters({ ...filters, end_date: moment(date).format("YYYY-MM-DD") });
    }
  };

  const fetchBuyerRoutes = async () => {
    setIsLoading(true);

    try {
      await dispatch(getBuyerRoutes(currentPage, selectCount, filters1));
    } catch (err) {
      console.log(err.message);
      toast.error(err.message.toLowerCase());
    }

    setIsLoading(false);
  };
  const fetchAllVerticals = async () => {
    try {
      await dispatch(getVerticals(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const fetchBuyers = async () => {
    try {
      await dispatch(getBuyers(1, 10));
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   fetchAllVerticals();
  //   fetchBuyers();
  // }, []);
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (profile.permission_ids.lead.includes("view")) {
        fetchBuyerRoutes();
      }
    } else {
      fetchBuyerRoutes();
    }
  }, [currentPage, selectCount, filters1]);

  const handleFilters = () => {
    setFilters1(filters);

    // setFilters1(data);

    setCurrentPage(1);
    localStorage.setItem(
      "page",
      JSON.stringify({ ...page, reportBuyerRoutes: 1 })
    );
    setOpenFilter(false);
  };

  const loadOptions = async (inputValue, callback, list, defaultOptions) => {
    try {
      const data =
        list === "vertical"
          ? await dispatch(getVerticals(1, 10, undefined, inputValue))
          : await dispatch(getBuyers(1, 10, undefined, inputValue));

      callback(
        data && list === "vertical"
          ? data.list.map((val) => ({
              value: val._id,
              label: val.name,
            }))
          : data.list.map((val) => ({
              value: val._id,
              label: val.firstname + " " + val.lastname,
            }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (filters.start_date && filters.end_date) {
      setFilters1({
        ...filters1,
        start_date: filters.start_date,
        end_date: filters.end_date,
      });
      setCurrentPage(1);
      localStorage.setItem(
        "page",
        JSON.stringify({ ...page, reportBuyerRoutes: 1 })
      );
    }
  }, [startDate, endDate]);
  const defaultOptions = allVerticals
    ? allVerticals.list.map((val) => ({ value: val._id, label: val.name }))
    : [];

  const defaultOptionsForBuyers = allBuyers
    ? allBuyers.list.map((val) => ({
        value: val._id,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];

  const handleOptionChange = (key, selectedOption) => {
    console.log("Selected Option:", selectedOption);

    const ids = selectedOption.map((val) => val.value);
    setSelectedValues({ ...selectedValues, [key]: selectedOption });
    setFilters({ ...filters, [key]: ids });
  };
  const handleDownload = async (type) => {
    setDownloading(true);
    try {
      await dispatch(
        download(
          currentPage,
          selectCount,
          { filters: filters1 },
          "routes",
          type
        )
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setDownloading(false);
  };
  const memoizedChildComponent = useMemo(() => {
    return (
      <LeadsWatchTable
        isOpen={isOpen}
        heading={["name", "vertical_name", "method", "status"]}
        screen="reports"
        //   handleSort={handleSort}

        data={buyerRoutes && buyerRoutes.list}
        // data={requiredData}
        // filter={openFilter}
        isLoading={isLoading}
      />
    );
  }, [isLoading, isOpen]);
  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <Hidden only={["sm", "base"]}>
            <VStack
              height={ah(35)}
              mt={ah(10)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
              zIndex={1}
            >
              <HStack justifyContent={"space-between"}>
                <HStack height={ah(32)} alignItems="center">
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    Show Entries :{" "}
                  </Text>
                  <Center>
                    <LeadsWatchSelect
                      control={control}
                      name={"select"}
                      list={["10", "20", "50", "100"]}
                      // value={selectCount}
                      handleChange={(value) =>
                        handleChangeSelect(parseInt(value))
                      }
                      width={79}
                      height={ah(32)}
                    />
                  </Center>
                </HStack>

                <HStack space={aw(12)} width={aw(670)}>
                  <LeadsWatchMenu
                    width={aw(120)}
                    mr={aw(5)}
                    title={
                      // <Pressable>
                      <Flex
                        direction="row"
                        borderColor="#D1D5DB"
                        bg="#FAFBFD"
                        borderWidth={aw(1)}
                        width={aw(115)}
                        height={ah(38)}
                        borderRadius={aw(5)}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        {downloading ? null : <LeadsWatchDownloadIcon />}
                        <Text
                          fontFamily="RobotoRegular"
                          color="black"
                          fontSize={aw(14)}
                        >
                          {downloading ? "Downloading..." : "Download"}
                        </Text>
                      </Flex>
                      // </Pressable>
                    }
                  >
                    <Menu.Item
                      bgColor="white"
                      _hover={{ bgColor: "#F5F7FB" }}
                      onPress={() => handleDownload("csv")}
                    >
                      <Text
                        fontSize={aw(14)}
                        fontFamily="RobotoRegular"
                        color="#212121"
                      >
                        CSV
                      </Text>
                    </Menu.Item>
                    <Menu.Item
                      bgColor="white"
                      _hover={{ bgColor: "#F5F7FB" }}
                      onPress={() => handleDownload("xlsx")}
                    >
                      <Text
                        fontSize={aw(14)}
                        fontFamily="RobotoRegular"
                        color="#212121"
                      >
                        XLSX
                      </Text>
                    </Menu.Item>
                  </LeadsWatchMenu>
                  <HStack>
                    <LeadsWatchCalendar
                      startDate={startDate}
                      handleFromDate={handleFromDate}
                      handleEndDate={handleEndDate}
                      width={aw(120)}
                      height={ah(36)}
                      endDate={endDate}
                    />
                  </HStack>
                  {/* <LeadsWatchSelect
                    control={control}
                    // value={filters1.status}
                    name={"status"}
                    placeholder="Status"
                    list={["Active", "InActive"]}
                    handleChange={(value) =>
                      setFilters1({ ...filters1, status: value })
                    }
                    width={aw(100)}
                    // height={ah(32)}
                  /> */}
                  <LeadsWatchAsyncSelect
                    isClearable={true}
                    height={ah(38)}
                    required={true}
                    minWidth={aw(135)}
                    defaultOptions={[
                      { label: "Active", value: "active" },
                      { label: "Inactive", value: "inactive" },
                    ]}
                    control={control}
                    placeholder="Status"
                    name="status"
                    handleChange={(value) => {
                      if (value) {
                        setCurrentPage(1);
                        localStorage.setItem(
                          "page",
                          JSON.stringify({ ...page, buyerRoutesReports: 1 })
                        );
                        setFilters1({ ...filters1, status: value.value });
                      } else {
                        setCurrentPage(1);
                        localStorage.setItem(
                          "page",
                          JSON.stringify({ ...page, buyerRoutesReports: 1 })
                        );
                        // // setFilters(delete filters.status);
                        delete filters1.status;
                        setFilters1({ ...filters1 });
                      }
                    }}
                  />
                  <LeadsWatchButton
                    bg="#FFFFFF"
                    onPress={() => setOpenFilter(!openFilter)}
                    leftIcon={<LeadsWatchFilterIcon />}
                    label={"Filter"}
                    style={{
                      width: aw(115),
                      height: ah(38),
                      borderColor: "#D1D5DB",
                    }}
                    fontWeight="400"
                  />
                  {openFilter && (
                    <Box
                      px={aw(10)}
                      position="absolute"
                      zIndex={1}
                      top={ah(35)}
                      right={aw(98)}
                      width={aw(570)}
                      //   minH={ah(180)}
                      py={ah(10)}
                      // my={ah(10)}
                      bgColor="#FFFFFF"
                      borderColor="#E1E1E1"
                      style={{
                        shadowColor: "#00000033",
                        shadowRadius: aw(20),
                      }}
                      borderWidth={aw(1)}
                      borderRadius={aw(6)}
                    >
                      <ScrollView maxH={ah(400)} px={aw(10)} py={aw(15)}>
                        <Flex direction="row" wrap="wrap" gap={ah(20)}>
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("vertical") &&
                                getValues("vertical").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              type="async"
                              defaultOptions={defaultOptions}
                              loadOptions={(inputValues, callback) =>
                                loadOptions(inputValues, callback, "vertical")
                              }
                              handleChange={(selectedOption) =>
                                handleOptionChange(
                                  "vertical_id",
                                  selectedOption
                                )
                              }
                              onFocus={() => {
                                if (allVerticals) {
                                  if (allVerticals.list.length < 10) {
                                    fetchAllVerticals();
                                  }
                                } else {
                                  fetchAllVerticals();
                                }
                              }}
                              onInputChange={(inputValues) => {
                                if (inputValues === "") {
                                  fetchAllVerticals();
                                  // return inputValue;
                                }
                              }}
                              placeholder="Verticals"
                              onChange={(selectedOption) =>
                                handleOptionChange(
                                  "vertical_id",
                                  selectedOption
                                )
                              }
                              control={control}
                              name="vertical"
                            />
                          </Flex>

                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("buyer") &&
                                getValues("buyer").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              type="async"
                              defaultOptions={defaultOptionsForBuyers}
                              loadOptions={(inputValues, callback) =>
                                loadOptions(inputValues, callback, "buyer")
                              }
                              onInputChange={async (inputValues) => {
                                if (inputValues === "") {
                                  fetchBuyers();
                                  // return inputValue;
                                }

                                // return inputValue;
                              }}
                              onFocus={() => {
                                if (allBuyers) {
                                  if (allBuyers.list.length < 10) {
                                    fetchBuyers();
                                  }
                                } else {
                                  fetchBuyers();
                                }
                              }}
                              handleChange={(selectedOption) =>
                                handleOptionChange("buyer_id", selectedOption)
                              }
                              control={control}
                              placeholder="Buyers"
                              name="buyer"
                            />
                          </Flex>
                          <Flex
                            width={aw(520)}
                            justifyContent={"flex-end"}
                            direction="row"
                            gap={aw(10)}
                            zIndex={-1}
                            mt={ah(10)}
                          >
                            <LeadsWatchButton
                              label="Cancel"
                              onPress={() => {
                                setOpenFilter(false);
                                if (
                                  (filters.vertical_id &&
                                    filters.vertical_id.length) ||
                                  (filters.buyer_id && filters.buyer_id.length)
                                ) {
                                  setSelectedValues({});
                                  setFilters({
                                    ...filters,
                                    vertical_id: [],
                                    buyer_id: [],
                                  });
                                }
                                if (
                                  (filters1.vertical_id &&
                                    filters1.vertical_id.length) ||
                                  (filters1.buyer_id &&
                                    filters1.buyer_id.length)
                                ) {
                                  setFilters1({
                                    ...filters1,
                                    vertical_id: [],
                                    buyer_id: [],
                                  });
                                }
                              }}
                              style={{ height: ah(32) }}
                            />
                            <LeadsWatchButton
                              onPress={handleFilters}
                              label="Apply"
                              style={{
                                height: ah(32),
                              }}
                              bg="#2230F8"
                              color="#FFFFFF"
                              borderColor="#05BBC9"
                            />
                          </Flex>
                        </Flex>
                      </ScrollView>
                    </Box>
                  )}
                </HStack>
              </HStack>
            </VStack>
          </Hidden>
          <VStack>
            <VStack mt={3}>{memoizedChildComponent}</VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={
                  buyerRoutes &&
                  buyerRoutes.total_count &&
                  buyerRoutes.total_count
                }
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, reportBuyerRoutes: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>
    </>
  );
};
export default BuyerRoute;
