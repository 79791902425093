import { Text, VStack, HStack, Center } from "native-base";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { aw, ah } from "../../../utils";
import LeadsWatchTable from "../../../components/LeadsWatchTable";
import { fetchAllNumbers, deleteNumber } from "../../../store/actions/calls";
import LeadsWatchPagination from "../../../components/LeadsWatchPagination";
import LeadsWatchModal from "../../../components/LeadsWatchModal";
import LeadsWatchButton from "../../../components/LeadsWatchButton";
import LeadsWatchSelect from "../../../components/LeadsWatchSelect";
const ManageNumbers = ({ isOpen }) => {
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const { control } = useForm({
    defaultValues: { select: "10" },
    mode: "all",
  });

  const dispatch = useDispatch();
  const allNumbers = useSelector((state) => state.calls.allBuyedNumbers);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sort, setSort] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectCount, setSelectCount] = useState(10);
  useEffect(() => {
    getAllNumbers();
  }, [currentPage, selectCount]);

  const getAllNumbers = async () => {
    setIsLoading(true);
    try {
      await dispatch(fetchAllNumbers(currentPage, selectCount));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  const handleSort = (order) => {
    setSort(order);
  };
  const handleDelete = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        await dispatch(deleteNumber({ number: deleteId }));
        setDeleteId(null);
        setConfirmModal(false);
        // await dispatch(fetchAllNumbers(currentPage));
        getAllNumbers();
        toast.success("Number Deleted Successfully");
      } catch (err) {
        setDeleteId(null);
        setConfirmModal(false);
        toast.error(err.message.toLowerCase());
      }
      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, numbers: 1 }));
  };
  const memoizedChildComponent = useMemo(() => {
    return (
      <LeadsWatchTable
        isOpen={isOpen}
        heading={[
          "number",
          "country",
          "name",
          "allocated",
          "campaign_id",
          "publisher_id",
          "renewat",
          "status",
          "Actions",
        ]}
        handleDelete={handleDelete}
        screen="number"
        // sortIcon={true}
        data={allNumbers && allNumbers.list}
        isLoading={isLoading}
        handleSort={handleSort}
        sort={sort}
      />
    );
  }, [isLoading, isOpen]);
  return (
    <>
      <HStack justifyContent={"space-between"}>
        <HStack height={ah(32)} alignItems="center">
          <Text fontSize={aw(14)} fontFamily="RobotoRegular" color="#212121">
            Show Entries :{" "}
          </Text>
          <Center>
            <LeadsWatchSelect
              control={control}
              name={"select"}
              list={["10", "20", "50", "100"]}
              // value={selectCount}
              handleChange={(value) => handleChangeSelect(parseInt(value))}
              width={79}
              // height={ah(32)}
            />
          </Center>
        </HStack>
      </HStack>
      <VStack top={5}>{memoizedChildComponent}</VStack>
      <VStack
        bottom={aw(5)}
        position="fixed"
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
      >
        <LeadsWatchPagination
          width="100%"
          totalCount={allNumbers && allNumbers.total_count}
          currentPage={currentPage}
          pageSize={10}
          onPageChange={(currentPage) => {
            setCurrentPage(currentPage);
            localStorage.setItem(
              "page",
              JSON.stringify({ ...page, numbers: currentPage })
            );
          }}
        />
      </VStack>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this Number
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};

export default ManageNumbers;
