import React, { useState, useEffect, useRef, Suspense } from "react";
import {
  HStack,
  // useToast,
  Text,
  VStack,
  Flex,
  // useBreakpointResolvedProps,
  // Input,
} from "native-base";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Plivo from "plivo-browser-sdk";
//import { io } from "socket.io-client";
import { toast } from "react-toastify";
import { ah, aw } from "./utils";
import { getProfile } from "./store/actions/auth";
// import Login from "./screens/auth/Login";
// import Register from "./screens/auth/Register";

import Leads from "./screens/leads/Leads";
// const Leads = React.lazy(() => import('./screens/leads/Leads'));
import Campaigns from "./screens/campaigns/Campaigns";
import Operations from "./screens/operations/Operations";
import Buyers from "./screens/buyers/Buyers";
import Verticals from "./screens/verticals/Verticals";
import Publishers from "./screens/publishers/Publishers";
import LeadsWatchSideBarTrial from "./components/LeadsWatchSideBarTrial";
import LeadsWatchTopBar from "./components/LeadsWatchTopBar";
import CreateCampaign from "./screens/campaigns/CreateCampaign";
import ViewVertical from "./screens/verticals/ViewVertical";
import BuyerRoutes from "./screens/buyers/BuyerRoutes";
import CreateBuyer from "./screens/buyers/CreateBuyer";
import Subscription from "./screens/subscription/Subscription";
import SubscriptionDetails from "./screens/subscription/SubscriptionDetails";
import Team from "./screens/settings/Team";
import Profile from "./screens/settings/Profile";
import Settings from "./screens/settings/Settings";
// import PaymentGateway from "./screens/settings/PaymentGateway";
import PayPublisher from "./screens/settings/PayPublisher";
import BuyerCards from "./screens/settings/BuyerCards";
import Calls from "./screens/calls/Calls";
import Ivr from "./screens/calls/ivr/Ivr";
import Numbers from "./screens/calls/numbers/Numbers";
import ManageNumbers from "./screens/calls/numbers/ManageNumbers";
import ManagePools from "./screens/calls/numbers/ManagePools";
import ManageBlockedNumbers from "./screens/calls/numbers/ManageBlockedNumbers";
import RoutingPlans from "./screens/calls/ivr/RoutingPlans";
import CreateIvrTree from "./screens/calls/ivr/CreateIvrTree";
import ManageIvrTrees from "./screens/calls/ivr/ManageIvrTree";
import ManageMedia from "./screens/calls/ivr/ManageMedia";
import Credits from "./screens/subscription/Credits";
import Invoice from "./screens/subscription/Invoice";
import IncomigCalls from "./screens/calls/IncomingCalls";
import LeadsWatchAnswerModal from "./components/LeadsWatchAnswerModal";
import MakeCalls from "./screens/calls/MakingCalls";
import Invoices from "./screens/invoices/Index";
//dashboard
import Dashboard from "./screens/dashboard/Index";
//reports
import Index from "./screens/reports/Index";
import VerticalsReport from "./screens/reports/Verticals";
import LeadsReport from "./screens/reports/Leads";
import PublishersReport from "./screens/reports/Publishers";
import CampaignsReport from "./screens/reports/Campaigns";
import BuyersReport from "./screens/reports/Buyers";
import BuyerRoutesReport from "./screens/reports/BuyerRoutes";
import VerticalFields from "./screens/reports/VerticalFields";
import CallLogs from "./screens/calls/CallsLog";
// import LoginNew from "./screens/auth/LoginNew";
import Groups from "./screens/buyers/Groups";

import IVR from "./screens/reports/IVR";
import PPC from "./screens/reports/PPC";
import Splash from "./screens/splash/Index";
//delete account
import DeleteAccount from "./screens/deleteaccount/Index";
//crm
import PipeLines from "./screens/crm/pipelines/Index";
import CrmCampaigns from "./screens/crm/campaigns/Index";
import CrmTemplates from "./screens/crm/templates/Index";
import CrmSignatures from "./screens/crm/signatures/Index";
import CrmContacts from "./screens/crm/contacts/Index";
import ContactDetails from "./screens/crm/contacts/ContactDetails";
import Smtps from "./screens/crm/signatures/Smtps";
import LeadsWatchButton from "./components/LeadsWatchButton";
import LeadsWatchIncomingCallIcon from "./components/svg/LeadsWatchIncomingCallIcon";
const LoginNew = React.lazy(() => import("./screens/auth/LoginNew"));
// const Dashboard = React.lazy(() => import("./screens/dashboard/Index"));
function App() {
  let global = {};
  // const priority = useSelector((state) => state.calls.priority);

  const [close, setClose] = useState(false);
  const [fetching, setFetching] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profile);
  // const toast = useToast();
  const [isOpen, setisOpen] = useState(true);
  const [auth, setAuth] = useState();
  const token = localStorage.getItem("auth");
  const isVerified = localStorage.getItem("isVerified");
  const plivoRef = useRef(null);
  const activeCall = useRef(null);
  const [outGoingCall, setOutGoingCall] = useState(null);
  const [callsModalisOpen, setcallsModalisOpen] = useState(true);
  const [calls, setCalls] = useState([]);
  const [displayLeads, setDisplayLeads] = useState([]);
  const [newLeads, setNewLeads] = useState(0);
  const [formFields, setFormFields] = useState([]);
  const [callInfo, setCallInfo] = useState(null);
  const [allCalls, setAllCalls] = useState([]);
  // const [priorityRinging, setPriorityRinging] = useState(false);

  // const [priorityTime, setPriorityTime] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [websocket, setWebsocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const reconnectInterval = useRef(null);
  const user = localStorage.getItem("userId");
  // const ringtoneElement = document.getElementById("playRingtone");
  // const ringtone = new Audio(
  //   "https://media.plivo.com/v1/Account/MAOGE4ODRIZTGZYTYZMD/Recording/72945708-0263-4632-8f42-28319aa60bcc.mp3"
  // );
  useEffect(() => {
    token && setAuth(token);
  }, [token, auth, profile]);

  const fetchProfile = async () => {
    try {
      await dispatch(getProfile());
    } catch (err) {
      console.log(err.message);
      if (
        err.message.toLowerCase() === "USER-DOES-NOT-EXISTS".toLowerCase() ||
        err.message.toLowerCase() === "TOKEN-EXPIRED".toLowerCase() ||
        err.message.toLowerCase() === "VALIDATE-TOKEN-ERROR".toLowerCase()
      ) {
        localStorage.removeItem("auth");
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem("phone");
        localStorage.removeItem("company");
        localStorage.removeItem("email");
        localStorage.removeItem("image");
        localStorage.removeItem("logo");
        localStorage.removeItem("userId");
        localStorage.removeItem("time_zone");
        localStorage.removeItem("verify_email");
        localStorage.removeItem("voice_id");
        localStorage.removeItem("page");
        localStorage.removeItem("pagecount");
        localStorage.removeItem("screen");
        // dispatch(removeProfile());
        localStorage.removeItem("isVerified");
        navigate("/");
      } else {
        toast.error("Something Went Wrong Please Try After Sometime");
      }
      setFetching(true);
    }
  };
  useEffect(() => {
    if (token) {
      setTimeout(() => {
        fetchProfile();
      }, 1000);
    }
  }, []);
  useEffect(() => {
    if (token) {
      if (location.pathname === "/register") {
        navigate("/");
      }
    }
  }, []);
  useEffect(() => {
    if (token && profile) {
      if (location.pathname === "/") {
        profile.role === "user" && navigate("/settings/profile");
      }
    }
  }, [profile]);

  useEffect(() => {
    // if (profile && profile.credits && profile.credit < 1) {
    if (token) {
      const ID = localStorage.getItem("voice_id");
      // const params = new Proxy(new URLSearchParams(window.location.search), {
      //   get: (searchParams, prop) => searchParams.get(prop),
      // });
      // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
      // let ID = params.id; // "some_value"
      // console.log("ID", ID);
      var options = {
        debug: "DEBUG",
        permOnClick: true,
        enableTracking: true,
        closeProtection: true,
        maxAverageBitrate: 48000,
        allowMultipleIncomingCalls: true,
      };
      const plivo = new Plivo(options);
      if (!plivo.client.isLoggedIn) {
        // console.log(plivo.client);
        //lwcalls051016347221159
        // let ID = "Aug366068995801632995280";
        // let pwd = "Shadow@123";
        // plivo.client.login("Aug366068995801632995280", "Shadow@123");
        plivo.client.login(ID, "LeadsWatch@123");
      }

      plivoRef.current = plivo;

      function onLogin() {
        console.log("login successfull");
      }

      function onLogout() {
        console.log("logout successfull");
      }

      function onLoginFailed() {
        console.log("login failed");
      }

      function onCallRemoteRinging(callInfo) {
        console.log("call remote ringing", callInfo);
        setOutGoingCall(callInfo);
      }

      function onIncomingCallCanceled(callInfo) {
        console.log("incoming call canceled");
        setOutGoingCall(null);
        setCalls((calls) => {
          return calls.filter((call) => call.callUUID !== callInfo.callUUID);
        });
        console.log(
          "activeCall",
          activeCall,
          callInfo.callUUID,
          activeCall.current &&
            activeCall.current.callUUID === callInfo.callUUID
        );
        if (
          activeCall.current &&
          activeCall.current.callUUID === callInfo.callUUID
        ) {
          // setActiveCall(null);
          activeCall.current = null;
        }

        setRefresh(!refresh);
      }

      function onIncomingCallIgnored(callInfo) {
        console.log("incoming call ignored");
        setOutGoingCall(null);
        setCalls((calls) => {
          return calls.filter((call) => call.callUUID !== callInfo.callUUID);
        });
        console.log("activeCall", activeCall, callInfo.callUUID);
        if (
          activeCall.current &&
          activeCall.current.callUUID === callInfo.callUUID
        ) {
          // setActiveCall(null);
          activeCall.current = null;
        }

        setRefresh(!refresh);
        console.log("activeCall", activeCall);
      }

      function onCallFailed(cause, callInfo) {
        console.log("call failed", cause, callInfo);
        setOutGoingCall(null);
        setClose(true);
        setCalls((calls) => {
          return calls.filter((call) => call.callUUID !== callInfo.callUUID);
        });
        console.log("activeCall", activeCall, callInfo.callUUID);
        if (
          activeCall.current &&
          activeCall.current.callUUID === callInfo.callUUID
        ) {
          // setActiveCall(null);
          activeCall.current = null;
        }

        setRefresh(!refresh);
      }

      function onCallAnswered(callInfo) {
        console.log("call answered", callInfo);
        if (activeCall.current) {
          if (activeCall.current.callUUID === callInfo.callUUID) {
            activeCall.current = { ...activeCall.current, ...callInfo };
            setCalls((calls) => {
              return calls.map((call) => {
                if (call.callUUID === callInfo.callUUID) {
                  return { ...call, ...callInfo };
                }
                return call;
              });
            });
          } else {
            activeCall.current = { ...callInfo };
            setCalls((calls) => {
              return calls.map((call) => {
                if (call.callUUID === callInfo.callUUID) {
                  return { ...call, ...callInfo };
                }
                return call;
              });
            });
          }
        }
        if (callInfo.direction === "outgoing") {
          setOutGoingCall(callInfo);
        }

        setRefresh(!refresh);
      }

      function onCallterminated(info) {
        // console.log("call terminated", callInfo);
        if (activeCall.current) {
          setCalls((calls) => {
            return calls.filter(
              (call) => call.callUUID !== activeCall.current.callUUID
            );
          });
          activeCall.current = null;
          setcallsModalisOpen(false);
        }
        setClose(true);
        setTimeout(() => {
          setOutGoingCall(null);
        }, [2000]);

        setRefresh(!refresh);
      }

      function onMediaConnected() {
        plivoRef.current.client.setRingTone(false);
        console.log("media connected");
      }

      function onCallTerminated(callInfo) {
        setOutGoingCall(null);
        console.log("call terminated", callInfo);
      }

      function onCalling(callInfo) {
        console.log("on calling");
      }

      function onIncomingCall(callerName, extraHeaders, callInfo, caller_Name) {
        // const incomingCall = callInfo.call;
        // callInfo.mute();

        // Mute the incoming call
        // incomingCall.mute();
        // var lastCallid = plivo.client.getLastCallUUID();
        // console.log("lastCallid", lastCallid, callInfo, callInfo.callUUID);
        console.info(
          "onIncomingCall : ",
          callerName,
          extraHeaders,
          callInfo,
          caller_Name
        );

        setCallInfo(callInfo);

        if (!activeCall.current) {
          // setActiveCall({ ...callInfo, callerId: caller_Name });
          activeCall.current = { ...callInfo, callerId: caller_Name };
          console.log("activeCall", activeCall.current);
        }
        if (activeCall.current.callUUID !== callInfo.callUUID) {
          setAllCalls([...allCalls, { ...callInfo, callerId: caller_Name }]);
        }
        setCalls((calls) => {
          if (!calls.find((call) => call.callUUID === callInfo.callUUID)) {
            return [...calls, { ...callInfo, callerId: caller_Name }];
          }
          return calls;
        });
        setRefresh(!refresh);

        if (profile && profile.credits && parseFloat(profile.credits) < 1) {
          plivoRef.current.client.reject(callInfo.callUUID);
          toast.info(
            `you have no credits left to take the call plaese recharge to continue using the service`
          );
        }
      }

      function onMediaPermission() {
        console.log("media permission");
      }

      function mediaMetrics() {
        console.log("media metrics");
      }

      function onConnectionChange(info) {
        console.log("connection change", info);
      }

      function onWebrtcNotSupported() {
        console.log("WebRTC not supported");
      }

      plivo.client.on("onWebrtcNotSupported", onWebrtcNotSupported);
      plivo.client.on("onLogin", onLogin);
      plivo.client.on("onLogout", onLogout);
      plivo.client.on("onLoginFailed", onLoginFailed);
      plivo.client.on("onCallRemoteRinging", onCallRemoteRinging);
      plivo.client.on("onIncomingCallCanceled", onIncomingCallCanceled);
      plivo.client.on("onIncomingCallIgnored", onIncomingCallIgnored);
      plivo.client.on("onCallFailed", onCallFailed);
      plivo.client.on("onCallAnswered", onCallAnswered);
      plivo.client.on("onCallTerminated", onCallterminated);
      plivo.client.on("onMediaConnected", onMediaConnected);
      plivo.client.on("onCallTerminated", onCallTerminated);
      plivo.client.on("onCalling", onCalling);
      plivo.client.on("onIncomingCall", onIncomingCall);
      plivo.client.on("onMediaPermission", onMediaPermission);
      plivo.client.on("mediaMetrics", mediaMetrics);
      plivo.client.on("onConnectionChange", onConnectionChange);
      // }
    }
  }, [profile]);

  const connectWebSocket = () => {
    const url = "wss://real.leadswatch.com/ws?clientID=app-" + global.clientID;
    const ws = new WebSocket(url);

    ws.onopen = () => {
      console.log("WebSocket open");
      setIsConnected(true);
      clearInterval(reconnectInterval.current);
      reconnectInterval.current = null;
      console.log("WebSocket connected");
    };

    ws.onclose = () => {
      setIsConnected(false);
      scheduleReconnect();
    };

    ws.onerror = (error) => {
      if (!reconnectInterval.current) {
        console.error("WebSocket error:", error);
      }
    };

    ws.onmessage = (event) => {
      console.log("Received message:", event.data);
      const data = JSON.parse(event.data);
      console.log("on message data", data);

      if (data.data.type === "new:lead") {
        const lead = data.data.payload;
        console.log("lead", lead);

        if (lead) {
          const reqData = lead.map((val) => {
            return {
              _id: val._id,
              response_id: val.response_id,
              buyer: val.buyer_name,
              publisher: val.publisher_name,
              campaign: val.campaign_name,
              created: val.created,
              buyer_response: val.buyer_response,
              system_response: val.system_response,
              cost: val.cost,
              price: val.price,
              profit: val.price - val.cost === 0 ? "0" : val.price - val.cost,
              email: val.details
                ? val.details.find((item) => item.name === "Email")?.value
                : null,
              phone: val.details
                ? val.details.find((item) => item.name === "Phone")?.value
                : null,
            };
          });
          console.log(reqData);
          setNewLeads((prev) => prev + lead.length);
          setDisplayLeads((prev) => [...reqData, ...prev]);
        }
      }
      if (data.data.type === "form:fields") {
        const fields = data.data.payload;
        setFormFields(fields);
      }

      if (data.data.type === "credit:limit") {
        const res = data.data.payload;

        toast.info(
          `your credit limit is ${res} which is below the threshold limit `
        );
      }

      // Handle received message
    };
    // setSocket(ws);
    setWebsocket(ws);
  };

  const scheduleReconnect = () => {
    if (!reconnectInterval.current) {
      console.log("WebSocket disconnected");
      reconnectInterval.current = setInterval(connectWebSocket, 5000); // Retry connection every 5 seconds
    }
  };

  const disconnectWebSocket = () => {
    if (websocket) {
      websocket.close();
      setWebsocket(null);
      setIsConnected(false);
      clearInterval(reconnectInterval.current);
      reconnectInterval.current = null;
    }
  };

  useEffect(() => {
    if (user) {
      global.clientID = user;
      connectWebSocket();
    }

    return () => {
      disconnectWebSocket();
    };
  }, []);

  useEffect(() => {
    if (isConnected) {
      console.log("Websocket Connected");
    } else {
      console.log("Websocket Not connected");
    }
  }, [isConnected]);

  useEffect(() => {
    if (isVerified && !token) {
      navigate("/settings/profile");
    }
  }, [isVerified]);
  return (
    <>
      {token || isVerified ? (
        profile ? (
          profile && profile.subscribed ? (
            profile.subscribed.plan_type === "Free" &&
            profile.subscribed.status !== "active" ? (
              <>
                {location.pathname.includes("404") ||
                location.pathname.includes("delete-account") ? null : (
                  <LeadsWatchTopBar
                    isOpen={isOpen}
                    setisOpen={setisOpen}
                    image={profile.image}
                  />
                )}
                <Routes>
                  <Route
                    path="/subscription/details/:id"
                    element={<SubscriptionDetails />}
                  />
                  <Route exact path="/" element={<Settings />}>
                    <Route exact index element={<Profile />} />
                    <Route
                      exact
                      path="/settings/credits"
                      element={<Credits />}
                    />
                    <Route
                      exact
                      path="/settings/invoice"
                      element={<Invoice />}
                    />
                    <Route exact path="/settings/team" element={<Team />} />
                    <Route
                      exact
                      path="/settings/Profile"
                      element={<Profile />}
                    />
                    <Route
                      exact
                      path="/settings/subscription"
                      element={<Subscription />}
                    />
                    <Route
                      exact
                      path="/settings/buyerCard"
                      element={<BuyerCards />}
                    />
                    <Route
                      exact
                      path="/settings/payPublisher"
                      element={<PayPublisher />}
                    />
                  </Route>
                  <Route path="*" element={<PageNotFound />}>
                    {" "}
                  </Route>
                  <Route path="/404" element={<NotFound />} />
                  <Route path="/delete-account" element={<DeleteAccount />} />
                </Routes>
              </>
            ) : (
              // <Router>
              <>
                {location.pathname.includes("404") ||
                location.pathname.includes("delete-account") ? null : (
                  <LeadsWatchTopBar
                    isOpen={isOpen}
                    setisOpen={setisOpen}
                    image={profile.image}
                  />
                )}
                <HStack>
                  {location.pathname.includes("404") ||
                  location.pathname.includes("delete-account") ? null : (
                    <LeadsWatchSideBarTrial
                      isOpen1={isOpen}
                      setisOpen={setisOpen}
                    />
                  )}
                  <Routes>
                    <Route
                      exact
                      path="/leads/:type"
                      element={
                        <Leads
                          displayLeads={displayLeads}
                          setDisplayLeads={setDisplayLeads}
                          newLeads={newLeads}
                          setNewLeads={setNewLeads}
                          isOpen={isOpen}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/"
                      element={
                        // <Suspense fallback={<HStack>Loading....</HStack>}>
                        <Dashboard isOpen={isOpen} />
                        // </Suspense>
                      }
                    />
                    <Route
                      exact
                      path="/leads/campaign/:id/:type"
                      element={
                        <Leads
                          displayLeads={displayLeads}
                          setDisplayLeads={setDisplayLeads}
                          newLeads={newLeads}
                          setNewLeads={setNewLeads}
                          isOpen={isOpen}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/campaigns/:type"
                      element={<Campaigns isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/campaigns/createCampaign/:type"
                      element={
                        <CreateCampaign name={"create"} isOpen={isOpen} />
                      }
                    />
                    <Route
                      exact
                      path="/campaigns/editCampaign/:id/:type"
                      element={<CreateCampaign name={"edit"} isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/buyers"
                      element={<Buyers isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/buyers/groups"
                      element={<Groups isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/buyers/buyerRoutes/:bId/:type"
                      element={<BuyerRoutes isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/buyers/buyerRoutes/:id/create/:type"
                      element={<CreateBuyer name={"create"} isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/buyers/editBuyerRoute/:id/:bId/:type"
                      element={<CreateBuyer name={"edit"} isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/verticals"
                      element={<Verticals isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/verticals/viewVertical/:id"
                      element={<ViewVertical isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/publishers"
                      element={<Publishers isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/operations"
                      element={
                        <Operations
                          displayLeads={displayLeads}
                          setDisplayLeads={setDisplayLeads}
                          newLeads={newLeads}
                          setNewLeads={setNewLeads}
                          isOpen={isOpen}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/subscription"
                      element={<Subscription isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/subscription/details/:id"
                      element={<SubscriptionDetails isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/calls"
                      element={
                        <Calls
                          plivoRef={plivoRef}
                          outGoingCall={outGoingCall}
                          setOutGoingCall={setOutGoingCall}
                          refresh={refresh}
                          close={close}
                          isOpen1={isOpen}
                        />
                      }
                    >
                      <Route exact path="ivr" element={<Ivr isOpen={isOpen} />}>
                        <Route
                          exact
                          path="routingPlans"
                          element={<RoutingPlans isOpen={isOpen} />}
                        />
                        <Route
                          exact
                          path="createIvrTree"
                          element={<CreateIvrTree isOpen={isOpen} />}
                        />
                        <Route
                          exact
                          path="manageIvrTrees"
                          element={<ManageIvrTrees isOpen={isOpen} />}
                        />
                        <Route
                          exact
                          path="manageMedia"
                          element={<ManageMedia isOpen={isOpen} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="numbers"
                        element={<Numbers isOpen={isOpen} />}
                      >
                        <Route
                          path="manageNumbers"
                          element={<ManageNumbers isOpen={isOpen} />}
                        />
                        <Route
                          path="managePools"
                          element={<ManagePools isOpen={isOpen} />}
                        />
                        <Route
                          path="manageBlockedNumbers"
                          element={<ManageBlockedNumbers isOpen={isOpen} />}
                        />
                      </Route>
                      <Route
                        exact
                        path="incomingCalls"
                        element={
                          <IncomigCalls
                            formFields={formFields}
                            activeCall={activeCall.current}
                            setFormFields={setFormFields}
                            onGoing={
                              activeCall.current &&
                              activeCall.current.state === "answered"
                                ? true
                                : false
                            }
                            handleHangUp={() => {
                              plivoRef.current.client.hangup();
                            }}
                            plivoRef={plivoRef}
                            isOpen1={isOpen}
                            handleMute={() => plivoRef.current.client.mute()}
                            handleUnMute={() =>
                              plivoRef.current.client.unmute()
                            }
                          />
                        }
                      />
                      <Route
                        exact
                        path="makeCalls"
                        element={
                          <MakeCalls
                            plivoRef={plivoRef}
                            outGoingCall={outGoingCall}
                            refresh={refresh}
                            isOpen={isOpen}
                          />
                        }
                      />
                      <Route
                        exact
                        path="callLogs"
                        element={<CallLogs isOpen={isOpen} />}
                      />
                    </Route>
                    <Route
                      exact
                      path="/settings"
                      element={<Settings isOpen={isOpen} />}
                    >
                      <Route
                        exact
                        path="team"
                        element={<Team isOpen={isOpen} />}
                      />
                      <Route
                        exact
                        path="Profile"
                        element={<Profile isOpen={isOpen} />}
                      />
                      <Route
                        exact
                        path="subscription"
                        element={<Subscription isOpen={isOpen} />}
                      />
                      <Route
                        exact
                        path="/settings/invoice"
                        element={<Invoice isOpen={isOpen} />}
                      />
                      <Route exact path="buyerCard" element={<BuyerCards />} />
                      <Route
                        exact
                        path="payPublisher"
                        element={<PayPublisher isOpen={isOpen} />}
                      />
                      <Route
                        exact
                        path="credits"
                        element={<Credits isOpen={isOpen} />}
                      />
                    </Route>
                    <Route
                      exact
                      path="/reports"
                      element={<Index isOpen={isOpen} />}
                    >
                      <Route
                        exact
                        path="verticals"
                        element={<VerticalsReport isOpen={isOpen} />}
                      />

                      <Route
                        exact
                        path="leads"
                        element={<LeadsReport isOpen={isOpen} />}
                      />
                      <Route
                        exact
                        path="ivr"
                        element={<IVR isOpen={isOpen} />}
                      />
                      <Route
                        exact
                        path="ppc"
                        element={<PPC isOpen={isOpen} />}
                      />
                      <Route
                        exact
                        path="publishers"
                        element={<PublishersReport isOpen={isOpen} />}
                      />
                      <Route
                        exact
                        path="campaigns"
                        element={<CampaignsReport isOpen={isOpen} />}
                      />
                      <Route
                        exact
                        path="buyers"
                        element={<BuyersReport isOpen={isOpen} />}
                      />
                      <Route
                        exact
                        path="buyerRoutes"
                        element={<BuyerRoutesReport isOpen={isOpen} />}
                      />
                      <Route
                        exact
                        path="verticalFields"
                        element={<VerticalFields isOpen={isOpen} />}
                      />
                    </Route>
                    {/* crm */}
                    <Route
                      path="/crm/pipelines"
                      element={<PipeLines isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/crm/campaigns"
                      element={<CrmCampaigns isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/crm/templates"
                      element={<CrmTemplates isOpen={isOpen} />}
                    />

                    <Route
                      exact
                      path="/crm/signatures"
                      element={<CrmSignatures isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path="/crm/contacts"
                      element={<CrmContacts isOpen={isOpen} />}
                    />
                    <Route
                      exact
                      path={"/crm/contacts/view/:id"}
                      element={<ContactDetails isOpen={isOpen} />}
                    />
                    <Route
                      path="/crm/smtps"
                      element={<Smtps isOpen={isOpen} />}
                    />
                    {/* <Route path="/pipelines/:id" element={<PipeLines />} /> */}
                    <Route
                      path="/invoices/:type"
                      element={<Invoices isOpen={isOpen} />}
                    />
                    <Route path="*" element={<PageNotFound />}>
                      {" "}
                    </Route>
                    <Route path="/404" element={<NotFound />} />
                    <Route path="/delete-account" element={<DeleteAccount />} />
                  </Routes>
                </HStack>
              </>
            )
          ) : (
            <>
              {location.pathname.includes("404") ||
              location.pathname.includes("delete-account") ? null : (
                <LeadsWatchTopBar
                  isOpen={isOpen}
                  setisOpen={setisOpen}
                  image={profile.image}
                />
              )}

              <Routes>
                <Route
                  path="/subscription/details/:id"
                  element={<SubscriptionDetails />}
                />
                <Route exact path="/" element={<Settings />}>
                  <Route exact index element={<Profile />} />
                  <Route exact path="/settings/credits" element={<Credits />} />
                  <Route exact path="/settings/team" element={<Team />} />
                  <Route exact path="/settings/Profile" element={<Profile />} />
                  <Route exact path="/settings/invoice" element={<Invoice />} />
                  <Route
                    exact
                    path="/settings/subscription"
                    element={<Subscription />}
                  />
                  <Route
                    exact
                    path="/settings/buyerCard"
                    element={<BuyerCards />}
                  />
                  <Route
                    exact
                    path="/settings/payPublisher"
                    element={<PayPublisher />}
                  />
                </Route>
                <Route path="*" element={<PageNotFound />}>
                  {" "}
                </Route>
                <Route path="/404" element={<NotFound />} />
                <Route path="/delete-account" element={<DeleteAccount />} />
              </Routes>
            </>
          )
        ) : token ? (
          !fetching ? (
            <HStack
              justifyContent="center"
              alignItems={"center"}
              width="100%"
              height={ah(768)}
            >
              {/* <Spinner size="lg" color="#2230F8" /> */}
              <Splash />
            </HStack>
          ) : (
            <HStack
              justifyContent="center"
              alignItems={"center"}
              width="100%"
              height={ah(768)}
            >
              <Text
                fontFamily="RobotoMedium"
                fontSize={aw(30)}
                color="#0000001A"
              >
                Failed to Fetch Data
              </Text>
            </HStack>
          )
        ) : (
          <>
            {location.pathname.includes("404") ? null : (
              <LeadsWatchTopBar
                isOpen={isOpen}
                setisOpen={setisOpen}
                image={profile && profile.image}
              />
            )}
            <Routes>
              <Route exact path="/settings" element={<Settings />}>
                <Route exact path="Profile" element={<Profile />} />
              </Route>
            </Routes>
          </>
        )
      ) : (
        <>
          {/* <LoginNew /> */}
          <Suspense
            fallback={
              <HStack
                justifyContent="center"
                alignItems={"center"}
                width="100%"
                height={ah(768)}
              >
                <Splash />
              </HStack>
            }
          >
            <LoginNew />
          </Suspense>
        </>
      )}
      <Flex
        style={{
          position: "absolute",
          top: ah(20),
          left: aw(170),
          alignItems: "flex-end",
          justifyContent: "center",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: ah(10),
        }}
      >
        {allCalls.map((call) => (
          <HStack
            key={call.callUUID}
            style={{
              backgroundColor: "#EBF4FF",
              width: aw(900),
              // marginBottom: ah(10),
              height: ah(40),
              justifyContent: "space-between",
              alignItems: "center",
              paddingHorizontal: aw(10),
              borderBottomWidth: aw(2),
              borderBottomColor: "#66B3FF",
            }}
          >
            <HStack alignItems={"center"}>
              <LeadsWatchIncomingCallIcon width={aw(15)} height={aw(15)} />
              <Text fontFamily="RobotoMedium" fontSize={aw(14)}>
                {" "}
                Incoming Call from{" "}
              </Text>
              <Text fontFamily="RobotoRegular" fontSize={aw(14)}>
                {call.src}
              </Text>
            </HStack>
            <HStack space={aw(10)} alignItems={"center"}>
              <LeadsWatchButton
                fontSize={aw(12)}
                label="Decline"
                style={{ width: aw(70), height: ah(20) }}
                color="#FFFFFF"
                bg="#D61F1F"
                onPress={() => {
                  plivoRef.current.client.reject(call.callUUID);
                  setAllCalls(allCalls.filter((val) => val.src !== call.src));
                }}
              ></LeadsWatchButton>
              <LeadsWatchButton
                fontSize={aw(12)}
                style={{ width: aw(70), height: ah(20) }}
                color="#FFFFFF"
                bg="#0263E0"
                onPress={() => {
                  plivoRef.current.client.answer(call.callUUID);
                  activeCall.current = { ...call };
                  setAllCalls(allCalls.filter((val) => val.src !== call.src));
                }}
                label="Answer"
              ></LeadsWatchButton>
            </HStack>
          </HStack>
        ))}
      </Flex>
      <LeadsWatchAnswerModal
        isOpen={
          activeCall.current && activeCall.current.state === "ringing"
            ? true
            : false
        }
        callInfo={activeCall.current}
        setIsOpen={setcallsModalisOpen}
        handleAnswer={() => {
          plivoRef.current.client.answer(activeCall.current.callUUID);
          navigate("/calls/incomingCalls");
        }}
        handleHangup={() => {
          plivoRef.current.client.reject(activeCall.current.callUUID);
          if (allCalls.length) {
            activeCall.current = allCalls[0];
          }
          setAllCalls(
            allCalls.filter(
              (val) => val.callUUID !== activeCall.current.callUUID
            )
          );
        }}
      />
    </>
    // <Splash />
  );
}

export default App;

export const PageNotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/404", { replace: true });
  }, []);
  return (
    <VStack
      justifyContent={"center"}
      alignItems={"center"}
      width="100%"
      height={ah(768)}
    >
      <Text fontFamily="RobotoMedium" fontSize={aw(50)} color="#0000001A">
        404
      </Text>
      <Text fontFamily="RobotoMedium" fontSize={aw(30)} color="#0000001A">
        Page not found
      </Text>
    </VStack>
  );
};
export const NotFound = () => {
  return (
    <VStack
      justifyContent={"center"}
      alignItems={"center"}
      width="100%"
      height={ah(768)}
    >
      <Text fontFamily="RobotoMedium" fontSize={aw(50)} color="#0000001A">
        404
      </Text>
      <Text fontFamily="RobotoMedium" fontSize={aw(30)} color="#0000001A">
        Page not found
      </Text>
    </VStack>
  );
};
