import * as React from "react";
import { aw, ah } from "../../utils";
const SVGComponent = (props) => (
  <svg
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.492 6.059H1.382a.484.484 0 0 1-.484-.485V3.801a2.422 2.422 0 0 1 2.419-2.418h14.24a2.422 2.422 0 0 1 2.42 2.418v1.773a.484.484 0 0 1-.485.485ZM1.867 5.094h17.14V3.805c0-.8-.65-1.45-1.45-1.45H3.318c-.8 0-1.45.65-1.45 1.45v1.289Z"
      fill={
        props.disabled
          ? "#C1C1C1"
          : props.hover === "true"
          ? "#2230F8"
          : "#212121"
      }
    />
    <path
      d="M15.21 20.587H7.071a.484.484 0 0 1-.484-.484V5.578c0-.268.216-.484.484-.484h12.421c.268 0 .485.216.485.484v8.052a.484.484 0 0 1-.485.485h-.701l-.193.792a.48.48 0 0 1-.335.35 3.567 3.567 0 0 0-.693.284.487.487 0 0 1-.48-.012l-.686-.421-.816.811.422.69c.09.145.094.33.011.48a3.65 3.65 0 0 0-.287.697.48.48 0 0 1-.35.331l-.785.185v1.155l.73.177a.486.486 0 0 1 .365.528.489.489 0 0 1-.484.425Zm-7.658-.969h6.078V17.92a.48.48 0 0 1 .37-.469l.895-.212a5.29 5.29 0 0 1 .146-.359l-.481-.788a.483.483 0 0 1 .07-.594l1.356-1.348a.488.488 0 0 1 .595-.07l.78.48c.118-.055.236-.102.358-.146l.217-.898a.484.484 0 0 1 .469-.37h.599V6.063H7.552v13.555Z"
      fill={
        props.disabled
          ? "#C1C1C1"
          : props.hover === "true"
          ? "#2230F8"
          : "#212121"
      }
    />
    <path
      d="M7.071 20.587H3.317A2.422 2.422 0 0 1 .898 18.17V5.579c0-.269.217-.485.485-.485h5.684c.268 0 .485.216.485.484v14.525a.483.483 0 0 1-.48.484ZM1.867 6.063v12.106c0 .8.65 1.45 1.45 1.45h3.27V6.062h-4.72Zm2.439-1.856a.485.485 0 1 0 0-.969.485.485 0 0 0 0 .97Zm1.627 0a.485.485 0 1 0 0-.969.485.485 0 0 0 0 .97Zm1.623 0a.485.485 0 1 0 0-.969.485.485 0 0 0 0 .97Zm9.924 0h-5.685a.484.484 0 1 1 0-.969h5.684a.484.484 0 1 1 0 .97Z"
      fill={
        props.disabled
          ? "#C1C1C1"
          : props.hover === "true"
          ? "#2230F8"
          : "#212121"
      }
    />
    <path
      d="M5.295 8.04H3.159a.484.484 0 1 1 0-.969h2.136a.484.484 0 1 1 0 .97Zm0 1.797H3.159a.484.484 0 1 1 0-.97h2.136a.484.484 0 1 1 0 .97Zm-.965 1.8H3.16a.484.484 0 1 1 0-.97h1.17a.484.484 0 1 1 0 .97Zm-.458 1.796H3.16a.484.484 0 1 1 0-.969h.713a.484.484 0 1 1 0 .97Zm16.448 11.18h-1.911a.48.48 0 0 1-.469-.37l-.217-.898a3.52 3.52 0 0 1-.354-.15l-.78.48a.483.483 0 0 1-.595-.066l-1.355-1.348a.482.482 0 0 1-.071-.594l.48-.788a4.029 4.029 0 0 1-.145-.359l-.895-.216a.484.484 0 0 1-.37-.47V17.92a.48.48 0 0 1 .37-.469l.895-.212a5.29 5.29 0 0 1 .146-.359l-.481-.788a.483.483 0 0 1 .07-.594l1.356-1.348a.488.488 0 0 1 .595-.07l.78.48c.118-.055.236-.102.358-.146l.217-.898a.484.484 0 0 1 .469-.37h1.91a.48.48 0 0 1 .47.37l.216.898c.118.044.24.09.358.15l.788-.48a.489.489 0 0 1 .595.07l1.347 1.348a.488.488 0 0 1 .071.594l-.48.788c.055.114.102.237.145.359l.899.213c.216.05.37.248.37.468v1.915a.48.48 0 0 1-.37.469l-.899.216c-.043.122-.094.24-.145.359l.48.788a.489.489 0 0 1-.07.594l-1.348 1.348a.488.488 0 0 1-.595.07l-.784-.484a4.08 4.08 0 0 1-.358.15l-.217.894a.5.5 0 0 1-.477.37Zm-1.533-.965h1.15l.19-.788a.484.484 0 0 1 .33-.35c.237-.071.465-.166.697-.292a.487.487 0 0 1 .481.012l.69.425.811-.811-.421-.69a.477.477 0 0 1-.012-.48c.118-.22.213-.445.287-.697a.474.474 0 0 1 .35-.331l.793-.19v-1.15l-.788-.189a.475.475 0 0 1-.35-.33 3.754 3.754 0 0 0-.288-.698.477.477 0 0 1 .011-.48l.422-.69-.812-.811-.689.421a.477.477 0 0 1-.48.012 3.354 3.354 0 0 0-.69-.284.478.478 0 0 1-.339-.35l-.193-.792h-1.15l-.193.792a.48.48 0 0 1-.335.35 3.564 3.564 0 0 0-.693.284.486.486 0 0 1-.48-.012l-.686-.421-.816.811.422.69c.09.145.094.33.012.48a3.65 3.65 0 0 0-.288.697.48.48 0 0 1-.35.331l-.785.185v1.155l.788.193c.166.04.3.165.347.33.075.249.17.477.288.698a.477.477 0 0 1-.012.48l-.422.69.816.811.685-.421a.494.494 0 0 1 .485-.016c.224.122.46.22.7.292a.495.495 0 0 1 .332.35l.185.784Z"
      fill={
        props.disabled
          ? "#C1C1C1"
          : props.hover === "true"
          ? "#2230F8"
          : "#212121"
      }
    />
    <path
      d="M19.366 21.395a2.515 2.515 0 0 1-2.513-2.514 2.515 2.515 0 0 1 2.513-2.513 2.515 2.515 0 0 1 2.514 2.514 2.515 2.515 0 0 1-2.514 2.513Zm0-4.062a1.548 1.548 0 1 0 0 3.097 1.548 1.548 0 0 0 0-3.097Zm-9.316-5.436a1.912 1.912 0 0 1-1.911-1.91c0-1.052.859-1.911 1.91-1.911.524 0 1.013.209 1.38.587.184.193.18.5-.013.685a.48.48 0 0 1-.681-.012.933.933 0 0 0-.682-.291.948.948 0 0 0-.945.945.948.948 0 0 0 1.568.713.481.481 0 0 1 .681.048c.174.2.154.508-.047.681-.354.3-.8.465-1.26.465Zm2.576.224a.484.484 0 0 1-.485-.484V8.391c0-.268.217-.485.485-.485h.827a1.258 1.258 0 0 1 .595 2.364c.362.343.658.796.662 1.367a.487.487 0 0 1-.48.489h-.005a.486.486 0 0 1-.484-.481c-.004-.347-.308-.658-.635-.89v.886c0 .264-.216.48-.48.48Zm.48-2.67h.347a.29.29 0 0 0 .288-.288.29.29 0 0 0-.288-.288h-.347v.576Zm4.834 2.127a.484.484 0 0 1-.485-.485V9.947l-.216.217a.483.483 0 0 1-.686 0l-.323-.323v1.252a.484.484 0 1 1-.969 0V8.675a.48.48 0 0 1 .3-.446.479.479 0 0 1 .527.107l.808.807.701-.7a.484.484 0 0 1 .827.339v2.315c0 .264-.216.48-.484.48Z"
      fill={
        props.disabled
          ? "#C1C1C1"
          : props.hover === "true"
          ? "#2230F8"
          : "#212121"
      }
    />
  </svg>
);
export default SVGComponent;
