import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllVerticals = (page, limit, sort, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/verticals?page=${page}&limit=${limit}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sort: sort, search: search }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allVerticals = resData.data;
        dispatch({ type: events.GET_ALL_VERTICALS, data: allVerticals });
        return allVerticals ? allVerticals : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const addVertical = (name, desc) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/verticals/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: name,
          desc: desc,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const newVertical = resData.data;
        dispatch({ type: events.ADD_VERTICAL, data: newVertical });
        return newVertical ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const editVerticalData = (id, name, desc) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/verticals/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: name,
          desc: desc,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const editedVertical = resData.data;
        dispatch({ type: events.EDIT_VERTICAL, data: editedVertical });
        return editedVertical ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const deleteVertical = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/verticals/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();
        console.log("Response Error", resError);
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        console.log(resData);
        const deletedVertical = resData.data;
        dispatch({ type: events.DELETE_VERTICAL, data: deletedVertical });
        return deletedVertical ? true : false;
      }
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };
};
export const viewVertical = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/verticals/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const verticalDetails = resData.data;
        dispatch({ type: events.GET_SINGLE_VERTICAL, data: verticalDetails });
        return verticalDetails ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const createVerticalField = (
  name,
  desc,
  datatype,
  format_type,
  defaultValue,
  mandatory,
  id
) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/verticals/${id}/verticalfields/create`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: name,
            desc: desc,
            datatype: datatype,
            format_type: format_type,
            default: defaultValue,
            mandatory: mandatory,
          }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const newVerticalField = resData.data;
        dispatch({ type: events.ADD_VERTICAL_FIELD, data: newVerticalField });
        return newVerticalField ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const createVerticalFieldFromDefaultFields = (allIds, id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/verticals/${id}/verticalfields/create`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(allIds),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const newVerticalField = resData.data;
        dispatch({
          type: events.CREATE_VERTICAL_FIELD_DEFAULT,
          data: newVerticalField,
        });
        return newVerticalField ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getAllVerticalFields = (page, limit, id, sort, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/verticals/${id}/verticalfields?page=${page}&limit=${limit}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sort: sort, search: search }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allVerticalFields = resData.data;
        dispatch({
          type: events.GET_ALL_VERTICAL_FIELDS,
          data: allVerticalFields,
        });
        return allVerticalFields ? allVerticalFields : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const editVerticalField = (
  name,
  desc,
  datatype,
  format_type,
  defaultValue,
  mandatory,
  vId,
  vfId,
  listData
) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/verticals/${vId}/verticalfields/${vfId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: name,
            desc: desc,
            datatype: datatype,
            format_type: format_type,
            default: defaultValue,
            mandatory: mandatory,
            listdata: listData,
          }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const editedField = resData.data;
        dispatch({
          type: events.EDIT_VERTICAL_FIELD,
          data: editedField,
        });
        return editedField ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const deleteVerticalField = (vId, vfId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/verticals/${vId}/verticalfields/${vfId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const deletedField = resData.data;
        dispatch({
          type: events.DELETE_VERTICAL_FIELD,
          data: deletedField,
        });
        return deletedField ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const chooseDefaultField = (page, limit, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/verticals/fields/default?page=${page}&limit=${limit}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ search: search }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const defaultFields = resData.data;
        dispatch({
          type: events.CHOOSE_DEFAULT_FIELD,
          data: defaultFields,
        });
        return defaultFields ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getVerticalFieldsByFormat = (page, limit, search, vfId) => {
  // if (type) {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/verticals/${vfId}/formatverticalfields?page=${page}&limit=${limit}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            search: search,
            // filters: { format_type: type },
          }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const verticalFields = resData.data;
        dispatch({
          type: events.GET_VERTICAL_FIELD_BY_FORMAT,
          data: verticalFields,
        });
        return verticalFields ? verticalFields : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
  // } else {
  //   return {
  //     type: events.GET_VERTICAL_FIELD_BY_FORMAT,
  //     payload: null,
  //   };
  // }
};
