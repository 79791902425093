import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  ScrollView,
  FlatList,
  Pressable,
  Flex,
  Switch,
} from "native-base";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
//utils
import { aw, ah } from "../../utils";
//components
import LeadsWatchCard from "../../components/LeadsWatchCard";
import LeadsWatchInput from "../../components/LeadsWatchInput";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";
//svg
import LeadsWatchEditIcon from "../../components/svg/LeadsWatchEditIcon";
import LeadsWatchDeleteIcon from "../../components/svg/LeadsWatchDeleteIcon";
import LeadsWatchCrossIcon from "../../components/svg/LeadsWatchCrossIcon";
import LeadsWatchApproveIcon from "../../components/svg/LeadsWatchApproveIcon";

const DataMappingForppc = (props) => {
  const [edit, setEdit] = useState({});
  const [toogle, setToogle] = useState(false);
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm({
    mode: "onSubmit",
  });
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
    getValues: getValues2,
  } = useForm({
    mode: "onSubmit",
  });
  const {
    buttonValue,
    editId,
    setEditId,
    setButtonValue,
    handleEditField,
    handleDelete,
    mapFields,
    setMapFields,
    isOpen,
  } = props;
  const handleMapFields = async (data) => {
    const newData = toogle
      ? {
          key: data.buyer_field,
          value: data.map_field,
          id: Date.now().toString(),
          default: 1,
        }
      : {
          key: data.buyer_field,
          map_field: data.map_field.value,
          id: Date.now().toString(),
          default: 0,
        };

    const exists = mapFields[`ping_${buttonValue}`].some(
      (val) => val.key.toLowerCase() === data.buyer_field.toLowerCase()
    );

    if (exists) {
      toast.error("Buyer field  already exists");
    } else {
      setMapFields({
        ...mapFields,
        [`ping_${buttonValue}`]: [newData, ...mapFields[`ping_${buttonValue}`]],
      });

      reset2({ map_field: "", buyer_field: "" });
    }
  };

  const handleEdit = (item) => {
    setEditId(item.id);
    if (item.default === 1) {
      setEdit({ edit_buyer_field: item.key, edit_map_field: item.value });
    } else {
      setEdit({
        edit_buyer_field: item.key,
        edit_map_field: {
          label:
            item.map_field === "bid_id"
              ? "Bid Id"
              : item.map_field === "url"
              ? "URL"
              : "Route Id",
          value: item.map_field,
        },
      });
    }
  };
  useEffect(() => {
    if (editId) {
      reset1(edit);
    } else {
      reset1({ edit_buyer_field: "", edit_map_field: "" });
    }
  }, [editId]);
  return (
    <Box marginTop={ah(30)}>
      <LeadsWatchCard
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        height={ah(3000)}
      >
        <VStack pb={6} width={"100%"} px={aw(15)}>
          <HStack
            width={"100%"}
            height={ah(50)}
            marginTop={ah(18)}
            justifyContent="space-between"
            alignItems={"baseline"}
          >
            <Text
              style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
              color="#121233"
            >
              Data Mapping
            </Text>
          </HStack>

          <>
            <VStack
              width={"100%"}
              borderRadius={"10px"}
              borderColor={"#D7D7D7"}
            >
              <HStack width={"100%"}>
                {["params", "body", "header", "auth"].map((val) => (
                  <Pressable
                    key={val}
                    width={"25%"}
                    height={ah(60)}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderWidth={buttonValue === val ? 1 : 0}
                    borderColor={"#D7D7D7"}
                    borderBottomWidth={buttonValue === val ? 0 : aw(1)}
                    borderTopLeftRadius={aw(10)}
                    borderTopRightRadius={aw(10)}
                    bg={buttonValue === val ? "#F9FAFB" : "#FFFFFF"}
                    onPress={() => setButtonValue(val)}
                  >
                    <Text
                      alignSelf={"center"}
                      style={{
                        fontFamily: "RobotoMedium",
                        fontSize: aw(18),
                      }}
                      color="#121233"
                      pl={1}
                    >
                      {val.charAt(0).toUpperCase() +
                        val.slice(1).replace("_", " ")}
                    </Text>
                  </Pressable>
                ))}
              </HStack>
              <HStack
                alignItems={"center"}
                style={{
                  height: ah(60),
                  background: "#F9FAFB 0% 0% no-repeat padding-box",
                  borderWidth: aw(1),
                  borderColor: "#D7D7D7",
                  borderTopWidth: 0,
                }}
              >
                {["Buyer Field", "Map Field", "Actions"].map((val) => {
                  return (
                    <HStack
                      width={aw(500)}
                      alignItems={"center"}
                      justifyContent="flex-start"
                      key={val}
                    >
                      {val === "Actions" ? (
                        <Text
                          width={aw(200)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          ml={aw(20)}
                          color="#121233"
                        >
                          {val}
                        </Text>
                      ) : (
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          ml={aw(20)}
                          color="#121233"
                        >
                          {val}
                        </Text>
                      )}
                    </HStack>
                  );
                })}
              </HStack>
              <ScrollView
                maxHeight={ah(200)}
                style={{
                  borderBottomRightRadius: aw(10),
                  borderBottomLeftRadius: aw(10),
                  borderbottomWidth: "1px solid #D7D7D7",
                  borderTopWidth: "0px",
                  borderColor: "#D7D7D7",
                  borderWidth: "1px",
                }}
              >
                <FlatList
                  ListEmptyComponent={
                    <HStack
                      justifyContent="center"
                      alignItems={"center"}
                      height={ah(100)}
                    >
                      <Text
                        color="black"
                        fontSize={aw(14)}
                        fontFamily={"RobotoRegular"}
                      >
                        No data found
                      </Text>
                    </HStack>
                  }
                  data={mapFields[`ping_${buttonValue}`]}
                  keyExtractor={(item, index) => index}
                  renderItem={({ item, index }) => (
                    <HStack
                      height={ah(50)}
                      style={{
                        borderTopWidth: index === 0 ? 0 : aw(1),
                        borderLeftWidth: "0px",
                        borderRightWidth: "0px",
                        borderBottomWidth: "0px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      {editId === item.id ? (
                        <HStack
                          width={aw(500)}
                          alignItems={"center"}
                          justifyContent="flex-start"
                          pl={aw(20)}
                        >
                          <LeadsWatchInput
                            control={control1}
                            name={"edit_buyer_field"}
                            style={{ width: aw(250), height: ah(40) }}
                            rules={{
                              required: {
                                value: true,
                              },
                            }}
                            invalid={errors1["edit_buyer_field"] ? true : false}
                            error={
                              errors1["edit_buyer_field"]
                                ? errors1["edit_buyer_field"].message
                                : ""
                            }
                          />
                        </HStack>
                      ) : (
                        <HStack
                          width={aw(500)}
                          alignItems={"center"}
                          justifyContent="flex-start"
                        >
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoRegular"}
                            ml={aw(20)}
                            color="#121233"
                          >
                            {item["key"]}
                          </Text>
                        </HStack>
                      )}
                      {editId === item.id ? (
                        item.default === 0 ? (
                          <HStack width={aw(500)} pt={1}>
                            <HStack width={aw(250)}>
                              <LeadsWatchAsyncSelect
                                required={true}
                                minHeight={34}
                                maxHeight={34}
                                minWidth={aw(250)}
                                defaultOptions={[
                                  { label: "Bid Id", value: "bid_id" },
                                  { label: "URL", value: "url" },
                                  { label: "Route Id", value: "route_id" },
                                ]}
                                control={control1}
                                placeholder="Select"
                                name={"edit_map_field"}
                                rules={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                invalid={
                                  errors1["edit_map_field"] ? true : false
                                }
                                error={
                                  errors1["edit_map_field"]
                                    ? errors1["edit_map_field"].message
                                    : ""
                                }
                                // invalid={true}
                              />
                            </HStack>
                          </HStack>
                        ) : (
                          <HStack
                            width={aw(500)}
                            alignItems={"center"}
                            justifyContent="flex-start"
                          >
                            <LeadsWatchInput
                              control={control1}
                              name={"edit_map_field"}
                              style={{
                                width: aw(250),
                                height: ah(40),
                              }}
                              rules={{
                                required: {
                                  value: true,
                                },
                              }}
                              invalid={errors1["edit_map_field"] ? true : false}
                              error={
                                errors1["edit_map_field"]
                                  ? errors1["edit_map_field"].message
                                  : ""
                              }
                            />
                          </HStack>
                        )
                      ) : (
                        <HStack
                          width={aw(500)}
                          alignItems={"center"}
                          justifyContent="flex-start"
                        >
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoRegular"}
                            ml={aw(20)}
                            color="#121233"
                          >
                            {/* {item["map_field"]} */}
                            {item.default === 0
                              ? item["map_field"] === "bid_id"
                                ? "Bid Id"
                                : item["map_field"] === "url"
                                ? "URL"
                                : "Route Id"
                              : item["value"]}
                          </Text>
                        </HStack>
                      )}
                      {editId === item.id ? (
                        <HStack
                          pl={5}
                          width={aw(500)}
                          alignItems={"center"}
                          justifyContent="flex-start"
                          space={aw(5)}
                        >
                          <Pressable onPress={handleSubmit1(handleEditField)}>
                            <LeadsWatchApproveIcon />
                          </Pressable>
                          <Pressable
                            onPress={() => {
                              setEditId("");
                            }}
                          >
                            <LeadsWatchCrossIcon />
                          </Pressable>
                        </HStack>
                      ) : (
                        <HStack
                          pl={5}
                          width={aw(500)}
                          alignItems={"center"}
                          justifyContent="flex-start"
                        >
                          <Pressable onPress={() => handleEdit(item)}>
                            <LeadsWatchEditIcon />
                          </Pressable>
                          <Pressable onPress={() => handleDelete(item.id)}>
                            <LeadsWatchDeleteIcon />
                          </Pressable>
                        </HStack>
                      )}
                    </HStack>
                  )}
                />
              </ScrollView>
            </VStack>
            {editId === "" && (
              <>
                <HStack mt={ah(20)}>
                  <HStack
                    width={"100%"}
                    alignItems={"center"}
                    style={{
                      height: ah(60),
                      background: "#F9FAFB 0% 0% no-repeat padding-box",
                      border: "1px solid #D7D7D7",
                      borderTopLeftRadius: aw(10),
                      borderTopRightRadius: aw(10),
                    }}
                  >
                    {["Buyer Field", "Map Field", "Map Field Type"].map(
                      (val) => (
                        <HStack
                          key={val}
                          width="33%"
                          alignItems={"center"}
                          justifyContent="flex-start"
                        >
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            ml={aw(20)}
                            color="#121233"
                          >
                            {val}
                          </Text>
                        </HStack>
                      )
                    )}
                  </HStack>
                </HStack>
                <HStack
                  width={"100%"}
                  alignItems={"center"}
                  style={{
                    height: ah(60),
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #D7D7D7",
                    borderTopWidth: "0px",
                    borderBottomLeftRadius: aw(10),
                    borderBottomRightRadius: aw(10),
                  }}
                >
                  <HStack width={"33%"} px={2}>
                    <LeadsWatchInput
                      control={control2}
                      name={"buyer_field"}
                      style={{ width: "80%", height: ah(40) }}
                      rules={{
                        required: {
                          value: true,
                        },
                      }}
                      invalid={errors2["buyer_field"] ? true : false}
                    />
                  </HStack>
                  <HStack width={"33%"}>
                    {!toogle ? (
                      <Flex width="80%">
                        <LeadsWatchAsyncSelect
                          required={true}
                          minHeight={37}
                          minWidth={"100%"}
                          defaultOptions={[
                            { label: "Bid Id", value: "bid_id" },
                            { label: "URL", value: "url" },
                            { label: "Route Id", value: "route_id" },
                          ]}
                          control={control2}
                          placeholder="Select"
                          name={"map_field"}
                          rules={{
                            required: {
                              value: true,
                            },
                          }}
                          invalid={errors2["map_field"] ? true : false}
                          error={
                            errors2["map_field"]
                              ? errors2["map_field"].message
                              : ""
                          }
                          // invalid={true}
                        />
                      </Flex>
                    ) : (
                      <LeadsWatchInput
                        control={control2}
                        name={"map_field"}
                        style={{ width: "80%", height: ah(40) }}
                        rules={{
                          required: {
                            value: true,
                          },
                        }}
                        invalid={errors2["map_field"] ? true : false}
                        error={
                          errors2["map_field"]
                            ? errors2["map_field"].message
                            : ""
                        }
                      />
                    )}
                  </HStack>
                  <HStack width={"33%"} px={aw(50)}>
                    <Switch
                      _hover={{
                        offTrackColor: "#C1C1C1",
                        onTrackColor: "#0000FF",
                      }}
                      onTrackColor="#0000FF"
                      offTrackColor="#C1C1C1"
                      isChecked={toogle}
                      onToggle={() => {
                        setToogle(!toogle);
                        reset2({
                          map_field: "",
                          buyer_field: getValues2("buyer_field"),
                        });
                      }}
                    />
                  </HStack>
                </HStack>

                <HStack
                  justifyContent={"center"}
                  alignItems={"center"}
                  mt={ah(20)}
                >
                  <LeadsWatchButton
                    onPress={handleSubmit2(handleMapFields)}
                    bg={"#0000FF"}
                    label={"add"}
                    style={{ width: aw(150) }}
                    color={"#FAFBFD"}
                  />
                </HStack>
              </>
            )}
          </>
        </VStack>
      </LeadsWatchCard>
    </Box>
  );
};

export default DataMappingForppc;
