import React, { useState } from "react";
import { Text, VStack, HStack } from "native-base";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useStripe } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";

import { aw, ah } from "../../utils";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchButton from "../../components/LeadsWatchButton";

import { stripePromise } from "../../utils";
// import { getProfile } from "../../store/actions/auth";
import { API_URL } from "../../store/Events";

import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";

const AddCredits = ({ openCreditsModal, setOpenCreditsModal }) => {
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("auth");
  const stripe = useStripe(stripePromise);
  const allCards = useSelector((state) => state.subscriptions.allCards);
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
  });
  const createSubscription = async (data) => {
    setLoading(true);
    let defaultCard;
    // const defaultCard =
    //   allCards && allCards.filter((val) => val.default === true);
    for (let c of allCards && allCards) {
      if (c.default === true) {
        defaultCard = c.id;
      }
    }

    const finalData = {
      pm: defaultCard,
      amt: parseFloat(data.amount),
      // leads: data.no_of_Leads,
      // price: 0.04,
    };
    try {
      const response = await fetch(`${API_URL}/purchase/credits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(finalData),
      });
      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      }

      const data = await response.json();

      if (data.data.clientSecret) {
        const confirm = await stripe.confirmCardPayment(data.data.clientSecret);
        if (confirm.error) {
          console.error(confirm.error);
        }
      }

      toast.success("Credits Added successfully, if not reflected refresh it");
      setOpenCreditsModal(false);
      setLoading(false);
      reset();
      setRefresh(!refresh);
      window.location.reload(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.message.toLowerCase());
    }
    setLoading(false);
  };

  return (
    <LeadsWatchModal
      width={aw(370)}
      height={ah(380)}
      showModal={openCreditsModal}
      onClose={() => {
        setOpenCreditsModal(false);
        reset();
        setRefresh(!refresh);
      }}
      heading={
        <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
          Add Credits
        </Text>
      }
    >
      <VStack>
        <LeadsWatchFilterInput
          refresh={refresh}
          float={true}
          onlyNumers={true}
          hasValue={
            getValues("amount") && getValues("amount").length > 0
              ? "edit"
              : "create"
          }
          control={control}
          name="amount"
          placeholder="Enter Amount"
          rules={{
            required: {
              value: true,
              message: "Please enter amount",
            },
          }}
          invalid={errors.amount ? true : false}
          error={errors.amount ? errors.amount.message : ""}
        />

        <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
          <LeadsWatchButton
            onPress={() => {
              setOpenCreditsModal(false);
              reset();
              setRefresh(!refresh);
            }}
            label="cancel"
            style={{
              width: aw(100),
              height: ah(32),
              borderRadius: aw(50),
            }}
          />
          <LeadsWatchButton
            isLoading={loading}
            onPress={handleSubmit(createSubscription)}
            label="purchase"
            style={{
              width: aw(100),
              height: ah(32),
              borderRadius: aw(50),
            }}
            bg={"#2230F8"}
            color={"#F9FAFB"}
          />
        </HStack>
      </VStack>
    </LeadsWatchModal>
  );
};

export default AddCredits;
