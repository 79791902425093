import {
  Text,
  VStack,
  HStack,
  Box,
  Pressable,
  Switch,
  Flex,
  Avatar,
  ScrollView,
  Tooltip,
} from "native-base";
import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
// import "react-phone-input-2/lib/material.css";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/material.css";

import LeadsWatchCard from "../../components/LeadsWatchCard";
import { aw, ah } from "../../utils";
import LeadsWatchEditIcon from "../../components/svg/LeadsWatchEditIcon";
import LeadsWatchUploadIcon from "../../components/svg/LeadsWatchUploadIcon";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";

import {
  updateProfile,
  editProfile,
  getProfile,
  updateLogo,
  register,
  selectUser,
} from "../../store/actions/auth";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchInfoIcon from "../../components/svg/LeadsWatchInfoIcon";

const Profile = ({ isOpen }) => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.auth.profile);
  const isVerified = JSON.parse(localStorage.getItem("isVerified"));
  const isModalOpen = Array.isArray(isVerified) ? true : false;

  const [checked, setChecked] = useState(
    localStorage.getItem("verify_email") === "true" ? true : false
  );
  const [haveInviteCode, setHaveInviteCode] = useState(false);
  // const [image, setImage] = useState(null);
  // const [image1, setImage1] = useState(null);
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const hiddenFileInput2 = useRef(null);
  const [phoneNo, setPhoneNo] = useState(null);
  const [edit, setEdit] = useState(false);

  const {
    control,
    handleSubmit,

    formState: { errors },
    reset,

    getValues,
  } = useForm({
    defaultValues: {
      firstname:
        localStorage.getItem("firstName") !== "null"
          ? localStorage.getItem("firstName")
          : "",
      lastname:
        localStorage.getItem("lastName") !== "null"
          ? localStorage.getItem("lastName")
          : "",
      email:
        localStorage.getItem("email") !== "null"
          ? localStorage.getItem("email")
          : "",
      company:
        localStorage.getItem("company") !== "null"
          ? localStorage.getItem("company")
          : "",
      phone:
        localStorage.getItem("phone") !== "null"
          ? localStorage.getItem("phone").replace(/[^0-9]/g, "")
          : "",
      time_zone:
        localStorage.getItem("time_zone") !== "null"
          ? {
              label: localStorage.getItem("time_zone"),
              value: localStorage.getItem("time_zone"),
            }
          : { label: "UTC", value: "UTC" },
    },

    mode: "all",
  });
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleClick1 = (event) => {
    hiddenFileInput2.current.click();
  };

  const handleEdit = () => {
    setEdit(!edit);
  };
  const handleEditData = async (data) => {
    if (profile) {
      try {
        await dispatch(
          editProfile({
            changePassword: false,
            firstname: data.firstname,
            lastname: data.lastname,
            company: data.company,
            phone: data.phone,
            time_zone: data.time_zone.value,
            verify_email: checked,
          })
        );
        setEdit(!edit);
        localStorage.setItem("firstName", data.firstname);
        localStorage.setItem("lastName", data.lastname);
        localStorage.setItem("phone", data.phone);
        localStorage.setItem("company", data.company);
        localStorage.setItem("time_zone", data.time_zone.value);
        localStorage.setItem("verify_email", checked);
        reset({
          firstname: data.firstname,
          lastname: data.lastname,
          company: data.company,
          phone: data.phone,
          time_zone: {
            value: data.time_zone.value,
            label: data.time_zone.value,
          },
        });

        toast.success("Profile Updated Successfully");
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    } else {
      try {
        await dispatch(
          register(
            data.firstname,
            data.lastname,
            data.email,
            data.invite_code,
            data.company,
            data.phone,
            isVerified._id
          )
        );
        setEdit(!edit);
        toast.success("User Registered Successfully");
        window.location.reload(false);
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
  };
  const handleChange = async (e) => {
    // setImage1(e.target.files[0]);
    // setImage(URL.createObjectURL(e.target.files[0]));
    if (e.target.files[0]) {
      let formData = new FormData();
      formData.append("image_file", e.target.files[0]);
      try {
        await dispatch(updateProfile(formData));

        toast.success("Image Uploaded Successfully");
        dispatch(getProfile());
        localStorage.setItem("image", URL.createObjectURL(e.target.files[0]));
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
  };
  const handleChange1 = async (e) => {
    // setImage1(e.target.files[0]);
    // setImage(URL.createObjectURL(e.target.files[0]));
    if (e.target.files[0]) {
      let formData = new FormData();
      formData.append("logo_file", e.target.files[0]);
      try {
        await dispatch(updateLogo(formData));
        toast.success("Logo Uploaded Successfully");
        dispatch(getProfile());
        localStorage.setItem("logo", URL.createObjectURL(e.target.files[0]));
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
  };
  const handleSelectUser = async (id, newUser) => {
    try {
      const user = await dispatch(
        id
          ? selectUser({ userid: id }, newUser)
          : selectUser({ email: localStorage.getItem("email") }, newUser)
      );
      if (user.incomplete) {
        localStorage.setItem("isVerified", JSON.stringify(user));
      } else {
        localStorage.setItem("auth", user.token);
        localStorage.setItem("userId", user._id);
        localStorage.setItem("email", user.email);
        localStorage.setItem("firstName", user.firstname);
        localStorage.setItem("lastName", user.lastname);
        localStorage.setItem("phone", user.phone);
        localStorage.setItem("company", user.company);
        localStorage.setItem("time_zone", user.time_zone);
        localStorage.setItem("verify_email", user.verify_email);
        localStorage.setItem("image", user.image);
        localStorage.setItem("logo", user.logo);
        localStorage.setItem("voice_id", user.voice_id);
        localStorage.setItem("isVerified", JSON.stringify(user));
        user.role === "admin" && navigate("/");
      }
      // window.location.reload(false);
      toast.success("User Selected Successfully");
      window.location.reload(false);
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
  };
  //group data based on emails
  const groupedData =
    Array.isArray(isVerified) &&
    isVerified.reduce((result, element) => {
      (result[element["company"]] = result[element["company"]] || []).push(
        element
      );
      return result;
    }, {});

  return (
    <>
      <Box marginTop={ah(30)}>
        <LeadsWatchCard
          // width={aw(1235)}
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
          // justifyContent={"space-between"}
          // alignItems={"center"}
          // width="98%"
          height={ah(330)}
          alignItems={"flex-start"}
        >
          <Flex px={aw(15)} gap={ah(15)} py={ah(20)} width={"100%"}>
            <HStack
              justifyContent={"space-between"}
              alignItems={"center"}
              // width={aw(1200)}
              width="100%"
            >
              <Text
                style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
                color="#121233"
              >
                Profile Details
              </Text>
              <LeadsWatchButton
                label={edit ? "update" : "Edit"}
                onPress={edit ? handleSubmit(handleEditData) : handleEdit}
                style={{
                  width: edit ? aw(100) : aw(70),
                  height: ah(40),
                  borderRadius: "6px",
                }}
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                fontWeight="400"
                leftIcon={!edit ? <LeadsWatchEditIcon /> : null}
              />
            </HStack>

            <Flex gap={ah(20)} width={"100%"}>
              <Flex
                justifyContent={"space-between"}
                width="100%"
                direction="row"
              >
                <Flex
                  width="49%"
                  direction="row"
                  justifyContent={"space-between"}
                >
                  <Flex width="49%">
                    <LeadsWatchFilterInput
                      width="100%"
                      screen={
                        getValues("firstname") &&
                        getValues("firstname").length > 0
                          ? "edit"
                          : "create"
                      }
                      isDisabled={!edit}
                      control={control}
                      name={"firstname"}
                      placeholder={"First Name"}
                      // style={{ width: aw(283), height: ah(40) }}
                      // width={aw(283)}
                      // height={ah(40)}
                      rules={{
                        required: {
                          value: true,
                          message: "First Name is required",
                        },
                        pattern: {
                          // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                          message:
                            "FirstName should start with alphabets and can not contain special characters",
                        },
                      }}
                      invalid={errors.firstname ? true : false}
                      error={errors.firstname ? errors.firstname.message : ""}
                    />
                  </Flex>
                  <Flex width="49%">
                    <LeadsWatchFilterInput
                      width="100%"
                      screen={
                        getValues("lastname") &&
                        getValues("lastname").length > 0
                          ? "edit"
                          : "create"
                      }
                      isDisabled={!edit}
                      control={control}
                      name={"lastname"}
                      placeholder={"Last Name"}
                      // style={{ width: aw(283), height: ah(40) }}
                      // width={aw(283)}
                      // height={ah(40)}
                      rules={{
                        required: {
                          value: true,
                          message: "Last Name is required",
                        },
                        pattern: {
                          // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                          message:
                            "FirstName should start with alphabets and can not contain special characters",
                        },
                      }}
                      invalid={errors.lastname ? true : false}
                      error={errors.lastname ? errors.lastname.message : ""}
                    />
                  </Flex>
                </Flex>
                <Flex width="49%">
                  <LeadsWatchFilterInput
                    screen={
                      getValues("company") && getValues("company").length > 0
                        ? "edit"
                        : "create"
                    }
                    isDisabled={!edit}
                    control={control}
                    name={"company"}
                    placeholder={"Company"}
                    // style={{ width: aw(587), height: ah(40) }}
                    width={"100%"}
                    // height={ah(40)}
                    rules={{
                      required: {
                        value: true,
                        message: "Company is required",
                      },
                      pattern: {
                        // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                        message:
                          "Company Name should start with alphabets and can not contain special characters",
                      },
                    }}
                    invalid={errors.company ? true : false}
                    error={errors.company ? errors.company.message : ""}
                  />
                </Flex>
              </Flex>
              <Flex gap={aw(23)} direction="row" width="100%">
                <Flex width="49%">
                  <LeadsWatchFilterInput
                    screen={"edit"}
                    // isDisabled={true}
                    isDisabled={!edit}
                    isReadOnly={true}
                    control={control}
                    name={"email"}
                    placeholder={"Email"}
                    // style={{ width: aw(587), height: ah(40) }}
                    width={"100%"}
                    // height={ah(40)}
                  />
                </Flex>
                <Flex zIndex={1} width={"49%"}>
                  <Controller
                    control={control}
                    name={"phone"}
                    style={{
                      width: "100%",
                      // heigth: ah(40),
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <PhoneInput
                        disabled={!edit}
                        // className={"phoneInput"}
                        // className="react-tel-input"

                        country={"in"}
                        isValid={(inputNumber, country, countries) => {
                          setPhoneNo(country);
                          return !errors.phone;
                        }}
                        // placeholder="Enter PhoneNumber"
                        value={value}
                        containerStyle={{
                          borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",
                          // borderColor: "#D1D5DB",
                          // _focus: {
                          //   borderColor: "#0000FF",
                          // },
                        }}
                        // _hover={{
                        //   borderColor: "#D1D5DB",
                        // }}
                        inputStyle={{
                          backgroundColor: "#fff",
                          borderWidth: errors.phone ? 2 : 1,
                          borderRadius: aw(5),
                          width: "100%",
                          height: ah(37),
                          fontFamily: "RobotoRegular",
                          fontSize: aw(15),
                          color: !edit ? "#00000029" : "#212121",
                          borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",
                        }}
                        onChange={(value) => {
                          onChange(value);
                        }}
                        onBlur={() => {
                          onBlur();
                        }}
                        dropdownStyle={{
                          height: ah(587),
                          width: aw(580),
                        }}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                      },
                      validate: (value) => {
                        return value && value.length > 2
                          ? value.length === phoneNo.format.match(/\./g).length
                          : true;
                      },
                    }}
                    invalid={errors.phone ? true : false}
                    error={errors.phone ? errors.phone.message : ""}
                  />
                  {errors.phone && (
                    <Text
                      color="#FA4E2D"
                      fontFamily="RobotoRegular"
                      fontSize={aw(12)}
                      pt={ah(2)}
                    >
                      {"Please enter a valid phone number"}
                    </Text>
                  )}
                </Flex>
              </Flex>
              <Flex justifyContent={"space-between"} direction="row">
                <Flex width={"49%"}>
                  <LeadsWatchAsyncSelect
                    isDisabled={!edit}
                    screen={"edit"}
                    // hasValue={getValues("") ? false : true}
                    minWidth={"100%"}
                    defaultOptions={[
                      { label: "UTC", value: "UTC" },
                      { label: "UTC+01:00", value: "UTC+01:00" },
                      { label: "UTC+02:00", value: "UTC+02:00" },
                      { label: "UTC+03:00", value: "UTC+03:00" },
                      { label: "UTC+04:00", value: "UTC+04:00" },
                      { label: "UTC:05:00", value: "UTC:05:00" },
                      { label: "UTC+05:30", value: "UTC+05:30" },
                      { label: "UTC+06:00", value: "UTC+06:00" },
                      { label: "UTC+07:00", value: "UTC+07:00" },
                      { label: "UTC+08:00", value: "UTC+08:00" },
                      { label: "UTC+09:00", value: "UTC+09:00" },
                      { label: "UTC+10:00", value: "UTC+10:00" },
                      { label: "UTC+11:00", value: "UTC+11:00" },
                      { label: "UTC+12:00", value: "UTC+12:00" },
                      { label: "UTC-11:00", value: "UTC-11:00" },
                      { label: "UTC-10:00", value: "UTC-10:00" },
                      { label: "UTC-09:00", value: "UTC-09:00" },
                      { label: "UTC-08:00", value: "UTC-08:00" },
                      { label: "UTC-07:00", value: "UTC-07:00" },
                      { label: "UTC-06:00", value: "UTC-06:00" },
                      { label: "UTC-05:00", value: "UTC-05:00" },
                      { label: "UTC-04:00", value: "UTC-04:00" },
                      { label: "UTC-03:30", value: "UTC-03:30" },
                      { label: "UTC-03:00", value: "UTC-03:00" },
                      { label: "UTC-02:00", value: "UTC-02:00" },
                      { label: "UTC-01:00", value: "UTC-01:00" },
                    ]}
                    control={control}
                    placeholder="Change Time Zone"
                    name="time_zone"
                    rules={{
                      required: {
                        value: true,
                      },
                    }}
                    invalid={errors.time_zone ? true : false}
                    error={errors.time_zone ? errors.time_zone.message : ""}
                  />
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  width={"49%"}
                  alignItems={"center"}
                  direction="row"
                >
                  <HStack>
                    <Text
                      color="#212121"
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                    >
                      Verify Email
                    </Text>
                    <Tooltip label="Verify the emails when you get leads">
                      <HStack>
                        <LeadsWatchInfoIcon />
                      </HStack>
                    </Tooltip>
                  </HStack>
                  <Switch
                    isDisabled={!edit}
                    offTrackColor="#C1C1C1"
                    onTrackColor="#0000FF"
                    _hover={{
                      offTrackColor: "#C1C1C1",
                      onTrackColor: "#0000FF",
                    }}
                    onToggle={() => setChecked(!checked)}
                    isChecked={checked}
                  />
                </Flex>
              </Flex>
              {!profile && (
                <HStack space={aw(23)}>
                  <HStack
                    justifyContent={"space-between"}
                    width={aw(587)}
                    alignItems={"center"}
                  >
                    <Text
                      color="#212121"
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                      px={1}
                    >
                      Have Invite Code
                    </Text>
                    <Switch
                      isDisabled={!edit}
                      offTrackColor="#C1C1C1"
                      onTrackColor="#0000FF"
                      _hover={{
                        offTrackColor: "#C1C1C1",
                        onTrackColor: "#0000FF",
                      }}
                      onToggle={() => setHaveInviteCode(!haveInviteCode)}
                      isChecked={haveInviteCode}
                    />
                  </HStack>
                  {haveInviteCode && (
                    <LeadsWatchFilterInput
                      screen={
                        getValues("invite_code") &&
                        getValues("invite_code").length > 0
                          ? "edit"
                          : "create"
                      }
                      // isDisabled={true}
                      isDisabled={!edit}
                      control={control}
                      name={"invite_code"}
                      placeholder={"Invite Code"}
                      // style={{ width: aw(587), height: ah(40) }}
                      width={aw(587)}
                      height={ah(40)}
                      rules={{
                        required: {
                          value: true,
                        },
                      }}
                      invalid={errors.invite_code ? true : false}
                      error={
                        errors.invite_code ? errors.invite_code.message : ""
                      }
                      // rightIcon={
                      //   !show ? (
                      //     <HStack mr={aw(5)}>
                      //       <Pressable onPress={() => setShow(!show)}>
                      //         <LeadsWatchEyeOffIcon />
                      //       </Pressable>
                      //     </HStack>
                      //   ) : (
                      //     <HStack mr={aw(5)}>
                      //       <Pressable onPress={() => setShow(!show)}>
                      //         <LeadsWatchEyeIcon />
                      //       </Pressable>
                      //     </HStack>
                      //   )
                      // }
                    />
                  )}
                </HStack>
              )}
            </Flex>
          </Flex>
        </LeadsWatchCard>
      </Box>
      {profile && (
        <Box marginTop={ah(30)} zIndex={-1}>
          <LeadsWatchCard
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
            height={ah(200)}
            alignItems={"flex-start"}
            // pb={ah(10)}
          >
            <Flex direction="row" justifyContent={"space=between"} width="100%">
              <Flex
                px={aw(15)}
                space={ah(15)}
                py={ah(20)}
                width={"80%"}
                // space={aw(20)}
                alignItems={"flex-start"}
              >
                <VStack
                  space={ah(15)}
                  width={aw(600)}
                  justifyContent={"center"}
                  // py={ah(15)}
                >
                  <Text
                    style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
                    color="#121233"
                    // mt={ah(15)}
                  >
                    Configuration
                  </Text>

                  <HStack
                    justifyContent={"space-between"}
                    width={aw(748)}
                    alignItems={"center"}
                  >
                    <Text
                      color="#212121"
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                      width={aw(140)}
                    >
                      Upload Custom Logo
                    </Text>
                    <LeadsWatchButton
                      isDisabled={
                        (profile && profile.role === "publisher") ||
                        (profile && profile.role === "user")
                          ? true
                          : false
                      }
                      label="Choose File"
                      onPress={handleClick1}
                      style={{ width: aw(126), height: ah(32) }}
                      leftIcon={<LeadsWatchUploadIcon />}
                    />
                    <input
                      ref={hiddenFileInput2}
                      onChange={handleChange1}
                      style={{ display: "none" }}
                      type="file"
                      name="picture"
                      accept="image/png, image/jpg,image/jpeg"
                    ></input>
                  </HStack>

                  <HStack
                    justifyContent={"space-between"}
                    width={aw(748)}
                    alignItems={"center"}
                  >
                    <Text
                      width={aw(140)}
                      color="#212121"
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                    >
                      Upload Your Photo
                    </Text>
                    <LeadsWatchButton
                      label="Choose File"
                      onPress={handleClick}
                      style={{ width: aw(126), height: ah(32) }}
                      leftIcon={<LeadsWatchUploadIcon />}
                    />
                    <input
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                      type="file"
                      name="picture"
                      accept="image/png, image/jpg,image/jpeg"
                    ></input>
                  </HStack>
                </VStack>
              </Flex>
              {profile.role === "admin" && (
                <Flex
                  alignItems={"flex-end"}
                  justifyContent={"flex-end"}
                  p={aw(10)}
                  width="20%"
                >
                  <LeadsWatchButton
                    label="Delete Account"
                    style={{ height: ah(32) }}
                    onPress={() => navigate("/delete-account")}
                  />
                </Flex>
              )}
            </Flex>
          </LeadsWatchCard>
        </Box>
      )}

      {/* <LeadsWatchModal
        // initialFocusRef={null}
        // finalFocusRef={null}
        required={true}
        width={aw(400)}
        height={ah(680)}
        showModal={isModalOpen}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Select User
          </Text>
        }
      >
        <ScrollView maxHeight={ah(400)}>
          <Flex gap="5">
            {Array.isArray(isVerified) &&
              isVerified.map((val) => (
                <Pressable
                  focusable={false}
                  key={val._id}
                  onPress={() => handleSelectUser(val._id, "false")}
                  // autoFocus={false}
                >
                  <Flex direction="row" gap={"5"}>
                    <Avatar
                      size={aw(36)}
                      source={{
                        uri: val.image ? val.image : undefined,
                      }}
                    >
                      {val.firstname
                        ? val.firstname &&
                          `${val.firstname.charAt(0).toUpperCase()}${
                            val.lastname && val.lastname.charAt(0).toUpperCase()
                          }`
                        : null}
                    </Avatar>
                    <Flex>
                      <Flex direction="row">
                        <Text fontFamily="RobotoMedium">Role : </Text>
                        <Text fontFamily="RobotoRegular" color="#919191">
                          {" "}
                          {val.role}
                        </Text>
                      </Flex>
                      <Flex direction="row">
                        <Text fontFamily="RobotoMedium">Company : </Text>
                        <Text fontFamily="RobotoRegular" color="#919191">
                          {" "}
                          {val.company}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Pressable>
              ))}

            <Pressable
              onPress={() => handleSelectUser(undefined, "true")}
              focusable={false}
              // autoFocus={false}
            >
              <Flex direction="row" gap={"5"} alignItems={"center"}>
                <Avatar size={aw(36)}>
                  <LeadsWatchAddIcon />
                </Avatar>
                <Flex alignItems={"center"}>
                  <Text fontFamily="RobotoMedium">New Registration</Text>
                </Flex>
              </Flex>
            </Pressable>
          </Flex>
        </ScrollView>
      </LeadsWatchModal> */}
      <LeadsWatchModal
        // initialFocusRef={null}
        // finalFocusRef={null}
        required={true}
        width={aw(400)}
        height={ah(680)}
        showModal={isModalOpen}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Select User
          </Text>
        }
      >
        <ScrollView maxHeight={ah(400)}>
          <Flex gap="5">
            {Array.isArray(isVerified) &&
              Object.keys(groupedData).map((val, index) => (
                <Flex key={index} gap={ah(10)}>
                  <Flex direction="row">
                    <Text fontFamily="RobotoMedium" fontSize={aw(14)}>
                      Company :{" "}
                    </Text>
                    <Text
                      fontFamily="RobotoRegular"
                      color="#919191"
                      fontSize={aw(14)}
                    >
                      {" "}
                      {val}
                    </Text>
                  </Flex>
                  {groupedData[val].map((item) => (
                    <Pressable
                      focusable={false}
                      key={item._id}
                      onPress={() => handleSelectUser(item._id, "false")}
                      // autoFocus={false}
                    >
                      <Flex direction="row" gap={aw(10)} alignItems={"center"}>
                        <Flex>
                          <Avatar
                            size={aw(30)}
                            source={{
                              uri: item.image ? item.image : undefined,
                            }}
                          >
                            <Text fontSize={aw(10)} color={"#FFFFFF"}>
                              {item.firstname
                                ? item.firstname &&
                                  `${item.firstname.charAt(0).toUpperCase()}${
                                    item.lastname &&
                                    item.lastname.charAt(0).toUpperCase()
                                  }`
                                : null}
                            </Text>
                          </Avatar>
                        </Flex>

                        <Text
                          fontFamily="RobotoMedium"
                          // color="#919191"
                          fontSize={aw(14)}
                        >
                          {" "}
                          {item["role"]}
                        </Text>
                      </Flex>
                    </Pressable>
                  ))}
                </Flex>
              ))}

            <Pressable
              onPress={() => handleSelectUser(undefined, "true")}
              focusable={false}
              // autoFocus={false}
            >
              <Flex direction="row" gap={"5"} alignItems={"center"}>
                <Flex alignItems={"center"}>
                  <Text fontFamily="RobotoMedium">New Registration</Text>
                </Flex>
              </Flex>
            </Pressable>
          </Flex>
        </ScrollView>
      </LeadsWatchModal>
    </>
  );
};

export default Profile;
