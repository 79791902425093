import {
  Text,
  VStack,
  HStack,
  Divider,
  Box,
  ScrollView,
  Pressable,
  Spinner,
  Flex,
} from "native-base";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Elements,
  CardCvcElement,
  CardNumberElement,
  useElements,
  useStripe,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

import { aw, ah } from "../../utils";
import LeadsWatchCard from "../../components/LeadsWatchCard";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchShowMoreIcon from "../../components/svg/LeadsWatchShowMoreIcon";
import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchTickIcon from "../../components/svg/LeadsWatchTickIcon";
import { stripePromise } from "../../utils";
import {
  deleteCard,
  getAllCards,
  getAllPlans,
  // getAllInvoices,
  // addSubscription,
  defaultCard,
} from "../../store/actions/subscription";

import LeadsWatchRemoveCardIcon from "../../components/svg/LeadsWatchRemoveCardIcon";
// import AddCard from "./AddCard";
import AddSubscription from "./AddSubscription";
// import PaymentCard from "./PaymentCard";
import { API_URL } from "../../store/Events";
const StripeWrapper = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};
const CardForm = ({
  setIsAddCardModalOpen,
  control,
  reset,
  handleSubmit,
  errors,
  getValues,
  refresh,
  setRefresh,
  toast,
  isAddCardModalOpen,
}) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("auth");
  const elements = useElements();
  const stripe = useStripe();

  // const [card, setCard] = useState(null);

  // console.log(date1);

  const [save, setSave] = useState(false);
  const [phoneNo, setPhoneNo] = useState(null);
  // const cardElement = elements && elements.getElement(CardElement);
  const cardNumberElement = elements && elements.getElement(CardNumberElement);
  const cardExpiryElement = elements && elements.getElement(CardExpiryElement);
  const cardCvcElement = elements && elements.getElement(CardCvcElement);
  useEffect(() => {
    if (isAddCardModalOpen === false) {
      setPhoneNo("in");
      cardNumberElement && cardNumberElement.clear();
      cardExpiryElement && cardExpiryElement.clear();
      cardCvcElement && cardCvcElement.clear();
    }
  }, [isAddCardModalOpen]);
  const handleCreateCard = async (data) => {
    const displayerror = document.getElementById("card-errors");
    console.log(displayerror);
    setSave(true);

    // Check if Stripe is loaded
    if (!stripe || !elements) {
      setSave(false);
      alert("Stripe is not loaded");
      return;
    }

    // Create a PaymentMethod with Card information

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
      billing_details: {
        address: {
          city: data.city,
          country: data.country,
          line1: data.address1,
          line2: data.address2,
          postal_code: data.zipcode,
          state: data.state,
        },
      },
    });
    if (paymentMethod) {
      try {
        const response = await fetch(`${API_URL}/pm/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ id: paymentMethod.id }),
        });
        console.log(response);

        if (!response.ok) {
          const resError = await response.json();
          console.log("Response Error", resError);
          if (resError && resError.error) {
            throw new Error(resError.error);
          }
        }
        const data = await response.json();

        if (data && data.data.clientSecret) {
          const confirm = await stripe.confirmCardPayment(
            data.data.clientSecret
          );
          if (confirm.error) {
            console.error(confirm.error);
          }
        }
        //   alert("Payment Successful! Subscription active.");
        setPhoneNo("in");
        setSave(false);
        reset({
          address: "",
          address2: "",
          card_CVC: "",
          country: "",
          expiry: "",
          number: "",
          city: "",
          phone: "91",
          state: "",
          zipcode: "",
        });

        setRefresh(!refresh);
        // clearErrors();
        await dispatch(getAllCards());
        setIsAddCardModalOpen(false);
        cardNumberElement.clear();
        cardExpiryElement.clear();
        cardCvcElement.clear();

        toast.success("Payment Method Added Successfully");
      } catch (err) {
        setSave(false);

        toast.error(err.message.toLowerCase());
      }
    }
    if (error) {
      setSave(false);
      // setError(error.message);

      toast.error(error.message.toLowerCase());
      console.log(error);
      return;
    }

    // Use the created PaymentMethod for further processing (e.g., send to server)
    console.log("PaymentMethod:", paymentMethod);
  };

  return (
    <VStack space={4}>
      <Text color="black" fontSize={aw(12)} fontFamily="RobotoRegular">
        Billing Details
      </Text>
      <LeadsWatchFilterInput
        refresh={refresh}
        screen={
          getValues("address") && getValues("address").length > 0
            ? "edit"
            : "create"
        }
        name="address"
        placeholder="Address Line 1"
        control={control}
        rules={{
          required: {
            value: true,
            message: "Address is required",
          },
        }}
        invalid={errors.address ? true : false}
        error={errors.address ? errors.address.message : ""}
      />
      <LeadsWatchFilterInput
        onChange={() => {}}
        refresh={refresh}
        screen={
          getValues("address2") && getValues("address2").length > 0
            ? "edit"
            : "create"
        }
        name="address2"
        placeholder="Address Line 2"
        control={control}
        // rules={{
        //   required: {
        //     value: true,
        //   },
        // }}
        // invalid={errors.address2 ? true : false}
        // error={errors.address2 ? errors.address2.message : ""}
      />
      <VStack zIndex={1}>
        <Controller
          control={control}
          name={"phone"}
          style={{
            width: aw(573),
            heigth: ah(40),
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <PhoneInput
              color="#212121"
              className={"phoneInput"}
              country={"in"}
              isValid={(inputNumber, country, countries) => {
                setPhoneNo(country);
                return !errors.phone;
              }}
              placeholder="Enter PhoneNumber"
              value={value}
              containerStyle={{
                borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",
              }}
              inputStyle={{
                borderWidth: errors.phone ? 2 : 1,
                borderRadius: aw(5),
                width: aw(573),
                height: ah(10),
                fontFamily: "RobotoRegular",
                backgroundColor: "#fff",
                // borderWidth: 0,
                fontSize: aw(15),
                borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",

                // borderColor: !errors.phone && "#D1D5DB",
              }}
              onChange={(value, data) => {
                onChange(value);
                // onChange(value);
              }}
              onBlur={() => {
                onBlur();
              }}
              dropdownStyle={{
                height: ah(150),
                width: aw(573),
              }}
            />
          )}
          // rules={{
          //   required: {
          //     value: true,
          //     // validate: (value) =>
          //     //   phoneNo &&
          //     //   phoneNo.format.match(/\./g).length === value.length,
          //     message: "Enter a valid password",
          //     validate: (value) =>
          //       value.length === phoneNo.format.match(/\./g).length,
          //   },
          // }}

          rules={{
            required: {
              value: true,
            },

            validate: (value) => {
              return (
                value && value.length === phoneNo.format.match(/\./g).length
              );
            },
          }}
          invalid={errors.phone ? true : false}
          // invalid={true}
          error={errors.phone ? errors.phone.message : ""}
        />
        {errors.phone && (
          <Text
            color="#FA4E2D"
            fontFamily="RobotoRegular"
            fontSize={aw(12)}
            pt={ah(2)}
          >
            {"Please enter a valid phone number"}
          </Text>
        )}
      </VStack>
      <HStack space={aw(16)}>
        <LeadsWatchFilterInput
          onChange={() => {}}
          refresh={refresh}
          screen={
            getValues("city") && getValues("city").length > 0
              ? "edit"
              : "create"
          }
          width={aw(280)}
          style={{ width: aw(280) }}
          name="city"
          placeholder="City"
          control={control}
          rules={{
            required: {
              value: true,
              message: "City is required",
            },
          }}
          invalid={errors.city ? true : false}
          error={errors.city ? errors.city.message : ""}
        />
        <LeadsWatchFilterInput
          onChange={() => {}}
          refresh={refresh}
          width={aw(275)}
          screen={
            getValues("state") && getValues("state").length > 0
              ? "edit"
              : "create"
          }
          style={{ width: aw(140) }}
          name="state"
          placeholder="State"
          control={control}
          rules={{
            required: {
              value: true,
              message: "State is required",
            },
          }}
          invalid={errors.state ? true : false}
          error={errors.state ? errors.state.message : ""}
        />
      </HStack>
      <HStack space={aw(16)}>
        <LeadsWatchFilterInput
          onChange={() => {}}
          refresh={refresh}
          screen={
            getValues("zipcode") && getValues("zipcode").length > 0
              ? "edit"
              : "create"
          }
          maxLength={10}
          width={aw(280)}
          style={{ width: aw(280) }}
          name="zipcode"
          placeholder="Zipcode"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Zipcode is required",
            },
          }}
          invalid={errors.zipcode ? true : false}
          error={errors.zipcode ? errors.zipcode.message : ""}
        />
        <LeadsWatchFilterInput
          onChange={() => {}}
          refresh={refresh}
          screen={
            getValues("country") && getValues("country").length > 0
              ? "edit"
              : "create"
          }
          width={aw(275)}
          style={{ width: aw(275) }}
          name="country"
          placeholder="Country"
          control={control}
          rules={{
            required: {
              value: true,
            },
          }}
          maxLength={2}
          toCaptlize={true}
          invalid={errors.country ? true : false}
          error={errors.country ? errors.country.message : ""}
        />
      </HStack>

      {/* <CardElement /> */}

      <HStack space={aw(10)}>
        <VStack
          style={{
            width: "60%",
            borderColor: "rgba(0,0,0,0.15)",
            borderWidth: aw(1),
            padding: aw(9),
            borderRadius: 4,

            justifyContent: "center",
          }}
        >
          <CardNumberElement />
        </VStack>
        <Box
          style={{
            width: aw(105),
            borderColor: "#D1D5DB",
            borderWidth: aw(1),
            padding: aw(9),
            borderRadius: 4,
          }}
        >
          <CardExpiryElement />
        </Box>
        <Box
          style={{
            width: aw(105),
            borderColor: "#D1D5DB",
            borderWidth: aw(1),
            padding: aw(9),
            borderRadius: 4,
          }}
        >
          <CardCvcElement />
        </Box>
      </HStack>

      <HStack space={aw(5)} justifyContent={"flex-end"}>
        <LeadsWatchButton
          label="cancel"
          style={{ height: ah(40), width: aw(100) }}
          onPress={() => {
            //   setId("");
            setIsAddCardModalOpen(false);
            reset({
              address: "",
              address2: "",
              card_CVC: "",
              country: "",
              expiry: "",
              number: "",
              city: "",
              phone: "91",
              state: "",
              zipcode: "",
            });

            setPhoneNo("in");
            setRefresh(!refresh);
            cardNumberElement.clear();
            cardExpiryElement.clear();
            cardCvcElement.clear();
          }}
        />
        <LeadsWatchButton
          isLoading={save}
          onPress={handleSubmit(handleCreateCard)}
          // onPress={createSubscription}
          label="save"
          bg={"#0000FF"}
          color="white"
          style={{ height: ah(40), width: aw(100) }}
        />
      </HStack>
    </VStack>
  );
};

const Subscription = ({ isOpen }) => {
  // const elements = useElements();
  // const stripe = useStripe();
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [paymentError, setPayMentError] = useState(false);
  const [openDefaultCardModal, setOpenDefaultCardModal] = useState(false);
  const profile = useSelector((state) => state.auth.profile);
  const allPlans = useSelector((state) => state.subscriptions.allPlans);
  const allCards = useSelector((state) => state.subscriptions.allCards);
  // const invoices = useSelector((state) => state.subscriptions.allInvoices);

  const [id, setId] = useState(null);
  // const [invoiceLoading, setInvoicesLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(allPlans ? false : true);
  const [isOpenSubscriptionModal, setIsOpenSubscriptionModal] = useState(false);

  const [defaultCardId, setDefaultCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const {
    control,
    handleSubmit,
    getValues,

    formState: { errors },

    reset,
  } = useForm({
    mode: "all",
  });
  const getData = async () => {
    setIsLoading(true);
    try {
      await dispatch(getAllPlans());
    } catch (err) {
      console.log(err.message);
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };

  const getCardsData = async () => {
    try {
      await dispatch(getAllCards());
    } catch (err) {
      console.log(err.message);
      toast.error(err.message.toLowerCase());
    }
  };
  // const getInvoicesData = async () => {
  //   setInvoicesLoading(true);
  //   try {
  //     await dispatch(getAllInvoices("sub"));
  //   } catch (err) {
  //     console.log(err.message);
  //     toast.show({
  //       placement: "top",
  //       render: () => {
  //         return (
  //           <ToastAlert
  //             desc={err.message}
  //             // handleClose={handleToastClose}
  //             status="error"
  //             title="error"
  //           />
  //         );
  //       },
  //     });
  //   }
  //   setInvoicesLoading(false);
  // };
  useEffect(() => {
    if (allPlans === null) {
      getData();
    }
    if (profile && profile.role !== "user") {
      getCardsData();
    }
    // getInvoicesData();
  }, []);
  // const handleDeleteCard = async (id) => {

  // };
  const handleDeleteCard = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      const data = { id: id };
      try {
        await dispatch(deleteCard(data));
        getCardsData();
        setConfirmModal(false);

        toast.success("Card Deleted Successfully");
      } catch (err) {
        toast.error(err.message.toLowerCase());
        setConfirmModal(false);
      }

      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };
  // const createSubscription = async () => {
  //   let defaultCard;
  //   // const defaultCard =
  //   //   allCards && allCards.filter((val) => val.default === true);
  //   for (let c of allCards && allCards) {
  //     if (c.default === true) {
  //       defaultCard = c.id;
  //     }
  //   }

  //   const data = {
  //     pm: defaultCard,
  //     price_id: id,
  //   };

  //   try {
  //     await dispatch(addSubscription(data));
  //     setIsOpenSubscriptionModal(false);

  //     toast.success("Subscribed Successfully")
  //   } catch (err) {
  //   toast.error(err.message.toLowerCase())
  //   }
  // };
  const handleAddDefaulrCard = () => {
    setLoading(true);
    try {
      dispatch(defaultCard({ id: defaultCardId }));
      getCardsData();
      setDefaultCardId(null);
      setOpenDefaultCardModal(false);
      getCardsData();
    } catch (err) {
      toast.error(err.message.toLowerCase());
      setDefaultCardId(null);
      setOpenDefaultCardModal(false);
    }
    setLoading(false);
  };

  // const PaymentCard = () => {
  //   return injectStripe(<CardElement />);
  // };
  return (
    <ScrollView height={ah(550)}>
      <VStack height={ah(540)}>
        <VStack width={aw(1248)}>
          {/* <HStack
            lineHeight={ah(34)}
            justifyContent={"space-between"}
            space={aw(8)}
            alignItems={"flex-end"}
          >
            <HStack
              alignItems={"baseline"}
              // width={aw(194)}
              justifyContent="space-between"
              space={aw(5)}
            >
              <Text
                fontSize={aw(26)}
                color={"#121233"}
                fontFamily="RobotoMedium"
              >
                Subscriptions
              </Text>
            </HStack>
          </HStack>
          <Text
            lineHeight={ah(34)}
            fontSize={aw(14)}
            fontFamily={"RobotoRegular"}
            color={"#919191"}
            fontWeight={400}
          >
            You're in free trial and it ends in 03-13-2023
          </Text>

          <Divider top={ah(10)} bg="#E6E7E9" /> */}
        </VStack>
        <Box mt={ah(20)} pb={ah(20)}>
          <LeadsWatchCard
            // px={aw(15)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1235)]
            }
            height={ah(700)}
            alignItems="flex-start"
          >
            {!isLoading ? (
              <Flex
                display="flex"
                flexWrap={"wrap"}
                direction="row"
                width="97%"
                // px={aw(15)}
                justifyContent={"space-between"}
              >
                {allPlans &&
                  allPlans.list &&
                  allPlans.list.map((item) => {
                    return profile && profile.subscribed ? (
                      profile.subscribed.price <= item.price && (
                        <HStack key={item._id} width="49%">
                          <Box
                            px={aw(10)}
                            borderRadius={aw(10)}
                            borderWidth={2}
                            m={aw(20)}
                            width={"100%"}
                            height={ah(140)}
                            borderColor={
                              (profile && profile.free) ||
                              (profile && profile.subscribed)
                                ? profile.subscribed
                                  ? profile.subscribed.planid === item.plan_id
                                    ? "#2230F8"
                                    : "#F9FAFB"
                                  : profile.free.planid === item.plan_id
                                  ? "#2230F8"
                                  : "#F9FAFB"
                                : "#F9FAFB"
                            }
                            backgroundColor={
                              (profile && profile.free) ||
                              (profile && profile.subscribed)
                                ? profile.subscribed
                                  ? profile.subscribed.planid === item.plan_id
                                    ? "#F9FAFB"
                                    : "#2230F8"
                                  : profile.free.planid === item.plan_id
                                  ? "#F9FAFB"
                                  : "#2230F8"
                                : "#2230F8"
                            }
                          >
                            <HStack
                              height={ah(70)}
                              my={aw(10)}
                              justifyContent={"space-between"}
                            >
                              <VStack>
                                <Text
                                  fontSize={aw(14)}
                                  fontFamily={"RobotoMedium"}
                                  fontWeight={400}
                                  color={
                                    profile &&
                                    profile.subscribed &&
                                    profile.subscribed.planid === item.plan_id
                                      ? "#121233"
                                      : "#FFFFFF"
                                  }
                                >
                                  {item.type}
                                </Text>
                                <Text
                                  fontSize={aw(11)}
                                  fontFamily={"RobotoRegular"}
                                  fontWeight={400}
                                  color={
                                    profile &&
                                    profile.subscribed &&
                                    profile.subscribed.planid === item.plan_id
                                      ? "#121233"
                                      : "#FFFFFF"
                                  }
                                >
                                  {item.interval} month
                                </Text>
                              </VStack>
                              <VStack>
                                <Text
                                  fontSize={aw(30)}
                                  fontFamily={"RobotoMedium"}
                                  fontWeight={400}
                                  color={
                                    profile &&
                                    profile.subscribed &&
                                    profile.subscribed.planid === item.plan_id
                                      ? "#121233"
                                      : "#FFFFFF"
                                  }
                                >
                                  {item.currency === "USD" ? "$" : "₹"}{" "}
                                  {item.price}
                                </Text>
                                <Text
                                  alignSelf={"flex-end"}
                                  pl={
                                    item.currency === "USD" ||
                                    item.name === "LeadsWatch Free"
                                      ? 2
                                      : aw(50)
                                  }
                                  fontSize={aw(11)}
                                  fontFamily={"RobotoRegular"}
                                  fontWeight={400}
                                  color={
                                    profile &&
                                    profile.subscribed &&
                                    profile.subscribed.planid === item.plan_id
                                      ? "#121233"
                                      : "#FFFFFF"
                                  }
                                >
                                  per {item.period}
                                </Text>
                              </VStack>
                            </HStack>
                            <Divider bgColor="#E6EBF2" height={ah(0.1)} />
                            <HStack
                              alignItems={"center"}
                              height={ah(45)}
                              py={ah(8)}
                              justifyContent={"space-between"}
                            >
                              {profile &&
                              profile.subscribed &&
                              profile.subscribed.planid === item.plan_id ? (
                                <Text
                                  fontSize={aw(11)}
                                  fontFamily={"RobotoRegular"}
                                  fontWeight={400}
                                  color={"#686868"}
                                >
                                  Your current plan
                                </Text>
                              ) : (
                                <LeadsWatchButton
                                  isDisabled={
                                    profile && profile.role === "user"
                                      ? true
                                      : false
                                  }
                                  onPress={() => {
                                    if (
                                      item.type === "Free" ||
                                      (allCards && allCards.length)
                                    ) {
                                      setIsOpenSubscriptionModal(true);
                                      setId({
                                        id: item.plan_id,
                                        name: item.type,
                                      });
                                    } else {
                                      setPayMentError(true);

                                      toast.error("Please Add Payment Method");
                                    }
                                  }}
                                  label="upgrade"
                                  style={{
                                    width: aw(100),
                                    height: ah(32),
                                    borderRadius: aw(50),
                                  }}
                                />
                              )}
                              <Pressable
                                onPress={() =>
                                  navigate(`/subscription/details/${item._id}`)
                                }
                              >
                                <HStack
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  <Text
                                    fontSize={aw(11)}
                                    fontFamily={"RobotoRegular"}
                                    fontWeight={400}
                                    color={
                                      profile &&
                                      profile.subscribed &&
                                      profile.subscribed.planid === item.plan_id
                                        ? "#121233"
                                        : "#FFFFFF"
                                    }
                                  >
                                    Learn more
                                  </Text>
                                  <LeadsWatchShowMoreIcon
                                    color={
                                      profile &&
                                      profile.subscribed &&
                                      profile.subscribed.planid === item.plan_id
                                        ? "#121233"
                                        : "#FFFFFF"
                                    }
                                  />
                                </HStack>
                              </Pressable>
                            </HStack>

                            {profile &&
                              profile.subscribed &&
                              profile.subscribed.planid === item.plan_id && (
                                <Box
                                  position="absolute"
                                  top={ah(-7)}
                                  right={aw(-6)}
                                  borderColor="#C1C1C1"
                                >
                                  <LeadsWatchTickIcon />
                                </Box>
                              )}
                          </Box>
                        </HStack>
                      )
                    ) : profile.free ? (
                      item.type !== "Free" && (
                        <Box
                          key={item._id}
                          px={aw(10)}
                          borderRadius={aw(10)}
                          borderWidth={2}
                          m={aw(20)}
                          width={"49%"}
                          height={ah(140)}
                          borderColor={
                            (profile && profile.free) ||
                            (profile && profile.subscribed)
                              ? profile.subscribed
                                ? profile.subscribed.planid === item.plan_id
                                  ? "#2230F8"
                                  : "#F9FAFB"
                                : profile.free.planid === item.plan_id
                                ? "#2230F8"
                                : "#F9FAFB"
                              : "#F9FAFB"
                          }
                          backgroundColor={
                            (profile && profile.free) ||
                            (profile && profile.subscribed)
                              ? profile.subscribed
                                ? profile.subscribed.planid === item.plan_id
                                  ? "#F9FAFB"
                                  : "#2230F8"
                                : profile.free.planid === item.plan_id
                                ? "#F9FAFB"
                                : "#2230F8"
                              : "#2230F8"
                          }
                        >
                          <HStack
                            height={ah(70)}
                            my={aw(10)}
                            justifyContent={"space-between"}
                          >
                            <VStack>
                              <Text
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                                fontWeight={400}
                                color={
                                  profile &&
                                  profile.subscribed &&
                                  profile.subscribed.planid === item.plan_id
                                    ? "#121233"
                                    : "#FFFFFF"
                                }
                              >
                                {item.type}
                              </Text>
                              <Text
                                fontSize={aw(11)}
                                fontFamily={"RobotoRegular"}
                                fontWeight={400}
                                color={
                                  profile &&
                                  profile.subscribed &&
                                  profile.subscribed.planid === item.plan_id
                                    ? "#121233"
                                    : "#FFFFFF"
                                }
                              >
                                {item.interval} month
                              </Text>
                            </VStack>
                            <VStack>
                              <Text
                                fontSize={aw(30)}
                                fontFamily={"RobotoMedium"}
                                fontWeight={400}
                                color={
                                  profile &&
                                  profile.subscribed &&
                                  profile.subscribed.planid === item.plan_id
                                    ? "#121233"
                                    : "#FFFFFF"
                                }
                              >
                                {item.currency === "USD" ? "$" : "₹"}{" "}
                                {item.price}
                              </Text>
                              <Text
                                pl={
                                  item.currency === "USD" ||
                                  item.name === "LeadsWatch Free"
                                    ? 2
                                    : aw(50)
                                }
                                fontSize={aw(11)}
                                fontFamily={"RobotoRegular"}
                                fontWeight={400}
                                color={
                                  profile &&
                                  profile.subscribed &&
                                  profile.subscribed.planid === item.plan_id
                                    ? "#121233"
                                    : "#FFFFFF"
                                }
                              >
                                per {item.period}
                              </Text>
                            </VStack>
                          </HStack>
                          <Divider bgColor="#E6EBF2" height={ah(0.1)} />
                          <HStack
                            alignItems={"center"}
                            height={ah(45)}
                            py={ah(8)}
                            justifyContent={"space-between"}
                          >
                            {profile &&
                            profile.subscribed &&
                            profile.subscribed.planid === item.plan_id ? (
                              <Text
                                fontSize={aw(11)}
                                fontFamily={"RobotoRegular"}
                                fontWeight={400}
                                color={"#686868"}
                              >
                                Your current plan
                              </Text>
                            ) : (
                              <LeadsWatchButton
                                isDisabled={
                                  profile && profile.role === "user"
                                    ? true
                                    : false
                                }
                                onPress={() => {
                                  if (
                                    item.type === "Free" ||
                                    (allCards && allCards.length)
                                  ) {
                                    setIsOpenSubscriptionModal(true);
                                    setId({
                                      id: item.plan_id,
                                      name: item.type,
                                    });
                                  } else {
                                    setPayMentError(true);
                                    toast.error("Please Add Payment Method");
                                  }
                                }}
                                label="upgrade"
                                style={{
                                  width: aw(100),
                                  height: ah(32),
                                  borderRadius: aw(50),
                                }}
                              />
                            )}
                            <Pressable
                              onPress={() =>
                                navigate(`/subscription/details/${item._id}`)
                              }
                            >
                              <HStack
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <Text
                                  fontSize={aw(11)}
                                  fontFamily={"RobotoRegular"}
                                  fontWeight={400}
                                  color={
                                    profile &&
                                    profile.subscribed &&
                                    profile.subscribed.planid === item.plan_id
                                      ? "#121233"
                                      : "#FFFFFF"
                                  }
                                >
                                  Learn more
                                </Text>
                                <LeadsWatchShowMoreIcon
                                  color={
                                    profile &&
                                    profile.subscribed &&
                                    profile.subscribed.planid === item.plan_id
                                      ? "#121233"
                                      : "#FFFFFF"
                                  }
                                />
                              </HStack>
                            </Pressable>
                          </HStack>

                          {profile &&
                            profile.subscribed &&
                            profile.subscribed.planid === item.plan_id && (
                              <Box
                                position="absolute"
                                top={ah(-7)}
                                right={aw(-6)}
                                borderColor="#C1C1C1"
                              >
                                <LeadsWatchTickIcon />
                              </Box>
                            )}
                        </Box>
                      )
                    ) : (
                      <HStack key={item._id} width="49%">
                        <Box
                          px={aw(10)}
                          borderRadius={aw(10)}
                          borderWidth={2}
                          m={aw(20)}
                          width={"100%"}
                          height={ah(140)}
                          borderColor={
                            (profile && profile.free) ||
                            (profile && profile.subscribed)
                              ? profile.subscribed
                                ? profile.subscribed.planid === item.plan_id
                                  ? "#2230F8"
                                  : "#F9FAFB"
                                : profile.free.planid === item.plan_id
                                ? "#2230F8"
                                : "#F9FAFB"
                              : "#F9FAFB"
                          }
                          backgroundColor={
                            (profile && profile.free) ||
                            (profile && profile.subscribed)
                              ? profile.subscribed
                                ? profile.subscribed.planid === item.plan_id
                                  ? "#F9FAFB"
                                  : "#2230F8"
                                : profile.free.planid === item.plan_id
                                ? "#F9FAFB"
                                : "#2230F8"
                              : "#2230F8"
                          }
                        >
                          <HStack
                            height={ah(70)}
                            my={aw(10)}
                            justifyContent={"space-between"}
                          >
                            <VStack>
                              <Text
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                                fontWeight={400}
                                color={
                                  profile &&
                                  profile.subscribed &&
                                  profile.subscribed.planid === item.plan_id
                                    ? "#121233"
                                    : "#FFFFFF"
                                }
                              >
                                {item.type}
                              </Text>
                              <Text
                                fontSize={aw(11)}
                                fontFamily={"RobotoRegular"}
                                fontWeight={400}
                                color={
                                  profile &&
                                  profile.subscribed &&
                                  profile.subscribed.planid === item.plan_id
                                    ? "#121233"
                                    : "#FFFFFF"
                                }
                              >
                                {item.interval} month
                              </Text>
                            </VStack>
                            <VStack>
                              <Text
                                fontSize={aw(30)}
                                fontFamily={"RobotoMedium"}
                                fontWeight={400}
                                color={
                                  profile &&
                                  profile.subscribed &&
                                  profile.subscribed.planid === item.plan_id
                                    ? "#121233"
                                    : "#FFFFFF"
                                }
                              >
                                {item.currency &&
                                  (item.currency === "USD" ? "$" : "₹")}{" "}
                                {item.price}
                              </Text>
                              <Text
                                pl={
                                  item.currency === "USD" ||
                                  item.name === "LeadsWatch Free"
                                    ? 2
                                    : aw(50)
                                }
                                fontSize={aw(11)}
                                fontFamily={"RobotoRegular"}
                                fontWeight={400}
                                color={
                                  profile &&
                                  profile.subscribed &&
                                  profile.subscribed.planid === item.plan_id
                                    ? "#121233"
                                    : "#FFFFFF"
                                }
                              >
                                per {item.period}
                              </Text>
                            </VStack>
                          </HStack>
                          <Divider bgColor="#E6EBF2" height={ah(0.1)} />
                          <HStack
                            alignItems={"center"}
                            height={ah(45)}
                            py={ah(8)}
                            justifyContent={"space-between"}
                          >
                            {profile &&
                            profile.subscribed &&
                            profile.subscribed.planid === item.plan_id ? (
                              <Text
                                fontSize={aw(11)}
                                fontFamily={"RobotoRegular"}
                                fontWeight={400}
                                color={"#686868"}
                              >
                                Your current plan
                              </Text>
                            ) : (
                              <LeadsWatchButton
                                isDisabled={
                                  profile && profile.role === "user"
                                    ? true
                                    : false
                                }
                                onPress={() => {
                                  if (
                                    item.type === "Free" ||
                                    (allCards && allCards.length)
                                  ) {
                                    setIsOpenSubscriptionModal(true);
                                    setId({
                                      id: item.plan_id,
                                      name: item.type,
                                    });
                                  } else {
                                    setPayMentError(true);

                                    toast.error("Please Add Payment Method");
                                  }
                                }}
                                label="upgrade"
                                style={{
                                  width: aw(100),
                                  height: ah(32),
                                  borderRadius: aw(50),
                                }}
                              />
                            )}
                            <Pressable
                              onPress={() =>
                                navigate(`/subscription/details/${item._id}`)
                              }
                            >
                              <HStack
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <Text
                                  fontSize={aw(11)}
                                  fontFamily={"RobotoRegular"}
                                  fontWeight={400}
                                  color={
                                    profile &&
                                    profile.subscribed &&
                                    profile.subscribed.planid === item.plan_id
                                      ? "#121233"
                                      : "#FFFFFF"
                                  }
                                >
                                  Learn more
                                </Text>
                                <LeadsWatchShowMoreIcon
                                  color={
                                    profile &&
                                    profile.subscribed &&
                                    profile.subscribed.planid === item.plan_id
                                      ? "#121233"
                                      : "#FFFFFF"
                                  }
                                />
                              </HStack>
                            </Pressable>
                          </HStack>

                          {profile &&
                            profile.subscribed &&
                            profile.subscribed.planid === item.plan_id && (
                              <Box
                                position="absolute"
                                top={ah(-7)}
                                right={aw(-6)}
                                borderColor="#C1C1C1"
                              >
                                <LeadsWatchTickIcon />
                              </Box>
                            )}
                        </Box>
                      </HStack>
                    );
                  })}
              </Flex>
            ) : (
              <HStack
                justifyContent="center"
                alignItems={"center"}
                height={ah(140)}
                width={aw(1160)}

                // style={{
                //   border: "1px solid #D7D7D7",
                //   borderTopWidth: 0,
                //   borderBottomLeftRadius: aw(10),
                //   borderBottomRightRadius: aw(10),
                // }}
              >
                <Spinner size="lg" color="#2230F8" />
              </HStack>
            )}
            {profile && profile.role !== "user" && (
              <VStack px={aw(20)}>
                <Text
                  mt={ah(10)}
                  lineHeight={ah(34)}
                  fontSize={aw(14)}
                  fontFamily={"RobotoMedium"}
                  color={"black"}
                  fontWeight={400}
                >
                  Payment Methods
                </Text>

                <HStack
                  space={aw(20)}
                  mb={ah(23)}
                  width={aw(1200)}
                  display="flex"
                  flexWrap={"wrap"}
                  distance={2}
                >
                  {/* <ScrollView horizontal> */}
                  {allCards &&
                    allCards.map((val, index) => (
                      <>
                        <Pressable
                          key={index}
                          onPress={() => {
                            val.default !== true &&
                              setOpenDefaultCardModal(true);
                            setDefaultCardId(val.id);
                          }}
                          position="relative"
                          borderRadius={aw(10)}
                          p={aw(10)}
                          width={aw(200)}
                          height={ah(100)}
                          borderColor={
                            val.default === true ? "#0000FF" : "#C1C1C1"
                          }
                          borderWidth={"2px"}
                        >
                          <Text
                            lineHeight={ah(34)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoRegular"}
                            color={"black"}
                            fontWeight={400}
                          >
                            Card {index + 1}
                          </Text>
                          <HStack space={aw(10)}>
                            <Box
                              bgColor="#0000001A"
                              height={ah(18)}
                              width={aw(30)}
                            ></Box>
                            <HStack
                              alignItems={"center"}
                              justifyContent={"center"}
                              height={ah(18)}
                            >
                              <Text
                                lineHeight={ah(34)}
                                fontSize={aw(14)}
                                fontFamily={"RobotoRegular"}
                                color={"black"}
                                fontWeight={400}
                              >
                                **** **** ****
                              </Text>
                              <Text
                                lineHeight={ah(34)}
                                fontSize={aw(14)}
                                fontFamily={"RobotoRegular"}
                                color={"black"}
                                fontWeight={400}
                              >
                                {val.card && val.card.last4}
                              </Text>
                            </HStack>
                          </HStack>
                          <HStack
                            // mt={ah(8)}
                            justifyContent={"flex-end"}
                            // width={aw(200)}
                          >
                            {/* <Box
                        width={aw(18)}
                        height={ah(18)}
                        bgColor="#919191"
                        borderRadius={aw(100)}
                        
                      >
                        <LeadsWatchRemoveCardIcon />
                      </Box> */}
                            <Pressable
                              onPress={() => handleDeleteCard(val.id)}
                              // style={{
                              //   width: aw(2),
                              //   height: aw(2),
                              //
                              // }}
                              backgroundColor={"#919191"}
                              // bacg="#919191"
                              // leftIcon={}
                              // p={aw(10)}
                              // width={aw(18)}
                              // height={ah(1)}
                              size={ah(18)}
                              borderRadius={"full"}
                              // alignSelf={"center"}
                              // alignContent={"center"}
                              alignItems={"center"}
                            >
                              <LeadsWatchRemoveCardIcon />
                            </Pressable>
                          </HStack>
                          {val.default === true && (
                            <Box
                              position="absolute"
                              top={ah(-7)}
                              right={aw(-6)}
                              borderColor="#C1C1C1"
                            >
                              <LeadsWatchTickIcon />
                            </Box>
                          )}
                        </Pressable>
                      </>
                    ))}

                  <HStack
                    width={aw(100)}
                    height={ah(100)}
                    alignItems={"center"}
                    justifyContent={"center"}
                    bgColor="#0000001A"
                    borderRadius={aw(10)}
                    borderWidth={
                      paymentError && allCards && allCards.length === 0
                        ? aw(1)
                        : 0
                    }
                    borderColor={
                      paymentError && allCards && allCards.length === 0
                        ? "#FA4E2D"
                        : "#C1C1C1"
                    }
                  >
                    {/* <LeadsWatchButton
                    onPress={() => setIsAddCardModalOpen(true)}
                    style={{
                      width: aw(32),
                      height: ah(32),
                      borderRadius: "100%",
                    }}
                    
                    leftIcon={<LeadsWatchAddIcon />}
                  /> */}
                    <Pressable
                      onPress={() => setIsAddCardModalOpen(true)}
                      size={ah(32)}
                      backgroundColor="#919191"
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderRadius={"full"}
                    >
                      <LeadsWatchAddIcon />
                    </Pressable>
                  </HStack>
                  {/* </ScrollView> */}
                </HStack>
              </VStack>
            )}
          </LeadsWatchCard>
        </Box>
        {/* <Box mt={ah(30)} pb={ah(30)}>
          <LeadsWatchCard width={aw(1248)} height={ah(390)}>
            <VStack p={aw(20)}>
              <Text
                lineHeight={ah(34)}
                fontSize={aw(14)}
                fontFamily={"RobotoMedium"}
                color={"black"}
                fontWeight={400}
              >
                Invoice History
              </Text>
              <HStack
                alignItems={"center"}
                style={{
                  height: ah(60),
                  background: "#F9FAFB 0% 0% no-repeat padding-box",
                  border: "1px solid #D7D7D7",
                  borderTopLeftRadius: aw(10),
                  borderTopRightRadius: aw(10),
                }}
              >
                {["No", "Date", "Billing", "Plan", "Amount", "Download"].map(
                  (val, index) => {
                    return (
                      <HStack
                        key={index}
                        alignItems={"center"}
                        width={aw(200)}
                        justifyContent="flex-start"
                      >
                        <Text
                          width={aw(200)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          ml={aw(20)}
                          color="#121233"
                        >
                          {val}
                        </Text>
                      </HStack>
                    );
                  }
                )}
              </HStack>
              {!invoiceLoading ? (
                <ScrollView
                  maxHeight={ah(300)}
                  // showsVerticalScrollIndicator={false}
                  style={{
                    borderBottomRightRadius: aw(10),
                    borderBottomLeftRadius: aw(10),
                    borderbottomWidth: "1px solid #D7D7D7",
                    borderTopWidth: "0px",
                    borderColor: "#D7D7D7",
                    borderWidth: "1px",
                  }}
                >
                  <FlatList
                    // showsVerticalScrollIndicator={false}
                    // height={ah(300)}
                    ListEmptyComponent={
                      <HStack
                        justifyContent="center"
                        alignItems={"center"}
                        height={ah(200)}
                      >
                        <Text
                          color="black"
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                        >
                          No data found
                        </Text>
                      </HStack>
                    }
                    data={invoices && invoices.list && invoices.list}
                    keyExtractor={(item) => item._id}
                    renderItem={({ item, index }) => (
                      <HStack>
                        {[
                          "no",
                          "date",
                          "billing",
                          "plan_name",
                          "amount",
                          "download",
                        ].map((val) => (
                          <HStack
                            key={val}
                            height={ah(60)}
                            width={aw(200)}
                            alignItems={"center"}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                              minHeight: ah(58),
                              borderTopWidth: index === 0 ? 0 : "1px",
                              borderLeftWidth: "0px",
                              borderRightWidth: "0px",
                              borderBottomWidth: "0px",
                              borderColor: "#D7D7D7",
                            }}
                          >
                            <Text
                              color="#121233"
                              width={aw(240)}
                              ml={aw(20)}
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                            >
                              {val === "download" || val === "billing" ? (
                                val === "download" ? (
                                  <Link href={`${item["pdf"]}`}>
                                    <Text underline color="#0000FF">
                                      {`invoice_${moment(item["date"]).format(
                                        "DDMMMMYY"
                                      )}`}
                                    </Text>
                                  </Link>
                                ) : (
                                  <Text
                                    color="#121233"
                                    fontSize={aw(14)}
                                    fontFamily={"RobotoMedium"}
                                  >
                                    Monthly
                                  </Text>
                                )
                              ) : val === "no" || val === "date" ? (
                                val === "no" ? (
                                  index + 1
                                ) : (
                                  moment(item[val]).format("YYYY-MM-DD")
                                )
                              ) : (
                                item[val]
                              )}
                            </Text>
                          </HStack>
                        ))}
                      </HStack>
                    )}
                  />
                </ScrollView>
              ) : (
                <HStack
                  justifyContent="center"
                  alignItems={"center"}
                  height={ah(200)}
                  // width={aw(580)}
                  style={{
                    border: "1px solid #D7D7D7",
                    borderTopWidth: 0,
                    borderBottomLeftRadius: aw(10),
                    borderBottomRightRadius: aw(10),
                  }}
                >
                  <Spinner size="lg" color="#2230F8" />
                </HStack>
              )}
            </VStack>
          </LeadsWatchCard>
        </Box> */}

        <LeadsWatchModal
          width={aw(420)}
          height={ah(200)}
          showModal={openDefaultCardModal}
          onClose={() => {
            setOpenDefaultCardModal(false);
          }}
          heading={
            <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
              Default Card
            </Text>
          }
        >
          <VStack>
            <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
              Are you sure want to make this card as default?
            </Text>
            <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
              <LeadsWatchButton
                onPress={() => setOpenDefaultCardModal(false)}
                label="cancel"
                style={{
                  width: aw(100),
                  height: ah(32),
                  borderRadius: aw(50),
                }}
              />
              <LeadsWatchButton
                isLoading={loading}
                onPress={handleAddDefaulrCard}
                label="Proceed"
                style={{
                  width: aw(100),
                  height: ah(32),
                  borderRadius: aw(50),
                }}
                bg={"#2230F8"}
                color={"#F9FAFB"}
              />
            </HStack>
          </VStack>
        </LeadsWatchModal>
      </VStack>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure want to delete this card?
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDeleteCard(deleteId)}
              label="Proceed"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>

      <LeadsWatchModal
        width={aw(600)}
        height={ah(680)}
        showModal={isAddCardModalOpen}
        onClose={() => {
          setIsAddCardModalOpen(false);
          reset({
            address: "",
            address2: "",
            card_CVC: "",
            country: "",
            expiry: "",
            number: "",
            city: "",
            phone: "91",
            state: "",
            zipcode: "",
          });

          setRefresh(!refresh);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Add Card Details
          </Text>
        }
      >
        <StripeWrapper>
          <CardForm
            isAddCardModalOpen={isAddCardModalOpen}
            toast={toast}
            setIsAddCardModalOpen={setIsAddCardModalOpen}
            control={control}
            handleSubmit={handleSubmit}
            reset={reset}
            errors={errors}
            getValues={getValues}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </StripeWrapper>
      </LeadsWatchModal>
      <StripeWrapper>
        <AddSubscription
          isOpenSubscriptionModal={isOpenSubscriptionModal}
          setIsOpenSubscriptionModal={setIsOpenSubscriptionModal}
          id={id}
        />
      </StripeWrapper>
      {/* <PaymentCard />
      <Cr
      <button onClick={handleCreateCard}>submit</button> */}
    </ScrollView>
  );
};

export default Subscription;
