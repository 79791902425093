import { React, useState, useEffect, useMemo } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  useBreakpointValue,
  Menu,
  Flex,
} from "native-base";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { aw, ah } from "../../utils";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchCalendar from "../../components/LeadsWatchCalendar";
import { getVerticals, download } from "../../store/actions/reports";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";

import LeadsWatchDownloadIcon from "../../components/svg/LeadsWatchDownloadIcon";
import LeadsWatchMenu from "../../components/LeadsWatchMenu";

const Verticals = ({ isOpen }) => {
  const [downloading, setDownloading] = useState(false);
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [sort, setSort] = useState({});
  const [filters, setFilters] = useState({});
  const [refresh, setRefresh] = useState(false);
  const profile = useSelector((state) => state.auth.profile);

  const [currentPage, setCurrentPage] = useState(
    page && page.verticals ? parseInt(page.verticals) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.verticalsReports
      ? parseInt(pagecount.verticalsReports)
      : 10
  );

  const [isLoading, setIsLoading] = useState(true);
  const [filters1, setFilters1] = useState({});

  const dispatch = useDispatch();
  const allVerticals = useSelector((state) => state.reports.verticals);

  const { control } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.verticalsReports
          ? pagecount.verticalsReports.toString()
          : "10",
    },
  });
  const handleFromDate = (date) => {
    if (date) {
      setStartDate(date);
      setFilters({ ...filters, start_date: moment(date).format("YYYY-MM-DD") });
    }
  };
  const handleEndDate = (date) => {
    if (date) {
      setEndDate(date);
      setFilters({ ...filters, end_date: moment(date).format("YYYY-MM-DD") });
    }
  };

  useEffect(() => {
    if (filters.start_date && filters.end_date) {
      setFilters1({
        ...filters1,
        start_date: filters.start_date,
        end_date: filters.end_date,
      });
    }
    setCurrentPage(1);
    localStorage.setItem(
      "page",
      JSON.stringify({ ...page, verticalsReports: 1 })
    );
  }, [filters.start_date, filters.end_date]);

  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, verticalsReports: value })
    );
    setCurrentPage(1);
    localStorage.setItem(
      "page",
      JSON.stringify({ ...page, verticalsReport: 1 })
    );
  };
  const fetchVerticals = async () => {
    setIsLoading(true);
    try {
      await dispatch(getVerticals(currentPage, selectCount, filters1));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchVerticals();
  }, [currentPage, selectCount, sort, filters1]);
  useEffect(() => {
    if (filters.start_date && filters.end_date) {
      setFilters1({
        ...filters1,
        start_date: filters.start_date,
        end_date: filters.end_date,
      });
      setCurrentPage(1);
      localStorage.setItem(
        "page",
        JSON.stringify({ ...page, verticalsReport: 1 })
      );
    }
  }, [filters.from_date, filters.end_date]);
  const handleDownload = async (type) => {
    setDownloading(true);
    try {
      await dispatch(
        download(
          currentPage,
          selectCount,
          { filters: filters1 },
          "verticals",
          type
        )
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setDownloading(false);
  };
  const memoizedChildComponent = useMemo(() => {
    return (
      <LeadsWatchTable
        isOpen={isOpen}
        keys={["name", "desc", "status"]}
        heading={["name", "desc", "status"]}
        data={allVerticals && allVerticals.list ? allVerticals.list : []}
        // data={allVerticals}
        isLoading={isLoading}
        screen="reports"
        currentPage={currentPage}
        selectCount={selectCount}
      />
    );
  }, [isLoading, isOpen]);
  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <VStack
            height={ah(35)}
            mt={ah(10)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
            zIndex={1}
          >
            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control}
                    name={"select"}
                    list={["10", "20", "50", "100"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    width={79}
                    height={ah(32)}
                  />
                </Center>
              </HStack>

              <HStack space={aw(12)}>
                <LeadsWatchMenu
                  width={aw(120)}
                  mr={aw(5)}
                  title={
                    // <Pressable>
                    <Flex
                      direction="row"
                      borderColor="#D1D5DB"
                      bg="#FAFBFD"
                      borderWidth={aw(1)}
                      width={aw(115)}
                      height={ah(38)}
                      borderRadius={aw(5)}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      {downloading ? null : <LeadsWatchDownloadIcon />}
                      <Text
                        fontFamily="RobotoRegular"
                        color="black"
                        fontSize={aw(14)}
                      >
                        {downloading ? "Downloading..." : "Download"}
                      </Text>
                    </Flex>
                    // </Pressable>
                  }
                >
                  <Menu.Item
                    bgColor="white"
                    _hover={{ bgColor: "#F5F7FB" }}
                    onPress={() => handleDownload("csv")}
                  >
                    <Text
                      fontSize={aw(14)}
                      fontFamily="RobotoRegular"
                      color="#212121"
                    >
                      CSV
                    </Text>
                  </Menu.Item>
                  <Menu.Item
                    bgColor="white"
                    _hover={{ bgColor: "#F5F7FB" }}
                    onPress={() => handleDownload("xlsx")}
                  >
                    <Text
                      fontSize={aw(14)}
                      fontFamily="RobotoRegular"
                      color="#212121"
                    >
                      XLSX
                    </Text>
                  </Menu.Item>
                </LeadsWatchMenu>
                <LeadsWatchCalendar
                  startDate={startDate}
                  handleFromDate={handleFromDate}
                  handleEndDate={handleEndDate}
                  width={aw(120)}
                  height={ah(36)}
                  endDate={endDate}
                />
                {/* <HStack>
                  <LeadsWatchSelect
                    control={control}
                    name={"status"}
                    placeholder="Status"
                    list={["Active", "InActive"]}
                    handleChange={(value) =>
                      setFilters1({ ...filters1, status: value })
                    }
                    width={aw(100)}
                    // height={ah(32)}
                  />
                </HStack> */}
                <LeadsWatchAsyncSelect
                  isClearable={true}
                  height={ah(38)}
                  required={true}
                  minWidth={aw(135)}
                  defaultOptions={[
                    { label: "Active", value: "active" },
                    { label: "Inactive", value: "inactive" },
                  ]}
                  control={control}
                  placeholder="Status"
                  name="status"
                  handleChange={(value) => {
                    if (value) {
                      setCurrentPage(1);
                      localStorage.setItem(
                        "page",
                        JSON.stringify({ ...page, verticalsReport: 1 })
                      );
                      setFilters1({ ...filters1, status: value.value });
                    } else {
                      setCurrentPage(1);
                      localStorage.setItem(
                        "page",
                        JSON.stringify({ ...page, verticalsReport: 1 })
                      );
                      // // setFilters(delete filters.status);
                      delete filters1.status;
                      setFilters1({ ...filters1 });
                    }
                  }}
                />
              </HStack>
            </HStack>
          </VStack>

          <VStack>
            <VStack mt={3}>{memoizedChildComponent}</VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={
                  allVerticals && allVerticals.total_count
                    ? allVerticals.total_count
                    : 1
                }
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, verticalsReport: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>
    </>
  );
};
export default Verticals;
