import React, { useState, useEffect } from "react";
import {
  HStack,
  VStack,
  Text,
  Divider,
  Stack,
  Box,
  ScrollView,
  Pressable,
  FlatList,
  Switch,
  Checkbox,
  Spinner,
  Flex,
  Tooltip,
  useBreakpointValue,
} from "native-base";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import LeadsWatchCard from "../../components/LeadsWatchCard";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import { aw, ah } from "../../utils";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchInput from "../../components/LeadsWatchInput";
import LeadsWatchCalendar from "../../components/LeadsWatchCalendar";
import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchTextArea from "../../components/LeadsWatchTextArea";
import LeadsWatchEditIcon from "../../components/svg/LeadsWatchEditIcon";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";

import {
  getAllVerticals,
  getAllVerticalFields,
} from "../../store/actions/verticals";
import {
  getBuyersByVertical,
  createCampaign,
  getSingleCampaign,
  editCampaign,
  getBuyersByType,
} from "../../store/actions/campaigns";
import { getAllPublishers } from "../../store/actions/publishers";
import LeadsWatchDeleteIcon from "../../components/svg/LeadsWatchDeleteIcon";
import {
  getVerticalGroups,
  getGroupByVertical,
} from "../../store/actions/groups";

import { getUnAllocatedNumbers } from "../../store/actions/calls";
import LeadsWatchCopyCodeIcon from "../../components/svg/LeadesWatchCopyCodeIcon";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
import LeadsWatchSingleProfileIcon from "../../components/svg/LeadsWatchSingleProfileIcon";
import LeadsWatchGroupIcon from "../../components/svg/LeadsWatchGroupIcon";
import LeadsWatchInfoIcon from "../../components/svg/LeadsWatchInfoIcon";
// import { getBuyerRoutes } from "../../store/actions/buyers";

const data = ["Vertical Field", "Filter Value", "Match Type"];

const CreateCampaign = (props) => {
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const [vfCurrentPage, setVfCurrentPage] = useState(1);
  const [vfCount, setVFCout] = useState(10);
  const [buyersType, setBuyersType] = useState("");
  const [callType, setCallType] = useState("");

  // const ivrBuyerRoutes = useSelector((state) => state.buyers.buyerRoutes);

  //pubpagination
  const [pubCurrentPage, setPubCurrentPage] = useState(1);
  const [pubCount, setPubCout] = useState(10);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [allBuyers, setAllBuyers] = useState([]);
  const [pubId, setPubId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [list, setList] = useState([]);
  const unallNum = useSelector((state) => state.calls.unallocatedNum);
  const { id, type } = useParams();
  const allVerticals = useSelector((state) => state.verticals.allVerticals);
  const singleCampaign = useSelector((state) => state.campaigns.singleCampaign);
  const allVerticalFields = useSelector(
    (state) => state.verticals.allVerticalFields
  );
  const allPublishers = useSelector((state) => state.publishers.allPublishers);
  const allVerticalBuyers = useSelector(
    (state) => state.campaigns.verticalBuyers
  );
  const allVerticalGroups = useSelector((state) => state.groups.verticalGroups);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [select, setSelect] = useState({ campaign_filters: [] });
  const [b, setB] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [save, setSave] = useState(false);
  const [checked, setChecked] = useState([]);
  const [campaignBuyers, setCampaignBuyers] = useState([]);
  const [isPublisherModal, setIsPublisherModal] = useState(false);
  const [publisher, setPublisher] = useState({});
  const [payOutType, setPayoutType] = useState("");
  const [campaignPublishers, setCampaignPublishers] = useState([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    trigger: trigger1,
    getValues,
  } = useForm({
    mode: "all",
    defaultValues: {
      select: "10",
      pubSelect: "10",
    },
  });
  const {
    control: control1,
    // handleSubmit: handleSubmit1,
    trigger,
    formState: { errors: errors1 },
    reset: reset1,

    getValues: getValues1,
  } = useForm({
    mode: "all",
  });
  const {
    control: control2,
    // handleSubmit: handleSubmit2,
    // formState: { errors: errors2 },
    // reset: reset2,
    getValues: getValues2,
  } = useForm({
    mode: "all",
  });
  const {
    control: control3,
    // handleSubmit: handleSubmit2,
    // formState: { errors: errors2 },
    reset: reset3,
    getValues: getValues3,
  } = useForm({
    mode: "all",
  });
  // hedings;
  const groupHeadings =
    type === "lead"
      ? ["Group Name", "Vertical Name"]
      : ["Group Name", "Buyers Count"];
  const groupData =
    type === "lead"
      ? ["group_name", "vertical_name"]
      : ["group_name", "buyer_count"];
  const routeHeadings =
    type === "ppc"
      ? ["Route Name", "Buyer Route Name", "Time(s)"]
      : ["Route Name", "Buyer Route Name"];
  const routeData =
    type === "ppc"
      ? ["route_name", "buyer_route_name", "time"]
      : ["route_name", "buyer_route_name"];
  const fetchVerticals = async () => {
    try {
      await dispatch(getAllVerticals(1, 10));
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchVerticals();
    unAllNumber();
  }, []);
  const handleCheckbox = (data, item) => {
    if (checked.includes(data)) {
      setChecked((old) => old.filter((row) => row !== data));
      setAllBuyers((old) => old.filter((row) => row._id !== item._id));
    } else {
      setChecked((old) => [...old, data]);
      setAllBuyers((old) => [...old, { ...item, type: buyersType }]);
    }
  };
  const handleChangeVertical = (value) => {
    setSelect({ ...select, vertical_id: value.value });
    setVfCurrentPage(1);
  };
  const unAllNumber = async () => {
    try {
      await dispatch(getUnAllocatedNumbers(1, 10));
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    unallNum &&
      unallNum.list &&
      setList([...unallNum.list.map((val) => val.number)]);
  }, [unallNum]);
  const fetchPublishers = async () => {
    // setPubLoading(true);
    try {
      await dispatch(
        getAllPublishers(
          pubCurrentPage,
          pubCount,
          undefined,
          undefined,
          getValues2("search")
        )
      );

      // response && setPaginatedPub([...paginatedPub, ...response.list]);
    } catch (err) {
      console.log(err.message);
    }
    // setPubLoading(false);
  };

  useEffect(() => {
    fetchPublishers();
  }, [pubCurrentPage, pubCount]);

  const handleChangeSelect = (value, from) => {
    if (from === "publisher") {
      setPubCout(parseInt(value));
      setPubCurrentPage(1);
    } else {
      setVFCout(parseInt(value));
      setVfCurrentPage(1);
    }
  };
  const onChangeHeaderCheckbox = () => {
    if (buyersType === "single") {
      if (allVerticalBuyers.list.every((val) => checked.includes(val._id))) {
        const allIds = allVerticalBuyers.list.map((val) => val._id);
        setChecked(checked.filter((val) => !allIds.includes(val)));
        setAllBuyers(allBuyers.filter((val) => !allIds.includes(val._id)));
      } else {
        setChecked([
          ...checked,
          ...allVerticalBuyers.list.map((val) => val._id),
        ]);
        setAllBuyers([
          ...allBuyers,
          ...allVerticalBuyers.list.map((val) => {
            return { ...val, type: buyersType };
          }),
        ]);
      }
    } else {
      if (allVerticalGroups.list.every((val) => checked.includes(val._id))) {
        const allIds = allVerticalGroups.list.map((val) => val._id);
        setChecked(checked.filter((val) => !allIds.includes(val)));
        setAllBuyers(allBuyers.filter((val) => !allIds.includes(val._id)));
      } else {
        setChecked([
          ...checked,
          ...allVerticalGroups.list.map((val) => val._id),
        ]);
        setAllBuyers([
          ...allBuyers,
          ...allVerticalGroups.list.map((val) => {
            return { ...val, type: buyersType };
          }),
        ]);
      }
    }
  };
  const handleOpenModal = (buyertype) => {
    if (type === "lead") {
      if (getValues("vertical_id")) {
        setIsModalOpen(true);
        setIsLoading(true);
        setBuyersType(buyertype);
        setCurrentPage(1);
      } else {
        toast.error("Please Select Vertical");
      }
    } else if (type === "ivr") {
      setIsModalOpen(true);
      setIsLoading(true);
      setBuyersType(buyertype);
      setCurrentPage(1);
    } else {
      setIsModalOpen(true);
      setBuyersType(buyertype);
      setCurrentPage(1);
    }
  };
  const handleAddBuyer = () => {
    const uniqueArray = allBuyers.filter(
      (item, index, self) => index === self.findIndex((t) => t._id === item._id)
    );
    // setCampaignBuyers(uniqueArray.map((val) => val));
    if (type === "ppc") {
      if (uniqueArray.length > 10) {
        toast.error("Buyers Can Not be more than 10");
      } else {
        setCampaignBuyers(
          uniqueArray.map((val) => {
            return { ...val, timeout: parseFloat(getValues3(val._id)) };
          })
        );
        setIsModalOpen(false);
      }
    } else {
      setCampaignBuyers(uniqueArray.map((val) => val));
      setIsModalOpen(false);
    }
  };

  const handleDeleteBuyer = (id) => {
    const finalCampaignBuyers = campaignBuyers.filter((val) => val._id !== id);
    setCampaignBuyers(finalCampaignBuyers);
    setChecked(checked.filter((val) => val !== id));
  };
  const handleAddPublisher = (data) => {
    setIsPublisherModal(true);
    setPublisher(data);
    // setList(list.filter((val) => val !== "none"));
  };
  const addPublisher = async () => {
    if (
      getValues1("payout_type") &&
      getValues1("currency") &&
      getValues1("amount") &&
      getValues1("post_back_url")
      // getValues1("number")
    ) {
      setIsPublisherModal(false);
      const newData = {
        publisher_id: publisher._id ? publisher._id : publisher.publisher_id,
        publisher_name: publisher.publisher_name,
        payout_type:
          getValues1("payout_type") && getValues1("payout_type").value,
        price: parseInt(getValues1("amount")),
        post_back_url: getValues1("post_back_url"),
        currency: getValues1("currency") && getValues1("currency").value,
        number: getValues1("number") && getValues1("number").value,
      };

      if (campaignPublishers.length) {
        if (
          campaignPublishers.find(
            (val) => val.publisher_id === newData.publisher_id
          )
        ) {
          const data = campaignPublishers.map((val) => {
            if (val.publisher_id === newData.publisher_id) {
              return newData;
            } else {
              return val;
            }
          });

          setCampaignPublishers(data);
        } else {
          setCampaignPublishers([...campaignPublishers, newData]);
        }
      } else {
        setCampaignPublishers([...campaignPublishers, newData]);
      }

      // setCampaignPublishers([...campaignPublishers, newData]);

      setRefresh(!refresh);
      getValues1("number") &&
        setList(list.filter((val) => val !== getValues1("number").value));
      reset1({
        post_back_url: "",
        amount: "",
        payout_type: "",
        currency: "",
        number: "",
      });
      setIsPublisherModal(false);
      setPubId(null);
    } else {
      await trigger();
    }
  };
  const handleDeletePublisher = (id, number) => {
    setCampaignPublishers(
      campaignPublishers.filter((val) => val.publisher_id !== id)
    );
    number && setList([...list, number]);
  };
  const handleFromDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }

    if (month < 9) {
      month = "0" + (month + 1);
    } else {
      month = month + 1;
    }

    const formate = `${year}-${month}-${day}`;
    setSelect({ ...select, fromDate: date, start_date: formate });
  };
  const handleEndDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }

    if (month < 9) {
      month = "0" + (month + 1);
    } else {
      month = month + 1;
    }

    const formate = `${year}-${month}-${day}`;
    setSelect({ ...select, toDate: date, end_date: formate });
  };

  const handleCreateCampaign = async (data) => {
    if (campaignPublishers.length && campaignBuyers.length) {
      if (select.start_date && select.end_date) {
        setSave(true);
        const newData = select.campaign_filters.map((val) => {
          if (val.key in data) {
            return {
              ...val,
              value:
                val.dataType === "number" && val.match !== "in_between"
                  ? parseFloat(data[`f_${val.key}`])
                    ? parseFloat(data[`f_${val.key}`])
                    : ""
                  : data[`${val.key}`],
            };
          } else {
            return val;
          }
        });
        const finalData = {
          name: data.campaign_name,
          desc: data.desc,
          status: data.campaign_status.value,
          vertical_id: data.vertical_id && data.vertical_id.value,
          vertical_name: data.vertical_id && data.vertical_id.label,
          lead_distribution:
            data.lead_distribution.value === "simultaneous"
              ? 0
              : data.lead_distribution.value === "round_robin"
              ? 2
              : 1,
          allow_duplicate:
            data.allow_duplicate.value === "in_this_campaigns" ? 0 : 1,
          start_date: select.start_date,
          end_date: select.end_date,
          campaign_filters: newData.filter(
            (val) =>
              val.value !== undefined &&
              val.value !== null &&
              val.value !== "" &&
              val.match !== undefined &&
              val.match !== ""
          ),
          campaign_buyers: campaignBuyers,
          campaign_publishers: campaignPublishers,
          type: type,
          call_type:
            type === "ppc" ? data.call_type && data.call_type.value : null,
          welcome_msg: data.welcome_msg,
          on_submit_key: data.finish_key,
          ivr_msg: data.ivr_msg,
        };

        if (props.name === "edit") {
          try {
            await dispatch(editCampaign(id, finalData));
            setSelect({ campaign_filters: [] });
            setCampaignBuyers([]);
            setCampaignPublishers([]);
            navigate(-1);
            reset();
          } catch (err) {
            toast.error(err.message.toLowerCase());
          }
        } else {
          try {
            await dispatch(createCampaign(finalData));
            setSelect({ campaign_filters: [] });
            setCampaignBuyers([]);
            setCampaignPublishers([]);
            reset();

            navigate(-1);
          } catch (err) {
            console.log(err);
            toast.error(err.message.toLowerCase());
          }
        }

        setSave(false);
      } else {
        setError(true);

        toast.error("Please Select Start Date and End Date");
      }
    } else {
      toast.error("Please Select AtLeast One Buyer and One Publisher");
    }
  };
  const onError = (errors, e) => {
    setSave(true);
    toast.error("Please Fill All  Required Fields");
    setSave(false);
  };

  //vertical Buyers
  useEffect(() => {
    const buyerVerticals = async () => {
      setIsLoading(true);
      if (buyersType === "single") {
        try {
          await dispatch(
            getBuyersByVertical(getValues("vertical_id").value, currentPage, 10)
          );
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          await dispatch(
            getVerticalGroups(getValues("vertical_id").value, currentPage, 10)
          );
        } catch (err) {
          console.log(err);
        }
      }
      setIsLoading(false);
    };
    const ivrbuyers = async () => {
      setIsLoading(true);
      if (buyersType === "single") {
        try {
          await dispatch(getBuyersByType(type, currentPage, 10));
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          await dispatch(getGroupByVertical("ivr", currentPage, 10));
        } catch (err) {
          console.log(err);
        }
      }
      setIsLoading(false);
    };

    if (isModalOpen && type !== "ppc") {
      if (type === "lead") {
        setTimeout(() => {
          buyerVerticals();
        }, 300);
      } else if (type === "ivr") {
        setTimeout(() => {
          ivrbuyers();
        }, 300);
      }
    }
  }, [getValues("vertical_id"), isModalOpen === true, currentPage]);
  useEffect(() => {
    const ppcBuyers = async () => {
      setIsLoading(true);
      try {
        await dispatch(getBuyersByType(type, currentPage, 10));
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    };
    if (type === "ppc") {
      ppcBuyers();
    }
  }, []);
  //for setting default time 10
  useEffect(() => {
    if (type === "ppc") {
      if (allVerticalBuyers && allVerticalBuyers.list) {
        const camp = allVerticalBuyers.list
          .map((val) => {
            const matchingTime = campaignBuyers.find(
              (time) => time._id === val._id
            );
            return matchingTime
              ? { ...matchingTime, timeout: matchingTime.timeout }
              : { ...val, timeout: 10 };
          })
          .flat();

        const resetvalues = camp.reduce((acc, val) => {
          acc[val._id] = val.timeout;
          return acc;
        }, {});
        reset3(resetvalues);
      }
    }
  }, [allVerticalBuyers, campaignBuyers]);
  //vertical fields
  useEffect(() => {
    const verticalFields = async () => {
      try {
        const response = await dispatch(
          getAllVerticalFields(
            vfCurrentPage,
            vfCount,
            getValues("vertical_id").value
          )
        );
      } catch (err) {
        console.log(err.message);
      }
    };
    if (getValues("vertical_id") && getValues("vertical_id").value) {
      verticalFields();
    }
  }, [getValues("vertical_id"), vfCurrentPage, vfCount]);

  // ........................for edit....................
  useEffect(() => {
    if (props.name === "edit" && singleCampaign) {
      let editData;
      let d;
      const date = new Date(singleCampaign.start_date);
      let year = date.getFullYear();
      let month = date.getMonth();
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }

      if (month < 9) {
        month = "0" + (month + 1);
      } else {
        month = month + 1;
      }
      const sFormate = `${year}-${month}-${day}`;
      const date1 = new Date(singleCampaign.end_date);
      let year1 = date1.getFullYear();
      let month1 = date1.getMonth();
      let day1 = date1.getDate();
      if (day1 < 10) {
        day1 = "0" + day1;
      }

      if (month1 < 9) {
        month1 = "0" + (month1 + 1);
      } else {
        month1 = month1 + 1;
      }

      const eFormate = `${year1}-${month1}-${day1}`;
      editData = {
        select: "10",
        pubSelect: "10",
        vertical_id: {
          value: singleCampaign.vertical_id,
          label: singleCampaign.vertical_name,
        },
        campaign_name: singleCampaign.name,
        desc: singleCampaign.desc,
        campaign_status: {
          value: singleCampaign.status,
          label:
            singleCampaign.status &&
            singleCampaign.status
              .toLowerCase()
              .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
        },
        call_type: {
          value: singleCampaign.call_type,
          label: singleCampaign.call_type === "post" ? "Post" : "Ping Post",
        },
        welcome_msg: singleCampaign.welcome_msg,
        ivr_msg: singleCampaign.ivr_msg,
        finish_key: singleCampaign.on_submit_key,
        posting_instructions: singleCampaign.posting_url,
        lead_distribution:
          singleCampaign.lead_distribution === 0
            ? { value: "simultaneous", label: "Simultaneous" }
            : singleCampaign.lead_distribution === 1
            ? { value: "priority", label: "Priority" }
            : { value: "round_robin", label: "Round Robin" },
        allow_duplicate:
          singleCampaign.allow_duplicate === 0
            ? { value: "in_this_campaigns", label: "In This Campaigns" }
            : {
                value: "in_entire_application",
                label: "In Entire Application",
              },
      };
      if (
        singleCampaign.campaign_filters &&
        singleCampaign.campaign_filters.length
      ) {
        for (let c of singleCampaign.campaign_filters) {
          editData = {
            ...editData,
            [c.key]: c["value"],
            [`select_${c.key}`]: { label: c["label"], value: c["match"] },
          };
        }
      }
      if (
        singleCampaign.campaign_filters &&
        singleCampaign.campaign_filters.length
      ) {
        for (let c of singleCampaign.campaign_filters) {
          d = {
            ...select,
            ...d,
            [c.key]: c.match,
            fromDate: new Date(singleCampaign.start_date),
            toDate: new Date(singleCampaign.end_date),
            start_date: sFormate,
            end_date: eFormate,
            campaign_filters: singleCampaign.campaign_filters,
          };
        }
      } else {
        d = {
          ...select,
          ...d,
          fromDate: new Date(singleCampaign.start_date),
          toDate: new Date(singleCampaign.end_date),
          start_date: sFormate,
          end_date: eFormate,
          campaign_filters: singleCampaign.campaign_filters
            ? singleCampaign.campaign_filters
            : [],
        };
      }

      singleCampaign.vertical_id &&
        handleChangeVertical(singleCampaign.vertical_id);
      setCampaignBuyers(
        singleCampaign.campaign_buyers.length
          ? singleCampaign.campaign_buyers
          : singleCampaign.campaign_groups
      );
      setChecked(singleCampaign.campaign_buyers.map((val) => val._id));
      setAllBuyers(singleCampaign.campaign_buyers);
      setCampaignPublishers(singleCampaign.campaign_publishers);
      setSelect(d);
      reset(editData);
    }
  }, [singleCampaign]);

  const fetchSingleCampaign = async () => {
    try {
      await dispatch(getSingleCampaign(id));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (props.name === "edit") {
      fetchSingleCampaign();
    }
  }, []);

  useEffect(() => {
    setError(false);
  }, [select.startDate, select.end_date]);
  //for Buyer priority settings

  // const handleUpPressed = (id) => {
  //   const indx = campaignBuyers.findIndex((val) => val._id === id);
  //   setCampaignBuyers(
  //     campaignBuyers.map((val, index) =>
  //       val._id === id || index === indx - 1
  //         ? val._id === id
  //           ? { ...val, priority: val.priority - 1 }
  //           : { ...val, priority: val.priority + 1 }
  //         : val
  //     )
  //   );
  // };
  // const handleDownPressed = (id) => {
  //   const indx = campaignBuyers.findIndex((val) => val._id === id);
  //   setCampaignBuyers(
  //     campaignBuyers.map((val, index) =>
  //       val._id === id || index === indx + 1
  //         ? val._id === id
  //           ? { ...val, priority: val.priority + 1 }
  //           : { ...val, priority: val.priority - 1 }
  //         : val
  //     )
  //   );
  // };
  const handleEditPublisher = (item) => {
    setPubId(item);
    setPublisher(item);
    setIsPublisherModal(true);
    if (item.number && list.includes(item.number) === false) {
      setList([...list, item.number]);
    }
    reset1({
      post_back_url: item.post_back_url,
      amount: item.price.toString(),
      payout_type: {
        value: item.payout_type,
        label: item.payout_type === "fixed" ? "Fixed Amount" : "Percentage",
      },
      currency: { value: item.currency, label: item.currency },
      number: item.number ? { value: item.number, label: item.number } : "",
    });
  };
  const handleChangeFilters = (value, item, label) => {
    if (value) {
      if (select.campaign_filters.length) {
        if (select.campaign_filters.find((val) => val.key === item.name)) {
          const data = select.campaign_filters.map((val) => {
            if (val.key === item.name) {
              return {
                ...val,
                match: value ? value : "",
                label: label ? label : "",
              };
            } else {
              return val;
            }
          });
          setSelect({
            ...select,
            [item.name]: value ? value : "",
            campaign_filters: data,
          });
        } else {
          setSelect({
            ...select,
            [item.name]: value ? value : "",
            campaign_filters: [
              ...select.campaign_filters,
              {
                key: item.name,
                match: value ? value : "",
                label: label ? label : "",
                dataType: item.datatype,
              },
            ],
          });
        }
      } else {
        setSelect({
          ...select,
          [item.name]: value ? value : "",
          campaign_filters: [
            ...select.campaign_filters,
            {
              key: item.name,
              match: value ? value : "",
              label: label ? label : "",
              dataType: item.datatype,
            },
          ],
        });
      }
    } else {
      clearErrors([item.name]);
      if (select.campaign_filters.length) {
        if (select.campaign_filters.find((val) => val.key === item.name)) {
          const data = select.campaign_filters.map((val) => {
            if (val.key === item.name) {
              return {
                ...val,
                match: value ? value : "",
                label: label ? label : "",
              };
            } else {
              return val;
            }
          });
          setSelect({
            ...select,
            [item.name]: value ? value : "",
            campaign_filters: data,
          });
        }
      }
    }
  };

  const loadOptions = async (inputValue, callback, list) => {
    try {
      const data =
        list === "numbers"
          ? dispatch(getUnAllocatedNumbers(1, 10, undefined, inputValue))
          : await dispatch(getAllVerticals(1, 10, undefined, inputValue));

      callback(
        list === "numbers"
          ? data &&
              data.list.map((val) => ({
                value: val.number,
                label: val.number,
              }))
          : data &&
              data.list.map((val) => ({
                value: val._id,
                label: val.name,
              }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const defaultVerticals = allVerticals
    ? allVerticals.list.map((val) => {
        return {
          label: val.name,
          value: val._id,
        };
      })
    : [];

  return (
    <HStack height={ah(698)}>
      <VStack
        // top={ah(70)}
        width={
          props.isOpen
            ? [aw(350), aw(350), aw(1210)]
            : [aw(350), aw(350), aw(1290)]
        }
        // width={[aw(350), aw(350), aw(1290)]}
        left={props.isOpen ? [aw(0), aw(3), aw(156)] : [aw(0), aw(3), aw(76)]}
      >
        <ScrollView
          marginLeft={aw(20)}
          // showsVerticalScrollIndicator={false}
          height={ah(698)}
        >
          <Box marginTop={ah(30)}>
            <LeadsWatchCard
              alignItems="flex-start"
              width="98%"
              minH={ah(360)}
              pt={ah(12)}
              pb={ah(20)}
            >
              <HStack
                justifyContent={"space-between"}
                alignItems={"center"}
                px={aw(15)}
                width="100%"
                // bgColor={"black"}
              >
                <VStack>
                  <Text
                    style={{ fontFamily: "RobotoMedium", fontSize: aw(26) }}
                    color="#121233"
                  >
                    {props.name === "edit"
                      ? "Edit Campaign"
                      : "Create Campaign"}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "RobotoRegular",
                      fontSize: aw(14),
                      color: "#919191",
                    }}
                  >
                    {props.name === "edit"
                      ? "Edit your Campaign"
                      : "Create your Campaign"}
                  </Text>
                </VStack>
                <Flex
                  gap={aw(12)}
                  direction={["column", "column", "row"]}
                  wrap="wrap"
                >
                  <LeadsWatchButton
                    label={"Cancel"}
                    style={{
                      width: aw(100),
                      height: ah(40),
                      borderRadius: "6px",
                    }}
                    color="#212121"
                    fontSize={aw(14)}
                    fontFamily="RobotoMedium"
                    bg={"#FFFFFF"}
                    borderColor={"#D1D5DB"}
                    fontWeight="400"
                    onPress={() => navigate(-1)}
                  />
                  <LeadsWatchButton
                    isLoading={save}
                    onPress={handleSubmit(handleCreateCampaign, onError)}
                    // onPress={handleCreateCampaign}

                    label={props.name === "edit" ? "update" : "create"}
                    style={{
                      width: aw(100),
                      height: ah(40),
                      borderRadius: "6px",
                    }}
                    color="#FFFFFF"
                    fontSize={aw(14)}
                    fontFamily="RobotoMedium"
                    bg={"#2230F8"}
                    fontWeight="400"
                  />
                </Flex>
              </HStack>
              <Divider
                marginTop={ah(18)}
                bg={"#D1D5DB"}
                mx={aw(10)}
                width="98%"
              />

              <VStack px={aw(15)} width="100%">
                <Text
                  marginTop={ah(18)}
                  style={{
                    fontFamily: "RobotoMedium",
                    color: "#121233",
                    fontSize: ah(18),
                  }}
                >
                  Campaign Details
                </Text>
                {type === "lead" ? (
                  <VStack space={ah(25)} mt={ah(5)} mb={ah(20)}>
                    <Flex
                      direction={["column", "column", "row"]}
                      // alignItems={"center"}
                      marginTop={ah(10)}
                      justifyContent={"space-between"}
                      gap={[ah(20), ah(20), 0]}
                      // bgColor="black"
                    >
                      <Flex width={["99%", "99%", "49%"]}>
                        <LeadsWatchFilterInput
                          screen={props.name}
                          hasValue={
                            getValues("campaign_name") &&
                            getValues("campaign_name").length > 0
                              ? false
                              : true
                          }
                          name={"campaign_name"}
                          placeholder={"Campaign Name"}
                          control={control}
                          // width={aw(587)}
                          // width={["100%", "100%", "50%"]}
                          width="100%"
                          // height={ah(40)}
                          rules={{
                            required: {
                              value: true,
                              message: "Campaign Name is required",
                            },
                            pattern: {
                              // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              value: /^[a-zA-Z_][a-zA-Z0-9_ ,\\-]{0,31}$/i,
                              message:
                                "FirstName should start with alphabets and can not contain special characters",
                            },
                          }}
                          invalid={errors.campaign_name ? true : false}
                          error={
                            errors.campaign_name
                              ? errors.campaign_name.message
                              : ""
                          }
                        />
                      </Flex>
                      <Flex width={["99%", "99%", "49%"]}>
                        <LeadsWatchAsyncSelect
                          height={ah(40)}
                          screen={props.name}
                          hasValue={getValues("campaign_status") ? false : true}
                          minWidth={"100%"}
                          defaultOptions={[
                            { label: "Active", value: "active" },
                            { label: "Inactive", value: "inactive" },
                          ]}
                          control={control}
                          placeholder="Campaign Status"
                          name="campaign_status"
                          rules={{
                            required: {
                              value: true,
                              message: "Campaign Status is required",
                            },
                          }}
                          invalid={errors.campaign_status ? true : false}
                          error={
                            errors.campaign_status
                              ? errors.campaign_status.message
                              : ""
                          }
                        />
                      </Flex>
                    </Flex>

                    <Flex
                      width="100%"
                      justifyContent={"space-between"}
                      direction={["column", "column", "row"]}
                      gap={[ah(20), ah(20), 0]}
                    >
                      <Flex width={["99%", "99%", "49%"]}>
                        <LeadsWatchTextArea
                          // screen={props.name}
                          // width="49%"
                          screen={
                            getValues("desc") && getValues("desc").length > 0
                              ? "edit"
                              : "create"
                          }
                          hasValue={
                            getValues("desc") && getValues("desc").length > 0
                              ? false
                              : true
                          }
                          height={ah(110)}
                          style={{ height: ah(100), width: "100%" }}
                          control={control}
                          name="desc"
                          placeholder="Description"
                          // width={aw(587)}
                        />
                      </Flex>
                      <VStack
                        // marginLeft={aw(21)}
                        space={ah(22)}
                        width={["100%", "100%", "49%"]}
                      >
                        <HStack zIndex={1}>
                          <LeadsWatchCalendar
                            borderColor={error ? "#FA4E2D" : "#D1D5DB"}
                            borderWidth={error ? aw(2) : aw(1)}
                            // width={aw(281)}
                            width={"100%"}
                            height={ah(40)}
                            handleFromDate={handleFromDate}
                            handleEndDate={handleEndDate}
                            startDate={select["fromDate"]}
                            endDate={select["toDate"]}
                          />
                        </HStack>
                        <Flex>
                          <LeadsWatchAsyncSelect
                            height={ah(40)}
                            screen={props.name}
                            hasValue={getValues("vertical_id") ? false : true}
                            // minWidth={aw(583)}
                            minWidth={"100%"}
                            type="async"
                            handleChange={(value) =>
                              handleChangeVertical(value)
                            }
                            loadOptions={(inputValues, callback) =>
                              loadOptions(inputValues, callback, "verticals")
                            }
                            onInputChange={(inputValues) => {
                              if (inputValues === "") {
                                fetchVerticals();
                              }
                            }}
                            defaultOptions={defaultVerticals}
                            control={control}
                            placeholder="Select Vertical"
                            name="vertical_id"
                            rules={{
                              required: {
                                value: true,
                                message: "Vertical is required",
                              },
                            }}
                            invalid={errors.vertical_id ? true : false}
                            error={
                              errors.vertical_id
                                ? errors.vertical_id.message
                                : ""
                            }
                          />
                        </Flex>
                      </VStack>
                    </Flex>
                  </VStack>
                ) : (
                  <Flex
                    mb={ah(20)}
                    direction={["column", "column", "row"]}
                    // alignItems={"center"}
                    marginTop={ah(10)}
                    justifyContent={"space-between"}
                    gap={[ah(25), ah(25), 0]}
                    // bgColor="black"
                  >
                    <Flex width={["99%", "99%", "49%"]} gap={ah(20)}>
                      <LeadsWatchFilterInput
                        screen={props.name}
                        hasValue={
                          getValues("campaign_name") &&
                          getValues("campaign_name").length > 0
                            ? false
                            : true
                        }
                        name={"campaign_name"}
                        placeholder={"Campaign Name"}
                        control={control}
                        // width={aw(587)}
                        // width={["100%", "100%", "50%"]}
                        width="100%"
                        // height={ah(40)}
                        rules={{
                          required: {
                            value: true,
                          },
                        }}
                        invalid={errors.campaign_name ? true : false}
                        error={
                          errors.campaign_name
                            ? errors.campaign_name.message
                            : ""
                        }
                      />
                      <Flex width={"100%"}>
                        <LeadsWatchAsyncSelect
                          height={ah(40)}
                          screen={props.name}
                          hasValue={getValues("campaign_status") ? false : true}
                          minWidth={"100%"}
                          defaultOptions={[
                            { label: "Active", value: "active" },
                            { label: "Inactive", value: "inactive" },
                          ]}
                          control={control}
                          placeholder="Campaign Status"
                          name="campaign_status"
                          rules={{
                            required: {
                              value: true,
                            },
                          }}
                          invalid={errors.campaign_status ? true : false}
                          error={
                            errors.campaign_status
                              ? errors.campaign_status.message
                              : ""
                          }
                        />
                      </Flex>
                      <HStack zIndex={1}>
                        <LeadsWatchCalendar
                          borderColor={error ? "#FA4E2D" : "#D1D5DB"}
                          borderWidth={error ? aw(2) : aw(1)}
                          // width={aw(281)}
                          width={"100%"}
                          height={ah(40)}
                          handleFromDate={handleFromDate}
                          handleEndDate={handleEndDate}
                          startDate={select["fromDate"]}
                          endDate={select["toDate"]}
                        />
                      </HStack>
                    </Flex>
                    <Flex width={["99%", "99%", "49%"]}>
                      <LeadsWatchTextArea
                        screen={
                          getValues("desc") && getValues("desc").length > 0
                            ? "edit"
                            : "create"
                        }
                        height={ah(160)}
                        style={{ height: ah(100), width: "100%" }}
                        control={control}
                        name="desc"
                        placeholder="Description"
                        // width={aw(587)}
                      />
                    </Flex>
                  </Flex>
                )}
              </VStack>
              {props.name === "edit" && (
                <HStack width={"100%"} px={aw(15)}>
                  <LeadsWatchFilterInput
                    screen={props.name}
                    isReadOnly={true}
                    width={"100%"}
                    height={ah(40)}
                    name="posting_instructions"
                    control={control}
                    placeholder="Posting Instructions"
                    rightIcon={
                      <CopyToClipboard
                        text={getValues("posting_instructions")}
                        onCopy={() => {
                          toast.success("Posting URL Copied to Clipboard");
                        }}
                      >
                        <LeadsWatchCopyCodeIcon />
                      </CopyToClipboard>
                    }
                  />
                </HStack>
              )}
            </LeadsWatchCard>
          </Box>
          {type === "lead" && (
            <Box marginTop={ah(30)} zIndex={-1}>
              <LeadsWatchCard
                width="98%"
                height={ah(497)}
                pb={ah(10)}
                alignItems="flex-start"
              >
                <VStack
                  justifyContent={"center"}
                  pb={ah(10)}
                  width="100%"
                  px={aw(15)}
                >
                  <HStack width={"100%"} height={ah(40)} marginTop={ah(18)}>
                    <Text
                      style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
                      color="#121233"
                    >
                      Data Filters
                    </Text>
                  </HStack>
                  {/* <ScrollView
                  horizontal={true}
                  minWidth={props.isOpen ? aw(1150) : aw(1195)}
                > */}
                  <VStack
                    // width={aw(1195)}
                    // width={props.isOpen ? aw(1120) : aw(1200)}
                    width="100%"
                    borderRadius={"10px"}
                    borderColor={"#D7D7D7"}
                  >
                    <HStack
                      alignItems={"center"}
                      style={{
                        height: ah(60),
                        background: "#F9FAFB 0% 0% no-repeat padding-box",
                        border: "1px solid #D7D7D7",
                        borderTopLeftRadius: aw(10),
                        borderTopRightRadius: aw(10),
                        borderBottom: "0px",
                      }}
                    >
                      {data.map((val) => {
                        return (
                          <HStack
                            alignItems={"center"}
                            width={
                              val === "Filter Value"
                                ? aw(450)
                                : val === "Vertical Field"
                                ? aw(400)
                                : aw(350)
                            }
                            justifyContent="flex-start"
                            key={val}
                          >
                            {val === "Filter Value" ? (
                              <HStack
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <Text
                                  // width={aw(340)}
                                  fontSize={aw(14)}
                                  fontFamily={"RobotoMedium"}
                                  ml={aw(20)}
                                  color="#121233"
                                >
                                  {val}
                                </Text>
                                <Tooltip
                                  label={
                                    "Please enter values based on data types. For match type ex: in between:[1,10], eaual to:1,exact:hello"
                                  }
                                  width={aw(150)}
                                >
                                  <Flex>
                                    <LeadsWatchInfoIcon />
                                  </Flex>
                                </Tooltip>
                              </HStack>
                            ) : (
                              <Text
                                width={aw(340)}
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                                ml={aw(20)}
                                color="#121233"
                              >
                                {val}
                              </Text>
                            )}
                          </HStack>
                        );
                      })}
                    </HStack>

                    <FlatList
                      borderColor="#D7D7D7"
                      borderBottomRightRadius={aw(10)}
                      borderBottomLeftRadius={aw(10)}
                      borderWidth={1}
                      maxHeight={ah(300)}
                      ListEmptyComponent={
                        <HStack
                          justifyContent="center"
                          alignItems={"center"}
                          height={ah(290)}
                        >
                          <Text
                            color="black"
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            No data found
                          </Text>
                        </HStack>
                      }
                      data={
                        getValues("vertical_id")
                          ? allVerticalFields &&
                            allVerticalFields.list &&
                            allVerticalFields.list
                          : []
                      }
                      keyExtractor={(item, index) => item.name}
                      renderItem={({ item, index }) => (
                        <VStack>
                          <HStack
                            height={ah(60)}
                            alignItems={"center"}
                            bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                            style={{
                              borderBottomWidth:
                                index === allVerticalFields.list.length - 1
                                  ? "0px"
                                  : "1px",
                              borderColor: "#D7D7D7",
                            }}
                          >
                            <Text
                              color="#121233"
                              width={aw(400)}
                              ml={aw(20)}
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                            >
                              {item.name}
                            </Text>
                            <Stack width={aw(450)}>
                              <LeadsWatchInput
                                control={control}
                                name={item.name}
                                // onChangeText={(value) =>
                                //   b.includes(item.name)
                                //     ? null
                                //     : setB([...b, item.name])
                                // }
                                onChangeText={(value) =>
                                  !value && clearErrors([`select_${item.name}`])
                                }
                                height={34}
                                onBlur={() => setB(!b)}
                                style={{ width: aw(140), height: 32 }}
                                rules={{
                                  required: {
                                    value: getValues(`select_${item.name}`)
                                      ? true
                                      : false,
                                  },
                                  // validate:
                                  // watch(`select_${item.name}`)==='triggerError'?true:false
                                }}
                                invalid={errors[`${item.name}`] ? true : false}
                                // invalid={true}
                                error={
                                  errors[`${item.name}`]
                                    ? errors[`${item.name}`].message
                                    : ""
                                }
                              />
                            </Stack>

                            <HStack width={aw(140)}>
                              <LeadsWatchAsyncSelect
                                minHeight={34}
                                required={true}
                                // screen={props.name}
                                // hasValue={getValues("vertical_id") ? false : true}
                                isClearable={true}
                                minWidth={aw(140)}
                                defaultOptions={
                                  item.datatype === "string"
                                    ? [
                                        {
                                          label: "exact",
                                          value: "exact",
                                        },
                                        {
                                          label: "contains",
                                          value: "contains",
                                        },
                                        {
                                          label: "not contains",
                                          value: "not_contains",
                                        },
                                      ]
                                    : item.datatype === "boolean"
                                    ? [
                                        {
                                          label: "equal to(=)",
                                          value: "=",
                                        },
                                        {
                                          label: "not equal to(!=)",
                                          value: "!=",
                                        },
                                      ]
                                    : item.datatype === "list"
                                    ? [
                                        {
                                          label: "equal to(=)",
                                          value: "=",
                                        },
                                        {
                                          label: "not equal to(!=)",
                                          value: "!=",
                                        },
                                        {
                                          label: "greater than(>)",
                                          value: ">",
                                        },
                                        {
                                          label: "lessthan(<)",
                                          value: "<",
                                        },
                                        {
                                          label: "greatertham or equal to(>=)",
                                          value: ">=",
                                        },
                                        {
                                          label: "lessthan or equal to(<=)",
                                          value: "<=",
                                        },
                                        {
                                          label: "in between",
                                          value: "in_between",
                                        },
                                        {
                                          label: "exact",
                                          value: "exact",
                                        },
                                        {
                                          label: "contains",
                                          value: "contains",
                                        },
                                        {
                                          label: "not contains",
                                          value: "not_contains",
                                        },
                                      ]
                                    : [
                                        {
                                          label: "equal to(=)",
                                          value: "=",
                                        },
                                        {
                                          label: "not equal to(!=)",
                                          value: "!=",
                                        },
                                        {
                                          label: "greater than(>)",
                                          value: ">",
                                        },
                                        {
                                          label: "lessthan(<)",
                                          value: "<",
                                        },
                                        {
                                          label: "greatertham or equal to(>=)",
                                          value: ">=",
                                        },
                                        {
                                          label: "lessthan or equal to(<=)",
                                          value: "<=",
                                        },
                                        {
                                          label: "in between",
                                          value: "in_between",
                                        },
                                      ]
                                }
                                control={control}
                                placeholder="Select"
                                name={`select_${item.name}`}
                                handleChange={(value) =>
                                  handleChangeFilters(
                                    value ? value.value : "",
                                    item,
                                    value ? value.label : ""
                                  )
                                }
                                rules={{
                                  required: {
                                    value: getValues(`${item.name}`)
                                      ? true
                                      : false,
                                  },
                                }}
                                invalid={
                                  errors[`select_${item.name}`] ? true : false
                                }
                                // invalid={true}
                                error={
                                  errors[`select_${item.name}`]
                                    ? errors[`select_${item.name}`].message
                                    : ""
                                }
                              />
                            </HStack>
                          </HStack>
                        </VStack>
                      )}
                    />
                  </VStack>
                  {/* </ScrollView> */}
                </VStack>
                <HStack
                  width={"99%"}
                  justifyContent={"space-between"}
                  px={aw(15)}
                >
                  {getValues("vertical_id") &&
                    allVerticalFields &&
                    allVerticalFields.total_count > vfCount && (
                      <LeadsWatchSelect
                        control={control}
                        name="select"
                        width={75}
                        list={["10", "15", "20", "25"]}
                        // value={status}
                        // handleChange={(value) => setStatus(value)}
                        handleChange={(value) =>
                          handleChangeSelect(parseInt(value))
                        }
                        height={ah(40)}
                      />
                    )}
                  <LeadsWatchPagination
                    type="box"
                    totalCount={
                      allVerticalFields && allVerticalFields.total_count
                        ? allVerticalFields.total_count
                        : 1
                    }
                    currentPage={vfCurrentPage}
                    pageSize={vfCount}
                    onPageChange={async (currentPage) => {
                      const combinedArray = allVerticalFields.list.reduce(
                        (acc, val) => {
                          acc.push(`select_${val.name}`, val.name);
                          return acc;
                        },
                        []
                      );

                      // console.log(combinedArray)
                      const valid = await trigger1(combinedArray);

                      // await trigger1()
                      if (valid) {
                        setVfCurrentPage(currentPage);
                      }
                    }}
                  />
                </HStack>
              </LeadsWatchCard>
            </Box>
          )}
          <Box marginTop={ah(30)} marginBottom={ah(59)} zIndex={-1}>
            <LeadsWatchCard
              width={"98%"}
              height={ah(1000)}
              alignItems="flex-start"
              pb={ah(10)}
            >
              <VStack
                maxHeight={ah(1000)}
                space={ah(20)}
                mb={ah(30)}
                px={aw(15)}
                width="100%"
              >
                <HStack height={ah(40)} marginTop={ah(18)}>
                  <Text
                    style={{
                      fontFamily: "RobotoMedium",
                      fontSize: aw(18),
                      color: "#121233",
                    }}
                  >
                    Campaign Configuration
                  </Text>
                </HStack>
                <Flex
                  justifyContent={"space-between"}
                  gap={[ah(20), ah(20), 0]}
                  direction={["column", "column", "row"]}
                  width="100%"
                >
                  <VStack
                    marginTop={ah(2)}
                    // justifyContent={"space-between"}
                    space={ah(50)}
                    width={["100%", "100%", "49%"]}
                  >
                    {type === "ppc" && (
                      <Flex>
                        <LeadsWatchAsyncSelect
                          minWidth={"100%"}
                          screen={props.name}
                          hasValue={getValues("call_type") ? false : true}
                          defaultOptions={[
                            {
                              label: "Post",
                              value: "post",
                            },
                            {
                              label: "Ping Post",
                              value: "ping-post",
                            },
                          ]}
                          control={control}
                          placeholder="Call Type"
                          name={"call_type"}
                          handleChange={(value) =>
                            setCallType(value ? value.value : "")
                          }
                          rules={{
                            required: {
                              value: true,
                              message: "Call Type is required",
                            },
                          }}
                          invalid={errors.call_type ? true : false}
                          error={
                            errors.call_type ? errors.call_type.message : ""
                          }
                        />
                      </Flex>
                    )}
                    <Flex>
                      <LeadsWatchAsyncSelect
                        minWidth={"100%"}
                        screen={props.name}
                        hasValue={getValues("allow_duplicate") ? false : true}
                        defaultOptions={[
                          {
                            label: "In This Campaigns",
                            value: "in_this_campaigns",
                          },
                          {
                            label: "In Entire Application",
                            value: "in_entire_application",
                          },
                        ]}
                        control={control}
                        placeholder="Mark Duplicates"
                        name={"allow_duplicate"}
                        rules={{
                          required: {
                            value: true,
                            message: "Mark Duplicates is required",
                          },
                        }}
                        invalid={errors.allow_duplicate ? true : false}
                        error={
                          errors.allow_duplicate
                            ? errors.allow_duplicate.message
                            : ""
                        }
                      />
                    </Flex>
                    <Flex width="100%">
                      {/* <LeadsWatchSelect
                        control={control}
                        name={"lead_distribution"}
                        placeholder="Leads Distribution"
                        width={aw(587)}
                        height={ah(40)}
                        list={["Simultaneos", "Priority"]}
                        rules={{
                          required: {
                            value: true,
                          },
                        }}
                        invalid={errors.lead_distribution ? true : false}
                        error={
                          errors.lead_distribution
                            ? errors.lead_distribution.message
                            : ""
                        } 
                      />*/}
                      <LeadsWatchAsyncSelect
                        minWidth={"100%"}
                        screen={props.name}
                        hasValue={getValues("lead_distribution") ? false : true}
                        defaultOptions={[
                          {
                            label: "Simultaneous",
                            value: "simultaneous",
                          },
                          {
                            label: "Priority",
                            value: "priority",
                          },
                          {
                            label: "Round Robin",
                            value: "round_robin",
                          },
                        ]}
                        control={control}
                        placeholder="Leads Distribution"
                        name={"lead_distribution"}
                        rules={{
                          required: {
                            value: true,
                            message: "Leads Distribution is required",
                          },
                        }}
                        invalid={errors.lead_distribution ? true : false}
                        error={
                          errors.lead_distribution
                            ? errors.lead_distribution.message
                            : ""
                        }
                      />
                    </Flex>
                  </VStack>
                  <Flex gap={ah(20)} width={["100%", "100%", "49%"]}>
                    <LeadsWatchCard
                      shadow={true}
                      borderBottomWidth={aw(1)}
                      borderColor={"#D7D7D7"}
                      width={"100%"}
                      height={ah(200)}
                      h={ah(300)}
                      alignItems="flex-start"
                    >
                      <VStack width="100%">
                        <HStack
                          style={{
                            borderTopLeftRadius: aw(10),
                            borderTopRightRadius: aw(10),
                          }}
                          height={ah(60)}
                          bg={"#F9FAFB"}
                          // width={"100%"}
                          borderWidth={aw(1)}
                          borderBottomWidth={0}
                          borderColor={"#D7D7D7"}
                          alignItems={"center"}
                          px={4}
                          justifyContent="space-between"
                        >
                          <Text
                            style={{
                              fontFamily: "RobotoMedium",
                              fontSize: aw(14),
                              color: "#121233",
                            }}
                          >
                            Publishers Available
                          </Text>
                          <LeadsWatchInput
                            control={control2}
                            name="search"
                            style={{ width: "40%", height: ah(32) }}
                            placeholder="Search"
                            leftIcon={
                              <HStack bgColor={"#FFFFFF"}>
                                <LeadsWatchSearchIcon />
                              </HStack>
                            }
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                fetchPublishers();
                              }
                            }}
                          />
                        </HStack>

                        <FlatList
                          maxHeight={ah(150)}
                          // width={aw(587)}
                          width="100%"
                          borderBottomRightRadius={aw(10)}
                          borderBottomLeftRadius={aw(10)}
                          borderColor="#D7D7D7"
                          borderWidth={aw(1)}
                          ListEmptyComponent={
                            <HStack
                              justifyContent="center"
                              alignItems={"center"}
                              height={ah(100)}
                            >
                              <Text
                                color="black"
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                              >
                                No data found
                              </Text>
                            </HStack>
                          }
                          data={
                            allPublishers &&
                            allPublishers.list &&
                            allPublishers.list
                          }
                          keyExtractor={(item) => item._id}
                          renderItem={({ item, index }) => (
                            <HStack
                              bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                              width={"100%"}
                              height={ah(50)}
                              style={{
                                borderBottomWidth:
                                  index === allPublishers.list.length - 1
                                    ? aw(0)
                                    : aw(1),

                                borderColor: "#D7D7D7",
                              }}
                              justifyContent={"space-between"}
                              px={aw(10)}
                              alignItems={"center"}
                            >
                              <Text
                                color="black"
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                              >
                                {item.publisher_name}
                              </Text>
                              {/* campaignPublishers.find((val) => val.publisher_id === newData.publisher_id) */}
                              {campaignPublishers.find(
                                (val) => val.publisher_id === item._id
                              ) ? (
                                <Pressable px={4} isDisabled={true}>
                                  <LeadsWatchAddIcon fill="#C1C1C1" />
                                </Pressable>
                              ) : (
                                <Pressable
                                  px={4}
                                  onPress={() => handleAddPublisher(item)}
                                >
                                  <LeadsWatchAddIcon fill="#212121" />
                                </Pressable>
                              )}
                            </HStack>
                          )}
                        />
                        {/* </ScrollView> */}
                      </VStack>
                    </LeadsWatchCard>
                    <HStack justifyContent={"space-between"}>
                      {allPublishers &&
                        allPublishers.total_count > pubCount && (
                          <LeadsWatchSelect
                            control={control}
                            name="pubSelect"
                            width={75}
                            list={["10", "15", "20", "25"]}
                            // value={status}
                            // handleChange={(value) => setStatus(value)}
                            handleChange={(value) =>
                              handleChangeSelect(parseInt(value), "publisher")
                            }
                            height={ah(40)}
                          />
                        )}

                      <LeadsWatchPagination
                        type="box"
                        totalCount={
                          allPublishers && allPublishers.total_count
                            ? allPublishers.total_count
                            : 1
                        }
                        currentPage={pubCurrentPage}
                        pageSize={pubCount}
                        onPageChange={(currentPage) => {
                          setPubCurrentPage(currentPage);
                        }}
                      />
                    </HStack>
                  </Flex>
                </Flex>

                <Divider marginTop={ah(18)} bg={"#D1D5DB"} />
                <Flex
                  direction={["column", "column", "row"]}
                  justifyContent={"space-between"}
                  gap={[ah(20), ah(20), 0]}
                >
                  <LeadsWatchCard
                    alignItems="flex-start"
                    shadow={true}
                    borderBottomWidth={aw(1)}
                    borderColor={"#D7D7D7"}
                    width={["100%", "100%", "49%"]}
                    height={ah(200)}
                    h={ah(200)}
                  >
                    <VStack width="100%">
                      <HStack
                        justifyContent={"space-between"}
                        height={ah(60)}
                        bg={"#F9FAFB"}
                        width={"100%"}
                        borderWidth={"1px"}
                        borderBottomWidth={"0px"}
                        borderColor={"#D7D7D7"}
                        borderTopLeftRadius={aw(10)}
                        borderTopRightRadius={aw(10)}
                        alignItems={"center"}
                        px={4}
                      >
                        <Text
                          style={{
                            fontFamily: "RobotoMedium",
                            fontSize: aw(14),
                            color: "#121233",
                          }}
                        >
                          Buyers in this Campaign
                        </Text>
                        <HStack space={aw(10)}>
                          {type !== "ppc" && (
                            <LeadsWatchButton
                              onPress={() => handleOpenModal("group")}
                              label={"Add Groups"}
                              style={{
                                width: aw(110),
                                height: ah(32),
                                borderRadius: "6px",
                              }}
                              leftIcon={<LeadsWatchAddIcon fill={"#212121"} />}
                              color="#212121"
                              fontSize={aw(14)}
                              fontFamily="RobotoMedium"
                              bg={"#FFFFFF"}
                              borderColor={"#D1D5DB"}
                              fontWeight="400"
                            />
                          )}
                          <LeadsWatchButton
                            onPress={() => handleOpenModal("single")}
                            label={"Add Buyers"}
                            style={{
                              width: aw(110),
                              height: ah(32),
                              borderRadius: "6px",
                            }}
                            leftIcon={<LeadsWatchAddIcon fill={"#212121"} />}
                            color="#212121"
                            fontSize={aw(14)}
                            fontFamily="RobotoMedium"
                            bg={"#FFFFFF"}
                            borderColor={"#D1D5DB"}
                            fontWeight="400"
                          />
                        </HStack>
                      </HStack>
                      <ScrollView
                        maxHeight={ah(150)}
                        width={"100%"}
                        // showsVerticalScrollIndicator={false}
                        borderColor="#D7D7D7"
                        borderWidth={aw(1)}
                        borderBottomRightRadius={aw(10)}
                        borderBottomLeftRadius={aw(10)}
                      >
                        <FlatList
                          showsVerticalScrollIndicator={false}
                          ListEmptyComponent={
                            <HStack
                              justifyContent="center"
                              alignItems={"center"}
                              height={ah(140)}
                            >
                              <Text
                                color="black"
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                              >
                                No data found
                              </Text>
                            </HStack>
                          }
                          data={campaignBuyers}
                          keyExtractor={(item) => item._id}
                          renderItem={({ item, index }) => (
                            <HStack width={"100%"}>
                              <HStack
                                width={"100%"}
                                height={ah(60)}
                                bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                                justifyContent={"space-between"}
                                px={aw(10)}
                                alignItems={"center"}
                                style={{
                                  borderBottomWidth:
                                    index === campaignBuyers.length - 1
                                      ? "0px"
                                      : "1px",
                                  borderColor: "#D7D7D7",
                                }}
                              >
                                <HStack space={aw(5)}>
                                  {item.type === "single" ? (
                                    <LeadsWatchSingleProfileIcon />
                                  ) : (
                                    <LeadsWatchGroupIcon />
                                  )}
                                  <Text
                                    color="black"
                                    fontSize={aw(14)}
                                    fontFamily={"RobotoMedium"}
                                  >
                                    {item.type === "single"
                                      ? `${item.buyer_name} ${item.route_name}`
                                      : item.group_name}
                                  </Text>
                                </HStack>
                                <Pressable
                                  px={4}
                                  onPress={() => handleDeleteBuyer(item._id)}
                                >
                                  <LeadsWatchDeleteIcon />
                                </Pressable>
                              </HStack>
                            </HStack>
                          )}
                        />
                      </ScrollView>
                    </VStack>
                  </LeadsWatchCard>
                  <LeadsWatchCard
                    shadow={true}
                    borderBottomWidth={aw(1)}
                    borderColor={"#D7D7D7"}
                    width={["100%", "100%", "49%"]}
                    alignItems="flex-start"
                    height={ah(200)}
                    h={ah(200)}
                  >
                    <VStack width="100%">
                      <HStack
                        justifyContent={"space-between"}
                        style={{
                          borderTopLeftRadius: aw(10),
                          borderTopRightRadius: aw(10),
                        }}
                        height={ah(60)}
                        bg={"#F9FAFB"}
                        width={"100%"}
                        borderWidth={"1px"}
                        ScrollView
                        borderBottomWidth={"0px"}
                        borderColor={"#D7D7D7"}
                        alignItems={"center"}
                        px={4}
                      >
                        <Text
                          style={{
                            fontFamily: "RobotoMedium",
                            fontSize: aw(14),
                            color: "#121233",
                          }}
                        >
                          Publishers in this Campaign
                        </Text>
                      </HStack>
                      <ScrollView
                        maxHeight={ah(150)}
                        width={"100%"}
                        // showsVerticalScrollIndicator={false}
                        borderBottomRightRadius={aw(10)}
                        borderBottomLeftRadius={aw(10)}
                        borderColor="#D7D7D7"
                        borderWidth={1}
                      >
                        <FlatList
                          showsVerticalScrollIndicator={false}
                          ListEmptyComponent={
                            <HStack
                              justifyContent="center"
                              alignItems={"center"}
                              height={ah(140)}
                            >
                              <Text
                                color="black"
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                              >
                                No data found
                              </Text>
                            </HStack>
                          }
                          data={campaignPublishers}
                          keyExtractor={(item, index) => index}
                          renderItem={({ item, index }) => (
                            <HStack width={"100%"}>
                              <HStack
                                width={"100%"}
                                height={ah(60)}
                                bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                                justifyContent={"space-between"}
                                px={aw(10)}
                                alignItems={"center"}
                                style={{
                                  borderBottomWidth:
                                    index === campaignPublishers.length - 1
                                      ? "0px"
                                      : "1px",
                                  borderColor: "#D7D7D7",
                                }}
                              >
                                <HStack space={aw(5)}>
                                  <Text
                                    color="black"
                                    fontSize={aw(14)}
                                    fontFamily={"RobotoMedium"}
                                  >
                                    {item.publisher_name}
                                  </Text>
                                </HStack>
                                <HStack>
                                  <Pressable
                                    onPress={() => handleEditPublisher(item)}
                                  >
                                    <LeadsWatchEditIcon />
                                  </Pressable>
                                  <Pressable
                                    onPress={() =>
                                      handleDeletePublisher(
                                        item.publisher_id,
                                        item.number
                                      )
                                    }
                                  >
                                    <LeadsWatchDeleteIcon />
                                  </Pressable>
                                </HStack>
                              </HStack>
                            </HStack>
                          )}
                        />
                      </ScrollView>
                    </VStack>
                  </LeadsWatchCard>
                </Flex>
              </VStack>
            </LeadsWatchCard>
            {getValues("call_type") &&
              getValues("call_type").value === "ping-post" && (
                <Box marginTop={ah(30)}>
                  <LeadsWatchCard
                    width={
                      props.isOpen
                        ? [aw(350), aw(350), aw(1168)]
                        : [aw(350), aw(350), aw(1248)]
                    }
                    height={ah(1000)}
                    alignItems={"flex-start"}
                  >
                    <Flex
                      marginTop={ah(10)}
                      gap={ah(15)}
                      width="100%"
                      px={4}
                      pb={ah(25)}
                      pt={ah(10)}
                    >
                      <HStack alignItems={"center"}>
                        <Text
                          style={{
                            fontFamily: "RobotoMedium",
                            fontSize: aw(18),
                            color: "#121233",
                          }}
                        >
                          Default Keys configuration
                        </Text>
                        <Tooltip
                          width={aw(300)}
                          label={`1. example for welcome message: "Welcome to LeadsWatch"\n2. example for ivr message: "Please Enter pincode"\n3.example for key to press after finish:"Please Press '#' after finish`}
                        >
                          <HStack>
                            <LeadsWatchInfoIcon />
                          </HStack>
                        </Tooltip>
                      </HStack>

                      <Flex direction="row" justifyContent={"space-between"}>
                        <Flex width="31.5%">
                          <LeadsWatchTextArea
                            screen={
                              getValues("welcome_msg") &&
                              getValues("welcome_msg").length > 0
                                ? "edit"
                                : "create"
                            }
                            control={control}
                            name="welcome_msg"
                            placeholder="Type welcome message"
                            height={ah(100)}
                            rules={{
                              required: {
                                value: true,
                                message: "Welcome message is required",
                              },
                            }}
                            invalid={errors.welcome_msg ? true : false}
                            error={
                              errors.welcome_msg
                                ? errors.welcome_msg.message
                                : ""
                            }
                          />
                        </Flex>
                        <Flex width="31.5%">
                          <LeadsWatchTextArea
                            screen={
                              getValues("ivr_msg") &&
                              getValues("ivr_msg").length > 0
                                ? "edit"
                                : "create"
                            }
                            control={control}
                            name="ivr_msg"
                            placeholder="Type ivr message"
                            height={ah(100)}
                            rules={{
                              required: {
                                value: true,
                                message: "ivr message is required",
                              },
                            }}
                            invalid={errors.ivr_msg ? true : false}
                            error={errors.ivr_msg ? errors.ivr_msg.message : ""}
                          />
                        </Flex>
                        <Flex width="31.5%">
                          <LeadsWatchTextArea
                            screen={
                              getValues("finish_key") &&
                              getValues("finish_key").length > 0
                                ? "edit"
                                : "create"
                            }
                            control={control}
                            name="finish_key"
                            placeholder="Type key to Press after Finish message"
                            height={ah(100)}
                            rules={{
                              required: {
                                value: true,
                                message: "Finish Key message is required",
                              },
                            }}
                            invalid={errors.finish_key ? true : false}
                            error={
                              errors.finish_key ? errors.finish_key.message : ""
                            }
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </LeadsWatchCard>
                </Box>
              )}
          </Box>
        </ScrollView>
      </VStack>

      <LeadsWatchModal
        height={ah(590)}
        width={aw(770)}
        showModal={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        heading={
          <HStack justifyContent={"center"} alignItems={"center"}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              {buyersType === "single" ? "All Available Buyers" : "All Groups"}
            </Text>
            {type === "ppc" && (
              <Tooltip label="You can add only 10 buyers">
                <HStack
                // alignItems={"center"}
                // height="100%"
                // justifyContent={"center"}
                >
                  <LeadsWatchInfoIcon />
                </HStack>
              </Tooltip>
            )}
          </HStack>
        }
      >
        {buyersType === "single" ? (
          <VStack>
            <HStack
              justifyContent="space-evenly"
              alignItems={"center"}
              bgColor="#D7D7D7"
              style={{
                height: ah(60),
                background: "#F9FAFB 0% 0% no-repeat padding-box",
                borderWidth: aw(1),
                borderColor: "#D7D7D7",
                borderBottomWidth: 0,
                borderTopLeftRadius: aw(10),
                borderTopRightRadius: aw(10),
              }}
            >
              <HStack width={aw(20)} justifyContent="center">
                <Checkbox
                  accessibilityLabel="headingcheck"
                  style={{ height: aw(16), width: aw(16) }}
                  bgColor="#FAFBFD"
                  _checked={{ bgColor: "#0000FF", borderColor: "#0000FF" }}
                  _icon={{ color: "#FAFBFD" }}
                  _hover={{ color: "#FAFBFD" }}
                  onChange={onChangeHeaderCheckbox}
                  isChecked={
                    allVerticalBuyers
                      ? allVerticalBuyers.list.every((val) =>
                          checked.includes(val._id)
                        )
                      : false
                  }
                />
              </HStack>
              {routeHeadings.map((val) => (
                <HStack
                  width={aw(120)}
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={val}
                >
                  {val === "Time(s)" ? (
                    <HStack justifyContent={"center"} alignItems={"center"}>
                      <Text
                        color="black"
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                      >
                        {" "}
                        {val}
                      </Text>
                      <Tooltip
                        label={
                          "Default time is 10s you can edit by clicking on it"
                        }
                        width={aw(150)}
                      >
                        <HStack>
                          <LeadsWatchInfoIcon />
                        </HStack>
                      </Tooltip>
                    </HStack>
                  ) : (
                    <Text
                      fontSize={aw(14)}
                      width={aw(120)}
                      fontFamily={"RobotoMedium"}
                      color="#212121"
                    >
                      {val}
                    </Text>
                  )}
                </HStack>
              ))}
            </HStack>
            {!isLoading ? (
              <ScrollView
                maxHeight={ah(280)}
                // showsVerticalScrollIndicator={false}
                borderBottomRightRadius={aw(10)}
                borderBottomLeftRadius={aw(10)}
                borderColor="#D7D7D7"
                borderWidth={1}
              >
                <FlatList
                  ListEmptyComponent={
                    <HStack
                      justifyContent="center"
                      alignItems={"center"}
                      height={ah(200)}
                    >
                      <Text
                        color="black"
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                      >
                        No data found
                      </Text>
                    </HStack>
                  }
                  data={
                    allVerticalBuyers && allVerticalBuyers.list
                      ? allVerticalBuyers.list
                      : []
                  }
                  keyExtractor={(item) => item._id}
                  renderItem={({ item, index }) => (
                    <HStack
                      key={index}
                      justifyContent="space-evenly"
                      alignItems={"center"}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                        minHeight: ah(58),
                        borderBottomWidth:
                          index === allVerticalBuyers.list.length - 1
                            ? "0px"
                            : "1px",
                        // borderBottomWidth: "1px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      <HStack width={aw(20)} justifyContent="center">
                        <Checkbox
                          accessibilityLabel={`row-${item._id}`}
                          style={{ height: aw(16), width: aw(16) }}
                          bgColor="#FAFBFD"
                          _checked={{
                            bgColor: "#0000FF",
                            borderColor: "#0000FF",
                          }}
                          _icon={{ color: "#FAFBFD" }}
                          _hover={{ color: "#FAFBFD" }}
                          isChecked={checked.includes(item._id)}
                          onChange={() => handleCheckbox(item._id, item)}
                        />
                      </HStack>
                      {routeData.map((val) => (
                        <HStack
                          width={aw(120)}
                          justifyContent="flex-start"
                          alignItems={"center"}
                          key={val}
                          // ml={aw(10)}
                        >
                          {val === "time" ? (
                            <LeadsWatchInput
                              textStyles={{
                                fontFamily: "RobotoRegular",
                                fontSize: aw(14),
                                backgroundColor: "transparent",
                              }}
                              borderColor={"white"}
                              onlyNumbers={true}
                              control={control3}
                              name={item._id}
                              style={{ width: aw(50), height: ah(32) }}
                              placeholder="Time"
                            />
                          ) : (
                            <Text
                              fontSize={aw(14)}
                              width={aw(120)}
                              fontFamily={"RobotoRegular"}
                              color="#212121"
                            >
                              {item[val]}
                            </Text>
                          )}
                        </HStack>
                      ))}
                    </HStack>
                  )}
                />
              </ScrollView>
            ) : (
              <HStack
                justifyContent="center"
                alignItems={"center"}
                height={ah(300)}
                borderColor="#D7D7D7"
                borderWidth={1}
                // ml={aw(10)}
              >
                <Spinner size="lg" color="#2230F8" />
              </HStack>
            )}
            <VStack bottom={ah(60)} position="fixed">
              <LeadsWatchPagination
                totalCount={
                  allVerticalBuyers ? allVerticalBuyers.total_count : 0

                  // 100
                }
                width={[aw(350), aw(350), aw(740)]}
                currentPage={currentPage}
                pageSize={10}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                }}
              />
            </VStack>
            <HStack mt={ah(60)} space={aw(20)} justifyContent="flex-end">
              <LeadsWatchButton
                label="cancel"
                style={{ width: aw(120), height: ah(40), borderRadius: aw(6) }}
                borderColor="#D1D5DB"
                fontSize={aw(15)}
                onPress={() => {
                  {
                    setIsModalOpen(false);
                    setChecked(campaignBuyers.map((val) => val._id));
                  }
                }}
              />
              <LeadsWatchButton
                onPress={handleAddBuyer}
                label="Update"
                style={{ width: aw(120), height: ah(40), borderRadius: aw(6) }}
                bg="#2230F8"
                borderColor="#D1D5DB"
                fontSize={aw(15)}
                color="#FFFFFF"
              />
            </HStack>
          </VStack>
        ) : (
          <VStack>
            <HStack
              justifyContent="space-evenly"
              alignItems={"center"}
              bgColor="#D7D7D7"
              style={{
                height: ah(60),
                background: "#F9FAFB 0% 0% no-repeat padding-box",
                borderWidth: aw(1),
                borderColor: "#D7D7D7",
                borderBottomWidth: 0,
                borderTopLeftRadius: aw(10),
                borderTopRightRadius: aw(10),
              }}
            >
              <HStack width={aw(20)} justifyContent="center">
                <Checkbox
                  accessibilityLabel="headingcheck"
                  style={{ height: aw(16), width: aw(16) }}
                  bgColor="#FAFBFD"
                  _checked={{ bgColor: "#0000FF", borderColor: "#0000FF" }}
                  _icon={{ color: "#FAFBFD" }}
                  _hover={{ color: "#FAFBFD" }}
                  onChange={onChangeHeaderCheckbox}
                  isChecked={
                    allVerticalGroups
                      ? allVerticalGroups.list.every((val) =>
                          checked.includes(val._id)
                        )
                      : false
                  }
                />
              </HStack>
              {groupHeadings.map((val) => (
                <HStack
                  width={aw(120)}
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={val}
                >
                  <Text
                    fontSize={aw(14)}
                    width={aw(120)}
                    fontFamily={"RobotoMedium"}
                    color="#212121"
                  >
                    {val}
                  </Text>
                </HStack>
              ))}
            </HStack>
            {!isLoading ? (
              <ScrollView
                maxHeight={ah(280)}
                // showsVerticalScrollIndicator={false}
                borderBottomRightRadius={aw(10)}
                borderBottomLeftRadius={aw(10)}
                borderColor="#D7D7D7"
                borderWidth={1}
              >
                <FlatList
                  ListEmptyComponent={
                    <HStack
                      justifyContent="center"
                      alignItems={"center"}
                      height={ah(200)}
                    >
                      <Text
                        color="black"
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                      >
                        No data found
                      </Text>
                    </HStack>
                  }
                  data={
                    allVerticalGroups && allVerticalGroups.list
                      ? allVerticalGroups.list
                      : []
                  }
                  keyExtractor={(item) => item._id}
                  renderItem={({ item, index }) => (
                    <HStack
                      key={index}
                      justifyContent="space-evenly"
                      alignItems={"center"}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                        minHeight: ah(58),
                        borderBottomWidth:
                          index === allVerticalGroups.list.length - 1
                            ? "0px"
                            : "1px",
                        // borderBottomWidth: "1px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      <HStack width={aw(20)} justifyContent="center">
                        <Checkbox
                          accessibilityLabel={`row-${item._id}`}
                          style={{ height: aw(16), width: aw(16) }}
                          bgColor="#FAFBFD"
                          _checked={{
                            bgColor: "#0000FF",
                            borderColor: "#0000FF",
                          }}
                          _icon={{ color: "#FAFBFD" }}
                          _hover={{ color: "#FAFBFD" }}
                          isChecked={checked.includes(item._id) === true}
                          onChange={() => handleCheckbox(item._id, item)}
                          // checked={
                          //   checked.includes(item._id) === true
                          // }
                          // defaultIsChecked={checked.includes(item._id) === true}
                          // onChange={() => handleCheckbox(item._id)}
                        />
                      </HStack>
                      {groupData.map((val) => (
                        <HStack
                          width={aw(120)}
                          justifyContent="flex-start"
                          alignItems={"center"}
                          key={val}
                          // ml={aw(10)}
                        >
                          <Text
                            fontSize={aw(14)}
                            width={aw(120)}
                            fontFamily={"RobotoRegular"}
                            color="#212121"
                          >
                            {item[`${val}`] ? item[`${val}`] : "_"}
                          </Text>
                        </HStack>
                      ))}
                    </HStack>
                  )}
                />
              </ScrollView>
            ) : (
              <HStack
                justifyContent="center"
                alignItems={"center"}
                height={ah(300)}
                borderColor="#D7D7D7"
                borderWidth={1}
                // ml={aw(10)}
              >
                <Spinner size="lg" color="#2230F8" />
              </HStack>
            )}
            <VStack bottom={ah(60)} position="fixed">
              <LeadsWatchPagination
                //                 totalCount={
                //                   allVerticalGroups ? allVerticalGroups.total_count : 0
                //  type === "lead"
                //                       ? allVerticalGroups && allVerticalGroups.list
                //                         ? allVerticalGroups.list
                //                         : []
                //                       : ivrGroups && ivrGroups.list
                //                       ? ivrGroups.list
                //                       : []
                //                   // 100
                //                 }
                totalCount={
                  allVerticalGroups ? allVerticalGroups.total_count : 0
                }
                width={[aw(350), aw(350), aw(740)]}
                currentPage={currentPage}
                pageSize={10}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                }}
              />
            </VStack>
            <HStack mt={ah(60)} space={aw(20)} justifyContent="flex-end">
              <LeadsWatchButton
                label="cancel"
                style={{ width: aw(120), height: ah(40), borderRadius: aw(6) }}
                borderColor="#D1D5DB"
                fontSize={aw(15)}
                onPress={() => {
                  {
                    setIsModalOpen(false);
                    setChecked(campaignBuyers.map((val) => val._id));
                  }
                }}
              />
              <LeadsWatchButton
                onPress={handleAddBuyer}
                label="Update"
                style={{ width: aw(120), height: ah(40), borderRadius: aw(6) }}
                bg="#2230F8"
                borderColor="#D1D5DB"
                fontSize={aw(15)}
                color="#FFFFFF"
              />
            </HStack>
          </VStack>
        )}
      </LeadsWatchModal>
      <LeadsWatchModal
        height={ah(590)}
        width={aw(500)}
        showModal={isPublisherModal}
        onClose={() => {
          if (pubId && getValues1("number")) {
            setList(list.filter((val) => val !== getValues1("number").value));
          }
          reset1({
            post_back_url: "",
            amount: "",
            payout_type: "",
            currency: "",
            number: "",
          });
          // unallNum &&
          //   unallNum.list &&
          //   setList([...unallNum.list.map((val) => val.number)]);

          setIsPublisherModal(false);
          setPubId(null);
        }}
        heading={
          <Text color={"#000000"} fontFamily={"RobotoMedium"} fontSize={aw(18)}>
            {publisher.publisher_name}
          </Text>
        }
      >
        <VStack space={ah(20)} alignItems={"center"}>
          <HStack width={aw(450)}>
            <LeadsWatchAsyncSelect
              refresh={refresh}
              screen={getValues1("payout_type") ? "edit" : "create"}
              minWidth={aw(450)}
              defaultOptions={[
                { label: "Fixed Amount", value: "fixed" },
                { label: "Percentage", value: "percentage" },
              ]}
              handleChange={(value) => setPayoutType(value.value)}
              placeholder="Payout Type"
              control={control1}
              name={"payout_type"}
              rules={{
                required: {
                  value: true,
                  message: "Payout Type is required",
                },
              }}
              invalid={errors1.payout_type ? true : false}
              error={errors1.payout_type ? errors1.payout_type.message : ""}
            />
          </HStack>
          <HStack width={aw(450)}>
            <LeadsWatchAsyncSelect
              refresh={refresh}
              screen={getValues1("currency") ? "edit" : "create"}
              minWidth={aw(450)}
              defaultOptions={[
                { label: "USD", value: "USD" },
                { label: "EURO", value: "EURO" },
              ]}
              control={control1}
              name={"currency"}
              placeholder="Currency"
              rules={{
                required: {
                  value: true,
                  message: "Currency is required",
                },
              }}
              invalid={errors1.currency ? true : false}
              error={errors1.currency ? errors1.currency.message : ""}
            />
          </HStack>
          <HStack width={aw(450)}>
            {/* <LeadsWatchAsyncSelect
              type=""
              isClearable={true}
              refresh={refresh}
              screen={getValues1("number") ? "edit" : "create"}
              minWidth={aw(450)}
              defaultOptions={list.map((val) => {
                return { label: val, name: val };
              })}
              control={control1}
              name={"number"}
              placeholder="Select Number"
            /> */}
            <LeadsWatchAsyncSelect
              // formatNoOptionsMessage={() => "Custom No Options Message"}
              // noOptionMessage={() => "No Options dddd"}
              components={{
                NoOptionsMessage: () => (
                  <Flex alignItems="center" py={ah(10)}>
                    <Text fontFamily="RobotoRegular" fontSize={aw(14)}>
                      No Numbers Availble
                    </Text>
                    <Text
                      onPress={() => navigate("/calls/numbers/manageNumbers")}
                      color={"#2230F8"}
                      underline
                      fontFamily="RobotoRegular"
                      fontSize={aw(14)}
                    >
                      Click Here To Buy Numbers
                    </Text>
                  </Flex>
                ),
              }}
              isClearable={true}
              refresh={refresh}
              screen={getValues1("number") ? "edit" : "create"}
              minWidth={aw(450)}
              type="async"
              loadOptions={(inputValues, callback) =>
                loadOptions(inputValues, callback, "numbers")
              }
              onInputChange={(inputValues) => {
                if (inputValues === "") {
                  unAllNumber();
                }
              }}
              defaultOptions={list.map((val) => {
                return { value: val, label: val };
              })}
              control={control1}
              placeholder="Select Number"
              name="number"
              // rules={{
              //   required: {
              //     value: type === "ivr" ? true : false,
              //   },
              // }}
              // invalid={errors1.number ? true : false}
              // error={errors1.number ? errors1.number.message : ""}
            />
          </HStack>

          <LeadsWatchFilterInput
            onlyNumers={true}
            refresh={refresh}
            screen={
              getValues1("amount") && getValues1("amount").length > 0
                ? "edit"
                : "create"
            }
            name={"amount"}
            placeholder={
              getValues1("payout_type") &&
              getValues1("payout_type").value === "percentage"
                ? "Percentage"
                : "Amount"
            }
            control={control1}
            width={aw(450)}
            // height={ah(40)}
            rules={{
              required: {
                value: true,
                message: "Amount is required",
              },
            }}
            invalid={errors1.amount ? true : false}
            error={errors1.amount ? errors1.amount.message : ""}
          />

          <LeadsWatchFilterInput
            refresh={refresh}
            screen={
              getValues1("post_back_url") &&
              getValues1("post_back_url").length > 0
                ? "edit"
                : "create"
            }
            rightIcon={
              <Tooltip
                label="Ex: your_url?clickid={clickid}&status={status}"
                width={aw(150)}
              >
                <HStack>
                  <LeadsWatchInfoIcon />
                </HStack>
              </Tooltip>
            }
            name={"post_back_url"}
            placeholder={"Post Back Url"}
            control={control1}
            width={aw(450)}
            // height={ah(40)}
            rules={{
              required: {
                value: true,
                message: "Post Back Url is required",
              },
              pattern: {
                value:
                  /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
                message: "Please enter valid url",
              },
              // /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i
            }}
            invalid={errors1.post_back_url ? true : false}
            error={errors1.post_back_url ? errors1.post_back_url.message : ""}
          />
          <LeadsWatchButton
            onPress={addPublisher}
            label={pubId ? "Update" : "Add To Campaign"}
            style={{
              width: aw(150),
              height: ah(40),
              borderRadius: "6px",
            }}
            fontSize={aw(14)}
            fontFamily="RobotoMedium"
            color={"#FFFFFF"}
            bg={"#2230F8"}
            borderColor={"#D1D5DB"}
            fontWeight="400"
          />
        </VStack>
      </LeadsWatchModal>
    </HStack>
  );
};

export default React.memo(CreateCampaign);
