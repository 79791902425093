import React, { useState, useEffect } from "react";
import {
  HStack,
  VStack,
  Text,
  Divider,
  Box,
  ScrollView,
  Pressable,
  FlatList,
  Switch,
  Flex,
} from "native-base";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import XMLParser from "react-xml-parser";
import { toast } from "react-toastify";
//components
import LeadsWatchCard from "../../components/LeadsWatchCard";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchInput from "../../components/LeadsWatchInput";
import LeadsWatchTextArea from "../../components/LeadsWatchTextArea";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
//utils
import { aw, ah } from "../../utils";
//svg
import LeadsWatchEditIcon from "../../components/svg/LeadsWatchEditIcon";
import LeadsWatchDeleteIcon from "../../components/svg/LeadsWatchDeleteIcon";
import LeadsWatchInfoIcon from "../../components/svg/LeadsWatchInfoIcon";
import LeadsWatchCrossIcon from "../../components/svg/LeadsWatchCrossIcon";
import LeadsWatchApproveIcon from "../../components/svg/LeadsWatchApproveIcon";
//redux
import { getAllVerticalFields } from "../../store/actions/verticals";

const DataMapping = (props) => {
  const [edit, setEdit] = useState({});
  const dispatch = useDispatch();
  const [toogle, setToogle] = useState(false);
  const {
    setPingFile,
    pingFile,
    file,
    pFile,
    setPFile,
    mapFields,
    setMapFields,
    select,
    buttonValue,
    buttonValue1,
    setButtonValue,
    setButtonValue1,
    handleClone,
    editId,
    setEditId,
    defaultVerticalFields,
    loadOptions,
    vfCount,
    vfCurrentPage,
    getValues,
    handleFileChosen,
    handleFileChosenForPing,
    handleEditField,
    handleDelete,
    control,
    setSelect,
    fMapFields,
    pingMapFields,
    name,
    setFMapFields,
    setFile,
    setPingMapFields,
  } = props;
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
    getValues: getValues1,
  } = useForm({
    mode: "onSubmit",
  });
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
    getValues: getValues2,
  } = useForm({
    mode: "onSubmit",
  });
  //add fields
  const handleMapFields = async (data) => {
    const newData = toogle
      ? {
          key: data.buyer_field,
          value: data.map_field,
          id: Date.now().toString(),
          default: 1,
        }
      : {
          key: data.buyer_field,
          map_field: data.map_field.value,
          id: Date.now().toString(),
          default: 0,
        };
    //checking for existance with same name
    const exists =
      buttonValue1 === "post"
        ? mapFields[buttonValue].some(
            (val) => val.key.toLowerCase() === data.buyer_field.toLowerCase()
          )
        : mapFields[`${buttonValue1}_${buttonValue}`].find(
            (val) => val.key.toLowerCase() === data.buyer_field.toLowerCase()
          );
    if (exists) {
      toast.error("Buyer field  already exists");
    } else {
      buttonValue1 === "post"
        ? setMapFields({
            ...mapFields,
            [buttonValue]: [newData, ...mapFields[`${buttonValue}`]],
          })
        : setMapFields({
            ...mapFields,
            [`${buttonValue1}_${buttonValue}`]: [
              newData,
              ...mapFields[`${buttonValue1}_${buttonValue}`],
            ],
          });

      reset2({ map_field: "", buyer_field: "" });
    }
  };
  //handleing edit for map fields
  const handleEdit = (item) => {
    setEditId(item.id);
    if (item.default === 1) {
      setEdit({ edit_buyer_field: item.key, edit_map_field: item.value });
    } else {
      setEdit({
        edit_buyer_field: item.key,
        edit_map_field: { label: item.map_field, value: item.map_field },
      });
    }
  };
  //filling the file data in textarea
  useEffect(() => {
    if (buttonValue1 === "post") {
      if (file) {
        reset1({ file: file });
      } else {
        reset1({ file: "" });
      }
    } else {
      if (pingFile) {
        reset1({ file: pingFile });
      } else {
        reset1({ file: "" });
      }
    }
  }, [file, pingFile, buttonValue1]);
  const handleChangeTextArea = () => {
    if (select.file_type === "xml") {
      if (buttonValue1 === "post") {
        const xml = new XMLParser().parseFromString(getValues1("file"));
        setFMapFields(xml);
        setFile(getValues1("file"));
      } else {
        const xml = new XMLParser().parseFromString(getValues1("file"));
        setPingMapFields(xml);
        setPingFile(getValues1("file"));
        // setPFile(getValues1("file"));
      }
      // const jsonData = xmljs.xml2js(getValues1("file"), {
      //   compact: true,
      //   spaces: 4,
      // });
      // setFMapFields(jsonData);
      // setFile(getValues1("file"));
    } else {
      if (buttonValue1 === "post") {
        setFile(getValues1("file"));
        setFMapFields(JSON.parse(getValues1("file")));
      } else {
        setPingFile(getValues1("file"));
        setPingMapFields(JSON.parse(getValues1("file")));
      }
    }
  };
  const cancelChangeTextArea = () => {
    if (select.file_type === "xml") {
      if (buttonValue1 === "post") {
        reset1({ file: file });
      } else {
        reset1({ file: pingFile });
      }
    } else {
      if (buttonValue1 === "post") {
        reset1({ file: file });
      } else {
        reset1({ file: pingFile });
      }
    }
  };
  useEffect(() => {
    if (editId) {
      reset1(edit);
    } else {
      reset1({ edit_buyer_field: "", edit_map_field: "" });
    }
  }, [editId]);

  // const GetAllKeys = ({ obj }) => {
  //   return Object.keys(obj).map((val) => {
  //     if (val !== "_declaration" && val !== "_instruction") {
  //       console.log(val, obj[val]);
  //       return Object.keys(obj[val]).includes("_text") ? (
  //         <HStack>
  //           <Text color="black">{"  "}</Text>
  //           <Text color="black">{val}</Text>
  //           <LeadsWatchSelect
  //             name={val}
  //             control={control}
  //             accessibilityLabel={"map fields "}
  //             list={["name", "firstname", "lastname"]}
  //             handleChange={(value) => setSelect({ ...select, [val]: value })}
  //             width={aw(250)}
  //             height={ah(40)}
  //             placeholder="select"
  //           />
  //         </HStack>
  //       ) : (
  //         <>
  //           <HStack>
  //             <Text color="black">{val}</Text>
  //           </HStack>
  //           <GetAllKeys obj={obj[val]} />
  //         </>
  //       );
  //     }
  //   });
  // };

  useEffect(() => {
    setFile(null);
    setPingFile(null);
    setFMapFields(null);
    setPingMapFields(null);
  }, [select.file_type]);

  return name === "create" ? (
    <Box marginTop={ah(30)}>
      <LeadsWatchCard
        width={
          props.isOpen
            ? [aw(350), aw(350), aw(1168)]
            : [aw(350), aw(350), aw(1248)]
        }
        height={ah(3000)}
      >
        <VStack pb={6} justifyContent={"center"} width="100%" px={aw(15)}>
          <HStack
            width={"100%"}
            height={ah(50)}
            marginTop={ah(18)}
            justifyContent="space-between"
            alignItems={"baseline"}
          >
            <Text
              style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
              color="#121233"
            >
              Data Mapping
            </Text>
          </HStack>
          <Divider bg="#0000001A" />
          <VStack mt={ah(18)}>
            <Text
              style={{
                fontFamily: "RobotoRegular",
                fontSize: aw(15),
                borderBottomWidth: aw(1),
                borderColor: "#212121",
              }}
              width={aw(296)}
              color="#121233"
            >
              Do you have a sample post Information file ?
            </Text>
            <VStack space={aw(10)} mb={ah(5)} mt={ah(23)}>
              <HStack justifyContent="space-between" alignItems={"center"}>
                <Text
                  style={{
                    fontFamily: "RobotoRegular",
                    fontSize: aw(14),
                    borderColor: "#212121",
                  }}
                  color="#121233"
                >
                  Upload the file or paste the sample data
                </Text>
                <LeadsWatchButton
                  label={"Yes"}
                  style={{ width: aw(80), height: ah(32) }}
                  onPress={() => {
                    setPFile("yes");
                    setMapFields({
                      params: [],
                      body: [],
                      header: [],
                      auth: [],
                      ping_params: [],
                      ping_body: [],
                      ping_header: [],
                      ping_auth: [],
                    });
                  }}
                  color={pFile === "yes" ? "#FAFBFD" : "#212121"}
                  bg={pFile === "yes" ? "#0000FF" : "#FAFBFD"}
                />
              </HStack>
              <HStack justifyContent="space-between" alignItems={"center"}>
                <Text
                  style={{
                    fontFamily: "RobotoRegular",
                    fontSize: aw(14),
                    borderColor: "#212121",
                  }}
                  color="#121233"
                >
                  Manually configure the API
                </Text>
                <LeadsWatchButton
                  label={"No"}
                  style={{ width: aw(80), height: ah(32) }}
                  onPress={() => {
                    setPFile("no");
                    setMapFields({
                      params: [],
                      body: [],
                      header: [],
                      auth: [],
                      ping_params: [],
                      ping_body: [],
                      ping_header: [],
                      ping_auth: [],
                    });
                  }}
                  color={pFile === "no" ? "#FAFBFD" : "#212121"}
                  bg={pFile === "no" ? "#0000FF" : "#FAFBFD"}
                />
              </HStack>
            </VStack>
          </VStack>
          {pFile === "no" && (
            <>
              <Divider bg="#0000001A" mb={ah(20)} mt={ah(10)} />
              {select.method && select.method === "ping-post" && (
                <HStack
                  justifyContent={"center"}
                  alignItems={"center"}
                  space={aw(10)}
                  mb={ah(30)}
                >
                  {["ping", "post"].map((val) => (
                    <HStack>
                      <LeadsWatchButton
                        bg={buttonValue1 === val ? "#0000FF" : "#FAFBFD"}
                        label={val}
                        style={{ width: aw(150) }}
                        onPress={() => setButtonValue1(val)}
                        color={buttonValue1 === val ? "#FAFBFD" : "#212121"}
                      />
                    </HStack>
                  ))}
                </HStack>
              )}
              {select.method &&
                select.method === "ping-post" &&
                buttonValue1 === "post" && (
                  <HStack
                    justifyContent={"center"}
                    alignItems={"center"}
                    space={aw(10)}
                    mb={ah(30)}
                  >
                    <HStack>
                      <LeadsWatchButton
                        label={"clone from ping"}
                        style={{ width: aw(150) }}
                        onPress={handleClone}
                        _hover={{
                          bg: "#0000FF",
                          _text: {
                            color: "#FAFBFD",
                          },
                        }}
                      />
                    </HStack>
                  </HStack>
                )}

              <VStack
                width={"100%"}
                borderRadius={"10px"}
                borderColor={"#D7D7D7"}
              >
                <HStack width={"100%"}>
                  {["params", "body", "header", "auth"].map((val, index) => (
                    <Pressable
                      key={index}
                      width={"25%"}
                      height={ah(60)}
                      alignItems={"center"}
                      justifyContent={"center"}
                      borderWidth={buttonValue === val ? 1 : 0}
                      borderColor={"#D7D7D7"}
                      borderBottomWidth={buttonValue === val ? 0 : aw(1)}
                      borderTopLeftRadius={aw(10)}
                      borderTopRightRadius={aw(10)}
                      bg={buttonValue === val ? "#F9FAFB" : "#FFFFFF"}
                      onPress={() => setButtonValue(val)}
                    >
                      <Text
                        alignSelf={"center"}
                        style={{
                          fontFamily: "RobotoMedium",
                          fontSize: aw(18),
                        }}
                        color="#121233"
                        pl={1}
                      >
                        {val.charAt(0).toUpperCase() +
                          val.slice(1).replace("_", " ")}
                      </Text>
                    </Pressable>
                  ))}
                </HStack>
                <HStack
                  alignItems={"center"}
                  style={{
                    height: ah(60),
                    background: "#F9FAFB 0% 0% no-repeat padding-box",
                    borderWidth: 1,
                    borderTopWidth: 0,
                    borderColor: "#D7D7D7",
                  }}
                >
                  {["Buyer Field", "Map Field", "Actions"].map((val, index) => {
                    return (
                      <HStack
                        key={index}
                        width={aw(500)}
                        alignItems={"center"}
                        justifyContent="flex-start"
                      >
                        {val === "Actions" ? (
                          <Text
                            width={aw(200)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            ml={aw(20)}
                            color="#121233"
                          >
                            {val}
                          </Text>
                        ) : (
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            ml={aw(20)}
                            color="#121233"
                          >
                            {val}
                          </Text>
                        )}
                      </HStack>
                    );
                  })}
                </HStack>
                <ScrollView
                  maxHeight={ah(200)}
                  style={{
                    borderBottomRightRadius: aw(10),
                    borderBottomLeftRadius: aw(10),
                    borderbottomWidth: "1px solid #D7D7D7",
                    borderTopWidth: "0px",
                    borderColor: "#D7D7D7",
                    borderWidth: "1px",
                  }}
                >
                  <FlatList
                    ListEmptyComponent={
                      <HStack
                        justifyContent="center"
                        alignItems={"center"}
                        height={ah(100)}
                      >
                        <Text
                          color="black"
                          fontSize={aw(14)}
                          fontFamily={"RobotoRegular"}
                        >
                          No data found
                        </Text>
                      </HStack>
                    }
                    data={
                      buttonValue1 === "post"
                        ? mapFields[buttonValue]
                        : mapFields[`${buttonValue1}_${buttonValue}`]
                    }
                    keyExtractor={(item, index) => index}
                    renderItem={({ item, index }) => (
                      <HStack
                        height={ah(50)}
                        style={{
                          borderTopWidth: index === 0 ? 0 : aw(1),
                          borderLeftWidth: "0px",
                          borderRightWidth: "0px",
                          borderBottomWidth: "0px",
                          borderColor: "#D7D7D7",
                        }}
                      >
                        {editId === item.id ? (
                          <HStack
                            width={aw(500)}
                            alignItems={"center"}
                            justifyContent="flex-start"
                            ml={aw(20)}
                          >
                            <LeadsWatchInput
                              control={control1}
                              name={"edit_buyer_field"}
                              style={{ width: aw(250), height: ah(40) }}
                              rules={{
                                required: {
                                  value: true,
                                },
                              }}
                              invalid={
                                errors1["edit_buyer_field"] ? true : false
                              }
                              error={
                                errors1["edit_buyer_field"]
                                  ? errors1["edit_buyer_field"].message
                                  : ""
                              }
                            />
                          </HStack>
                        ) : (
                          <HStack
                            width={aw(500)}
                            alignItems={"center"}
                            justifyContent="flex-start"
                          >
                            <Text
                              fontSize={aw(14)}
                              fontFamily={"RobotoRegular"}
                              ml={aw(20)}
                              color="#121233"
                            >
                              {item["key"]}
                            </Text>
                          </HStack>
                        )}
                        {editId === item.id ? (
                          item.default === 0 ? (
                            <HStack width={aw(500)} pt={1}>
                              <HStack width={aw(250)}>
                                <LeadsWatchAsyncSelect
                                  required={true}
                                  minHeight={34}
                                  maxHeight={34}
                                  minWidth={aw(250)}
                                  type="async"
                                  loadOptions={(inputValues, callback) =>
                                    loadOptions(
                                      inputValues,
                                      callback,
                                      "verticalFields"
                                    )
                                  }
                                  onInputChange={async (inputValues) => {
                                    if (inputValues === "") {
                                      try {
                                        await dispatch(
                                          getAllVerticalFields(
                                            vfCurrentPage,
                                            vfCount,
                                            select.vertical_id
                                          )
                                        );
                                      } catch (err) {
                                        console.log(err.message);
                                      }
                                    }
                                  }}
                                  onBlur={async () => {
                                    try {
                                      await dispatch(
                                        getAllVerticalFields(
                                          vfCurrentPage,
                                          vfCount,
                                          select.vertical_id
                                        )
                                      );
                                    } catch (err) {
                                      console.log(err.message);
                                    }
                                  }}
                                  defaultOptions={defaultVerticalFields}
                                  control={control1}
                                  placeholder="Select"
                                  name={"edit_map_field"}
                                  rules={{
                                    required: {
                                      value: true,
                                    },
                                  }}
                                  invalid={
                                    errors1["edit_map_field"] ? true : false
                                  }
                                  error={
                                    errors1["edit_map_field"]
                                      ? errors1["edit_map_field"].message
                                      : ""
                                  }
                                />
                              </HStack>
                            </HStack>
                          ) : (
                            <HStack
                              width={aw(500)}
                              alignItems={"center"}
                              justifyContent="flex-start"
                            >
                              <LeadsWatchInput
                                control={control1}
                                name={"edit_map_field"}
                                style={{
                                  width: aw(250),
                                  height: ah(40),
                                }}
                                rules={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                invalid={
                                  errors1["edit_map_field"] ? true : false
                                }
                                error={
                                  errors1["edit_map_field"]
                                    ? errors1["edit_map_field"].message
                                    : ""
                                }
                              />
                            </HStack>
                          )
                        ) : (
                          <HStack
                            width={aw(500)}
                            alignItems={"center"}
                            justifyContent="flex-start"
                          >
                            <Text
                              fontSize={aw(14)}
                              fontFamily={"RobotoRegular"}
                              ml={aw(20)}
                              color="#121233"
                            >
                              {item.default === 0
                                ? item["map_field"]
                                : item["value"]}
                            </Text>
                          </HStack>
                        )}
                        {editId === item.id ? (
                          <HStack
                            pl={2}
                            width={aw(500)}
                            alignItems={"center"}
                            justifyContent="flex-start"
                            space={aw(15)}
                          >
                            <Pressable onPress={handleSubmit1(handleEditField)}>
                              <LeadsWatchApproveIcon />
                            </Pressable>
                            <Pressable
                              onPress={() => {
                                setEditId("");
                              }}
                            >
                              <LeadsWatchCrossIcon />
                            </Pressable>
                          </HStack>
                        ) : (
                          <HStack
                            pl={5}
                            width={aw(500)}
                            alignItems={"center"}
                            justifyContent="flex-start"
                          >
                            <Pressable onPress={() => handleEdit(item)}>
                              <LeadsWatchEditIcon />
                            </Pressable>
                            <Pressable onPress={() => handleDelete(item.id)}>
                              <LeadsWatchDeleteIcon />
                            </Pressable>
                          </HStack>
                        )}
                      </HStack>
                    )}
                  />
                </ScrollView>
              </VStack>
              {editId === "" && (
                <>
                  <HStack mt={ah(20)} width={"100%"}>
                    <HStack
                      width={"100%"}
                      alignItems={"center"}
                      style={{
                        height: ah(60),
                        background: "#F9FAFB 0% 0% no-repeat padding-box",
                        // border: "1px solid #D7D7D7",
                        borderWidth: aw(1),
                        borderColor: "#D7D7D7",
                        borderTopLeftRadius: aw(10),
                        borderTopRightRadius: aw(10),
                      }}
                    >
                      {["Buyer Field", "Map Field", "Map Field Type"].map(
                        (val, index) => (
                          <HStack
                            key={index}
                            width="33%"
                            alignItems={"center"}
                            justifyContent="flex-start"
                          >
                            <Text
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                              ml={aw(20)}
                              color="#121233"
                            >
                              {val}
                            </Text>
                          </HStack>
                        )
                      )}
                    </HStack>
                  </HStack>
                  <HStack
                    width={"100%"}
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      height: ah(60),

                      // background: "#F9FAFB 0% 0% no-repeat padding-box",
                      border: "1px solid #D7D7D7",
                      borderTopWidth: "0px",
                      borderBottomLeftRadius: aw(10),
                      borderBottomRightRadius: aw(10),
                    }}
                  >
                    <HStack width={"33%"} px={2} height={36}>
                      <LeadsWatchInput
                        control={control2}
                        name={"buyer_field"}
                        height={36}
                        style={{ width: "80%" }}
                        rules={{
                          required: {
                            value: true,
                          },
                        }}
                        invalid={errors2["buyer_field"] ? true : false}
                        error={
                          errors2["buyer_field"]
                            ? errors2["buyer_field"].message
                            : ""
                        }
                      />
                    </HStack>
                    <HStack width={"33%"} height={36}>
                      {!toogle ? (
                        <Flex width={"80%"} height={36}>
                          <LeadsWatchAsyncSelect
                            required={true}
                            minHeight={36}
                            minWidth={"100%"}
                            type="async"
                            loadOptions={(inputValues, callback) =>
                              loadOptions(
                                inputValues,
                                callback,
                                "verticalFields"
                              )
                            }
                            onInputChange={async (inputValues) => {
                              if (inputValues === "") {
                                try {
                                  await dispatch(
                                    getAllVerticalFields(
                                      vfCurrentPage,
                                      vfCount,
                                      select.vertical_id
                                    )
                                  );
                                } catch (err) {
                                  console.log(err.message);
                                }
                              }
                            }}
                            onBlur={async () => {
                              try {
                                await dispatch(
                                  getAllVerticalFields(
                                    vfCurrentPage,
                                    vfCount,
                                    select.vertical_id
                                  )
                                );
                              } catch (err) {
                                console.log(err.message);
                              }
                            }}
                            defaultOptions={defaultVerticalFields}
                            control={control2}
                            placeholder="Select"
                            name={"map_field"}
                            rules={{
                              required: {
                                value: true,
                              },
                            }}
                            invalid={errors2["map_field"] ? true : false}
                            error={
                              errors2["map_field"]
                                ? errors2["map_field"].message
                                : ""
                            }
                            // invalid={true}
                          />
                        </Flex>
                      ) : (
                        <LeadsWatchInput
                          control={control2}
                          name={"map_field"}
                          height={36}
                          style={{ width: "80%" }}
                          rules={{
                            required: {
                              value: true,
                            },
                          }}
                          invalid={errors2["map_field"] ? true : false}
                          error={
                            errors2["map_field"]
                              ? errors2["map_field"].message
                              : ""
                          }
                        />
                      )}
                    </HStack>
                    <HStack width={"33%"} px={aw(50)}>
                      <Switch
                        _hover={{
                          offTrackColor: "#C1C1C1",
                          onTrackColor: "#0000FF",
                        }}
                        onTrackColor="#0000FF"
                        offTrackColor="#C1C1C1"
                        isChecked={toogle}
                        onToggle={() => {
                          setToogle(!toogle);
                          reset2({
                            map_field: "",
                            buyer_field: getValues2("buyer_field"),
                          });
                        }}
                      />
                    </HStack>
                  </HStack>

                  <HStack
                    justifyContent={"center"}
                    alignItems={"center"}
                    mt={ah(20)}
                  >
                    <LeadsWatchButton
                      onPress={handleSubmit2(handleMapFields)}
                      bg={"#0000FF"}
                      label={"add"}
                      style={{ width: aw(150) }}
                      color={"#FAFBFD"}
                    />
                  </HStack>
                </>
              )}
            </>
          )}

          {pFile === "yes" && (
            <>
              <Divider bg="#0000001A" mb={ah(20)} mt={ah(10)} />
              {select.method && select.method === "ping-post" ? (
                <>
                  <HStack
                    justifyContent={"center"}
                    alignItems={"center"}
                    space={aw(10)}
                    mb={ah(10)}
                  >
                    {["ping", "post"].map((val, index) => (
                      <HStack key={index}>
                        <LeadsWatchButton
                          bg={buttonValue1 === val ? "#0000FF" : "#FAFBFD"}
                          label={val}
                          style={{ width: aw(150) }}
                          onPress={() => setButtonValue1(val)}
                          color={buttonValue1 === val ? "#FAFBFD" : "#212121"}
                        />
                      </HStack>
                    ))}
                  </HStack>
                  <VStack width={aw(587)} space={ah(20)}>
                    <Text
                      style={{
                        fontFamily: "RobotoRegular",
                        fontSize: aw(14),
                      }}
                      color="#121233"
                    >
                      Select a file type to upload your sample post data
                    </Text>

                    {/* <LeadsWatchSelect
                      name={"file_type"}
                      control={control}
                      placeholder={"Select File Type"}
                      list={["XML", "JSON"]}
                      value={select["file_type"]}
                      handleChange={(value) =>
                        setSelect({
                          ...select,
                          file_type: value,
                        })
                      }
                      width={aw(300)}
                      height={ah(40)}
                    /> */}
                    <HStack>
                      <LeadsWatchAsyncSelect
                        minHeight={34}
                        screen={getValues("file_type") ? "edit" : "create"}
                        minWidth={aw(250)}
                        defaultOptions={[
                          {
                            label: "XML",
                            value: "xml",
                          },
                          {
                            label: "JSON",
                            value: "json",
                          },
                        ]}
                        name={"file_type"}
                        control={control}
                        placeholder={"Select File Type"}
                        handleChange={(value) =>
                          setSelect({
                            ...select,
                            file_type: value.value,
                          })
                        }
                      />
                    </HStack>
                    {buttonValue1 === "post" && select.file_type && (
                      <HStack mt={ah(10)}>
                        <input
                          name={"postFile"}
                          type="file"
                          onChange={(e) => handleFileChosen(e)}
                          accept={
                            select.file_type === "xml"
                              ? "text/xml"
                              : "application/json"
                          }
                        />
                      </HStack>
                    )}
                    {buttonValue1 === "ping" && select.file_type && (
                      <HStack mt={ah(10)}>
                        <input
                          name={"pingFile"}
                          type="file"
                          onChange={(e) => handleFileChosenForPing(e)}
                          accept={
                            select.file_type === "xml"
                              ? "text/xml"
                              : "application/json"
                          }
                        />
                      </HStack>
                    )}
                  </VStack>
                </>
              ) : (
                <VStack width={aw(587)} space={ah(20)}>
                  <Text
                    style={{
                      fontFamily: "RobotoRegular",
                      fontSize: aw(14),
                    }}
                    color="#121233"
                  >
                    Select a file type to upload your sample post data
                  </Text>
                  <HStack>
                    <LeadsWatchAsyncSelect
                      minHeight={34}
                      screen={getValues("file_type") ? "edit" : "create"}
                      minWidth={aw(250)}
                      defaultOptions={[
                        {
                          label: "XML",
                          value: "xml",
                        },
                        {
                          label: "JSON",
                          value: "json",
                        },
                      ]}
                      name={"file_type"}
                      control={control}
                      placeholder={"Select File Type"}
                      handleChange={(value) =>
                        setSelect({
                          ...select,
                          file_type: value.value,
                        })
                      }
                    />
                  </HStack>
                  {select.file_type && (
                    <HStack mt={ah(10)}>
                      <input
                        type="file"
                        onChange={(e) => handleFileChosen(e)}
                        accept={
                          select.file_type === "xml"
                            ? "text/xml"
                            : "application/json"
                        }
                      />
                    </HStack>
                  )}
                </VStack>
              )}
              <VStack height={ah(270)} my={ah(20)}>
                <HStack
                  justifyContent={"space-between"}
                  bgColor={"#F9FAFB"}
                  borderColor="#D1D5DB"
                  borderWidth={aw(1)}
                  borderBottomWidth={0}
                  height={ah(40)}
                  alignItems={"center"}
                  px={aw(10)}
                  borderTopLeftRadius={aw(5)}
                  borderTopRightRadius={aw(5)}
                >
                  <Text
                    style={{
                      fontFamily: "RobotoRegular",
                      fontSize: aw(14),
                    }}
                    color="#121233"
                  >
                    Paste sample post data and map the fields
                  </Text>
                  <LeadsWatchInfoIcon />
                </HStack>
                <LeadsWatchTextArea
                  borderRadius={aw(0)}
                  control={control1}
                  name={"file"}
                  height={ah(235)}
                  width={aw(1195)}
                  focusOutlineColor={"#D1D5DB"}
                  // placeholder={"r"}
                />
              </VStack>
              <HStack space={aw(10)}>
                <LeadsWatchButton
                  onPress={handleChangeTextArea}
                  bg={"#0000FF"}
                  label={"save"}
                  style={{ width: aw(80), height: ah(32) }}
                  color={"#FAFBFD"}
                />
                <LeadsWatchButton
                  onPress={cancelChangeTextArea}
                  bg={"#0000FF"}
                  label={"Revert"}
                  style={{ width: aw(80), height: ah(32) }}
                  color={"#FAFBFD"}
                />
              </HStack>
              <VStack mt={ah(30)}>
                {buttonValue1 === "post"
                  ? select.file_type === "xml"
                    ? fMapFields &&
                      fMapFields["children"] && (
                        <>
                          <HStack
                            bgColor={"#F9FAFB"}
                            px={10}
                            width={"100%"}
                            height={ah(60)}
                            // bgColor="amber.100"
                            alignItems={"center"}
                            style={{
                              borderWidth: aw(1),
                              borderColor: "#D7D7D7",
                              borderTopRightRadius: aw(10),
                              borderTopLeftRadius: aw(10),
                            }}
                          >
                            <Text
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                              ml={aw(20)}
                              color="#121233"
                              width={aw(400)}
                            >
                              Group
                            </Text>
                            <Text
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                              ml={aw(20)}
                              color="#121233"
                              width={aw(400)}
                            >
                              Buyer Field
                            </Text>
                            <Text
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                              ml={aw(20)}
                              color="#121233"
                              width={aw(400)}
                            >
                              Map Field
                            </Text>
                          </HStack>
                          <ScrollView
                            width={"100%"}
                            maxHeight={aw(340)}
                            // showsVerticalScrollIndicator={false}
                            style={{
                              borderBottomRightRadius: aw(10),
                              borderBottomLeftRadius: aw(10),
                              borderTopWidth: "0px",
                              borderColor: "#D7D7D7",
                              borderWidth: aw(1),
                            }}
                          >
                            {fMapFields &&
                              fMapFields["children"] &&
                              fMapFields["children"].map((val, index) => {
                                if (val["children"]) {
                                  return (
                                    <>
                                      <HStack
                                        key={index}
                                        px={10}
                                        width={"100%"}
                                        height={ah(60)}
                                        // bgColor="amber.100"
                                        alignItems={"center"}
                                        style={{
                                          borderWidth: aw(1),
                                          borderColor: "#D7D7D7",
                                          borderTopWidth:
                                            index === 0 ? 0 : aw(1),
                                          borderLeftWidth: 0,
                                          borderRightWidth: 0,
                                        }}
                                        bgColor={"#F9FAFB"}
                                      >
                                        <Text
                                          color="black"
                                          width={aw(400)}
                                          fontFamily={"RobotoRegular"}
                                          pl={aw(20)}
                                        >
                                          {val.name}
                                        </Text>
                                      </HStack>
                                      {val["children"].map((d, i) => (
                                        <HStack
                                          key={d.name}
                                          style={{
                                            borderWidth: aw(1),
                                            borderColor: "#D7D7D7",
                                            borderTopWidth: 0,
                                            borderLeftWidth: 0,
                                            borderRightWidth: 0,
                                            // borderBottomWidth: aw(1),
                                            borderBottomWidth:
                                              i === val["children"].length - 1
                                                ? 0
                                                : aw(1),
                                          }}
                                          px={10}
                                          width={"100%"}
                                          height={ah(60)}
                                          // bgColor="amber.200"
                                          alignItems={"center"}
                                        >
                                          <Text
                                            fontFamily={"RobotoRegular"}
                                            color="black"
                                            width={aw(400)}
                                          ></Text>
                                          <Text
                                            color="black"
                                            width={aw(400)}
                                            px={10}
                                            fontFamily={"RobotoRegular"}
                                          >
                                            {d.name}
                                          </Text>
                                          <HStack width={aw(400)}>
                                            <HStack width={aw(250)}>
                                              <LeadsWatchAsyncSelect
                                                //  isClearable={true}
                                                required={true}
                                                minHeight={37}
                                                minWidth={aw(250)}
                                                type="async"
                                                loadOptions={(
                                                  inputValues,
                                                  callback
                                                ) =>
                                                  loadOptions(
                                                    inputValues,
                                                    callback,
                                                    "verticalFields"
                                                  )
                                                }
                                                onInputChange={async (
                                                  inputValues
                                                ) => {
                                                  if (inputValues === "") {
                                                    try {
                                                      await dispatch(
                                                        getAllVerticalFields(
                                                          vfCurrentPage,
                                                          vfCount,
                                                          select.vertical_id
                                                        )
                                                      );
                                                    } catch (err) {
                                                      console.log(err.message);
                                                    }
                                                  }
                                                }}
                                                onBlur={async () => {
                                                  try {
                                                    await dispatch(
                                                      getAllVerticalFields(
                                                        vfCurrentPage,
                                                        vfCount,
                                                        select.vertical_id
                                                      )
                                                    );
                                                  } catch (err) {
                                                    console.log(err.message);
                                                  }
                                                }}
                                                handleChange={(value) => {
                                                  if (buttonValue1 === "post") {
                                                    if (
                                                      mapFields[
                                                        val[
                                                          "name"
                                                        ].toLowerCase()
                                                      ] &&
                                                      mapFields[
                                                        val[
                                                          "name"
                                                        ].toLowerCase()
                                                      ].length
                                                    ) {
                                                      if (
                                                        mapFields[
                                                          val.name.toLowerCase()
                                                        ].find(
                                                          (item) =>
                                                            d["name"] ===
                                                            item.key
                                                        )
                                                      ) {
                                                        const data = mapFields[
                                                          val.name.toLowerCase()
                                                        ].map((item) => {
                                                          if (
                                                            d["name"] ===
                                                            item.key
                                                          ) {
                                                            return {
                                                              ...item,
                                                              map_field:
                                                                value.value,
                                                            };
                                                          } else {
                                                            return item;
                                                          }
                                                        });

                                                        setMapFields({
                                                          ...mapFields,
                                                          [val.name.toLowerCase()]:
                                                            data,
                                                        });
                                                      } else {
                                                        setMapFields({
                                                          ...mapFields,
                                                          [val[
                                                            "name"
                                                          ].toLowerCase()]: [
                                                            ...mapFields[
                                                              val[
                                                                "name"
                                                              ].toLowerCase()
                                                            ],
                                                            {
                                                              key: d["name"],
                                                              map_field:
                                                                value.value,
                                                              id: Date.now().toString(),
                                                              default: 0,
                                                            },
                                                          ],
                                                        });
                                                      }
                                                    } else {
                                                      setMapFields({
                                                        ...mapFields,
                                                        [val[
                                                          "name"
                                                        ].toLowerCase()]: [
                                                          ...mapFields[
                                                            val[
                                                              "name"
                                                            ].toLowerCase()
                                                          ],
                                                          {
                                                            key: d["name"],
                                                            map_field:
                                                              value.value,
                                                            id: Date.now().toString(),
                                                            default: 0,
                                                          },
                                                        ],
                                                      });
                                                    }
                                                  } else {
                                                    if (
                                                      mapFields[
                                                        `ping_${val.name.toLowerCase()}`
                                                      ] &&
                                                      mapFields[
                                                        `ping_${val.name.toLowerCase()}`
                                                      ].length
                                                    ) {
                                                      if (
                                                        mapFields[
                                                          `ping_${val.name.toLowerCase()}`
                                                        ].find(
                                                          (item) =>
                                                            d["name"] ===
                                                            item.key
                                                        )
                                                      ) {
                                                        const data = mapFields[
                                                          `ping_${val.name.toLowerCase()}`
                                                        ].map((item) => {
                                                          if (
                                                            d["name"] ===
                                                            item.key
                                                          ) {
                                                            return {
                                                              ...item,
                                                              map_field:
                                                                value.value,
                                                            };
                                                          } else {
                                                            return item;
                                                          }
                                                        });

                                                        setMapFields({
                                                          ...mapFields,
                                                          [`ping_${val.name.toLowerCase()}`]:
                                                            data,
                                                        });
                                                      } else {
                                                        setMapFields({
                                                          ...mapFields,
                                                          [`ping_${val.name.toLowerCase()}`]:
                                                            [
                                                              ...mapFields[
                                                                `ping_${val.name.toLowerCase()}`
                                                              ],
                                                              {
                                                                key: d["name"],
                                                                map_field:
                                                                  value.value,
                                                                id: Date.now().toString(),
                                                                default: 0,
                                                              },
                                                            ],
                                                        });
                                                      }
                                                    } else {
                                                      setMapFields({
                                                        ...mapFields,
                                                        [`ping_${val.name.toLowerCase()}`]:
                                                          [
                                                            ...mapFields[
                                                              `ping_${val.name.toLowerCase()}`
                                                            ],
                                                            {
                                                              key: d["name"],
                                                              map_field:
                                                                value.value,
                                                              id: Date.now().toString(),
                                                              default: 0,
                                                            },
                                                          ],
                                                      });
                                                    }
                                                  }
                                                }}
                                                defaultOptions={
                                                  defaultVerticalFields
                                                }
                                                control={control}
                                                placeholder="Select"
                                                name={`${buttonValue1}_${val.name}_${d.name}`}

                                                // invalid={true}
                                              />
                                            </HStack>
                                          </HStack>
                                        </HStack>
                                      ))}
                                    </>
                                  );
                                }
                              })}
                          </ScrollView>
                        </>
                      )
                    : fMapFields &&
                      Object.keys(fMapFields).length > 0 && (
                        <>
                          <HStack
                            bgColor={"#F9FAFB"}
                            px={10}
                            width={"100%"}
                            height={ah(60)}
                            // bgColor="amber.100"
                            alignItems={"center"}
                            style={{
                              borderWidth: aw(1),
                              borderColor: "#D7D7D7",
                              borderTopRightRadius: aw(10),
                              borderTopLeftRadius: aw(10),
                            }}
                          >
                            <Text
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                              ml={aw(20)}
                              color="#121233"
                              width={aw(400)}
                            >
                              Group
                            </Text>
                            <Text
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                              ml={aw(20)}
                              color="#121233"
                              width={aw(400)}
                            >
                              Buyer Field
                            </Text>
                            <Text
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                              ml={aw(20)}
                              color="#121233"
                              width={aw(400)}
                            >
                              Map Field
                            </Text>
                          </HStack>
                          <ScrollView
                            maxHeight={aw(340)}
                            width={"100%"}
                            // showsVerticalScrollIndicator={false}
                            style={{
                              borderBottomRightRadius: aw(10),
                              borderBottomLeftRadius: aw(10),
                              borderTopWidth: "0px",
                              borderColor: "#D7D7D7",
                              borderWidth: aw(1),
                            }}
                          >
                            {Object.keys(fMapFields).map((val, index) => {
                              return (
                                <>
                                  <HStack
                                    key={index}
                                    px={10}
                                    width={"100%"}
                                    height={ah(60)}
                                    // bgColor="amber.100"
                                    alignItems={"center"}
                                    style={{
                                      borderWidth: aw(1),
                                      borderColor: "#D7D7D7",
                                      borderTopWidth: 0,
                                      borderLeftWidth: 0,
                                      borderRightWidth: 0,
                                    }}
                                    bgColor={"#F9FAFB"}
                                  >
                                    <Text
                                      color="black"
                                      width={aw(400)}
                                      fontFamily={"RobotoRegular"}
                                      pl={aw(20)}
                                    >
                                      {val}
                                    </Text>
                                  </HStack>

                                  {Object.keys(fMapFields[val]).map((d) => (
                                    <HStack
                                      style={{
                                        borderWidth: aw(1),
                                        borderColor: "#D7D7D7",
                                        borderTopWidth: 0,
                                        borderLeftWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: aw(1),
                                      }}
                                      px={10}
                                      width={"100%"}
                                      height={ah(60)}
                                      // bgColor="amber.200"
                                      alignItems={"center"}
                                    >
                                      <Text
                                        color="black"
                                        width={aw(400)}
                                        fontFamily={"RobotoRegular"}
                                      ></Text>
                                      <Text
                                        color="black"
                                        width={aw(400)}
                                        px={10}
                                        fontFamily={"RobotoRegular"}
                                      >
                                        {d}
                                      </Text>
                                      <HStack width={aw(400)}>
                                        <HStack width={aw(250)}>
                                          {/* <LeadsWatchSelect
                                            notToLowerCase={true}
                                            name={`${buttonValue1}_${val}_${d}`}
                                            control={control}
                                            accessibilityLabel={"map fields "}
                                            list={
                                              allVerticalFields &&
                                              allVerticalFields.list
                                                ? allVerticalFields.list.map(
                                                    (val) => val.name
                                                  )
                                                : []
                                            }
                                            handleChange={(value) => {
                                              if (buttonValue1 === "post") {
                                                if (
                                                  val.toLowerCase() &&
                                                  mapFields[val.toLowerCase()]
                                                    .length
                                                ) {
                                                  if (
                                                    mapFields[
                                                      val.toLowerCase()
                                                    ].find(
                                                      (item) =>
                                                        d.toLowerCase() ===
                                                        item.key
                                                    )
                                                  ) {
                                                    const data = mapFields[
                                                      val.toLowerCase()
                                                    ].map((item) => {
                                                      if (
                                                        d.toLowerCase() ===
                                                        item.key
                                                      ) {
                                                        return {
                                                          ...item,
                                                          map_field: value,
                                                        };
                                                      } else {
                                                        return item;
                                                      }
                                                    });

                                                    setMapFields({
                                                      ...mapFields,
                                                      [val.toLowerCase()]: data,
                                                    });
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [val.toLowerCase()]: [
                                                        ...mapFields[
                                                          val.toLowerCase()
                                                        ],
                                                        {
                                                          key: d,
                                                          map_field: value,
                                                          id: Date.now().toString(),
                                                          default: 0,
                                                        },
                                                      ],
                                                    });
                                                  }
                                                } else {
                                                  setMapFields({
                                                    ...mapFields,
                                                    [val.toLowerCase()]: [
                                                      ...mapFields[
                                                        val.toLowerCase()
                                                      ],
                                                      {
                                                        key: d,
                                                        map_field: value,
                                                        id: Date.now().toString(),
                                                        default: 0,
                                                      },
                                                    ],
                                                  });
                                                }
                                              } else {
                                                if (
                                                  mapFields[
                                                    `ping_${val.toLowerCase()}`
                                                  ] &&
                                                  mapFields[
                                                    `ping_${val.toLowerCase()}`
                                                  ].length
                                                ) {
                                                  if (
                                                    mapFields[
                                                      `ping_${val.toLowerCase()}`
                                                    ].find(
                                                      (item) =>
                                                        d.toLowerCase() ===
                                                        item.key
                                                    )
                                                  ) {
                                                    const data = mapFields[
                                                      `ping_${val.toLowerCase()}`
                                                    ].map((item) => {
                                                      if (
                                                        d.toLowerCase() ===
                                                        item.key
                                                      ) {
                                                        return {
                                                          ...item,
                                                          map_field: value,
                                                        };
                                                      } else {
                                                        return item;
                                                      }
                                                    });

                                                    setMapFields({
                                                      ...mapFields,
                                                      [`ping_${val.toLowerCase()}`]:
                                                        data,
                                                    });
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [`ping_${val.toLowerCase()}`]:
                                                        [
                                                          ...mapFields[
                                                            `ping_${val.toLowerCase()}`
                                                          ],
                                                          {
                                                            key: d,
                                                            map_field: value,
                                                            id: Date.now().toString(),
                                                            default: 0,
                                                          },
                                                        ],
                                                    });
                                                  }
                                                } else {
                                                  setMapFields({
                                                    ...mapFields,
                                                    [`ping_${val.toLowerCase()}`]:
                                                      [
                                                        ...mapFields[
                                                          `ping_${val.toLowerCase()}`
                                                        ],
                                                        {
                                                          key: d,
                                                          map_field: value,
                                                          id: Date.now().toString(),
                                                          default: 0,
                                                        },
                                                      ],
                                                  });
                                                }
                                              }
                                            }}
                                            width={aw(250)}
                                            height={ah(40)}
                                            placeholder="select"
                                          /> */}
                                          <LeadsWatchAsyncSelect
                                            //  isClearable={true}
                                            required={true}
                                            minHeight={37}
                                            minWidth={aw(250)}
                                            type="async"
                                            loadOptions={(
                                              inputValues,
                                              callback
                                            ) =>
                                              loadOptions(
                                                inputValues,
                                                callback,
                                                "verticalFields"
                                              )
                                            }
                                            onInputChange={async (
                                              inputValues
                                            ) => {
                                              if (inputValues === "") {
                                                try {
                                                  await dispatch(
                                                    getAllVerticalFields(
                                                      vfCurrentPage,
                                                      vfCount,
                                                      select.vertical_id
                                                    )
                                                  );
                                                } catch (err) {
                                                  console.log(err.message);
                                                }
                                              }
                                            }}
                                            onBlur={async () => {
                                              try {
                                                await dispatch(
                                                  getAllVerticalFields(
                                                    vfCurrentPage,
                                                    vfCount,
                                                    select.vertical_id
                                                  )
                                                );
                                              } catch (err) {
                                                console.log(err.message);
                                              }
                                            }}
                                            handleChange={(value) => {
                                              if (buttonValue1 === "post") {
                                                if (
                                                  val.toLowerCase() &&
                                                  mapFields[val.toLowerCase()]
                                                    .length
                                                ) {
                                                  if (
                                                    mapFields[
                                                      val.toLowerCase()
                                                    ].find(
                                                      (item) => d === item.key
                                                    )
                                                  ) {
                                                    const data = mapFields[
                                                      val.toLowerCase()
                                                    ].map((item) => {
                                                      if (d === item.key) {
                                                        return {
                                                          ...item,
                                                          map_field:
                                                            value.value,
                                                        };
                                                      } else {
                                                        return item;
                                                      }
                                                    });

                                                    setMapFields({
                                                      ...mapFields,
                                                      [val.toLowerCase()]: data,
                                                    });
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [val.toLowerCase()]: [
                                                        ...mapFields[
                                                          val.toLowerCase()
                                                        ],
                                                        {
                                                          key: d,
                                                          map_field:
                                                            value.value,
                                                          id: Date.now().toString(),
                                                          default: 0,
                                                        },
                                                      ],
                                                    });
                                                  }
                                                } else {
                                                  setMapFields({
                                                    ...mapFields,
                                                    [val.toLowerCase()]: [
                                                      ...mapFields[
                                                        val.toLowerCase()
                                                      ],
                                                      {
                                                        key: d,
                                                        map_field: value.value,
                                                        id: Date.now().toString(),
                                                        default: 0,
                                                      },
                                                    ],
                                                  });
                                                }
                                              } else {
                                                if (
                                                  mapFields[
                                                    `ping_${val.toLowerCase()}`
                                                  ] &&
                                                  mapFields[
                                                    `ping_${val.toLowerCase()}`
                                                  ].length
                                                ) {
                                                  if (
                                                    mapFields[
                                                      `ping_${val.toLowerCase()}`
                                                    ].find(
                                                      (item) => d === item.key
                                                    )
                                                  ) {
                                                    const data = mapFields[
                                                      `ping_${val.toLowerCase()}`
                                                    ].map((item) => {
                                                      if (d === item.key) {
                                                        return {
                                                          ...item,
                                                          map_field:
                                                            value.value,
                                                        };
                                                      } else {
                                                        return item;
                                                      }
                                                    });

                                                    setMapFields({
                                                      ...mapFields,
                                                      [`ping_${val.toLowerCase()}`]:
                                                        data,
                                                    });
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [`ping_${val.toLowerCase()}`]:
                                                        [
                                                          ...mapFields[
                                                            `ping_${val.toLowerCase()}`
                                                          ],
                                                          {
                                                            key: d,
                                                            map_field:
                                                              value.value,
                                                            id: Date.now().toString(),
                                                            default: 0,
                                                          },
                                                        ],
                                                    });
                                                  }
                                                } else {
                                                  setMapFields({
                                                    ...mapFields,
                                                    [`ping_${val.toLowerCase()}`]:
                                                      [
                                                        ...mapFields[
                                                          `ping_${val.toLowerCase()}`
                                                        ],
                                                        {
                                                          key: d,
                                                          map_field:
                                                            value.value,
                                                          id: Date.now().toString(),
                                                          default: 0,
                                                        },
                                                      ],
                                                  });
                                                }
                                              }
                                            }}
                                            defaultOptions={
                                              defaultVerticalFields
                                            }
                                            control={control}
                                            placeholder="Select"
                                            name={`${buttonValue1}_${val}_${d}`}

                                            // invalid={true}
                                          />
                                        </HStack>
                                      </HStack>
                                    </HStack>
                                  ))}
                                </>
                              );
                            })}
                          </ScrollView>
                        </>
                      )
                  : select.file_type === "xml"
                  ? pingMapFields &&
                    pingMapFields["children"] && (
                      <>
                        <HStack
                          bgColor={"#F9FAFB"}
                          px={10}
                          width={"100%"}
                          height={ah(60)}
                          // bgColor="amber.100"
                          alignItems={"center"}
                          style={{
                            borderWidth: aw(1),
                            borderColor: "#D7D7D7",
                            borderTopRightRadius: aw(10),
                            borderTopLeftRadius: aw(10),
                          }}
                        >
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            ml={aw(20)}
                            color="#121233"
                            width={aw(400)}
                          >
                            Group
                          </Text>
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            ml={aw(20)}
                            color="#121233"
                            width={aw(400)}
                          >
                            Buyer Field
                          </Text>
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            ml={aw(20)}
                            color="#121233"
                            width={aw(400)}
                          >
                            Map Field
                          </Text>
                        </HStack>
                        <ScrollView
                          width={"100%"}
                          maxHeight={aw(340)}
                          // showsVerticalScrollIndicator={false}
                          borderColor="#D7D7D7"
                          style={{
                            borderBottomRightRadius: aw(10),
                            borderBottomLeftRadius: aw(10),
                            borderTopWidth: "0px",
                            borderColor: "#D7D7D7",
                            borderWidth: aw(1),
                          }}
                        >
                          {pingMapFields &&
                            pingMapFields["children"] &&
                            pingMapFields["children"].map((val, index) => {
                              if (val["children"]) {
                                return (
                                  <>
                                    <HStack
                                      px={10}
                                      width={"100%"}
                                      height={ah(60)}
                                      // bgColor="amber.100"
                                      alignItems={"center"}
                                      style={{
                                        borderWidth: aw(1),
                                        borderColor: "#D7D7D7",
                                        borderTopWidth: 0,
                                        borderLeftWidth: 0,
                                        borderRightWidth: 0,
                                      }}
                                      bgColor={"#F9FAFB"}
                                    >
                                      <Text
                                        color="black"
                                        width={aw(400)}
                                        fontFamily={"RobotoRegular"}
                                        pl={aw(20)}
                                      >
                                        {val.name}
                                      </Text>
                                    </HStack>
                                    {val["children"].map((d, i) => (
                                      <HStack
                                        style={{
                                          borderWidth: aw(1),
                                          borderColor: "#D7D7D7",
                                          borderTopWidth: 0,
                                          borderLeftWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: aw(1),
                                        }}
                                        px={10}
                                        width={"100%"}
                                        height={ah(60)}
                                        // bgColor="amber.200"
                                        alignItems={"center"}
                                      >
                                        <Text
                                          color="black"
                                          width={aw(400)}
                                        ></Text>
                                        <Text
                                          color="black"
                                          width={aw(400)}
                                          px={10}
                                          fontFamily={"RobotoRegular"}
                                        >
                                          {d.name}
                                        </Text>
                                        <HStack width={aw(400)}>
                                          <HStack width={aw(250)}>
                                            {/* <LeadsWatchSelect
                                              notToLowerCase={true}
                                              name={`${buttonValue1}_${val.name}_${d.name}`}
                                              control={control}
                                              accessibilityLabel={"map fields "}
                                              list={
                                                allVerticalFields &&
                                                allVerticalFields.list
                                                  ? allVerticalFields.list.map(
                                                      (val) => val.name
                                                    )
                                                  : []
                                              }
                                              handleChange={(value) => {
                                                if (buttonValue1 === "post") {
                                                  if (
                                                    mapFields[
                                                      val["name"].toLowerCase()
                                                    ] &&
                                                    mapFields[
                                                      val["name"].toLowerCase()
                                                    ].length
                                                  ) {
                                                    if (
                                                      mapFields[
                                                        val.name.toLowerCase()
                                                      ].find(
                                                        (item) =>
                                                          d[
                                                            "name"
                                                          ].toLowerCase() ===
                                                          item.key
                                                      )
                                                    ) {
                                                      const data = mapFields[
                                                        val.name.toLowerCase()
                                                      ].map((item) => {
                                                        if (
                                                          d[
                                                            "name"
                                                          ].toLowerCase() ===
                                                          item.key
                                                        ) {
                                                          return {
                                                            ...item,
                                                            map_field: value,
                                                          };
                                                        } else {
                                                          return item;
                                                        }
                                                      });

                                                      setMapFields({
                                                        ...mapFields,
                                                        [val.name.toLowerCase()]:
                                                          data,
                                                      });
                                                    } else {
                                                      setMapFields({
                                                        ...mapFields,
                                                        [val[
                                                          "name"
                                                        ].toLowerCase()]: [
                                                          ...mapFields[
                                                            val[
                                                              "name"
                                                            ].toLowerCase()
                                                          ],
                                                          {
                                                            key: d["name"],
                                                            map_field: value,
                                                            id: Date.now().toString(),
                                                            default: 0,
                                                          },
                                                        ],
                                                      });
                                                    }
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [val[
                                                        "name"
                                                      ].toLowerCase()]: [
                                                        ...mapFields[
                                                          val[
                                                            "name"
                                                          ].toLowerCase()
                                                        ],
                                                        {
                                                          key: d["name"],
                                                          map_field: value,
                                                          id: Date.now().toString(),
                                                          default: 0,
                                                        },
                                                      ],
                                                    });
                                                  }
                                                } else {
                                                  if (
                                                    mapFields[
                                                      `ping_${val.name.toLowerCase()}`
                                                    ] &&
                                                    mapFields[
                                                      `ping_${val.name.toLowerCase()}`
                                                    ].length
                                                  ) {
                                                    if (
                                                      mapFields[
                                                        `ping_${val.name.toLowerCase()}`
                                                      ].find(
                                                        (item) =>
                                                          d[
                                                            "name"
                                                          ].toLowerCase() ===
                                                          item.key
                                                      )
                                                    ) {
                                                      const data = mapFields[
                                                        `ping_${val.name.toLowerCase()}`
                                                      ].map((item) => {
                                                        if (
                                                          d[
                                                            "name"
                                                          ].toLowerCase() ===
                                                          item.key
                                                        ) {
                                                          return {
                                                            ...item,
                                                            map_field: value,
                                                          };
                                                        } else {
                                                          return item;
                                                        }
                                                      });

                                                      setMapFields({
                                                        ...mapFields,
                                                        [`ping_${val.name.toLowerCase()}`]:
                                                          data,
                                                      });
                                                    } else {
                                                      setMapFields({
                                                        ...mapFields,
                                                        [`ping_${val.name.toLowerCase()}`]:
                                                          [
                                                            ...mapFields[
                                                              `ping_${val.name.toLowerCase()}`
                                                            ],
                                                            {
                                                              key: d["name"],
                                                              map_field: value,
                                                              id: Date.now().toString(),
                                                              default: 0,
                                                            },
                                                          ],
                                                      });
                                                    }
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [`ping_${val.name.toLowerCase()}`]:
                                                        [
                                                          ...mapFields[
                                                            `ping_${val.name.toLowerCase()}`
                                                          ],
                                                          {
                                                            key: d["name"],
                                                            map_field: value,
                                                            id: Date.now().toString(),
                                                            default: 0,
                                                          },
                                                        ],
                                                    });
                                                  }
                                                }
                                              }}
                                              width={aw(250)}
                                              height={ah(40)}
                                              placeholder="select"
                                            /> */}
                                            <LeadsWatchAsyncSelect
                                              //  isClearable={true}
                                              required={true}
                                              minHeight={37}
                                              minWidth={aw(250)}
                                              type="async"
                                              loadOptions={(
                                                inputValues,
                                                callback
                                              ) =>
                                                loadOptions(
                                                  inputValues,
                                                  callback,
                                                  "verticalFields"
                                                )
                                              }
                                              onInputChange={async (
                                                inputValues
                                              ) => {
                                                if (inputValues === "") {
                                                  try {
                                                    await dispatch(
                                                      getAllVerticalFields(
                                                        vfCurrentPage,
                                                        vfCount,
                                                        select.vertical_id
                                                      )
                                                    );
                                                  } catch (err) {
                                                    console.log(err.message);
                                                  }
                                                }
                                              }}
                                              onBlur={async () => {
                                                try {
                                                  await dispatch(
                                                    getAllVerticalFields(
                                                      vfCurrentPage,
                                                      vfCount,
                                                      select.vertical_id
                                                    )
                                                  );
                                                } catch (err) {
                                                  console.log(err.message);
                                                }
                                              }}
                                              handleChange={(value) => {
                                                if (buttonValue1 === "post") {
                                                  if (
                                                    mapFields[
                                                      val["name"].toLowerCase()
                                                    ] &&
                                                    mapFields[
                                                      val["name"].toLowerCase()
                                                    ].length
                                                  ) {
                                                    if (
                                                      mapFields[
                                                        val.name.toLowerCase()
                                                      ].find(
                                                        (item) =>
                                                          d["name"] === item.key
                                                      )
                                                    ) {
                                                      const data = mapFields[
                                                        val.name.toLowerCase()
                                                      ].map((item) => {
                                                        if (
                                                          d["name"] === item.key
                                                        ) {
                                                          return {
                                                            ...item,
                                                            map_field:
                                                              value.value,
                                                          };
                                                        } else {
                                                          return item;
                                                        }
                                                      });

                                                      setMapFields({
                                                        ...mapFields,
                                                        [val.name.toLowerCase()]:
                                                          data,
                                                      });
                                                    } else {
                                                      setMapFields({
                                                        ...mapFields,
                                                        [val[
                                                          "name"
                                                        ].toLowerCase()]: [
                                                          ...mapFields[
                                                            val[
                                                              "name"
                                                            ].toLowerCase()
                                                          ],
                                                          {
                                                            key: d["name"],
                                                            map_field:
                                                              value.value,
                                                            id: Date.now().toString(),
                                                            default: 0,
                                                          },
                                                        ],
                                                      });
                                                    }
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [val[
                                                        "name"
                                                      ].toLowerCase()]: [
                                                        ...mapFields[
                                                          val[
                                                            "name"
                                                          ].toLowerCase()
                                                        ],
                                                        {
                                                          key: d["name"],
                                                          map_field:
                                                            value.value,
                                                          id: Date.now().toString(),
                                                          default: 0,
                                                        },
                                                      ],
                                                    });
                                                  }
                                                } else {
                                                  if (
                                                    mapFields[
                                                      `ping_${val.name.toLowerCase()}`
                                                    ] &&
                                                    mapFields[
                                                      `ping_${val.name.toLowerCase()}`
                                                    ].length
                                                  ) {
                                                    if (
                                                      mapFields[
                                                        `ping_${val.name.toLowerCase()}`
                                                      ].find(
                                                        (item) =>
                                                          d["name"] === item.key
                                                      )
                                                    ) {
                                                      const data = mapFields[
                                                        `ping_${val.name.toLowerCase()}`
                                                      ].map((item) => {
                                                        if (
                                                          d["name"] === item.key
                                                        ) {
                                                          return {
                                                            ...item,
                                                            map_field:
                                                              value.value,
                                                          };
                                                        } else {
                                                          return item;
                                                        }
                                                      });

                                                      setMapFields({
                                                        ...mapFields,
                                                        [`ping_${val.name.toLowerCase()}`]:
                                                          data,
                                                      });
                                                    } else {
                                                      setMapFields({
                                                        ...mapFields,
                                                        [`ping_${val.name.toLowerCase()}`]:
                                                          [
                                                            ...mapFields[
                                                              `ping_${val.name.toLowerCase()}`
                                                            ],
                                                            {
                                                              key: d["name"],
                                                              map_field:
                                                                value.value,
                                                              id: Date.now().toString(),
                                                              default: 0,
                                                            },
                                                          ],
                                                      });
                                                    }
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [`ping_${val.name.toLowerCase()}`]:
                                                        [
                                                          ...mapFields[
                                                            `ping_${val.name.toLowerCase()}`
                                                          ],
                                                          {
                                                            key: d["name"],
                                                            map_field:
                                                              value.value,
                                                            id: Date.now().toString(),
                                                            default: 0,
                                                          },
                                                        ],
                                                    });
                                                  }
                                                }
                                              }}
                                              defaultOptions={
                                                defaultVerticalFields
                                              }
                                              control={control}
                                              placeholder="Select"
                                              name={`${buttonValue1}_${val.name}_${d.name}`}

                                              // invalid={true}
                                            />
                                          </HStack>
                                        </HStack>
                                      </HStack>
                                    ))}
                                  </>
                                );
                              }
                            })}
                        </ScrollView>
                      </>
                    )
                  : pingMapFields &&
                    Object.keys(pingMapFields).length > 0 && (
                      <>
                        <HStack
                          bgColor={"#F9FAFB"}
                          px={10}
                          width={"100%"}
                          height={ah(60)}
                          // bgColor="amber.100"
                          alignItems={"center"}
                          style={{
                            borderWidth: aw(1),
                            borderColor: "#D7D7D7",
                            borderTopRightRadius: aw(10),
                            borderTopLeftRadius: aw(10),
                          }}
                        >
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            ml={aw(20)}
                            color="#121233"
                            width={aw(400)}
                          >
                            Group
                          </Text>
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            ml={aw(20)}
                            color="#121233"
                            width={aw(400)}
                          >
                            Buyer Field
                          </Text>
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            ml={aw(20)}
                            color="#121233"
                            width={aw(400)}
                          >
                            Map Field
                          </Text>
                        </HStack>
                        <ScrollView
                          maxHeight={aw(340)}
                          showsVerticalScrollIndicator={false}
                          style={{
                            borderBottomRightRadius: aw(10),
                            borderBottomLeftRadius: aw(10),
                            borderTopWidth: "0px",
                            borderColor: "#D7D7D7",
                            borderWidth: aw(1),
                          }}
                        >
                          {Object.keys(pingMapFields).map((val, index) => {
                            return (
                              <>
                                <HStack
                                  px={10}
                                  width={"100%"}
                                  height={ah(60)}
                                  // bgColor="amber.100"
                                  alignItems={"center"}
                                  style={{
                                    borderWidth: aw(1),
                                    borderColor: "#D7D7D7",
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    borderRightWidth: 0,
                                  }}
                                  bgColor={"#F9FAFB"}
                                >
                                  <Text
                                    color="black"
                                    width={aw(400)}
                                    fontFamily={"RobotoRegular"}
                                    pl={aw(20)}
                                  >
                                    {val}
                                  </Text>
                                </HStack>

                                {Object.keys(pingMapFields[val]).map(
                                  (d, index) => (
                                    <HStack
                                      style={{
                                        borderWidth: aw(1),
                                        borderColor: "#D7D7D7",
                                        borderTopWidth: 0,
                                        borderLeftWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: aw(1),
                                      }}
                                      px={10}
                                      width={"100%"}
                                      height={ah(60)}
                                      // bgColor="amber.200"
                                      alignItems={"center"}
                                    >
                                      <Text
                                        color="black"
                                        width={aw(400)}
                                        fontFamily={"RobotoRegular"}
                                      ></Text>
                                      <Text
                                        color="black"
                                        width={aw(400)}
                                        px={10}
                                        fontFamily={"RobotoRegular"}
                                      >
                                        {d}
                                      </Text>
                                      <HStack width={aw(400)}>
                                        <HStack width={aw(250)}>
                                          {/* <LeadsWatchSelect
                                            notToLowerCase={true}
                                            name={`${buttonValue1}_${val}_${d}`}
                                            control={control}
                                            accessibilityLabel={"map fields "}
                                            list={
                                              allVerticalFields &&
                                              allVerticalFields.list
                                                ? allVerticalFields.list.map(
                                                    (val) => val.name
                                                  )
                                                : []
                                            }
                                            handleChange={(value) => {
                                              if (buttonValue1 === "post") {
                                                if (
                                                  val.toLowerCase() &&
                                                  mapFields[val.toLowerCase()]
                                                    .length
                                                ) {
                                                  if (
                                                    mapFields[
                                                      val.toLowerCase()
                                                    ].find(
                                                      (item) =>
                                                        d.toLowerCase() ===
                                                        item.key
                                                    )
                                                  ) {
                                                    const data = mapFields[
                                                      val.toLowerCase()
                                                    ].map((item) => {
                                                      if (
                                                        d.toLowerCase() ===
                                                        item.key
                                                      ) {
                                                        return {
                                                          ...item,
                                                          map_field: value,
                                                        };
                                                      } else {
                                                        return item;
                                                      }
                                                    });

                                                    setMapFields({
                                                      ...mapFields,
                                                      [val.toLowerCase()]: data,
                                                    });
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [val.toLowerCase()]: [
                                                        ...mapFields[
                                                          val.toLowerCase()
                                                        ],
                                                        {
                                                          key: d,
                                                          map_field: value,
                                                          id: Date.now().toString(),
                                                          default: 0,
                                                        },
                                                      ],
                                                    });
                                                  }
                                                } else {
                                                  setMapFields({
                                                    ...mapFields,
                                                    [val.toLowerCase()]: [
                                                      ...mapFields[
                                                        val.toLowerCase()
                                                      ],
                                                      {
                                                        key: d,
                                                        map_field: value,
                                                        id: Date.now().toString(),
                                                        default: 0,
                                                      },
                                                    ],
                                                  });
                                                }
                                              } else {
                                                if (
                                                  mapFields[
                                                    `ping_${val.toLowerCase()}`
                                                  ] &&
                                                  mapFields[
                                                    `ping_${val.toLowerCase()}`
                                                  ].length
                                                ) {
                                                  if (
                                                    mapFields[
                                                      `ping_${val.toLowerCase()}`
                                                    ].find(
                                                      (item) =>
                                                        d.toLowerCase() ===
                                                        item.key
                                                    )
                                                  ) {
                                                    const data = mapFields[
                                                      `ping_${val.toLowerCase()}`
                                                    ].map((item) => {
                                                      if (
                                                        d.toLowerCase() ===
                                                        item.key
                                                      ) {
                                                        return {
                                                          ...item,
                                                          map_field: value,
                                                        };
                                                      } else {
                                                        return item;
                                                      }
                                                    });

                                                    setMapFields({
                                                      ...mapFields,
                                                      [`ping_${val.toLowerCase()}`]:
                                                        data,
                                                    });
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [`ping_${val.toLowerCase()}`]:
                                                        [
                                                          ...mapFields[
                                                            `ping_${val.toLowerCase()}`
                                                          ],
                                                          {
                                                            key: d,
                                                            map_field: value,
                                                            id: Date.now().toString(),
                                                            default: 0,
                                                          },
                                                        ],
                                                    });
                                                  }
                                                } else {
                                                  setMapFields({
                                                    ...mapFields,
                                                    [`ping_${val.toLowerCase()}`]:
                                                      [
                                                        ...mapFields[
                                                          `ping_${val.toLowerCase()}`
                                                        ],
                                                        {
                                                          key: d,
                                                          map_field: value,
                                                          id: Date.now().toString(),
                                                          default: 0,
                                                        },
                                                      ],
                                                  });
                                                }
                                              }
                                            }}
                                            width={aw(250)}
                                            height={ah(40)}
                                            placeholder="select"
                                          /> */}
                                          <LeadsWatchAsyncSelect
                                            //  isClearable={true}
                                            required={true}
                                            minHeight={37}
                                            minWidth={aw(250)}
                                            type="async"
                                            loadOptions={(
                                              inputValues,
                                              callback
                                            ) =>
                                              loadOptions(
                                                inputValues,
                                                callback,
                                                "verticalFields"
                                              )
                                            }
                                            onInputChange={async (
                                              inputValues
                                            ) => {
                                              if (inputValues === "") {
                                                try {
                                                  await dispatch(
                                                    getAllVerticalFields(
                                                      vfCurrentPage,
                                                      vfCount,
                                                      select.vertical_id
                                                    )
                                                  );
                                                } catch (err) {
                                                  console.log(err.message);
                                                }
                                              }
                                            }}
                                            onBlur={async () => {
                                              try {
                                                await dispatch(
                                                  getAllVerticalFields(
                                                    vfCurrentPage,
                                                    vfCount,
                                                    select.vertical_id
                                                  )
                                                );
                                              } catch (err) {
                                                console.log(err.message);
                                              }
                                            }}
                                            handleChange={(value) => {
                                              if (buttonValue1 === "post") {
                                                if (
                                                  val.toLowerCase() &&
                                                  mapFields[val.toLowerCase()]
                                                    .length
                                                ) {
                                                  if (
                                                    mapFields[
                                                      val.toLowerCase()
                                                    ].find(
                                                      (item) => d === item.key
                                                    )
                                                  ) {
                                                    const data = mapFields[
                                                      val.toLowerCase()
                                                    ].map((item) => {
                                                      if (d === item.key) {
                                                        return {
                                                          ...item,
                                                          map_field:
                                                            value.value,
                                                        };
                                                      } else {
                                                        return item;
                                                      }
                                                    });

                                                    setMapFields({
                                                      ...mapFields,
                                                      [val.toLowerCase()]: data,
                                                    });
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [val.toLowerCase()]: [
                                                        ...mapFields[
                                                          val.toLowerCase()
                                                        ],
                                                        {
                                                          key: d,
                                                          map_field:
                                                            value.value,
                                                          id: Date.now().toString(),
                                                          default: 0,
                                                        },
                                                      ],
                                                    });
                                                  }
                                                } else {
                                                  setMapFields({
                                                    ...mapFields,
                                                    [val.toLowerCase()]: [
                                                      ...mapFields[
                                                        val.toLowerCase()
                                                      ],
                                                      {
                                                        key: d,
                                                        map_field: value.value,
                                                        id: Date.now().toString(),
                                                        default: 0,
                                                      },
                                                    ],
                                                  });
                                                }
                                              } else {
                                                if (
                                                  mapFields[
                                                    `ping_${val.toLowerCase()}`
                                                  ] &&
                                                  mapFields[
                                                    `ping_${val.toLowerCase()}`
                                                  ].length
                                                ) {
                                                  if (
                                                    mapFields[
                                                      `ping_${val.toLowerCase()}`
                                                    ].find(
                                                      (item) => d === item.key
                                                    )
                                                  ) {
                                                    const data = mapFields[
                                                      `ping_${val.toLowerCase()}`
                                                    ].map((item) => {
                                                      if (d === item.key) {
                                                        return {
                                                          ...item,
                                                          map_field:
                                                            value.value,
                                                        };
                                                      } else {
                                                        return item;
                                                      }
                                                    });

                                                    setMapFields({
                                                      ...mapFields,
                                                      [`ping_${val.toLowerCase()}`]:
                                                        data,
                                                    });
                                                  } else {
                                                    setMapFields({
                                                      ...mapFields,
                                                      [`ping_${val.toLowerCase()}`]:
                                                        [
                                                          ...mapFields[
                                                            `ping_${val.toLowerCase()}`
                                                          ],
                                                          {
                                                            key: d,
                                                            map_field:
                                                              value.value,
                                                            id: Date.now().toString(),
                                                            default: 0,
                                                          },
                                                        ],
                                                    });
                                                  }
                                                } else {
                                                  setMapFields({
                                                    ...mapFields,
                                                    [`ping_${val.toLowerCase()}`]:
                                                      [
                                                        ...mapFields[
                                                          `ping_${val.toLowerCase()}`
                                                        ],
                                                        {
                                                          key: d,
                                                          map_field:
                                                            value.value,
                                                          id: Date.now().toString(),
                                                          default: 0,
                                                        },
                                                      ],
                                                  });
                                                }
                                              }
                                            }}
                                            defaultOptions={
                                              defaultVerticalFields
                                            }
                                            control={control}
                                            placeholder="Select"
                                            // name={`${buttonValue1}_${val.name}_${d.name}`}
                                            name={`${buttonValue1}_${val}_${d}`}

                                            // invalid={true}
                                          />
                                        </HStack>
                                      </HStack>
                                    </HStack>
                                  )
                                )}
                              </>
                            );
                          })}
                        </ScrollView>
                      </>
                    )}
              </VStack>
            </>
          )}
        </VStack>
      </LeadsWatchCard>
    </Box>
  ) : (
    <Box marginTop={ah(30)}>
      <LeadsWatchCard
        width={
          props.isOpen
            ? [aw(350), aw(350), aw(1168)]
            : [aw(350), aw(350), aw(1248)]
        }
        height={ah(3000)}
      >
        <VStack pb={6} width={"100%"} px={aw(15)}>
          <HStack
            width={"100%"}
            height={ah(50)}
            marginTop={ah(18)}
            justifyContent="space-between"
            alignItems={"baseline"}
          >
            <Text
              style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
              color="#121233"
            >
              Data Mapping
            </Text>
          </HStack>

          <>
            {select.method && select.method === "ping-post" && (
              <HStack
                justifyContent={"center"}
                alignItems={"center"}
                space={aw(10)}
                mb={ah(30)}
              >
                {["ping", "post"].map((val) => (
                  <HStack>
                    <LeadsWatchButton
                      bg={buttonValue1 === val ? "#0000FF" : "#FAFBFD"}
                      label={val}
                      style={{ width: aw(150) }}
                      onPress={() => setButtonValue1(val)}
                      color={buttonValue1 === val ? "#FAFBFD" : "#212121"}
                    />
                  </HStack>
                ))}
              </HStack>
            )}
            {select.method &&
              select.method === "ping-post" &&
              buttonValue1 === "post" && (
                <HStack
                  justifyContent={"center"}
                  alignItems={"center"}
                  space={aw(10)}
                  mb={ah(30)}
                >
                  <HStack>
                    <LeadsWatchButton
                      // bg={"#0000FF"}
                      label={"clone from ping"}
                      style={{ width: aw(150) }}
                      onPress={handleClone}
                      // color={"#FAFBFD"}
                      _hover={{
                        bg: "#0000FF",
                        color: "#FAFBFD",
                        _text: {
                          color: "#FAFBFD",
                        },
                      }}
                    />
                  </HStack>
                </HStack>
              )}

            <VStack
              width={"100%"}
              borderRadius={"10px"}
              borderColor={"#D7D7D7"}
            >
              <HStack width={"100%"}>
                {["params", "body", "header", "auth"].map((val) => (
                  <Pressable
                    key={val}
                    width={"25%"}
                    height={ah(60)}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderWidth={buttonValue === val ? 1 : 0}
                    borderColor={"#D7D7D7"}
                    borderBottomWidth={buttonValue === val ? 0 : aw(1)}
                    borderTopLeftRadius={aw(10)}
                    borderTopRightRadius={aw(10)}
                    bg={buttonValue === val ? "#F9FAFB" : "#FFFFFF"}
                    onPress={() => setButtonValue(val)}
                  >
                    <Text
                      alignSelf={"center"}
                      style={{
                        fontFamily: "RobotoMedium",
                        fontSize: aw(18),
                      }}
                      color="#121233"
                      pl={1}
                    >
                      {val.charAt(0).toUpperCase() +
                        val.slice(1).replace("_", " ")}
                    </Text>
                  </Pressable>
                ))}
              </HStack>
              <HStack
                alignItems={"center"}
                style={{
                  height: ah(60),
                  background: "#F9FAFB 0% 0% no-repeat padding-box",
                  // border: "1px solid #D7D7D7",
                  borderWidth: aw(1),
                  borderColor: "#D7D7D7",
                  borderTopWidth: 0,
                }}
              >
                {["Buyer Field", "Map Field", "Actions"].map((val) => {
                  return (
                    <HStack
                      width={aw(500)}
                      alignItems={"center"}
                      justifyContent="flex-start"
                      key={val}
                    >
                      {val === "Actions" ? (
                        <Text
                          width={aw(200)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          ml={aw(20)}
                          color="#121233"
                        >
                          {val}
                        </Text>
                      ) : (
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          ml={aw(20)}
                          color="#121233"
                        >
                          {val}
                        </Text>
                      )}
                    </HStack>
                  );
                })}
              </HStack>
              <ScrollView
                maxHeight={ah(200)}
                // showsVerticalScrollIndicator={false}
                style={{
                  borderBottomRightRadius: aw(10),
                  borderBottomLeftRadius: aw(10),
                  borderbottomWidth: "1px solid #D7D7D7",
                  borderTopWidth: "0px",
                  borderColor: "#D7D7D7",
                  borderWidth: "1px",
                }}
              >
                <FlatList
                  ListEmptyComponent={
                    <HStack
                      justifyContent="center"
                      alignItems={"center"}
                      height={ah(100)}
                    >
                      <Text
                        color="black"
                        fontSize={aw(14)}
                        fontFamily={"RobotoRegular"}
                      >
                        No data found
                      </Text>
                    </HStack>
                  }
                  data={
                    buttonValue1 === "post"
                      ? mapFields[buttonValue]
                      : mapFields[`${buttonValue1}_${buttonValue}`]
                  }
                  keyExtractor={(item, index) => index}
                  renderItem={({ item, index }) => (
                    <HStack
                      height={ah(50)}
                      style={{
                        borderTopWidth: index === 0 ? 0 : aw(1),
                        borderLeftWidth: "0px",
                        borderRightWidth: "0px",
                        borderBottomWidth: "0px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      {editId === item.id ? (
                        <HStack
                          width={aw(500)}
                          alignItems={"center"}
                          justifyContent="flex-start"
                          pl={aw(20)}
                        >
                          <LeadsWatchInput
                            control={control1}
                            name={"edit_buyer_field"}
                            style={{ width: aw(250), height: ah(40) }}
                            rules={{
                              required: {
                                value: true,
                              },
                            }}
                            invalid={errors1["edit_buyer_field"] ? true : false}
                            error={
                              errors1["edit_buyer_field"]
                                ? errors1["edit_buyer_field"].message
                                : ""
                            }
                          />
                        </HStack>
                      ) : (
                        <HStack
                          width={aw(500)}
                          alignItems={"center"}
                          justifyContent="flex-start"
                        >
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoRegular"}
                            ml={aw(20)}
                            color="#121233"
                          >
                            {item["key"]}
                          </Text>
                        </HStack>
                      )}
                      {editId === item.id ? (
                        item.default === 0 ? (
                          <HStack width={aw(500)} pt={1}>
                            <HStack width={aw(250)}>
                              <LeadsWatchAsyncSelect
                                required={true}
                                minHeight={34}
                                maxHeight={34}
                                minWidth={aw(250)}
                                type="async"
                                loadOptions={(inputValues, callback) =>
                                  loadOptions(
                                    inputValues,
                                    callback,
                                    "verticalFields"
                                  )
                                }
                                onInputChange={async (inputValues) => {
                                  if (inputValues === "") {
                                    try {
                                      await dispatch(
                                        getAllVerticalFields(
                                          vfCurrentPage,
                                          vfCount,
                                          select.vertical_id
                                        )
                                      );
                                    } catch (err) {
                                      console.log(err.message);
                                    }
                                  }
                                }}
                                onBlur={async () => {
                                  try {
                                    await dispatch(
                                      getAllVerticalFields(
                                        vfCurrentPage,
                                        vfCount,
                                        select.vertical_id
                                      )
                                    );
                                  } catch (err) {
                                    console.log(err.message);
                                  }
                                }}
                                defaultOptions={defaultVerticalFields}
                                control={control1}
                                placeholder="Select"
                                name={"edit_map_field"}
                                rules={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                invalid={
                                  errors1["edit_map_field"] ? true : false
                                }
                                error={
                                  errors1["edit_map_field"]
                                    ? errors1["edit_map_field"].message
                                    : ""
                                }
                                // invalid={true}
                              />
                            </HStack>
                          </HStack>
                        ) : (
                          <HStack
                            width={aw(500)}
                            alignItems={"center"}
                            justifyContent="flex-start"
                          >
                            <LeadsWatchInput
                              control={control1}
                              name={"edit_map_field"}
                              style={{
                                width: aw(250),
                                height: ah(40),
                              }}
                              rules={{
                                required: {
                                  value: true,
                                },
                              }}
                              invalid={errors1["edit_map_field"] ? true : false}
                              error={
                                errors1["edit_map_field"]
                                  ? errors1["edit_map_field"].message
                                  : ""
                              }
                            />
                          </HStack>
                        )
                      ) : (
                        <HStack
                          width={aw(500)}
                          alignItems={"center"}
                          justifyContent="flex-start"
                        >
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoRegular"}
                            ml={aw(20)}
                            color="#121233"
                          >
                            {/* {item["map_field"]} */}
                            {item.default === 0
                              ? item["map_field"]
                              : item["value"]}
                          </Text>
                        </HStack>
                      )}
                      {editId === item.id ? (
                        <HStack
                          pl={5}
                          width={aw(500)}
                          alignItems={"center"}
                          justifyContent="flex-start"
                          space={aw(5)}
                        >
                          <Pressable onPress={handleSubmit1(handleEditField)}>
                            <LeadsWatchApproveIcon />
                          </Pressable>
                          <Pressable
                            onPress={() => {
                              setEditId("");
                            }}
                          >
                            <LeadsWatchCrossIcon />
                            {/*  */}
                            {/* <LeadsWatchCrossIcon /> */}
                          </Pressable>
                        </HStack>
                      ) : (
                        <HStack
                          pl={5}
                          width={aw(500)}
                          alignItems={"center"}
                          justifyContent="flex-start"
                        >
                          <Pressable onPress={() => handleEdit(item)}>
                            <LeadsWatchEditIcon />
                          </Pressable>
                          <Pressable onPress={() => handleDelete(item.id)}>
                            <LeadsWatchDeleteIcon />
                          </Pressable>
                        </HStack>
                      )}
                    </HStack>
                  )}
                />
              </ScrollView>
            </VStack>
            {editId === "" && (
              <>
                <HStack mt={ah(20)}>
                  <HStack
                    width={"100%"}
                    alignItems={"center"}
                    style={{
                      height: ah(60),
                      background: "#F9FAFB 0% 0% no-repeat padding-box",
                      border: "1px solid #D7D7D7",
                      borderTopLeftRadius: aw(10),
                      borderTopRightRadius: aw(10),
                    }}
                  >
                    {["Buyer Field", "Map Field", "Map Field Type"].map(
                      (val) => (
                        <HStack
                          key={val}
                          width="33%"
                          alignItems={"center"}
                          justifyContent="flex-start"
                        >
                          <Text
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            ml={aw(20)}
                            color="#121233"
                          >
                            {val}
                          </Text>
                        </HStack>
                      )
                    )}
                  </HStack>
                </HStack>
                <HStack
                  width={"100%"}
                  alignItems={"center"}
                  style={{
                    height: ah(60),
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #D7D7D7",
                    borderTopWidth: "0px",
                    borderBottomLeftRadius: aw(10),
                    borderBottomRightRadius: aw(10),
                  }}
                >
                  <HStack width={"33%"} px={2}>
                    <LeadsWatchInput
                      control={control2}
                      name={"buyer_field"}
                      style={{ width: "80%", height: ah(40) }}
                      rules={{
                        required: {
                          value: true,
                        },
                      }}
                      invalid={errors2["buyer_field"] ? true : false}
                      // error={
                      //   errors2["buyer_field"]
                      //     ? errors2["buyer_field"].message
                      //     : ""
                      // }
                    />
                  </HStack>
                  <HStack width={"33%"}>
                    {!toogle ? (
                      <Flex width="80%">
                        <LeadsWatchAsyncSelect
                          //  isClearable={true}
                          required={true}
                          minHeight={37}
                          minWidth={"100%"}
                          type="async"
                          loadOptions={(inputValues, callback) =>
                            loadOptions(inputValues, callback, "verticalFields")
                          }
                          onInputChange={async (inputValues) => {
                            if (inputValues === "") {
                              try {
                                await dispatch(
                                  getAllVerticalFields(
                                    vfCurrentPage,
                                    vfCount,
                                    select.vertical_id
                                  )
                                );
                              } catch (err) {
                                console.log(err.message);
                              }
                            }
                          }}
                          onBlur={async () => {
                            try {
                              await dispatch(
                                getAllVerticalFields(
                                  vfCurrentPage,
                                  vfCount,
                                  select.vertical_id
                                )
                              );
                            } catch (err) {
                              console.log(err.message);
                            }
                          }}
                          defaultOptions={defaultVerticalFields}
                          control={control2}
                          placeholder="Select"
                          name={"map_field"}
                          rules={{
                            required: {
                              value: true,
                            },
                          }}
                          invalid={errors2["map_field"] ? true : false}
                          error={
                            errors2["map_field"]
                              ? errors2["map_field"].message
                              : ""
                          }
                          // invalid={true}
                        />
                      </Flex>
                    ) : (
                      <LeadsWatchInput
                        control={control2}
                        name={"map_field"}
                        style={{ width: "80%", height: ah(40) }}
                        rules={{
                          required: {
                            value: true,
                          },
                        }}
                        invalid={errors2["map_field"] ? true : false}
                        error={
                          errors2["map_field"]
                            ? errors2["map_field"].message
                            : ""
                        }
                      />
                    )}
                  </HStack>
                  <HStack width={"33%"} px={aw(50)}>
                    <Switch
                      _hover={{
                        offTrackColor: "#C1C1C1",
                        onTrackColor: "#0000FF",
                      }}
                      onTrackColor="#0000FF"
                      offTrackColor="#C1C1C1"
                      isChecked={toogle}
                      onToggle={() => {
                        setToogle(!toogle);
                        reset2({
                          map_field: "",
                          buyer_field: getValues2("buyer_field"),
                        });
                      }}
                    />
                  </HStack>
                </HStack>

                <HStack
                  justifyContent={"center"}
                  alignItems={"center"}
                  mt={ah(20)}
                >
                  <LeadsWatchButton
                    onPress={handleSubmit2(handleMapFields)}
                    bg={"#0000FF"}
                    label={"add"}
                    style={{ width: aw(150) }}
                    color={"#FAFBFD"}
                  />
                </HStack>
              </>
            )}
          </>
        </VStack>
      </LeadsWatchCard>
    </Box>
  );
};
export default React.memo(DataMapping);
