import React from "react";
import { usePagination, DOTS } from "./usePagination";
import { Text, Stack, Pressable, HStack } from "native-base";
import { ah, aw } from "../utils";
import LeadsWatchLeftArrow from "./svg/LeadsWatchLeftArrow";
import LeadsWatchRightArrow from "./svg/LeadsWatchRightArrow";
import LeadsWatchPrevIcon from "./svg/LeadsWatchPrevIcon";
import LeadsWatchNextIcon from "./svg/LeadsWatchNextIcon";

const LeadsWatchPagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 0,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    currentPage !== lastPage
      ? onPageChange(currentPage + 1)
      : onPageChange(currentPage);
  };

  const onPrevious = () => {
    currentPage !== 1
      ? onPageChange(currentPage - 1)
      : onPageChange(currentPage);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return props.type === "box" ? (
    <HStack justifyContent={"flex-end"} width={props.width}>
      <Pressable
        borderLeftRadius={"5px"}
        // borderRightRadius={"10px"}
        onPress={onPrevious}
        disabled={currentPage === 1 ? true : false}
        // py={ah(3)}
        width={aw(45)}
        height={ah(40)}
        borderWidth={aw(1.5)}
        borderRightWidth={0}
        borderColor="#E6E6E6"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <LeadsWatchPrevIcon disabled={currentPage === 1 ? true : false} />
      </Pressable>
      <Text
        textAlign={"center"}
        lineHeight={ah(40)}
        height={ah(40)}
        width={aw(45)}
        borderWidth={aw(1.5)}
        borderRightWidth={0}
        borderColor="#E6E6E6"
        fontFamily={"RobotoMedium"}
        fontSize={ah(13)}
      >
        {currentPage}
      </Text>
      <Pressable
        alignItems={"center"}
        justifyContent={"center"}
        borderRightRadius={"5px"}
        onPress={onNext}
        width={aw(45)}
        height={ah(40)}
        borderWidth={aw(1.5)}
        // borderRightWidth={0}
        borderColor="#E6E6E6"
      >
        {/* <LeadsWatchLeftArrow
      disabled={currentPage === lastPage ? true : false}
    /> */}
        <LeadsWatchNextIcon
          disabled={currentPage === lastPage ? true : false}
        />
      </Pressable>
    </HStack>
  ) : (
    <HStack
      width={props.width || [aw(350), aw(350), aw(1248)]}
      style={{
        background: "#FFFFFF 0% 0% no-repeat padding-box",

        height: ah(48),
        borderTopColor: "#D7D7D7",
        borderTopWidth: aw(1),
      }}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack>
        <Pressable onPress={onPrevious}>
          <HStack justifyContent={"center"} alignItems={"center"}>
            <LeadsWatchLeftArrow disabled={currentPage === 1 ? true : false} />
            <Text
              fontSize={aw(14)}
              color={currentPage === 1 ? "#c1c1c1" : "#212121"}
              fontFamily="RobotoRegular"
            >
              Previous
            </Text>
          </HStack>
        </Pressable>
      </Stack>
      <HStack
        width={aw(252)}
        height={ah(60)}
        alignItems="center"
        justifyContent={"center"}
      >
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <HStack
                key={index}
                height={ah(48)}
                width={aw(36)}
                alignItems="center"
                justifyContent={"center"}
              >
                <Text
                  // height={ah(28)}
                  // width={aw(36)}
                  fontSize={aw(20)}
                  color="#919191"
                  fontFamily="RobotoMedium"
                >
                  &#8230;
                </Text>
              </HStack>
            );
          }

          return (
            <Pressable onPress={() => onPageChange(pageNumber)} key={index}>
              <Stack
                px={aw(5)}
                alignItems="center"
                justifyContent={"center"}
                height={ah(48)}
                // width={aw(36)}
                width={pageNumber.toString().length < 5 && aw(36)}
                borderTopWidth={aw(2)}
                borderTopColor={
                  pageNumber === currentPage ? "#0000FF" : "transparent"
                }
              >
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoMedium"
                  style={{
                    color: pageNumber === currentPage ? "#0000FF" : "#919191",
                  }}
                  // onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </Text>
              </Stack>
            </Pressable>
          );
        })}
      </HStack>
      <Stack>
        <Pressable onPress={onNext}>
          <HStack justifyContent={"center"} alignItems={"center"}>
            <Text
              fontFamily="RobotoRegular"
              fontSize={aw(14)}
              color={currentPage === lastPage ? "#C1C1C1" : "#212121"}
            >
              Next
            </Text>
            <LeadsWatchRightArrow
              disabled={currentPage === lastPage ? true : false}
            />
          </HStack>
        </Pressable>
      </Stack>
    </HStack>
  );
};

export default LeadsWatchPagination;
