import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllContacts = (page, limit, sort, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/crm/contacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          page: page,
          limit: limit,
          sort: sort,
          filters: filters,
          search: search,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allContacts = resData.data;
        dispatch({ type: events.GET_ALL_CRMCONTACTS, data: allContacts });
        return allContacts ? allContacts : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//get single contact
export const getSingleContact = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/crm/contacts/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const contactDetails = resData.data;
        dispatch({ type: events.GET_SINGLE_CONTACT, data: contactDetails });
        return contactDetails ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//get conatct email
export const getEmails = (id, type, page, limit) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/crm/contacts/${id}/${type}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ page: page, limit: limit }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const emails = resData.data;
        dispatch({ type: events.GET_CONTACT_EMAILS, data: emails });
        return emails ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
//remove emails
export const removeEmails = () => {
  return {
    type: events.REMOVE_EMAILS,
  };
};
//remove contactDetails
export const removeContactDetails = () => {
  return {
    type: events.REMOVE_CONTACT_DETAILS,
  };
};
