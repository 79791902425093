import React, { useRef, useState, useEffect } from "react";
import { Menu, Box, Pressable, Tooltip } from "native-base";
import { aw } from "../utils";

const LeadsWatchMenu = (props) => {
  const menuRef = useRef(null);

  useEffect(() => {
    // Ensure the ref is assigned before accessing its methods
    if (menuRef.current) {
      // menuRef.current._root.close()
      console.log(menuRef.current);
    }
  }, [menuRef]);
  return (
    <Box
    // style={{ ...props.boxStyle }}
    // style={{
    //   shadowOffset: { width: 2, height: 4 },
    //   shadowColor: "red.100",
    //   shadow: 8,
    // }}
    >
      <Menu
        ref={menuRef}
        // position="absolute"
        // placement="bottom"
        // isDisabled={props.disabled}

        placement={props.placement || "bottom"}
        mr={props.mr}
        isOpen={props.isOpen}
        // isOpen={isOpen}
        // mr={aw(8)}
        ml={props.ml}
        mt={props.mt || 0.8}
        mb={props.mb}
        bgColor={props.bg || "white"}
        position={props.position}
        // position="right"
        onOpen={props.onOpen}
        onClose={props.onClose}
        closeOnSelect={props.closeOnSelect || true}
        defaultIsOpen={props.defaultIsOpen || false}
        // shouldOverlapWithTrigger={props.shouldOverlapWithTrigger || false}
        w={props.width || aw(190)}
        // style={{ ...props.menuStyle }}
        trigger={(triggerProps) => {
          return props.disabled ? (
            <Tooltip label={"No Access"}>
              <Pressable
                alignItems={"center"}
                justifyContent={"center"}
                size={props.size}
                accessibilityLabel="More options menu"
                borderWidth={props.borderWidth}
                borderRadius={props.borderRadius}
                borderColor={props.borderColor}
                // {...triggerProps}
                position="relative"
                style={{ cursor: "normal" }}
              >
                {props.title}
              </Pressable>
            </Tooltip>
          ) : (
            <Pressable
              // onMouseEnter={() => setIsopen(true)}
              // onMouseLeave={() => setIsopen(false)}
              alignItems={"center"}
              justifyContent={"center"}
              size={props.size}
              accessibilityLabel="More options menu"
              borderWidth={props.borderWidth}
              borderRadius={props.borderRadius}
              borderColor={props.borderColor}
              {...triggerProps}
              position="relative"
            >
              {props.title}
            </Pressable>
          );
        }}
      >
        {props.children}
      </Menu>
    </Box>
  );
};

export default React.memo(LeadsWatchMenu);

// import React, { useState } from "react";
// import { Box, HamburgerIcon, Menu, Pressable, Text } from "native-base";

// function Example() {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   return (
//     <Box w="90%" alignItems="center">
//       <Menu
//         trigger={(triggerProps) => (
//           <Pressable
//             {...triggerProps}
//             accessibilityLabel="More options menu"
//             onMouseEnter={() => {
//               console.log("hovered", triggerProps);
//               setIsMenuOpen(true);
//               triggerProps.onPress();
//             }}
//           >
//             <HamburgerIcon />
//           </Pressable>
//         )}
//       >
//         <Menu.Item>Arial</Menu.Item>
//         <Menu.Item>Nunito Sans</Menu.Item>
//         <Menu.Item>Roboto</Menu.Item>
//         <Menu.Item>Poppins</Menu.Item>
//         <Menu.Item>SF Pro</Menu.Item>
//         <Menu.Item>Helvetica</Menu.Item>
//         <Menu.Item isDisabled>Sofia</Menu.Item>
//         <Menu.Item>Cookie</Menu.Item>
//       </Menu>
//     </Box>
//   );
// }

// export default Example;
